import { useLocation } from 'react-router-dom';

export function useIsContextualLinkSource(): boolean {
    const search = useLocation().search;
    const isContextualLinkQueryParam = new URLSearchParams(search).get('isContextualLink');

    let isContextualLink = false;
    isContextualLink = isContextualLinkQueryParam === 'true';

    return isContextualLink;
}
