import { useSelector } from 'react-redux';
import UserActionRecommendation from '../UserActionRecommendation';
import { strings } from '../../utils/Strings';
import { RootState } from '../../store/store';
import { ErrorModalMessage } from '../Error/ErrorModalMessage';

export const ModalContent = () => {
    const error = useSelector((state: RootState) => state.error.message);
    const isErrorModal = error ? true : false;

    if (isErrorModal) {
        return <ErrorModalMessage errorMessage={error} />;
    } else {
        return <UserActionRecommendation headingText={strings.login_modal_title} isModal={true} />;
    }
};
