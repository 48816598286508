import { useController, UseControllerProps } from 'react-hook-form';
import { ChangeEvent, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, Node } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Grid, Typography } from '@mui/material';

import {
    IMultiChoiceOption,
    IQuizQuestionUserResponseChoice,
    QuizWriteInConfig,
} from '../../../models/QuizTransactionService';
import { MultiChoiceInput } from './MultiChoiceInput';
import { IRationaleInformation } from '../../../models/Primitives';
import colors from '../../../config/colorConfig';
import { strings } from '../../../utils/Strings';
import { frontMatterRenderOptions } from '../frontmatter/renderOptions';

export interface MultiChoiceOptionsProps extends UseControllerProps {
    options: IMultiChoiceOption[];
    type: string;
    readOnly: boolean;
    rationale?: null | IRationaleInformation;
}

export function MultiChoiceOptions({
    options,
    type,
    control,
    name,
    readOnly,
    rationale,
}: MultiChoiceOptionsProps) {
    const { field } = useController({
        control,
        name,
    });

    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState<any>(field.value);

    // variable for the string value to be used for radio and checkbox inputs
    let answerValue: string = '';

    const handleRadioChange =
        (optionId: string, isWriteIn: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
            if (readOnly) return;
            const reactFormValue = [
                e.target.checked
                    ? {
                          optionId: optionId,
                          optionValue: e.target.value,
                          ...(isWriteIn && { hasWriteInValue: true }),
                      }
                    : null,
            ];
            // send data to react hook form
            field.onChange(reactFormValue);
        };

    const handleCheckBoxChange =
        (optionId: string, isWriteIn: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
            if (readOnly) return;
            let updatedArray = selectedCheckBoxes;

            if (e.target.checked) {
                updatedArray.push({
                    optionId: optionId,
                    optionValue: e.target.value,
                    ...(isWriteIn && { hasWriteInValue: true }),
                });
            } else {
                updatedArray = updatedArray.filter(
                    (item: IQuizQuestionUserResponseChoice) => item.optionValue !== e.target.value
                );
            }

            // send data to react hook form
            field.onChange(updatedArray);

            // update local state
            setSelectedCheckBoxes(updatedArray);
        };

    if (!options) {
        return <></>;
    }

    const getRenderOption = (option: IMultiChoiceOption) => {
        return documentToReactComponents(option.text.contentfulRichText as Document, {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node: Node) => {
                    answerValue = documentToPlainTextString(node as Document);

                    const answerLabel = documentToReactComponents(node as Document, {
                        renderNode: {
                            [BLOCKS.PARAGRAPH]: (_node, _children) => {
                                return (
                                    <label
                                        style={{
                                            marginBottom: 0,
                                            pointerEvents: readOnly ? 'none' : 'auto',
                                        }}
                                        htmlFor={option.optionId}
                                    >
                                        {_children}
                                    </label>
                                );
                            },
                        },
                    });

                    return (
                        <Grid item>
                            <MultiChoiceInput
                                optionValue={answerValue}
                                label={answerLabel}
                                isWriteIn={option.writeIn}
                                writeInValue={
                                    type === 'radio'
                                        ? field?.value && field.value[0]
                                        : field?.value &&
                                          field?.value?.find(
                                              (item: IQuizQuestionUserResponseChoice) =>
                                                  item?.optionValue === answerValue
                                          )
                                }
                                checked={
                                    type === 'radio'
                                        ? field?.value &&
                                          field?.value[0]?.optionValue === answerValue
                                        : field?.value &&
                                          field.value.find(
                                              (item: IQuizQuestionUserResponseChoice) =>
                                                  item?.optionValue === answerValue
                                          )
                                }
                                handleOptionChange={
                                    type === 'radio'
                                        ? handleRadioChange(
                                              option.optionId,
                                              option.writeIn !== QuizWriteInConfig.None
                                          )
                                        : handleCheckBoxChange(
                                              option.optionId,
                                              option.writeIn !== QuizWriteInConfig.None
                                          )
                                }
                                type={type}
                                questionName={name}
                                optionId={option.optionId}
                                readOnly={readOnly}
                                histogramInformation={option.histogramInformation}
                                rationale={option.rationale}
                                isMarkedCorrect={option.isMarkedCorrect}
                                isMarkedIncorrect={option.isMarkedIncorrect}
                                isUserSelected={option.userSelected}
                                responseChip={option.responseChip}
                            />
                        </Grid>
                    );
                },
            },
        });
    };

    return (
        <Grid container direction="column" gap="32px">
            <Grid item>
                <Grid container direction="column" gap="16px">
                    {options.map((option) => getRenderOption(option))}
                </Grid>
            </Grid>
            {rationale && (
                <Grid item>
                    <div
                        style={{
                            borderTop: `1px solid #EFF2F8`,
                            borderRadius: `4px`,
                            padding: `16px 8px 0px 10px`,
                        }}
                    >
                        <Typography style={{ fontWeight: '500', fontSize: '19px' }}>
                            {strings.rationale_title}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Metric',
                                fontSize: '18px',
                                lineHeight: '24px',
                                color: colors['--ui-slate'],
                                marginBottom: '8px',
                            }}
                        >
                            {rationale?.text?.contentfulRichText &&
                                documentToReactComponents(
                                    rationale.text.contentfulRichText as Document,
                                    frontMatterRenderOptions
                                )}
                        </Typography>
                    </div>
                </Grid>
            )}
        </Grid>
    );
}
