import { styled } from '@mui/material';
import { ContainedButton } from '../StyledButtons';
import colors from '../../config/colorConfig';
import { strings } from '../../utils/Strings';

//
// FrontMatterButtonSection dynamically displays a styled button
// with button disclaimer text in an html section element
//

interface FrontMatterButtonProps {
    buttonText: string;
    disclaimerText: string;
    handleClick: () => void;
}

export function FrontMatterButtonSection({
    handleClick,
    buttonText,
    disclaimerText,
}: FrontMatterButtonProps) {
    const StyledSection = styled('section')({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        backgroundColor: colors['--ui-grey-ultra-light'],
        padding: '8px',
    });

    const StyledButtonDisclaimerText = styled('h3')(({ theme }) => ({
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'center',
        marginBottom: '8px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        },
    }));

    const StyledButtonLegalText = styled('p')(({ theme }) => ({
        margin: 0,
        textAlign: 'center',
        marginTop: '8px',
        maxWidth: '1200px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        },
    }));

    return (
        <StyledSection>
            <StyledButtonDisclaimerText data-testid="disclaimer-text">
                {disclaimerText}
            </StyledButtonDisclaimerText>
            <ContainedButton
                type="button"
                width={{ xs: '100%', md: '500px' }}
                onClick={handleClick}
            >
                {buttonText}
            </ContainedButton>
            <StyledButtonLegalText data-testid="legal-text">
                {strings.frontMatter_legal_text}
            </StyledButtonLegalText>
        </StyledSection>
    );
}
