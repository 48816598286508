import { styled } from '@mui/material';
import { ReactNode } from 'react';

interface FullScreenLoadingContainerProps {
    children: ReactNode;
}

export function FullScreenLoadingContainer({ children }: FullScreenLoadingContainerProps) {
    const LoadingContainer = styled('div')(() => ({
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        '@media screen and (min-width: 1200px)': {
            height: 'calc(100vh - 80px)',
            width: '752px',
        },
        '@media screen and (min-width:768px) and (max-width: 1199px)': {
            height: 'calc(100vh - 280px)',
            width: '752px',
        },
        '@media screen and (max-width: 767px)': {
            height: 'calc(100vh - 280px)',
        },
    }));

    return <LoadingContainer>{children}</LoadingContainer>;
}
