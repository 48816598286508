import { useState, useEffect, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from '../models/Tabs';
import { RootState } from '../store/store';

export function useIsElementXPercentInViewport(
    ref: RefObject<HTMLDivElement>,
    percentVisible: number,
    _swiperRef?: RefObject<HTMLDivElement>,
    desktop?: boolean
): boolean {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    function isElementXPercentInViewport() {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const rect = ref?.current?.getBoundingClientRect();
        const swiperRef = _swiperRef?.current?.getBoundingClientRect();

        // If the child's right is less than the parent's left, then it is completely hidden to the left.
        // If the childs left is greater than the parents right, then it is completely hidden to the right.
        // Or if the difference between child's right and parents left divided by the width of the card is less than 20%, then it is partially hidden to the left
        // Or if the difference between child's left and parents right divided by the width of the card is less than 20%, then it is partially hidden to the right
        if (currentSourceTab === Tabs.DISCOVER) {
            if (desktop) {
                percentVisible = 1;
            }
            if (swiperRef && rect) {
                if (rect.right < swiperRef.left || rect?.left > swiperRef.right) {
                    return false;
                } else if (
                    ((rect.right - swiperRef.left) / (rect.right - rect.left)) * 100 >
                        percentVisible ||
                    ((rect.left - swiperRef.right) / (rect.right - rect.left)) * 100 >
                        percentVisible
                ) {
                    return true;
                }
            } else {
                return false;
            }
        }
        if (rect) {
            return !(
                Math.floor(100 - ((rect?.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) <
                    percentVisible ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
                    percentVisible
            );
        } else {
            return false;
        }
    }

    // update element visibility state on scroll
    const handleScroll = () => {
        const cardRect: DOMRect | undefined = ref?.current?.getBoundingClientRect();
        // During Vertical scrolling, Check to see if the collection is Visible
        if (currentSourceTab === Tabs.DISCOVER) {
            const isCollectionVisible =
                cardRect &&
                cardRect?.y < window.innerHeight &&
                cardRect?.y > 0 &&
                ((cardRect?.y > 0 && (window.innerHeight - cardRect?.y) / cardRect?.height > 0.5) ||
                    (cardRect?.y <= 0 && cardRect?.bottom / cardRect?.height > 0.5));
            if (!isCollectionVisible) {
                // If the collection is not Visible, all the cards in the collection are set to false
                if (isVisible) {
                    setIsVisible(false);
                }
                return;
            }
        }
        // If the collection is Visible, each card is evaluated and its status is set based on its visibility inside the collection
        // OR for a regular vertical scroll, its visibility is checked and updated
        if (ref.current) {
            const newIsVisibleStatus = isElementXPercentInViewport() ?? false;
            if (newIsVisibleStatus !== isVisible) {
                setIsVisible(newIsVisibleStatus);
            }
        }
    };

    const handleVerticalScroll = (event: any) => {
        if (currentSourceTab === Tabs.DISCOVER) {
            if (event.path?.length !== 2) {
                return;
            }
            handleScroll();
        }
        handleScroll();
    };

    window.addEventListener('scroll', handleVerticalScroll, true);

    // update visibility state on initial page render when ref becomes available
    useEffect(() => {
        handleScroll();
    }, [ref.current]);

    handleScroll();

    return isVisible;
}
