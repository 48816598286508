import { CollectionAmplitudeState, ResponseAmplitudeState } from '../models/AmplitudeState';
import {
    isActivityCard,
    CMECard,
    isSeriesPageCard,
    DisplayGraphic,
    Chip,
} from '../models/CMECommonResponse';
import { VerticalViewCard } from './VerticalViewCard';

export interface PolymorphicVerticalCardProps {
    card: CMECard;
    responseAmplitudeData?: ResponseAmplitudeState | undefined;
    collectionAmplitudeData?: CollectionAmplitudeState | undefined;
    cardIndex: number;
    matchedCardCount?: number;
}

export function PolymorphicVerticalCard({
    card,
    responseAmplitudeData,
    collectionAmplitudeData,
    cardIndex,
    matchedCardCount,
}: PolymorphicVerticalCardProps) {
    const banner: DisplayGraphic = { imageUrl: '' };
    const chip: Chip = {
        text: '',
        fgColor: '',
        bgColor: '',
    };
    if (card?.cardType === 'cme-activity' && card.cardPayload && isActivityCard(card.cardPayload)) {
        return (
            <VerticalViewCard
                activityId={card.cardPayload.activityId}
                thumbnailUrl={card.cardPayload.thumbnailUrl}
                chipText={card.cardPayload.chipText || ''}
                description={card.cardPayload.description}
                expirationInDays={card.cardPayload.expirationInDays}
                mediaFormat={card.cardPayload.mediaFormat}
                title={card.cardPayload.title}
                creditText={card.cardPayload.creditText}
                durationText={card.cardPayload.durationText}
                accessCode={card.cardPayload.accessCode}
                durationInMinutes={card.cardPayload.durationInMinutes}
                creditAmount={card.cardPayload.creditAmount}
                subtypeName={card.cardPayload.subtypeName}
                daysSincePublication={card.cardPayload.daysSincePublication}
                isSaved={card.cardPayload.isSaved || false}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
                cardIndex={cardIndex + 1}
                isSeriesCard={false}
                seriesPageId={''}
                banner={banner}
                chip={chip}
                matchedCardCount={matchedCardCount}
            />
        );
    } else if (
        card?.cardType === 'cme-series' &&
        card.cardPayload &&
        isSeriesPageCard(card.cardPayload)
    ) {
        return (
            <VerticalViewCard
                activityId={card.cardPayload.seriesPageId}
                thumbnailUrl={card.cardPayload.banner.imageUrl}
                chipText={card.cardPayload.chipText}
                expirationInDays={card.cardPayload.expirationInDays}
                title={card.cardPayload.title}
                description={card.cardPayload.description}
                durationText={card.cardPayload.durationText}
                creditText={card.cardPayload.creditText}
                durationInMinutes={card.cardPayload.durationInMinutes}
                creditAmount={card.cardPayload.creditAmount}
                daysSincePublication={card.cardPayload.daysSincePublication}
                isSaved={card.cardPayload.isSaved || false}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
                cardIndex={cardIndex + 1}
                isSeriesCard={true}
                seriesText={card.cardPayload.seriesText}
                seriesPartCount={card.cardPayload.seriesPartCount}
                seriesPageId={''}
                banner={banner}
                chip={chip}
                matchedCardCount={matchedCardCount}
            />
        );
    } else {
        // Unknown card case -- explicitly skip over this card
        return <></>;
    }
}
