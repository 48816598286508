import { styled } from '@mui/material';

interface SeriesPageSupporterTextProps {
    supporterText: string | JSX.Element;
}

export function SeriesPageSupporterText({ supporterText }: SeriesPageSupporterTextProps) {
    const StyledText = styled('div')(({ theme }) => ({
        margin: '8px 0 0 0',
        fontSize: '16px',
        [theme.breakpoints.up('xs')]: {
            padding: '0 16px 0 16px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '0 24px 0 24px',
        },
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
    }));

    return <StyledText data-testid="seriesSupporterText">{supporterText}</StyledText>;
}
