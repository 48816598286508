import { useController, UseControllerProps } from 'react-hook-form';
import { ChangeEvent } from 'react';

import { StyledSingleLineInput } from './quizStyledComponents';
import { strings } from '../../../utils/Strings';

interface SingleLineProps extends UseControllerProps {
    isVisible: boolean;
    readOnly?: boolean;
    borderColor?: string;
}

export function SingleLineInput({
    control,
    name,
    isVisible,
    readOnly,
    borderColor,
}: SingleLineProps) {
    const { field } = useController({
        name,
        control,
    });
    const cursor = readOnly ? 'not-allowed' : 'auto';

    return (
        <StyledSingleLineInput
            isVisible={isVisible}
            borderColor={borderColor}
            placeholder={readOnly ? field.value : strings.placeholder_input_text}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // send value to hook form
                field.onChange(e.target.value);
            }}
            onBlur={field.onBlur} // notify when input is touched/blur
            value={field.value} // input value
            name={field.name} // send down the input name
            ref={field.ref} // send input ref, so we can focus on input when error appear
            maxLength={255}
            type="text"
            aria-label={field.name}
            readOnly={readOnly}
            cursor={cursor}
        />
    );
}
