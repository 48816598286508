import { useQuery, UseQueryResult } from 'react-query';
import { IProgramTransactionRequestor } from '../components/epolms/EPOLMSProgramExecutor';
import { IProgramTransactResponse } from '../models/EPOLMSModels';
import { EPOLMSProgramTransactAxiosCall } from '../services/epolmsProgramTransact';

export const GetEPOLMSProgramTransactData = (
    latestTransactionRequestor: IProgramTransactionRequestor,
    programId: string | undefined,
    uniqueSessionId: string
): UseQueryResult<IProgramTransactResponse> => {
    return useQuery<IProgramTransactResponse>(
        ['ProgramTransact', programId, uniqueSessionId, latestTransactionRequestor],
        () => EPOLMSProgramTransactAxiosCall(latestTransactionRequestor.backendRequest),
        {
            onSuccess: (data) => {
                if (data.session) {
                    window.sessionStorage.setItem('cme-epolms-program-session-state', data.session);
                } else {
                    window.sessionStorage.removeItem('cme-epolms-program-session-state');
                }
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: Boolean(latestTransactionRequestor.backendRequest && programId),
            keepPreviousData: true,
        }
    );
};
