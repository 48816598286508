import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateIsSearchActive, updateSearchText } from '../Slices/DisplaySlice';

export const useShowSearchResultsFromSearchParam = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const searchParam = searchParams.get('searchText');

    useEffect(() => {
        if (searchParam) {
            dispatch(updateIsSearchActive(true));
            dispatch(updateSearchText(searchParam));
        }
    }, [searchParam]);
};
