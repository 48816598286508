import { Grid, styled } from '@mui/material';

import { IProgramOutline, IProgramGlobalInfo, NavigationIntent } from '../../models/EPOLMSModels';
import { GetAccountProfile } from '../../queries/AccountProfileQuery';
import { theme } from '../../styles/theme';
import { SmallScreen } from '../../utils/responsive';
import { EPOLMSHeader } from './EPOLMSHeader';
import { ExitProgramButton } from './programOutline/ExitProgramBtn';
import { ProgramOutline } from './programOutline/ProgramOutline';
import { FixedLoadingSpinner } from './FixedLoadingSpinner';
import { ReactNode } from 'react';

interface ProgramLayoutProps {
    headerInformation: IProgramGlobalInfo;
    performNavigationIntent: (intent: NavigationIntent) => void;
    programOutline?: IProgramOutline | null;
    children: ReactNode;
    isLoading: boolean;
}

export const ProgramLayout = ({
    headerInformation,
    performNavigationIntent,
    programOutline,
    children,
    isLoading,
}: ProgramLayoutProps) => {
    const isSmallScreen = SmallScreen();
    const { data: profileData } = GetAccountProfile();

    const ProgramOutlineLayout = styled(Grid)(() => ({
        [theme.breakpoints.down('xl')]: {
            maxWidth: '216px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '0px',
        },
        maxWidth: '320px',
    }));

    const ProgramOutlineContainer = styled(Grid)(() => ({
        position: 'sticky',
        top: 0,
        [theme.breakpoints.down('xl')]: {
            width: '216px',
        },
        [theme.breakpoints.down('md')]: {
            width: '0px',
            height: '0px',
        },
        width: '320px',
        height: '100vh',
    }));

    const ProgramContentLayout = styled(Grid)(() => ({
        [theme.breakpoints.down('xl')]: {
            maxWidth: '552px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '375px',
        },
        maxWidth: '1120px',
    }));

    return (
        <Grid container direction="row" justifyContent={'center'}>
            <ProgramOutlineLayout>
                <ProgramOutlineContainer>
                    {!isSmallScreen && (
                        <ProgramOutline
                            performNavigationIntent={performNavigationIntent}
                            globalInfo={headerInformation}
                            outline={programOutline}
                            firstName={profileData?.data?.profile?.firstName}
                            lastName={profileData?.data?.profile?.lastName}
                        />
                    )}
                </ProgramOutlineContainer>
            </ProgramOutlineLayout>
            <ProgramContentLayout>
                <Grid container direction="column">
                    {isSmallScreen && (
                        <Grid item>
                            <ExitProgramButton />
                        </Grid>
                    )}
                    <Grid item>
                        <EPOLMSHeader
                            fromText={headerInformation.supporterText?.plain}
                            title={headerInformation.programTitle?.plain}
                            releaseDate={headerInformation.publicationDateText?.plain}
                            expiresDate={headerInformation.expirationDateText?.plain}
                            creditText={headerInformation.maxCreditAmountText?.plain}
                            durationText={headerInformation.durationText?.plain}
                            creditType={headerInformation.creditTypeText?.plain}
                            imageUrl={headerInformation.bannerImageUrl}
                        />
                    </Grid>
                    {isSmallScreen && (
                        <Grid item marginBottom="16px">
                            <ProgramOutline
                                performNavigationIntent={performNavigationIntent}
                                globalInfo={headerInformation}
                                outline={programOutline}
                                firstName={profileData?.data?.profile?.firstName}
                                lastName={profileData?.data?.profile?.lastName}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <>
                            {isLoading && <FixedLoadingSpinner />}
                            {children}
                        </>
                    </Grid>
                </Grid>
            </ProgramContentLayout>
        </Grid>
    );
};
