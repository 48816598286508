import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
interface ViewMoreButtonProps {
    onClick: () => void;
    isCollapsed: boolean;
    color: string;
    viewMoreText: string;
    viewLessText: string;
    fontWeight?: number;
    fontSize?: number | string;
}

export const ViewMoreButton = ({
    onClick,
    isCollapsed,
    color,
    viewMoreText,
    viewLessText,
    fontSize,
    fontWeight,
}: ViewMoreButtonProps) => {
    const TextBtn = styled('button')({
        fontStyle: 'normal',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
    });

    const CenteredBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    });

    const handleClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
    };

    return (
        <TextBtn
            type="button"
            onClick={handleClick}
            sx={{ color: color, fontSize: fontSize, fontWeight: fontWeight, padding: 0 }}
        >
            <CenteredBox>
                <CenteredBox>
                    {isCollapsed ? (
                        <ArrowDropDown sx={{ marginRight: '-2px' }} />
                    ) : (
                        <ArrowDropUp sx={{ marginRight: '-2px' }} />
                    )}
                    {isCollapsed ? viewMoreText : viewLessText}
                </CenteredBox>
            </CenteredBox>
        </TextBtn>
    );
};
