import colors from '../../config/colorConfig';
import SearchBar from '../Search/SearchBar';
import NavigationTabs, { NavigationTabProps } from './NavigationTabs';
import { featureSearchEnabled } from '../../utils/staticFeatureFlags';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Box, Typography } from '@mui/material';

export default function LeftNav(props: NavigationTabProps) {
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    return (
        <Box
            sx={{
                '@media only screen and (min-width:1200px) and (max-width:1440px)': {
                    marginLeft: '72px',
                },
                '@media only screen and (max-width: 1240px) and (min-width: 1200px)': {
                    width: '280px',
                },
                width: '320px',
                paddingRight: '0px',
                backgroundColor: colors['--pure-white'],
                filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))',
                flexShrink: 0,
                alignSelf: 'stretch',
                marginRight: '24px',
                minHeight: '100%',
            }}
        >
            <div style={{ position: 'sticky', top: 0 }}>
                <div
                    style={{
                        width: '100%',
                        padding: '24px 24px 0px 24px',
                        display: 'flex',
                        gap: '16px',
                    }}
                >
                    <img src="CME_Icon_Multi_Tone.svg" alt="CME" />
                    <Typography
                        sx={{
                            fontFamily: 'Metric',
                            fontSize: '38px',
                            fontWeight: 'light',
                            lineHeight: '40px',
                            fontStyle: 'normal',
                        }}
                    >
                        CME
                    </Typography>
                </div>
                {featureSearchEnabled(currentSourceTab) ? (
                    <div
                        style={{
                            padding: '24px 16px',
                            backgroundColor: colors['--pure-white'],
                            width: '100%',
                        }}
                    >
                        <SearchBar withLogo={false} />
                    </div>
                ) : (
                    <Box sx={{ height: '24px' }}></Box>
                )}
                <div>
                    <NavigationTabs value={props.value} />
                </div>
            </div>
        </Box>
    );
}
