import { styled } from '@mui/material';
import colors from '../../config/colorConfig';

export const SupportLink = styled('a')({
    color: colors['--primary-teal'],
});

import config from '../../config/envConfig';
import { strings } from '../../utils/Strings';

export const StyledLink = styled('a')({
    fontWeight: 500,
    color: colors['--primary-teal'],
    '&:hover': {
        textDecoration: 'none',
        color: colors['--primary-teal'],
    },
    '&:active': {
        color: colors['--ui-link-pressed'],
    },
});

export const HelpAndFeedbackLink = () => {
    const eolURL = config.environment.eolURL;

    return (
        <StyledLink href={`${eolURL}feedback`} target="_blank">
            {strings.help_and_feedback}
        </StyledLink>
    );
};
