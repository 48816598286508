import { UseQueryResult, useQuery } from 'react-query';
import { DynamicHomeScreenActivitiesResponse } from '../models/DynamicHomeScreenActivities';
import { isAuthenticatedUser } from '../utils/cookies';
import { getDynamicHomeScreenActivities } from '../services/getDynamicHomeScreenActivities';

export const GetDynamicHomeScreenActivities = (
    limit: number
): UseQueryResult<DynamicHomeScreenActivitiesResponse, unknown> => {
    return useQuery<DynamicHomeScreenActivitiesResponse>(
        ['DynamicHomeScreenActivities'],
        () => getDynamicHomeScreenActivities(limit),
        {
            enabled: isAuthenticatedUser(),
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            keepPreviousData: true,
        }
    );
};
