import {
    CME_SEARCH_CLEAR_CLICK,
    CME_SEARCH_MANUAL_SEARCH_CLICK,
    CME_SEARCH_BAR_CLICK,
    CME_SEARCH_RESULTS_CLICK,
} from './analytics';
import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CollectionAmplitudeState } from '../models/AmplitudeState';

export const sendSearchBarClick = (searchText?: string) =>
    sendAmplitudeEvent(CME_SEARCH_BAR_CLICK, { 'Search Term': searchText || '' });

export const sendSearchBarManualSearchClick = (searchText?: string) =>
    sendAmplitudeEvent(CME_SEARCH_MANUAL_SEARCH_CLICK, { 'Search Term': searchText || '' });

export const sendSearchClearClick = (searchText?: string) =>
    sendAmplitudeEvent(CME_SEARCH_CLEAR_CLICK, { 'Search Term': searchText || '' });

export const sendSearchResultsDisplay = (
    searchText: string | undefined,
    numberOfActivitiesDisplayed: number | undefined,
    collectionAmplitudeData: CollectionAmplitudeState | undefined
) => {
    const amplitudeData = {
        'Collection Process Instance ID': collectionAmplitudeData?.collectionProcessID,
        'Collection Process Version ID': collectionAmplitudeData?.collectionProcessVersionID,
        'Number of Activities Returned': numberOfActivitiesDisplayed,
        'Search Term': searchText ?? '',
    };
    sendAmplitudeEvent(CME_SEARCH_RESULTS_CLICK, amplitudeData);
};
