import * as toolkit from '@reduxjs/toolkit';
import { FilterType } from '../models/FilterType';
// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserFilterSelection } from '../models/ICatalogCardsRequest';

export interface ExpandCollapseFilterState {
    filterName: string;
    isExpand: boolean;
}

export interface ActiveFilterInformation {
    isActive: boolean;
    filterType: FilterType;
    filterName?: string;
}

export interface FilterState {
    activeFilterInformation: ActiveFilterInformation;
    activeFilterSelections: UserFilterSelection[];
    expandCollapseFiltersState: ExpandCollapseFilterState[];
    totalResults: number;
}

export const initialState: FilterState = {
    activeFilterInformation: { isActive: false, filterName: '', filterType: FilterType.None },
    activeFilterSelections: [],
    expandCollapseFiltersState: [],
    totalResults: 0,
};

export interface FiltersPayload extends ActiveFilter {
    filterState?: boolean;
    clearAll?: boolean;
    clearQuickFilter?: string;
}

export interface ActiveFilter {
    filterName?: string;
    filterValue?: string;
}

export const filterSlice = toolkit.createSlice({
    name: 'Filter',
    initialState,
    reducers: {
        updateActiveFilters: (state, action: toolkit.PayloadAction<FiltersPayload>) => {
            if (action.payload.clearAll) {
                state.activeFilterSelections = [];
                return;
            }
            if (action.payload.clearQuickFilter) {
                for (let i = 0; i < state.activeFilterSelections.length; i++) {
                    if (
                        action.payload.clearQuickFilter ===
                        state.activeFilterSelections[i].filterName
                    ) {
                        state.activeFilterSelections.splice(i, 1);
                        return;
                    }
                }
            }

            if (action.payload.filterState === true) {
                //If the filterName exists, push into the selectedValues Array for that filterName
                for (let i = 0; i < state.activeFilterSelections.length; i++) {
                    if (state.activeFilterSelections[i].filterName === action.payload.filterName) {
                        if (action.payload?.filterValue) {
                            state.activeFilterSelections[i].selectedValues.push(
                                action.payload?.filterValue
                            );
                            return;
                        }
                    }
                }
                //If it doesnt exist, create a new object and push it into the activeFilters
                if (action.payload?.filterValue && action.payload?.filterName) {
                    let selectedValuesArray: string[] = [action.payload?.filterValue];
                    let userFilterSelection: UserFilterSelection = {
                        filterName: action.payload.filterName,
                        selectedValues: selectedValuesArray,
                    };
                    state.activeFilterSelections.push(userFilterSelection);
                }
                return;
            }

            // filterName already exists in the activeFilters, find the location and remove the filter from the selectedValues for that filterName
            for (let i = 0; i < state.activeFilterSelections.length; i++) {
                if (state.activeFilterSelections[i].filterName === action.payload.filterName) {
                    const selectValues: string[] = state.activeFilterSelections[i].selectedValues;
                    if (action.payload?.filterValue) {
                        const index = selectValues.indexOf(action.payload.filterValue);
                        if (index > -1) {
                            state.activeFilterSelections[i].selectedValues.splice(index, 1);
                        }
                        // If there are no more selected filters in that category, remove the category itself
                        if (state.activeFilterSelections[i].selectedValues.length === 0) {
                            state.activeFilterSelections.splice(i, 1);
                        }
                        return;
                    }
                }
            }
        },
        updateFilterState: (state, action: toolkit.PayloadAction<ActiveFilterInformation>) => {
            state.activeFilterInformation = action.payload;
        },

        updateExpandCollapseFilters: (
            state,
            action: toolkit.PayloadAction<ExpandCollapseFilterState>
        ) => {
            for (let i = 0; i < state.expandCollapseFiltersState.length; i++) {
                if (state.expandCollapseFiltersState[i].filterName === action.payload.filterName) {
                    state.expandCollapseFiltersState[i].isExpand = action.payload.isExpand;
                    return;
                }
            }
            state.expandCollapseFiltersState.push(action.payload);
        },
        updateTotalResult: (state, action: toolkit.PayloadAction<number>) => {
            state.totalResults = action.payload;
        },
    },
});

export const {
    updateActiveFilters,
    updateFilterState,
    updateExpandCollapseFilters,
    updateTotalResult,
} = filterSlice.actions;

const filterReducer = filterSlice.reducer;
export default filterReducer;
