import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../config/colorConfig';
import { FilterState, updateFilterState, updateTotalResult } from '../../Slices/FilterSlice';
import { RootState } from '../../store/store';
import { sendAmplitudeEvent } from '../../amplitude/AmplitudeUtil';
import { CME_FILTER_VIEW_ACTIVITIES } from '../../amplitude/analytics';
import { getAmplitudeFilterData } from '../Filters/AllFilters';
import { FilterType } from '../../models/FilterType';
import { ViewResultsProperties } from './ViewResultsAsset';
import { sendQuickFilterAmplitudeEvent } from '../../amplitude/FilterAmplitudeEvent';
import { QuickFilterEventType } from '../../models/QuickFilterEventType';

export const ViewResultsButton = (props: ViewResultsProperties) => {
    const dispatch = useDispatch();
    if (props?.totalResults) {
        dispatch(updateTotalResult(props?.totalResults));
    }
    const filterState: FilterState = useSelector((state: RootState) => state.filter);
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    const ViewResultsButtonClick = async () => {
        if (filterState.activeFilterInformation.filterType === FilterType.QuickFilter) {
            await sendQuickFilterAmplitudeEvent(
                QuickFilterEventType.viewResults,
                currentSourceTab,
                filterState
            );
        } else {
            sendAmplitudeEvent(
                CME_FILTER_VIEW_ACTIVITIES,
                await getAmplitudeFilterData(filterState, currentSourceTab)
            );
        }
        dispatch(
            updateFilterState({ isActive: false, filterType: FilterType.None, filterName: '' })
        );
    };
    return (
        <button
            onClick={ViewResultsButtonClick}
            style={{
                width: '320px',
                height: '40px',
                border: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '3px',
                backgroundColor: colors['--primary-midnight'],
                cursor: 'pointer',
            }}
        >
            <Typography
                sx={{
                    width: '120px',
                    height: '15px',
                    textAlign: 'center',
                    fontFamily: 'Metric',
                    fontSize: '16px',
                    fontWeight: '500',
                    fontStretch: 'normal',
                    letterSpacing: 'normal',
                    lineHeight: 0.94,
                    color: colors['--pure-white'],
                }}
            >
                View Results ({props.totalResults})
            </Typography>
        </button>
    );
};
