import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Divider, Grid, styled } from '@mui/material';
import { Document, BLOCKS, Node } from '@contentful/rich-text-types';
import { Controller, useFormContext } from 'react-hook-form';

import { QuestionContainer, StyledParagraph } from './quizStyledComponents';
import { IFrontendQuizQuestion } from '../../../models/QuizTransactionService';
import colors, { colorLookup } from '../../../config/colorConfig';
import { PolymorphicQuizAnswer } from './PolymorphicQuizAnswer';
import {
    ContainerHighlight,
    setQuestionContainerHighlight,
} from '../../../utils/setQuestionContainerHighlight';
import { ReactNode } from 'react';

export interface QuizQuestionContainerProps {
    question: IFrontendQuizQuestion;
    isConcluded: boolean;
}

export function QuizQuestionContainer({ question, isConcluded }: QuizQuestionContainerProps) {
    const containerShadowColor: ContainerHighlight = setQuestionContainerHighlight(
        question.highlightMode
    );

    const LabelContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
    });

    const StyledQuestionLabel = styled('h2')({
        marginBottom: 0,
        fontFamily: 'Metric',
        color: colors['--ui-grey'],
        fontSize: '20px',
        fontWeight: 500,
    });

    const StyledDivider = styled(Divider)({
        color: colors['--ui-grey-light'],
        width: '100%',
    });

    const StyledRequiredChip = styled('p')({
        marginLeft: 'auto',
        marginBottom: 0,
        fontFamily: 'Metric',
        color: colors['--ui-grey'],
        fontSize: '16px',
    });

    const StyledAlertText = ({ color, children }: { color?: string; children: ReactNode }) => {
        const StyledChipText = styled('span')({
            fontFamily: 'Metric',
            fontWeight: 500,
            fontSize: '16px',
            marginBottom: 0,
            color: colorLookup(color, '--color-alert-critical'),
        });
        return <StyledChipText>{children}</StyledChipText>;
    };

    const quizRenderOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: Node, children: any) => (
                <StyledParagraph>{children}</StyledParagraph>
            ),
        },
    };

    const renderedQuestion = documentToReactComponents(
        question.stem.contentfulRichText as Document,
        quizRenderOptions
    );

    // https://react-hook-form.com/api/useformcontext/#main
    const { control } = useFormContext();

    // wrap custom inputs in react-hook-form Controller or useController hook (for reusable components)
    // Passing control to Controller registers component to React Hook Form
    // The Controller name prop will be stored in the react-hook-form state as a key value pair

    // https://react-hook-form.com/api/usecontroller/controller/
    // https://react-hook-form.com/api/useform/control/
    const formInputs = (
        <Controller
            control={control}
            render={() => (
                <PolymorphicQuizAnswer
                    question={question}
                    key={`${question.questionId}-container`}
                    questionName={`${question.questionId}+${question.labelText?.plain}`}
                />
            )}
            name={`${question.questionId}+${question.labelText?.plain}`}
        />
    );

    return (
        <Grid item>
            <section>
                <QuestionContainer
                    isConcluded={isConcluded}
                    containerShadowColor={containerShadowColor}
                >
                    <LabelContainer>
                        <StyledQuestionLabel>{question.labelText?.plain}</StyledQuestionLabel>
                        {question.requiredChip && (
                            <StyledRequiredChip>{question.requiredChip?.plain}</StyledRequiredChip>
                        )}
                    </LabelContainer>
                    <>{renderedQuestion}</>
                    <StyledDivider />
                    {question.statusChip?.plain && (
                        <StyledAlertText color={question.statusChip?.fgColor}>
                            {question.statusChip?.plain}
                        </StyledAlertText>
                    )}
                    {formInputs}
                </QuestionContainer>
            </section>
        </Grid>
    );
}
