// Receives props to build quickFilter that contains the quickfilter buttons, clear and ViewResults button

import { Grid, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../../config/colorConfig';
import { AvailableUserFilter } from '../../../models/CatalogCardsResponse';
import { FilterState, updateActiveFilters } from '../../../Slices/FilterSlice';
import { RootState } from '../../../store/store';
import { ViewResultsButton } from '../../custom/ViewResultsButton';
import { QuickFilterButtons } from './QuickFilterButtons';
import { sendQuickFilterAmplitudeEvent } from '../../../amplitude/FilterAmplitudeEvent';
import { QuickFilterEventType } from '../../../models/QuickFilterEventType';

interface QuickFilterProps {
    userFilter: AvailableUserFilter | null;
    viewResultCount: number | undefined;
}

export const QuickFilter = (props: QuickFilterProps) => {
    const dispatch = useDispatch();
    const filterState: FilterState = useSelector((state: RootState) => state.filter);
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    let viewResultCount: number = 0;
    const clearButtonDisabled: boolean =
        filterState.activeFilterSelections.findIndex(
            (activeFilterSelection) =>
                activeFilterSelection.filterName === props.userFilter?.filterName &&
                activeFilterSelection.selectedValues.length > 0
        ) > -1
            ? false
            : true;
    const ClearButton = styled('button')(() => ({
        backgroundColor: colors['--pure-white'],
        border: 'none',
        cursor: clearButtonDisabled ? 'default' : 'pointer',
        '&:focus': {
            outline: 'none',
        },
        ':disabled': clearButtonDisabled,
        opacity: clearButtonDisabled ? 0.4 : 1.0,
    }));

    const handleClear = async () => {
        await sendQuickFilterAmplitudeEvent(
            QuickFilterEventType.clear,
            currentSourceTab,
            filterState
        );
        dispatch(updateActiveFilters({ clearQuickFilter: props?.userFilter?.filterName }));
    };

    if (props?.viewResultCount) {
        viewResultCount = props?.viewResultCount;
    }
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="middle"
            direction="column"
            style={{
                padding: '24px',
                backgroundColor: colors['--pure-white'],
                width: '368px',
                borderRadius: '3px',
                boxShadow:
                    '0px 8px 12px 1px rgba(0, 0, 0, 0.07), 0px 3px 16px 2px rgba(0, 0, 0, 0.06), 0px 5px 10px -3px rgba(0, 0, 0, 0.1)',
                position: 'fixed',
            }}
            onClick={(e) => e.stopPropagation()}
            gap="16px"
        >
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <QuickFilterButtons userFilter={props.userFilter} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item style={{ width: '32px' }}>
                        <ClearButton onClick={handleClear}>
                            <Typography
                                sx={{
                                    fontFamily: 'Metric',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: colors['--primary-teal'],
                                    opacity: 0.5,
                                }}
                            >
                                {' '}
                                <u> Clear </u>{' '}
                            </Typography>
                        </ClearButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ViewResultsButton totalResults={viewResultCount} />
            </Grid>
        </Grid>
    );
};
