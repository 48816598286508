import { AxiosRequestConfig } from 'axios';
import { axiosClient } from './axiosClient';
import { IProgramTransactRequest, IProgramTransactResponse } from '../models/EPOLMSModels';
import config from '../config/envConfig';

export async function EPOLMSProgramTransactAxiosCall(
    nextRequest: IProgramTransactRequest
): Promise<IProgramTransactResponse> {
    const { programTransactUrl } = config.environment;
    const result = await axiosClient.post(programTransactUrl, nextRequest, {
        authMode: 'auto',
    } as AxiosRequestConfig);

    return result.data as IProgramTransactResponse;
}
