import colors from '../config/colorConfig';

export function setQuizInputAccent(
    isChecked: boolean,
    isReadOnly: boolean,
    isMarkedCorrect: boolean,
    isMarkedIncorrect: boolean,
    isUserSelected: boolean
): string {
    if (isChecked) {
        if (isReadOnly) {
            if (isMarkedCorrect) {
                return colors['--color-alert-success'];
            } else if (isMarkedIncorrect) {
                return colors['--color-alert-critical'];
            } else {
                return colors['--ui-grey'];
            }
        } else {
            if (isMarkedCorrect && isUserSelected) {
                return colors['--color-alert-success'];
            } else if (isMarkedIncorrect && isUserSelected) {
                return colors['--color-alert-critical'];
            } else {
                return colors['--primary-teal-night'];
            }
        }
    }
    return 'none';
}
