import axios, { AxiosRequestConfig } from 'axios';
import { AuthRequest } from '../models/AuthRequest';
import { ResponseErrorObject } from '../utils/responseErrorObject';
import config from '../config/envConfig';
import * as cmeService from './cmeAuthenticationService';
import { VerifyRequest } from '../models/VerifyRequest';
import { AuthorizationCodeRedeemRequest } from '../models/AuthorizationCodeRedeemRequest';
import { store } from '../store/store';
import { getCookie } from '../utils/cookies';
import { getWebUserAgent } from '@athena/epoc-frontend-library/dist';

export const authenticationService = (request: any) => {
    const state = store.getState();
    const { authenticationUrl } = config.environment;
    const isDeviceManagementEnabled = state.epocNavRedesign.isDeviceDetectionEnabled;

    const instance = axios.create({
        baseURL: authenticationUrl,
        timeout: 60000,
    });

    const axiosConfig: AxiosRequestConfig = {
        headers: isDeviceManagementEnabled
            ? {
                  'Epoc-Platform': 'Web',
                  'Device-Id':
                      window.location.hostname === 'localhost'
                          ? `234fcgcmelocalhost`
                          : getCookie('ebi'),
                  'Device-Family': getWebUserAgent(),
              }
            : {},
    };

    const authRequest: AuthRequest = request as AuthRequest;

    const url = isDeviceManagementEnabled ? `/v2/login` : `/v1/login`;

    return instance
        .post(url, request, axiosConfig)
        .then((response: any) => {
            if (authRequest.isCMELogin) {
                const authResponse = response;
                const cmeResponse = cmeService.cmeAuthenticationService(
                    authResponse.data.tokens.accessToken,
                    authRequest,
                    authResponse.data.tokens
                );
                return cmeResponse;
            }
            return response;
        })
        .catch((error: any) => {
            const data = ResponseErrorObject(error.response);
            return data;
        });
};

export const verificationService = (request: any) => {
    const state = store.getState();
    const isDeviceManagementEnabled = state.epocNavRedesign.isDeviceDetectionEnabled;
    const { authenticationUrl } = config.environment;

    const requestHeaders = isDeviceManagementEnabled
        ? {
              'Epoc-Platform': 'Web',
              'Device-Id':
                  window.location.hostname === 'localhost'
                      ? `234fcgcmelocalhost`
                      : getCookie('ebi'),
              'Device-Family': getWebUserAgent(),
          }
        : {};

    const instance = axios.create({
        baseURL: authenticationUrl,
        timeout: 60000,
    });

    const verifyRequest: VerifyRequest = request.verifyRequest as VerifyRequest;

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...requestHeaders,
        },
    } as AxiosRequestConfig;

    const url = isDeviceManagementEnabled
        ? `/v2/user/${verifyRequest.userId}/token`
        : `/v1/user/${verifyRequest.userId}/token`;

    return instance
        .post(url, { refreshToken: request.verifyRequest.refreshToken }, axiosConfig)
        .then((response: any) => response)
        .catch((error: any) => {
            const data = ResponseErrorObject(error.response);
            return data;
        });
};

export const authorizationCodeRedemptionService = (request: any) => {
    const { authenticationUrl } = config.environment;

    const instance = axios.create({
        baseURL: authenticationUrl,
        timeout: 60000,
    });

    const authCodeRedeemRequest: AuthorizationCodeRedeemRequest =
        request.authorizationCodeRedeemRequest as AuthorizationCodeRedeemRequest;

    return instance
        .post('/v1/authorizationcode/redeem', authCodeRedeemRequest)
        .then((response: any) => response)
        .catch((error: any) => {
            const data = ResponseErrorObject(error.response);
            return data;
        });
};
