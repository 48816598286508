import { Grid } from '@mui/material';
import colors from '../../config/colorConfig';
import { AvailableFilterSelection, AvailableUserFilter } from '../../models/CatalogCardsResponse';
import { FilterTextBox } from '../custom/FilterTextBox';
import { VerticalFilters } from './VerticalFilters';

export interface FilterDisplayProps {
    userFilter: AvailableUserFilter;
}

export const FilterDisplay = (props: FilterDisplayProps) => {
    let isLargeDisplay: boolean = false;
    if (props.userFilter.selections.length <= 2) {
        isLargeDisplay = true;
    }

    props?.userFilter?.selections?.map((selection: AvailableFilterSelection) => {
        if (selection?.subtitle?.length > 0) {
            isLargeDisplay = true;
        }
    });

    return (
        <>
            <Grid container direction="column" rowGap="16px">
                <Grid item>
                    <div
                        style={{
                            textTransform: 'uppercase',
                            fontFamily: 'Metric',
                            fontSize: '12px',
                            color: colors['--primary-midnight'],
                            fontWeight: 500,
                        }}
                    >
                        {' '}
                        {props.userFilter.filterName}{' '}
                    </div>
                </Grid>

                <Grid item>
                    {isLargeDisplay && props.userFilter.orientation === 'horizontal' && (
                        <Grid
                            container
                            style={{ marginBottom: '32px' }}
                            direction="row"
                            wrap="wrap"
                            columns={2}
                            columnGap="8px"
                            rowGap="8px"
                        >
                            {props.userFilter.selections.map(
                                (selection: AvailableFilterSelection, index: number) => {
                                    return (
                                        <FilterTextBox
                                            enabled={selection.enabled}
                                            subtitle={selection.subtitle}
                                            text={selection.value}
                                            isLargeDisplay={isLargeDisplay}
                                            filterName={props.userFilter.filterName}
                                            key={index}
                                        />
                                    );
                                }
                            )}
                        </Grid>
                    )}
                    {!isLargeDisplay && props.userFilter.orientation === 'horizontal' && (
                        <Grid
                            container
                            style={{ marginBottom: '32px' }}
                            direction="row"
                            wrap="wrap"
                            columns={3}
                            columnGap="8px"
                            rowGap="8px"
                        >
                            {props.userFilter.selections.map(
                                (selection: AvailableFilterSelection, index: number) => {
                                    return (
                                        <Grid key={index} item>
                                            <FilterTextBox
                                                enabled={selection.enabled}
                                                subtitle={selection.subtitle}
                                                text={selection.value}
                                                isLargeDisplay={isLargeDisplay}
                                                filterName={props.userFilter.filterName}
                                            />
                                        </Grid>
                                    );
                                }
                            )}
                        </Grid>
                    )}
                    {props.userFilter.orientation === 'vertical' && (
                        <VerticalFilters userFilter={props.userFilter} />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
