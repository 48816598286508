import { Box, LinearProgress, Typography, styled } from '@mui/material';
import colors, { colorLookup } from '../../../config/colorConfig';

type PercentReferenceBarProps = {
    histogramBackgroundColor?: null | undefined | string;
    start: string;
    end: string;
};

export function PercentReferenceBar({
    histogramBackgroundColor,
    start,
    end,
}: PercentReferenceBarProps) {
    const StyledHistogram = styled(LinearProgress)({
        height: 4,
        borderRadius: 4,
        backgroundColor: colorLookup(histogramBackgroundColor ?? colors['--ui-grey-light']),
    });
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <StyledHistogram variant="determinate" value={0} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            style={{
                                position: 'relative',
                                left: '0px',
                                flex: 1,
                                marginTop: '4px',
                            }}
                        >
                            {start}
                        </Typography>
                        <Typography
                            style={{
                                position: 'relative',
                                marginTop: '4px',
                            }}
                        >
                            {end}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ minWidth: 35 }} />
            </Box>
        </>
    );
}
