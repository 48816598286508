import { Box, Grid, Typography, styled } from '@mui/material';
import { ChangeEvent, ReactNode } from 'react';
import { Document } from '@contentful/rich-text-types';
import colors from '../../../config/colorConfig';
import {
    IHistogramInformation,
    IQuizQuestionUserResponseChoice,
    QuizWriteInConfig,
} from '../../../models/QuizTransactionService';
import { Histogram } from './Histogram';
import { IRationaleInformation } from '../../../models/Primitives';
import { useFormContext } from 'react-hook-form';
import { SingleLineInput } from './SingleLineInput';
import { MultiLineInput } from './MultiLineInput';
import { setQuizOptionBackground } from '../../../utils/setQuizOptionBackground';
import { setQuizInputAccent } from '../../../utils/setQuizInputAccent';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { frontMatterRenderOptions } from '../frontmatter/renderOptions';
import { QuizResponseChip } from './QuizResponseChip';
import { IDisplayChip } from '../../../models/FrontEndCommonStructures';

export interface MultiChoiceInputProps {
    optionValue: string;
    label: ReactNode;
    checked: boolean;
    type: string;
    isWriteIn: QuizWriteInConfig;
    writeInValue?: IQuizQuestionUserResponseChoice;
    questionName: string;
    optionId: string;
    handleOptionChange: (e: ChangeEvent<HTMLInputElement>) => void;
    readOnly: boolean;
    histogramInformation: IHistogramInformation | null;
    rationale?: null | IRationaleInformation;
    isMarkedCorrect?: boolean | undefined;
    isMarkedIncorrect?: boolean | undefined;
    isUserSelected?: boolean | undefined;
    responseChip: IDisplayChip | null;
}

export function MultiChoiceInput({
    optionValue,
    label,
    checked,
    type,
    isWriteIn,
    optionId,
    questionName,
    handleOptionChange,
    readOnly,
    histogramInformation,
    rationale,
    isMarkedCorrect,
    isMarkedIncorrect,
    isUserSelected,
    responseChip,
}: MultiChoiceInputProps) {
    const { control } = useFormContext();
    const trimmedQuestionName = questionName.replace('.choices', '');
    const cursor = readOnly ? 'not-allowed' : 'auto';

    let hasHistogramInfo: boolean = histogramInformation ? true : false;
    const StyledInputHighlightContainer = styled(Grid)({
        backgroundColor: setQuizOptionBackground(
            checked,
            hasHistogramInfo,
            readOnly,
            isMarkedCorrect || false,
            isMarkedIncorrect || false,
            isUserSelected || false
        ),
        padding: '8px',
        borderRadius: '4px',
        cursor: cursor,
    });

    const accentColor = setQuizInputAccent(
        checked,
        readOnly,
        isMarkedCorrect || false,
        isMarkedIncorrect || false,
        isUserSelected || false
    );

    const StyledInput = styled('input')({
        accentColor: accentColor,
        border: `1px solid ${colors['--ui-grey-med']}`,
        width: '18px',
        height: '18px',
        minWidth: '18px',
        cursor: cursor,
        marginTop: '4px',
        pointerEvents: readOnly ? 'none' : 'auto',
        cursorEvents: readOnly ? 'not-allowed' : 'pointer',
    });

    const HistogramContainer = styled('div')({
        marginTop: '8px',
        marginLeft: '8px',
    });

    const InputContainer = styled('div')({
        marginTop: '4px',
        marginLeft: '8px',
    });

    // add padding to the bottom of the histogram if there is no option level rationale
    // Don't add padding with option level rationale, because padding top added by option level rationale div
    const addPadding = responseChip?.text.plain === 'CORRECT RESPONSE' && !rationale;

    return (
        <>
            <Box
                sx={{
                    border:
                        responseChip?.text.plain === 'CORRECT RESPONSE'
                            ? `2px solid ${colors['--background-success']}`
                            : 'none',
                    borderRadius: '4px',
                    padding: addPadding ? '0px 0px 8px 0px' : 0,
                    margin: 0,
                }}
            >
                <StyledInputHighlightContainer
                    container
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <Grid
                        item
                        container
                        sx={{
                            display: 'flex',
                            gap: '11px',
                            alignContent: 'start',
                            flexWrap: 'nowrap',
                        }}
                    >
                        <StyledInput
                            value={optionValue}
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (readOnly) return;
                                handleOptionChange(e);
                            }}
                            checked={checked}
                            type={type}
                            id={optionId}
                            readOnly={readOnly}
                            aria-disabled={readOnly}
                        />
                        {label}
                        {readOnly && <QuizResponseChip text={responseChip?.text.plain} />}
                    </Grid>
                    {rationale && (
                        <Grid item>
                            {rationale && (
                                <div
                                    style={{
                                        padding: `8px 8px 0px 8px`,
                                    }}
                                >
                                    <Typography
                                        component="section"
                                        style={{
                                            fontFamily: 'Metric',
                                            fontWeight: '400',
                                            fontSize: '18px',
                                            lineHeight: '24px',
                                            color: colors['--ui-slate'],
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <Typography style={{ fontWeight: '500', fontSize: '19px' }}>
                                            Rationale:
                                        </Typography>
                                        {rationale?.text?.contentfulRichText &&
                                            documentToReactComponents(
                                                rationale.text.contentfulRichText as Document,
                                                frontMatterRenderOptions
                                            )}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    )}
                </StyledInputHighlightContainer>
                {isWriteIn === QuizWriteInConfig.SingleLine && (
                    <InputContainer>
                        <SingleLineInput
                            control={control}
                            isVisible={checked}
                            readOnly={readOnly}
                            name={`${trimmedQuestionName}.writeInValue`}
                        />
                    </InputContainer>
                )}
                {isWriteIn === QuizWriteInConfig.MultiLine && (
                    <InputContainer>
                        <MultiLineInput
                            control={control}
                            isVisible={checked}
                            readOnly={readOnly}
                            name={`${trimmedQuestionName}.writeInValue`}
                        />
                    </InputContainer>
                )}
                {histogramInformation && (
                    <HistogramContainer>
                        <Histogram
                            histogramPercent={histogramInformation.histogramPercent}
                            histogramBackgroundColor={histogramInformation.histogramBackgroundColor}
                            histogramColor={histogramInformation.histogramColor}
                        />
                    </HistogramContainer>
                )}
            </Box>
        </>
    );
}
