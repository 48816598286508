export function formatDate(unformattedDate: string): string {
    if (unformattedDate.includes('/')) {
        return unformattedDate;
    }
    // only format dates with dashes coming from Contentful
    let date = new Date(`${unformattedDate}T00:00:00`);
    let dateWithDashes = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    return String(dateWithDashes);
}
