import { AxiosRequestConfig } from 'axios';
import { axiosClient } from './axiosClient';
import {
    IQuizTransactionRequest,
    IQuizTransactionResponse,
} from '../models/QuizTransactionService';
import config from '../config/envConfig';

export async function EPOLMSQuizTransactAxiosCall(
    quizRequest: IQuizTransactionRequest
): Promise<IQuizTransactionResponse> {
    const { quizTransactUrl } = config.environment;
    const result = await axiosClient.post(quizTransactUrl, quizRequest, {
        authMode: 'auto',
    } as AxiosRequestConfig);
    return result.data as IQuizTransactionResponse;
}
