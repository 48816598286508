import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

export function useReturnToPreviousScrollPosition() {
    const scrollPosition: number = useSelector(
        (state: RootState) => state.displayState?.scrollPosition
    );

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );

    const isSearchActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSearchActive
    );

    useEffect(() => {
        if (scrollPosition > 0 && (!isSeriesActive || !isSearchActive)) {
            window.scrollTo(0, scrollPosition);
        }
    }, [isSeriesActive, isSearchActive]);
}
