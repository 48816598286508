import { ErrorPage } from './ErrorPage';
import { strings } from '../../utils/Strings';
import { HelpAndFeedbackLink } from './HelpAndFeedBackLink';

export function ErrorFallback() {
    return (
        <ErrorPage
            title={strings.error_fallback_title}
            message={
                <>
                    {strings.error_fallback_message.part1}
                    <HelpAndFeedbackLink />
                    {strings.error_fallback_message.part2}
                </>
            }
        />
    );
}
