function pad(string: number) {
    return ('0' + string).slice(-2);
}

export function format(seconds: number): string {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
        return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
}

type DurationProps = {
    seconds: number;
};

export function Duration({ seconds }: DurationProps) {
    return <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>;
}
