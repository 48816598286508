import { Route, Routes } from 'react-router-dom';
import { Authorizeable } from '../containers/Authorizeable';
import { ACHLActivitySSO } from './ACHLActivitySSO';
import { ArcheMedXActivitySSO } from './ArcheMedXActivitySSO';
import { AnnenbergActivitySSO } from './AnnenbergActivitySSO';
import { GenericProviderActivitySSO } from './GenericProviderActivitySSO';

export const SSORoutes = () => {
    return (
        <Authorizeable
            element={
                <Routes>
                    <Route
                        path="annenberg/activity/:activityId"
                        element={<AnnenbergActivitySSO annenbergServer="annenberg" />}
                    />
                    <Route
                        path="achl-fa/activity/:activityId"
                        element={<ACHLActivitySSO achlServer="achl-fa" />}
                    />
                    <Route
                        path="achl-cme/activity/:activityId"
                        element={<ACHLActivitySSO achlServer="achl-cme" />}
                    />
                    <Route
                        path="t2d-adapted-achl-cme/activity/:activityId"
                        element={<ACHLActivitySSO achlServer="t2d-adapted-achl-cme" />}
                    />
                    <Route
                        path="achl-customed/activity/:activityId"
                        element={<ACHLActivitySSO achlServer="achl-customed" />}
                    />
                    <Route
                        path="alopecia-kids-adapted-achl-cme/activity/:activityId"
                        element={<ACHLActivitySSO achlServer="alopecia-kids-adapted-achl-cme" />}
                    />
                    <Route
                        path="archemedx/activity/:activityId"
                        element={<ArcheMedXActivitySSO archeMedXDomain="archemedx" />}
                    />
                    <Route
                        path="archemedx-aco/activity/:activityId"
                        element={<ArcheMedXActivitySSO archeMedXDomain="archemedx-aco" />}
                    />
                    <Route
                        path="archemedx-ach/activity/:activityId"
                        element={<ArcheMedXActivitySSO archeMedXDomain="archemedx-ach" />}
                    />
                    <Route
                        path="archemedx-rme/activity/:activityId"
                        element={<ArcheMedXActivitySSO archeMedXDomain="archemedx-rme" />}
                    />
                    <Route
                        path="archemedx-frf/activity/:activityId"
                        element={<ArcheMedXActivitySSO archeMedXDomain="archemedx-frf" />}
                    />
                    <Route
                        path="archemedx-smx/activity/:activityId"
                        element={<ArcheMedXActivitySSO archeMedXDomain="archemedx-smx" />}
                    />
                    {/* Keep this generic route last! */}
                    <Route
                        path=":genericProviderName/activity/:activityId"
                        element={<GenericProviderActivitySSO />}
                    />
                </Routes>
            }
        />
    );
};
