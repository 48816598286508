import { Grid, Snackbar, styled, Typography } from '@mui/material';
import { useMutation, UseQueryResult } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import colors from '../config/colorConfig';
import {
    ISetUserAttitudesRequest,
    IUserAttitude,
    IUserActivityAttitude,
} from '../models/ISetUserAttitudesRequest';
import { Tabs } from '../models/Tabs';
import { GetSavedScreenActivities } from '../queries/SavedScreenQueries';
import { setUserActivityAttitudes } from '../services/setUserActivityAttitudesService';
import {
    UserAttitudeProperties,
    updateAttitudeProperties,
    updateUndoFlag,
} from '../Slices/UserAttitudeSlice';
import { RootState } from '../store/store';
import { ErrorState, setError } from '../Slices/ErrorSlice';
import { HelpAndFeedbackLink } from './Error/HelpAndFeedBackLink';
import { strings } from '../utils/Strings';
import { updateActiveTab } from '../Slices/DisplaySlice';
import { CatalogCardsResponse } from '../models/CatalogCardsResponse';

interface ToastProps {
    primaryText?: string | string[];
    preTextSecondary?: string | string[];
    buttonText?: string;
    postTextSecondary?: string;
    buttonHandler?: () => void;
}

export const RemoveActionToast = ({
    primaryText,
    preTextSecondary,
    buttonText,
    postTextSecondary,
    buttonHandler,
}: ToastProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const undoFlag: boolean | undefined = useSelector(
        (state: RootState) => state.snackBarState.undoFlag
    );

    const actionState: string = useSelector((state: RootState) => state.snackBarState.action);

    const type: string = useSelector((state: RootState) => state.snackBarState.type);

    const userAttitudeProperties: UserAttitudeProperties = useSelector(
        (state: RootState) => state.snackBarState
    );

    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const isErrorState = actionState === 'error';

    const handleClose = () => {
        let snackBarProperties: UserAttitudeProperties = {
            action: '',
            type: '',
            isSnackBarOpen: false,
            cardId: '',
        };
        dispatch(updateAttitudeProperties(snackBarProperties));
        if (isErrorState) {
            let nullErrorState: ErrorState = { message: null, statusCode: null };
            dispatch(setError(nullErrorState));
        }
    };

    const Button = styled('button')(() => ({
        '&:focus': {
            outline: 'none',
        },
        cursor: 'pointer',
    }));

    let displayResponse: UseQueryResult<CatalogCardsResponse>;

    if (currentSourceTab === Tabs.SAVED) {
        displayResponse = GetSavedScreenActivities();
    }

    const setUserAttitudeMutation = useMutation(
        (setUserAttitudesRequest: ISetUserAttitudesRequest): Promise<{}> => {
            return setUserActivityAttitudes(setUserAttitudesRequest);
        },
        {
            onSuccess: () => {
                displayResponse?.refetch();

                let snackBarProperties: UserAttitudeProperties | undefined = undefined;

                snackBarProperties = {
                    action: 'saved',
                    type: userAttitudeProperties.type === 'Series' ? 'Series' : 'Activity',
                    isSnackBarOpen: true,
                    cardId: userAttitudeProperties.cardId,
                };
                dispatch(updateAttitudeProperties(snackBarProperties));
            },
        }
    );

    if (actionState === 'unsaved') {
        primaryText = strings.formatString(strings.toast_removal_success, { type: type });
        preTextSecondary = strings.formatString(strings.toast_removed_post_text_secondary, {
            type: type,
        });
        buttonText = strings.toast_click_button_text;
        postTextSecondary = strings.toast_undo_post_text_secondary;
        buttonHandler = () => {
            if (currentSourceTab === Tabs.CATALOG || currentSourceTab === Tabs.DISCOVER) {
                dispatch(updateUndoFlag(true));
            } else if (currentSourceTab === Tabs.SAVED) {
                let requestJson: IUserAttitude = {
                    activityId: '',
                    isSavedByUser: false,
                } as IUserActivityAttitude;
                if (userAttitudeProperties.type === 'Series') {
                    requestJson = {
                        seriesPageId: userAttitudeProperties.cardId,
                        isSavedByUser: true,
                    };
                } else {
                    requestJson = {
                        activityId: userAttitudeProperties.cardId,
                        isSavedByUser: true,
                    };
                }

                setUserAttitudeMutation.mutate({
                    userActivityAttitudes: [requestJson],
                });
            }
        };
    } else if (actionState === 'saved') {
        primaryText = undoFlag
            ? strings.formatString(strings.toast_removal_undone, { type: type })
            : strings.formatString(strings.toast_save_success, { type: type });
        preTextSecondary = '';
        buttonText = strings.toast_click_button_text;
        postTextSecondary = strings.toast_view_saved_content_post_text_secondary;

        buttonHandler = () => {
            handleClose();
            dispatch(updateActiveTab(Tabs.SAVED));
            navigate('/cme/saved');
        };
    } else if (isErrorState) {
        primaryText = strings.error_toast.primaryText;
        preTextSecondary = strings.error_toast.preTextSecondary;
        postTextSecondary = strings.error_toast.postTextSecondary;
    }

    const toast = () => {
        return (
            <Grid
                container
                direction="row"
                style={{
                    paddingRight: '16px',
                    paddingLeft: '16px',
                    backgroundColor: colors['--pure-white'],
                }}
                gap="16px"
                flexWrap="nowrap"
                alignItems="center"
            >
                <Grid item>
                    <Grid
                        container
                        style={{ paddingRight: '20px', gap: '19px', alignItems: 'center' }}
                        flexWrap="nowrap"
                    >
                        <Grid item>
                            <div
                                style={{
                                    height: '64px',
                                    width: '6px',
                                    borderRadius: '3px',
                                    background: isErrorState
                                        ? colors['--error']
                                        : colors['--success'],
                                }}
                            />
                        </Grid>
                        <Grid item>
                            {isErrorState ? (
                                <img
                                    style={{ height: '25px', width: '25px' }}
                                    src="Error.svg"
                                    alt="exclamation point"
                                />
                            ) : (
                                <img
                                    style={{ height: '25px', width: '25px' }}
                                    src="checkmark.svg"
                                    alt="checkmark"
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                    <Grid container direction="column" gap="8px">
                        <Grid item>
                            <Typography
                                style={{
                                    fontFamily: 'Metric',
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    color: colors['--primary-midnight'],
                                }}
                            >
                                {' '}
                                {primaryText}{' '}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                style={{
                                    fontFamily: 'Metric',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    color: colors['--primary-midnight'],
                                }}
                            >
                                {' '}
                                {preTextSecondary}{' '}
                                {isErrorState ? (
                                    <HelpAndFeedbackLink />
                                ) : (
                                    <Button
                                        type="button"
                                        onClick={buttonHandler}
                                        style={{
                                            border: 'none',
                                            padding: 0,
                                            backgroundColor: colors['--pure-white'],
                                            color: colors['--primary-teal'],
                                        }}
                                    >
                                        {' '}
                                        {buttonText}{' '}
                                    </Button>
                                )}{' '}
                                {postTextSecondary}{' '}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container style={{ height: '100%' }} alignItems="center">
                        <Grid item>
                            <Button
                                onClick={handleClose}
                                style={{
                                    border: 'none',
                                    padding: 0,
                                    backgroundColor: colors['--pure-white'],
                                }}
                            >
                                <img
                                    src="close-grey.svg"
                                    alt="close button"
                                    style={{ height: '16px', width: '16px' }}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    const open = true;

    return (
        <Snackbar
            style={{
                boxShadow:
                    '0px 4px 6px rgba(0, 0, 0, 0.07), 0px 1px 12px rgba(0, 0, 0, 0.06), 0px 2px 6px -1px rgba(0, 0, 0, 0.1)',
            }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
        >
            {toast()}
        </Snackbar>
    );
};
