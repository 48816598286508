import { styled } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useNavigate } from 'react-router';

import colors from '../../../config/colorConfig';
import { strings } from '../../../utils/Strings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { HandleExitNavigation } from './ExitProgramHandler';

//
// ExitProgramButton navigates the user out of a program and back to:
// 1. CME Catalog Screen for contextual link users
// 2. The previous CME Screen for non-contextual link users
//

export function ExitProgramButton() {
    const ExitButton = styled('button')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '24px',
        padding: '24px 0 0 0',
        border: 'none',
        backgroundColor: 'transparent',
        color: colors['--ui-grey'],
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '20px',
            padding: '16px 0 16px 0',
        },
    }));

    const navigate = useNavigate();

    const persistedSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    return (
        <ExitButton onClick={() => HandleExitNavigation({ persistedSourceTab, navigate })}>
            <ArrowBackIosRoundedIcon sx={{ paddingRight: '8px' }} fontSize={'large'} />
            {strings.program_outline_exit}
        </ExitButton>
    );
}
