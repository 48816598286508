import { FrontMatterScreenResponse } from '../models/FrontMatterScreenResponse';
import { IFrontMatterScreenRequest } from './../models/IFrontMatterScreenRequest';
import { axiosClient } from './axiosClient';
import config from '../config/envConfig';
const { cmeProcessApiUrl } = config.environment;

export const getFrontMatterScreen = async (
    request: IFrontMatterScreenRequest
): Promise<FrontMatterScreenResponse> => {
    const url = `${cmeProcessApiUrl}/Product__Cme/GetFrontMatterScreen`;
    try {
        const headers = {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'Epoc-App-Version': 'latest',
            DeviceType: 'Web',
            'Epoc-Platform': 'Web',
        };

        const response = await axiosClient.post(url, request, {
            headers,
            authMode: 'auto',
        } as any);
        return response as unknown as FrontMatterScreenResponse;
    } catch (error) {
        throw error;
    }
};
