import { axiosClient } from './axiosClient';
import config from '../config/envConfig';
import { DynamicHomeScreenActivitiesResponse } from '../models/DynamicHomeScreenActivities';

const { cmeProcessApiUrl } = config.environment;

export const getDynamicHomeScreenActivities = async (
    limit: number
): Promise<DynamicHomeScreenActivitiesResponse> => {
    const url = `${cmeProcessApiUrl}/Product__Cme/GetDynamicHomeScreenActivities`;
    try {
        const headers = {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'Epoc-App-Version': 'latest',
            DeviceType: 'Web',
            'Epoc-Platform': 'Web',
        };

        const response = await axiosClient.post(url, { limit: limit }, {
            headers,
            authMode: 'auto',
        } as any);
        return response as unknown as DynamicHomeScreenActivitiesResponse;
    } catch (error) {
        throw error;
    }
};
