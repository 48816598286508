import { IProgramGlobalInfo, IProgramOutlineEntry } from '../models/EPOLMSModels';
import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CME_EPOLMS_SLIDE_VIEW } from './analytics';
import { getCurrentUserId } from '../utils/cookies';

export function sendSlideViewEvent(
    programId: string,
    slideId: string,
    outlineEntry?: IProgramOutlineEntry | null | undefined,
    globalInfo?: IProgramGlobalInfo | null | undefined
) {
    return sendAmplitudeEvent(CME_EPOLMS_SLIDE_VIEW, {
        'Program Id': String(programId),
        'Slide Id': String(slideId),
        Source: outlineEntry?.text.plain,
        'Credit Amount': globalInfo?.creditAmount,
        'Duration in Minutes': globalInfo?.durationInMinutes,
        Title: globalInfo?.programTitle?.plain,
        'Activity Type': globalInfo?.activityType,
        'Media Type': globalInfo?.mediaTypes?.join(', '),
        UserId: getCurrentUserId(),
    });
}
