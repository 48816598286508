import { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import {
    IProgramGlobalInfo,
    IProgramOutline,
    IProgramOutlineEntry,
    IQuizSlide,
    NavigationIntent,
    ParticipationMode,
} from '../../models/EPOLMSModels';
import { QuizIntent } from '../../models/QuizTransactionService';
import { ContextId } from '../../models/IdTypes';
import { GetEPOLMSQuizTransactData } from '../../queries/EPOLMSQuizTransactQuery';
import { ProgramLayout } from './ProgramLayout';
import { QuizForm } from './quiz/QuizForm';
import { SmallScreen } from '../../utils/responsive';
import colors from '../../config/colorConfig';
import { transformReactHookFormData } from '../../utils/transformReactHookFormData';
import { AlertBanner } from './quiz/AlertBanner';
import { AttemptsBanner } from './quiz/AttemptsBanner';
import { QuizScoreCard } from './quiz/QuizScoreCard';
import { RecommendedCollection } from './quiz/RecommendedCollection';
import { QuizResultCard } from './quiz/QuizResultCard';
import { PreviewBanner } from './PreviewBanner';
import { HandleExitNavigation } from './programOutline/ExitProgramHandler';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export function EPOLMSQuizSlide(props: {
    performNavigationIntent: (
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined
    ) => void;
    global: IProgramGlobalInfo;
    quizSlide: IQuizSlide;
    contextId: ContextId;
    outline?: IProgramOutline | null;
    isLoading: boolean;
    mode?: undefined | ParticipationMode;
}) {
    const QuizTitle = styled('h1')({
        fontFamily: 'Metric',
        fontWeight: 500,
        fontSize: '24px',
        margin: 0,
        padding: '32px 0 24px 24px',
    });

    const QuizContainer = styled('div')(({ theme }) => ({
        paddingBottom: '80px',
        backgroundColor: colors['--surface-ultra-light'],
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '32px',
            width: '1120px',
        },
        [theme.breakpoints.up('md') && theme.breakpoints.down('xl')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
            maxWidth: '768px',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
            maxWidth: '375px',
        },
    }));

    const [quizEndpointCounter, setQuizEndpointCounter] = useState<number>(0);

    let quizTransactRequest: any = null;
    quizTransactRequest = {
        intent: QuizIntent.Resume,
        mode: props.mode,
        quizId: props.quizSlide.quizId,
        contextId: props.contextId,
        session: window.sessionStorage.getItem('cme-epolms-quiz-session-state'),
    };
    const {
        data: quizData,
        refetch,
        isLoading,
        isFetching,
        isSuccess,
    } = GetEPOLMSQuizTransactData(quizTransactRequest);

    const isSmallScreen = SmallScreen();

    const outlineQuizTitle = props.outline?.items.filter((entry) => entry.current)[0].text.plain;

    const performNavigate = (intent: NavigationIntent) => {
        props.performNavigationIntent(
            intent,
            props.outline?.items.filter((entry) => entry.current)[0],
            props.global
        );
    };

    const navigate = useNavigate();

    const persistedSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const handleSubmit = (data: any, e: SubmitEvent) => {
        e.preventDefault();
        if (quizData?.isConcluded) {
            if (quizData?.isAdvanceable) {
                performNavigate(props.quizSlide.continueButton?.intent ?? 'next');
            } else {
                // quiz being concluded but not advanceable means it failed; user can exit, but can't continue with program
                HandleExitNavigation({ persistedSourceTab, navigate });
            }
        } else {
            quizTransactRequest.intent = quizData?.continueButton?.intent ?? QuizIntent.Submit;
            quizTransactRequest.submission = { responses: transformReactHookFormData(data) };
            refetch();
            window.scrollTo(0, 0);
        }
    };

    const handleReviewIntent = (reviewIntent: string, e?: Event) => {
        e?.preventDefault();
        quizTransactRequest.intent = reviewIntent;
        refetch();
    };

    useEffect(() => {
        let quizTemporaryCounter: number = quizEndpointCounter;
        if (isSuccess && !isFetching && !isLoading) {
            quizTemporaryCounter = quizEndpointCounter + 1;
            setQuizEndpointCounter(quizTemporaryCounter);
        }
        if (quizTemporaryCounter >= 2 && quizData?.canAdvanceSpontaneously) {
            performNavigate(props.quizSlide.continueButton?.intent ?? 'next');
        }
    }, [quizData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!quizData && !isLoading && !isFetching) return <></>;

    return (
        <>
            <ProgramLayout
                headerInformation={props.global}
                programOutline={props.outline}
                performNavigationIntent={props.performNavigationIntent}
                isLoading={isLoading || isFetching || props.isLoading}
            >
                <>
                    {quizData?.questions &&
                        !(quizEndpointCounter >= 2 && quizData?.canAdvanceSpontaneously) && (
                            <QuizContainer>
                                {!isSmallScreen && <QuizTitle>{outlineQuizTitle}</QuizTitle>}
                                {(props.mode === ParticipationMode.PublishPreview ||
                                    ParticipationMode.TesterPreview) && (
                                    <Box sx={{ maxWidth: '744px' }}>
                                        <PreviewBanner mode={props.mode} />
                                    </Box>
                                )}
                                {quizData?.statusChip?.plain && !quizData?.isConcluded && (
                                    <Box sx={{ maxWidth: '744px' }}>
                                        <AlertBanner
                                            bannerText={quizData?.statusChip.plain}
                                            bannerIcon={quizData?.statusChip?.icon}
                                            bannerTitle={quizData?.statusChip?.caption}
                                            backgroundColor={quizData?.statusChip?.bgColor}
                                        />
                                    </Box>
                                )}
                                {!quizData.isConcluded &&
                                    quizData?.attemptsText?.plain &&
                                    quizData?.requiredScoreText?.plain && (
                                        <AttemptsBanner
                                            requiredScoreText={quizData?.requiredScoreText?.plain}
                                            attemptsText={quizData?.attemptsText?.plain}
                                            previousScoreText={quizData?.lastScoreText?.plain}
                                        />
                                    )}
                                {quizData?.resultCard && (
                                    <QuizResultCard
                                        resultCard={quizData.resultCard}
                                        performNavigationIntent={() =>
                                            performNavigate(
                                                quizData.resultCard?.button?.intent || 'next'
                                            )
                                        }
                                    />
                                )}
                                {quizData?.scoreCard && (
                                    <QuizScoreCard
                                        handleReviewIntent={handleReviewIntent}
                                        scoreCard={quizData.scoreCard}
                                    />
                                )}
                                {quizData?.statusChip?.caption === 'Review' &&
                                    quizData?.isConcluded && (
                                        <Box sx={{ maxWidth: '744px' }}>
                                            <AlertBanner
                                                bannerText={quizData?.statusChip.plain}
                                                bannerIcon={null}
                                                bannerTitle={quizData?.statusChip?.caption}
                                                backgroundColor={quizData?.statusChip?.bgColor}
                                            />
                                        </Box>
                                    )}
                                <QuizForm
                                    questions={quizData?.questions}
                                    handleSubmit={handleSubmit}
                                    isConcluded={quizData?.isConcluded}
                                    quizNavBtn={quizData?.continueButton}
                                    programNavBtn={props.quizSlide.continueButton}
                                />
                                {quizData?.isConcluded && !quizData?.isAdvanceable && (
                                    <RecommendedCollection isProgramEndpoint={true} />
                                )}
                            </QuizContainer>
                        )}
                </>
            </ProgramLayout>
        </>
    );
}
