import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import colors from '../config/colorConfig';

interface StyledButtonProps {
    children: ReactNode;
    width?: any;
    maxWidth?: any;
    height?: string | number | string[];
    margin?: string | number | string[];
    type: 'button' | 'submit' | 'reset' | undefined;
    onClick?: () => void;
    id?: string;
    color?: string;
    disabled?: boolean;
}

const OutlinedBtn = styled('button')({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '16px',
    borderRadius: '3px',
    color: colors['--primary-midnight'],
    backgroundColor: colors['--pure-white'],
    border: `solid 1.5px ${colors['--ui-grey']}`,
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
        border: `solid 1.5px ${colors['--ui-grey']}`,
        backgroundColor: 'rgba(28, 47, 73, 0.05)',
    },
    '&:focus': {
        outline: 'none',
    },
});

const ContainedBtn = styled('button')({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '16px',
    borderRadius: '3px',
    border: 'none',
    color: colors['--pure-white'],
    backgroundColor: colors['--primary-midnight'],
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        backgroundColor: colors['--ui-slate'],
    },
    '&:focus': {
        outline: 'none',
    },
});

const HighlihtedContainedBtn = styled('button')(({ theme }) => ({
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '16px',
    borderRadius: '3px',
    border: 'none',
    color: colors['--pure-white'],
    backgroundColor: colors['--primary-teal-night'],
    cursor: 'pointer',
    padding: '12px 64px',
    '&:hover': {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        backgroundColor: colors['--ui-slate'],
    },
    '&:focus': {
        outline: 'none',
    },
    [theme.breakpoints.down('md')]: {
        width: '295px',
    },
    width: '198px',
}));

const TextBtn = styled('button')({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    '&:focus': {
        outline: 'none',
    },
});

export const ContainedButton = ({
    width,
    height,
    margin,
    type,
    children,
    id,
    onClick,
    disabled,
}: StyledButtonProps) => {
    return (
        <ContainedBtn
            sx={{ width: width, minHeight: height || '39px', margin: margin }}
            type={type}
            id={id}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </ContainedBtn>
    );
};

export const HighlightedContainedButton = ({
    height,
    margin,
    type,
    children,
    id,
    onClick,
    disabled,
}: StyledButtonProps) => {
    return (
        <HighlihtedContainedBtn
            sx={{
                minHeight: height || '39px',
                margin: margin,
            }}
            type={type}
            id={id}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </HighlihtedContainedBtn>
    );
};
export const OutlinedButton = ({
    width,
    height,
    margin,
    type,
    children,
    id,
    onClick,
    disabled,
}: StyledButtonProps) => {
    return (
        <OutlinedBtn
            sx={{ width: width, height: height || '48px', margin: margin }}
            type={type}
            id={id}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </OutlinedBtn>
    );
};

export const TextButton = ({
    width,
    height,
    margin,
    type,
    children,
    id,
    onClick,
    color,
    disabled,
}: StyledButtonProps) => {
    return (
        <TextBtn
            sx={{ width: width, height: height, margin: margin, color: color }}
            type={type}
            id={id}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </TextBtn>
    );
};
