import { useQuery, UseQueryResult } from 'react-query';
import { ISeriesRequest } from '../models/ISeriesRequest';
import { SeriesPageResponse } from '../models/SeriesPageResponse';
import { getSeriesPage } from '../services/seriesPageService';

export const GetSeriesPage = (seriesPageId: string): UseQueryResult<SeriesPageResponse> =>
    useQuery<SeriesPageResponse>(
        ['series-page', { seriesPageId: seriesPageId }],
        () => getSeriesPage({ seriesPageId: seriesPageId } as ISeriesRequest),
        {
            staleTime: 86400000,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            refetchOnMount: false,
        }
    );
