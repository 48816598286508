import { Typography, styled, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../config/colorConfig';
import { FilterState, updateFilterState } from '../../Slices/FilterSlice';
import { UserFilterSelection } from '../../models/ICatalogCardsRequest';
import { RootState } from '../../store/store';
import { SortBy } from '../SortBy';
import { ResponseAmplitudeState } from '../../models/AmplitudeState';
import { useEffect, useState } from 'react';
import { sendAmplitudeEvent } from '../../amplitude/AmplitudeUtil';
import {
    CME_SAVED_FILTER_SELECT,
    CME_SAVED_FILTER_CLICK,
    CME_CATALOG_FILTER_CLICK,
} from '../../amplitude/analytics';
import { GetSavedScreenActivities } from '../../queries/SavedScreenQueries';
import { isAuthenticatedUser } from '../../utils/cookies';
import { FilterType } from '../../models/FilterType';
import { QuickFilters } from '../../models/QuickFilters';
import { CatalogCardsData, CatalogCardsResponse } from '../../models/CatalogCardsResponse';
import { GetCatalogCards } from '../../queries/CatalogScreenQueries';
import { Tabs } from '../../models/Tabs';

interface FilterNavProps {
    onlyEssential: boolean;
    value: number;
    responseAmplitudeData?: ResponseAmplitudeState;
    initialSortSelection: string;
}

export function FilterNav(props: FilterNavProps) {
    const [filterButtonDisabled, setFilterButtonDisabled] = useState<boolean>(true);
    const [sortButtonDisabled, setSortButtonDisabled] = useState<boolean>(true);

    let activeFilters: UserFilterSelection[] = useSelector(
        (state: RootState) => state.filter.activeFilterSelections
    );

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    let settingsSliderImgSrc = 'settings-slider-unselected.svg';
    let allFiltersTextColor = colors['--primary-midnight'];
    let allFiltersText = isMobile ? `Filters` : `All Filters`;
    let allFiltersBackgroundColor = colors['--ui-grey-light'];
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    let matchedCardCount: number = 0;
    let cardsResponse: CatalogCardsResponse = {} as CatalogCardsResponse;
    if (currentSourceTab === Tabs.SAVED) {
        cardsResponse = GetSavedScreenActivities()?.data as unknown as CatalogCardsResponse;
    } else {
        cardsResponse = GetCatalogCards()?.data as unknown as CatalogCardsResponse;
    }
    let displayCardsResponse: CatalogCardsData = cardsResponse?.data;
    matchedCardCount = displayCardsResponse?.results?.matchedCardCount ?? -1;

    let isCDQuickFilterEnabled: boolean | undefined =
        displayCardsResponse?.availableUserFilters?.find((availableUserFilter) => {
            return availableUserFilter?.filterName === 'Credits & Duration';
        })?.isQuickFilter;

    if (isCDQuickFilterEnabled === undefined) {
        isCDQuickFilterEnabled = false;
    }

    let isMediaTypeQuickFilterEnabled: boolean | undefined =
        displayCardsResponse?.availableUserFilters?.find((availableUserFilter) => {
            return availableUserFilter?.filterName === QuickFilters.MediaType;
        })?.isQuickFilter;

    if (isMediaTypeQuickFilterEnabled === undefined) {
        isMediaTypeQuickFilterEnabled = false;
    }

    const isUserAuthenticated: boolean = isAuthenticatedUser();

    let filterState: FilterState = useSelector((state: RootState) => state.filter);
    let bgColorCD = colors['--ui-grey-light'];
    let bgColorMediaType = colors['--ui-grey-light'];
    let textColorCD = colors['--primary-midnight'];
    let textColorMediaType = colors['--primary-midnight'];
    let cmeCredit = <img src="Regular_CME-credit.svg" alt="Credits & Duration" />;
    let regularDocument = <img src="Regular_documents-many.svg" alt="Media Type" />;
    let dropDownIconCD = <img src="Icons_Streamline_regular_24pt_filter.svg" alt="DropDown" />;
    let dropDownIconMT = <img src="Icons_Streamline_regular_24pt_filter.svg" alt="DropDown" />;
    // Change the color based on the activeFilterSelection
    filterState.activeFilterSelections.map((userFilterSelection: UserFilterSelection) => {
        if (userFilterSelection.filterName === 'Credits & Duration') {
            bgColorCD = colors['--primary-midnight'];
            textColorCD = colors['--pure-white'];
            cmeCredit = <img src="CME-credit.svg" alt="Credits & Duration" />;
            dropDownIconCD = <img src="filter.svg" alt="DropDown" />;
        } else if (userFilterSelection.filterName === 'Media Types') {
            bgColorMediaType = colors['--primary-midnight'];
            textColorMediaType = colors['--pure-white'];
            regularDocument = <img src="documents-many.svg" alt="Media Type" />;
            dropDownIconMT = <img src="filter.svg" alt="DropDown" />;
        }
    });

    const dispatch = useDispatch();

    let totalActiveFilters: number = filterState?.activeFilterSelections?.reduce(function (
        previousValue,
        currentValue
    ) {
        return previousValue + currentValue.selectedValues.length;
    },
    0);
    const handleQuickFilterButton = (filterSelected: string) => {
        dispatch(
            updateFilterState({
                isActive: true,
                filterType: FilterType.QuickFilter,
                filterName: filterSelected,
            })
        );
    };

    if (
        (!isUserAuthenticated && currentSourceTab === Tabs.SAVED) ||
        matchedCardCount == 0 ||
        (matchedCardCount == 1 && activeFilters.length === 0)
    ) {
        if (!filterButtonDisabled) setFilterButtonDisabled(true);
    } else if (matchedCardCount > 0) {
        if (filterButtonDisabled) setFilterButtonDisabled(false);
    }

    // handle disabling SortBy button
    if (
        (!isUserAuthenticated && currentSourceTab === Tabs.SAVED) ||
        matchedCardCount == 0 ||
        matchedCardCount == 1
    ) {
        if (!sortButtonDisabled) setSortButtonDisabled(true);
    } else if (matchedCardCount > 0) {
        if (sortButtonDisabled) setSortButtonDisabled(false);
    }

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );

    const TopDiv = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('lg')]: {
            marginTop: 24,
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '750px',
        },
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            gap: 8,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: isSeriesActive ? '24px' : '0px',
        },
    }));

    useEffect(() => {
        if (
            filterState?.activeFilterSelections?.length > 0 &&
            !filterState.activeFilterInformation.isActive
        ) {
            let amplitudeFilterData: any = {};
            filterState?.activeFilterSelections?.map((activeFilter: UserFilterSelection) => {
                if (activeFilter.filterName === 'Credits & Duration') {
                    amplitudeFilterData['Credits and Duration'] =
                        activeFilter.selectedValues.toString();
                    return;
                }
                amplitudeFilterData[activeFilter.filterName] =
                    activeFilter.selectedValues.toString();
            });
            amplitudeFilterData['Number of Activities'] = filterState?.totalResults;
            amplitudeFilterData['Number of Filter Sections Applied'] =
                filterState?.activeFilterSelections?.length;
            amplitudeFilterData['Number of Filters Applied'] = totalActiveFilters;
            sendAmplitudeEvent(CME_SAVED_FILTER_SELECT, amplitudeFilterData);
        }
    }, [filterState.activeFilterInformation.isActive]);

    if (totalActiveFilters > 0) {
        settingsSliderImgSrc = 'settings-slider-selected.svg';
        allFiltersTextColor = colors['--pure-white'];
        allFiltersText = `${isMobile ? 'Filters' : 'All Filters'} (${totalActiveFilters})`;
        allFiltersBackgroundColor = colors['--primary-midnight'];
    }

    const handleAllFilterClick = () => {
        if (currentSourceTab === Tabs.SAVED) sendAmplitudeEvent(CME_SAVED_FILTER_CLICK);
        else if (currentSourceTab === Tabs.CATALOG) sendAmplitudeEvent(CME_CATALOG_FILTER_CLICK);
        dispatch(
            updateFilterState({ isActive: true, filterType: FilterType.Regular, filterName: '' })
        );
    };

    const settingsSlider = (
        <img src={settingsSliderImgSrc} alt="FilterList" width="12px" height="12px" />
    );

    return (
        <TopDiv>
            <Button
                disableRipple
                disabled={filterButtonDisabled}
                sx={{
                    '&:focus': { outline: 'none !important' },
                    '&:hover': { backgroundColor: allFiltersBackgroundColor },
                    backgroundColor: allFiltersBackgroundColor,
                    padding: '2px 16px 2px 8px',
                    height: 24,
                    opacity: filterButtonDisabled ? 0.4 : 1,
                    fontFamily: 'Metric',
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: 0.94,
                    textTransform: 'none',
                    '& .cme-web-MuiButton-startIcon': {
                        marginRight: '4px',
                        marginLeft: 0,
                    },
                }}
                startIcon={settingsSlider}
                onClick={handleAllFilterClick}
            >
                <Typography
                    sx={{
                        fontSize: '16px',
                        color: allFiltersTextColor,
                        fontWeight: '500',
                        letterSpacing: 'normal',
                        fontFamily: 'Metric',
                        marginLeft: '4px',
                    }}
                >
                    {allFiltersText}
                </Typography>
            </Button>
            {!props.onlyEssential && (
                <>
                    <Button
                        disableRipple
                        disabled={!isCDQuickFilterEnabled}
                        sx={{
                            '&:focus': { outline: 'none !important' },
                            '&:hover': { backgroundColor: bgColorCD },
                            backgroundColor: bgColorCD,
                            padding: '4px 16px 4px 12px',
                            height: 24,
                            width: 182,
                            opacity: isCDQuickFilterEnabled ? 1.0 : 0.4,
                            fontFamily: 'Metric',
                            fontSize: 16,
                            fontWeight: 500,
                            lineHeight: 0.94,
                            textTransform: 'none',
                            cursor: 'pointer',
                            '& .cme-web-MuiButton-startIcon': {
                                marginRight: '4px',
                                marginLeft: 0,
                            },
                            '& .cme-web-MuiButton-endIcon': { marginLeft: '4px' },
                        }}
                        startIcon={cmeCredit}
                        endIcon={dropDownIconCD}
                        onClick={() => {
                            handleQuickFilterButton(QuickFilters.CreditsAndDuration);
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: textColorCD,
                                fontWeight: '500',
                                letterSpacing: 'normal',
                                fontFamily: 'Metric',
                            }}
                        >
                            Credits & Duration
                        </Typography>
                    </Button>
                    <Button
                        disableRipple
                        disabled={!isMediaTypeQuickFilterEnabled}
                        sx={{
                            '&:focus': { outline: 'none !important' },
                            '&:hover': { backgroundColor: bgColorMediaType },
                            backgroundColor: bgColorMediaType,
                            padding: '4px 16px 4px 12px',
                            height: 24,
                            width: 138,
                            opacity: isMediaTypeQuickFilterEnabled ? 1.0 : 0.4,
                            fontFamily: 'Metric',
                            fontSize: 16,
                            fontWeight: 500,
                            lineHeight: 0.94,
                            textTransform: 'none',
                            '& .cme-web-MuiButton-startIcon': {
                                marginRight: '4px',
                                marginLeft: 0,
                            },
                            '& .cme-web-MuiButton-endIcon': { marginLeft: '4px' },
                        }}
                        startIcon={regularDocument}
                        endIcon={dropDownIconMT}
                        onClick={() => {
                            handleQuickFilterButton(QuickFilters.MediaType);
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: textColorMediaType,
                                fontWeight: '500',
                                letterSpacing: 'normal',
                                fontFamily: 'Metric',
                            }}
                        >
                            Media Type
                        </Typography>
                    </Button>
                </>
            )}
            <SortBy
                responseAmplitudeData={props.responseAmplitudeData}
                initialSortSelection={props.initialSortSelection}
                disabled={sortButtonDisabled}
            />
        </TopDiv>
    );
}
