//
// BodyColumn is a presentation component which provides layout styling to various
// EPOLMS components: EPOLMSContentSlide, EPOLMSCreditClaimSlide,
// EPOLMSFrontMatterSlide,EPOLMSQuizSlide, EPOLMSReviewSlide.
// This file may be removed when the Layout for Programs is established.
//

export function BodyColumn(inner: JSX.Element | JSX.Element[]): JSX.Element {
    return (
        <div
            style={{
                position: 'relative',
                top: '0',
                width: '68%',
                left: '5%',
                bottom: '0',
                border: '3px',
                padding: '8px',
                borderColor: '#444499',
                backgroundColor: '#beccda',
                margin: '16px',
                borderWidth: '3px',
                borderStyle: 'solid',
                color: '#111144',
                boxShadow: '8px #000000dd',
            }}
        >
            {inner}
        </div>
    );
}
