import { Box, Divider, Grid, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    updateIsSearchActive,
    updateIsSeriesActive,
    updateScrollPosition,
    updateSearchText,
    updateSeriesId,
} from '../Slices/DisplaySlice';
import { setError } from '../Slices/ErrorSlice';
import { updateActiveFilters } from '../Slices/FilterSlice';
import { updateModalOpen } from '../Slices/ModalSlice';
import { updateSaveAttemptId } from '../Slices/SaveAttemptSlice';
import {
    UserAttitudeProperties,
    updateAttitudeProperties,
    updateUndoFlag,
} from '../Slices/UserAttitudeSlice';
import { getSimplifiedCardData, sendAmplitudeEvent } from '../amplitude/AmplitudeUtil';
import { sendUserRecommendationActionAmplitudeEvent } from '../amplitude/UserActionRecommendationEvent';
import {
    CME_ACTIVITY_CLICK,
    CME_ACTIVITY_EXPAND_CLICK,
    CME_ACTIVITY_IMPRESSION,
    CME_ACTIVITY_SAVE_CLICK,
    CME_SAVED_REMOVE_CLICK,
    CME_SERIES_CLICK,
    CME_SERIES_EXPAND_CLICK,
    CME_SERIES_IMPRESSION,
} from '../amplitude/analytics';
import { sendSaveErrorEvent } from '../amplitude/sendSaveErrorEvent';
import colors from '../config/colorConfig';
import { useIsElementXPercentInViewport } from '../hooks/useIsElementXPercentInViewPort';
import { CollectionAmplitudeState } from '../models/AmplitudeState';
import { ISetUserAttitudesRequest } from '../models/ISetUserAttitudesRequest';
import { Tabs } from '../models/Tabs';
import { executeLaunchAction } from '../services/launchActionService';
import { setUserActivityAttitudes } from '../services/setUserActivityAttitudesService';
import { RootState } from '../store/store';
import { strings } from '../utils/Strings';
import { isAuthenticatedUser } from '../utils/cookies';
import { createUserActivityAttitudeRequest } from '../utils/createUserActivityAttitudeRequest';
import { coerceResponseData } from '../utils/formatData';
import { formatError } from '../utils/formatError';
import { Desktop } from '../utils/responsive';
import { setMediaImageSrc } from '../utils/setMediaImageSrc';
import { ShowErrorModal } from './Error/ShowErrorModal';
import HorizontalViewActivityCard from './HorizontalViewActivityCard';
import SavedButton from './SavedButton';
import SeriesPageBanner, { SeriesBannerProps } from './Series/SeriesPageBanner';
import TextChip from './TextChip';
import { ViewMoreButton } from './ViewMoreButton';

//
// This vertically scrollable card only appears on Catalog, Saved and See All screens.
// It is responsive for xs - xl screen sizes. The styling adapts to a cme-activity card
// or a cme-series card.
//

interface VerticalViewCardProps extends SeriesBannerProps {
    activityId: string;
    announcementText?: string | null;
    chipText?: string | undefined;
    mediaFormat?: string;
    durationText?: string;
    durationInMinutes?: number;
    creditAmount?: number | undefined;
    thumbnailUrl?: string | null;
    type?: string;
    accessCode?: string;
    shareableUrl?: string;
    subtypeId?: number;
    subtypeName?: string;
    debug?: { [key: string]: any };
    collectionAmplitudeData?: CollectionAmplitudeState | null;
    seriesPartCount?: number;
    cardIndex: number;
    isSeriesCard: boolean;
    swiperRef?: React.RefObject<HTMLDivElement>;
    isProgramEndpoint?: boolean;
    matchedCardCount?: number;
}

export function VerticalViewCard(props: VerticalViewCardProps) {
    const desktop = Desktop();
    const [isSavedState, setIsSavedState] = useState<boolean>(!!props.isSaved);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const isSearchActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSearchActive
    );

    const cardRef = useRef<HTMLDivElement>(null);
    const [isBeingRemoved, setIsBeingRemoved] = useState<boolean>(false);
    const simplifiedData = getSimplifiedCardData(props);
    const isCardVisible = useIsElementXPercentInViewport(cardRef, 20, props.swiperRef, desktop);

    const dispatch = useDispatch();
    const [displayState, setDisplayState] = useState<boolean>(true);
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    const activeTab: number = useSelector((state: RootState) => state.displayState.activeTab);

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );

    const userAttitudeProperties: UserAttitudeProperties = useSelector(
        (state: RootState) => state.snackBarState
    );

    let snackBarProperties: UserAttitudeProperties | undefined = undefined;

    const isAuthenticated = isAuthenticatedUser();
    let title: string = coerceResponseData(props.title);
    let description: string = coerceResponseData(props.description || '');

    const queryClient = useQueryClient();

    const { mutate: setUserAttitudeMutation } = useMutation(
        (setUserAttitudesRequest: ISetUserAttitudesRequest): Promise<{}> => {
            return setUserActivityAttitudes(setUserAttitudesRequest);
        },
        {
            onSuccess: () => {
                if (isSavedState && currentSourceTab === Tabs.SAVED && !isSearchActive) {
                    setDisplayState(false);
                    // clear all filters if only remaining filtered result is unsaved
                    if (props.matchedCardCount === 1) {
                        dispatch(updateActiveFilters({ clearAll: true }));
                    }
                    // refetch saved cards
                    queryClient.invalidateQueries({ queryKey: ['savedForLaterCards'] });
                }
                if (
                    isSavedState &&
                    (currentSourceTab === Tabs.DISCOVER ||
                        currentSourceTab === Tabs.CATALOG ||
                        isSearchActive)
                ) {
                    setIsBeingRemoved(false);
                }
                setIsSavedState(!isSavedState);
                if (isSavedState) {
                    sendAmplitudeEvent(CME_SAVED_REMOVE_CLICK, simplifiedData);
                    snackBarProperties = {
                        action: 'unsaved',
                        type: props.isSeriesCard ? 'Series' : 'Activity',
                        isSnackBarOpen: true,
                        cardId: props.activityId,
                    };
                } else {
                    // CME_ACTIVITY_SAVE_CLICK
                    sendAmplitudeEvent(CME_ACTIVITY_SAVE_CLICK, simplifiedData);
                    snackBarProperties = {
                        action: 'saved',
                        type: props.isSeriesCard ? 'Series' : 'Activity',
                        isSnackBarOpen: true,
                        cardId: props.activityId,
                    };
                    setIsBeingRemoved(false);
                }
                dispatch(updateAttitudeProperties(snackBarProperties));
            },
            onError: (error) => {
                const formattedError = formatError(error);
                dispatch(setError(formattedError));
                if (isSavedState) {
                    snackBarProperties = {
                        action: 'error',
                        type: props.isSeriesCard ? 'Series' : 'Activity',
                        isSnackBarOpen: true,
                        cardId: props.activityId,
                    };
                    dispatch(updateAttitudeProperties(snackBarProperties));
                } else {
                    snackBarProperties = {
                        action: 'error',
                        type: props.isSeriesCard ? 'Series' : 'Activity',
                        isSnackBarOpen: true,
                        cardId: props.activityId,
                    };
                    dispatch(updateAttitudeProperties(snackBarProperties));
                }
                sendSaveErrorEvent(
                    currentSourceTab,
                    props.isSeriesCard,
                    props.activityId,
                    isSavedState
                );
            },
        }
    );

    const handleSavedClick = (): void => {
        if (isAuthenticated) {
            if (!!isSavedState) {
                setIsBeingRemoved(true);
            }

            const requestJson = createUserActivityAttitudeRequest(
                props.isSeriesCard,
                !isSavedState,
                props.activityId
            );

            setUserAttitudeMutation({
                userActivityAttitudes: [requestJson],
            });
        } else {
            dispatch(updateSaveAttemptId(props.activityId));
            dispatch(updateModalOpen(true));
            sendUserRecommendationActionAmplitudeEvent(currentSourceTab, props.isSeriesCard);
        }
    };

    const scrollCardIntoViewByRef = () => cardRef.current?.scrollIntoView();

    useEffect(() => {
        if (
            userAttitudeProperties.undoFlag &&
            userAttitudeProperties.cardId === props.activityId &&
            (currentSourceTab === Tabs.CATALOG || currentSourceTab === Tabs.DISCOVER)
        ) {
            handleSavedClick();
            dispatch(updateUndoFlag(false));
        }
    }, [userAttitudeProperties.undoFlag]);

    useEffect(() => {
        if (props.isSeriesCard && isCardVisible) {
            sendAmplitudeEvent(CME_SERIES_IMPRESSION, simplifiedData);
        } else if (!props.isSeriesCard && isCardVisible) {
            sendAmplitudeEvent(CME_ACTIVITY_IMPRESSION, simplifiedData);
        }
    }, [isCardVisible]);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const saveAttemptId = searchParams.get('saveAttempt');
        const searchTextParameter = searchParams.get('searchText');
        if (!saveAttemptId) return;

        if (props.activityId === saveAttemptId && isAuthenticated) {
            if (!isSavedState) {
                const requestJson = createUserActivityAttitudeRequest(
                    props.isSeriesCard,
                    true,
                    props.activityId
                );

                setUserAttitudeMutation({
                    userActivityAttitudes: [requestJson],
                });
            } else {
                snackBarProperties = {
                    action: 'saved',
                    type: props.isSeriesCard ? 'Series' : 'Activity',
                    isSnackBarOpen: true,
                    cardId: props.activityId,
                };
                dispatch(updateAttitudeProperties(snackBarProperties));
            }
            if (searchTextParameter) {
                dispatch(updateSearchText(searchTextParameter));
                dispatch(updateIsSearchActive(true));
            }
            dispatch(updateSaveAttemptId(''));
            scrollCardIntoViewByRef();
        }
    }, []);

    const CustomCard = styled('div')(({ theme }) => ({
        marginTop: '24px',
        border: 'none',
        borderRadius: '4px',
        color: props.isSeriesCard ? colors['--pure-white'] : colors['--primary-midnight'],
        backgroundColor: props.isSeriesCard ? colors['--primary-midnight'] : colors['--pure-white'],
        filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))',
        [theme.breakpoints.up('xs')]: {
            width: '343px',
            minHeight: props.isSeriesCard ? '224px' : '220px',
        },
        [theme.breakpoints.up('md')]: {
            width: '720px',
            minHeight: '149px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '750px',
        },
        cursor: 'pointer',
    }));

    const ExpandedCardContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            width: '343px',
        },
        [theme.breakpoints.up('md')]: {
            width: '720px',
            borderRadius: isCollapsed ? 'none' : '0 0 4px 4px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '750px',
        },
    }));

    const CollapsedCardContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        border: 'none',
        [theme.breakpoints.up('xs')]: {
            width: '343px',
            height: '200px',
            flexDirection: 'column',
            borderRadius: isCollapsed ? '0 0 4px 4px' : '4px',
        },
        [theme.breakpoints.up('md')]: {
            width: '720px',
            height: '149px',
            flexDirection: 'row',
            borderRadius: isCollapsed ? '4px' : '4px 4px 0 0',
        },
        [theme.breakpoints.up('lg')]: {
            width: '750px',
        },
    }));

    const ThumbnailContainer = styled('div')(({ theme }) => ({
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            width: '343px',
            height: '164px',
            borderRadius: '4px 4px 0 0',
        },
        [theme.breakpoints.up('md')]: {
            width: '267px',
            height: '149px',
            borderRadius: isCollapsed ? '4px 0px 0px 4px' : '4px 0 0 0',
        },
    }));

    const CardImage = styled('img')(({ theme }) => ({
        objectFit: 'cover',
        [theme.breakpoints.up('xs')]: {
            width: '343px',
            height: '164px',
            borderRadius: '4px 4px 0 0',
        },
        [theme.breakpoints.up('md')]: {
            width: '267px',
            height: '149px',
            borderRadius: isCollapsed ? '4px 0px 0px 4px' : '4px 0 0 0',
        },
    }));

    const ThumbnailOverlay = styled('div')(({ theme }) => ({
        position: 'absolute',
        bottom: 0,
        left: 0,
        [theme.breakpoints.up('xs')]: {
            width: '343px',
            height: '164px',
            borderRadius: '4px 4px 0 0',
            background: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.25) 100%)',
        },
        [theme.breakpoints.up('md')]: {
            width: '267px',
            height: '149px',
            borderRadius: isCollapsed ? '4px 0px 0px 4px' : '4px 0 0 0',
            background: props.isSeriesCard
                ? 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #06162D 100%)'
                : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%)',
        },
    }));

    const CardContentContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            width: '343px',
            height: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '453px',
            height: '149px',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('lg')]: {
            width: '483px',
        },
    }));

    const CreditsDurationExpirationContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        padding: '16px',
        width: '100%',
        fontSize: '16px',
        lineHeight: '18px',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'space-between',
            height: props.isSeriesCard ? '60px' : '56px',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: props.isSeriesCard ? 'flex-start' : 'space-between',
            columnGap: props.isSeriesCard ? '16px' : 'none',
            height: '60px',
        },
    }));

    const CardDivider = styled(Divider)(({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
            background: props.isSeriesCard ? colors['--pure-white'] : colors['--ui-grey-med'],
        },
    }));

    const CardTitle = styled(Typography)(({ theme }) => ({
        fontSize: '24px',
        lineHeight: '28px',
        display: '-webkit-box',
        [theme.breakpoints.up('xs')]: {
            margin: '16px 16px 0 16px',
            color: colors['--pure-white'],
            maxWidth: '311px',
            minHeight: '84px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            fontWeight: 400,
            fontSize: '24px',
        },
        [theme.breakpoints.up('md')]: {
            color: props.isSeriesCard ? colors['--pure-white'] : colors['--primary-midnight'],
            maxWidth: '451px',
            minHeight: '56px',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            margin: '16px',
        },
    }));

    const ChipMediaSavedBtnContainer = styled('div')(({ theme }) => ({
        position: 'absolute',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            top: '132px',
            left: '16px',
            paddingRight: '24px',
        },
        [theme.breakpoints.up('md')]: {
            top: '110px',
            left: '16px',
            paddingRight: '27px',
        },
    }));

    const ExpirationText = styled(Typography)(({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    }));

    const setExpirationString = () => {
        let string = `Expires in ${props.expirationInDays} days`;
        if (props.expirationInDays === 1) {
            string = string.slice(0, -1);
            return string;
        } else if (props.expirationInDays === 0) {
            return `Expires today`;
        }
        return string;
    };

    const handleActivityClick = () => {
        if (props.isSeriesCard) {
            const currentScrollPosition = window.pageYOffset;
            dispatch(updateScrollPosition(currentScrollPosition));
            dispatch(updateIsSeriesActive(true));
            dispatch(updateSeriesId(props.activityId));
            if (props.collectionAmplitudeData) {
                sendAmplitudeEvent(CME_SERIES_CLICK, simplifiedData);
            }
            return;
        }
        try {
            if (props.collectionAmplitudeData) {
                sendAmplitudeEvent(CME_ACTIVITY_CLICK, simplifiedData);
            }
            executeLaunchAction({
                cmeSSODestination: 'activity',
                cmeSSOActivityCode: props.accessCode,
            });
        } catch (error) {
            ShowErrorModal(error, dispatch, strings.error_action);
        }
    };

    const handleViewMoreClick = () => {
        setIsCollapsed(!isCollapsed);
        if (isCollapsed) {
            const expandCardData = getSimplifiedCardData(props);
            const amplitudeEventTitle = props.isSeriesCard
                ? CME_SERIES_EXPAND_CLICK
                : CME_ACTIVITY_EXPAND_CLICK;
            sendAmplitudeEvent(amplitudeEventTitle, expandCardData);
        }
    };

    if (
        props.isProgramEndpoint ||
        (currentSourceTab === Tabs.DISCOVER && !isSearchActive) ||
        activeTab === -1 ||
        isSeriesActive ||
        props.responseAmplitudeData.source === ('SERIES' || 'CONTEXTUAL LINK')
    ) {
        if (props.isSeriesCard) {
            return (
                <SeriesPageBanner
                    title={title}
                    creditText={props.creditText}
                    responseAmplitudeData={props.responseAmplitudeData}
                    banner={props.banner}
                    chip={props.chip}
                    seriesText={props.seriesText}
                    seriesPageId={props.activityId}
                    card={true}
                    isSaved={isSavedState}
                    isBeingRemoved={isBeingRemoved}
                    handleActivityClick={handleActivityClick}
                    handleSavedClick={handleSavedClick}
                    cardRef={cardRef}
                ></SeriesPageBanner>
            );
        }
        return (
            <HorizontalViewActivityCard
                activityId={props.activityId}
                thumbnailUrl={props.thumbnailUrl || ''}
                chipText={props.chipText}
                description={description}
                expirationInDays={props.expirationInDays || null}
                mediaFormat={props.mediaFormat || ''}
                title={title}
                creditText={props.creditText}
                durationText={props.durationText}
                accessCode={props.accessCode}
                durationInMinutes={props.durationInMinutes}
                creditAmount={props.creditAmount}
                subtypeName={props.subtypeName}
                daysSincePublication={props.daysSincePublication}
                isSaved={isSavedState}
                responseAmplitudeData={props.responseAmplitudeData}
                collectionAmplitudeData={props.collectionAmplitudeData}
                cardIndex={props.cardIndex}
                cardRef={cardRef}
                isBeingRemoved={isBeingRemoved}
                handleActivityClick={handleActivityClick}
                handleSavedClick={handleSavedClick}
            />
        );
    }

    return displayState ? (
        <Grid item container direction="column" alignItems="center" justifyContent="center">
            <CustomCard ref={cardRef} onClick={handleActivityClick} data-testid={props.activityId}>
                <CollapsedCardContainer>
                    <ThumbnailContainer>
                        <CardImage
                            src={
                                props.thumbnailUrl ||
                                `${require('../images/thumbnail-default.png')}`
                            }
                            alt="CME course thumbnail image"
                        />
                        <ThumbnailOverlay>
                            <CardTitle
                                variant="h1"
                                sx={{
                                    display: {
                                        xs: '-webkit-box',
                                        md: 'none',
                                    },
                                }}
                            >
                                {title}
                            </CardTitle>
                            <ChipMediaSavedBtnContainer>
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    {props.chipText && <TextChip text={props.chipText} />}
                                    {props.mediaFormat && (
                                        <img
                                            src={setMediaImageSrc(props.mediaFormat)}
                                            alt={`media type: ${props.mediaFormat}`}
                                        />
                                    )}
                                </Box>

                                <SavedButton
                                    isSaved={isSavedState}
                                    onClick={handleSavedClick}
                                    isBeingRemoved={isBeingRemoved}
                                />
                            </ChipMediaSavedBtnContainer>
                        </ThumbnailOverlay>
                    </ThumbnailContainer>
                    <CardContentContainer>
                        <CardTitle
                            variant="h1"
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: '-webkit-box',
                                },
                            }}
                        >
                            {title}
                        </CardTitle>
                        <CardDivider />
                        <CreditsDurationExpirationContainer>
                            <>
                                {props.isSeriesCard ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            background: 'rgba(126, 225, 232, 0.25)',
                                            borderRadius: '4px',
                                            padding: '2px',
                                        }}
                                    >
                                        <Box>
                                            <img src={'regular-cme.svg'} alt="series icon" />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: '4px',
                                            }}
                                        >
                                            {props.seriesText}
                                        </Box>
                                    </Box>
                                ) : (
                                    <ExpirationText variant="subtitle1">
                                        {setExpirationString()}
                                    </ExpirationText>
                                )}
                            </>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={
                                        props.isSeriesCard
                                            ? 'regular-cme-credit-pure-white.svg'
                                            : 'regular-cme-credit.svg'
                                    }
                                    alt={
                                        props.isSeriesCard
                                            ? 'light credit icon'
                                            : 'dark credit icon'
                                    }
                                />
                                <Box sx={{ marginLeft: '4px' }}>{props.creditText}</Box>
                            </Box>
                            {props.isSeriesCard ? (
                                <></>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img src={'clock-circle-black.svg'} alt="clock icon" />
                                        <Box
                                            sx={{ marginLeft: '4px' }}
                                        >{`${props.durationText}`}</Box>
                                    </Box>
                                </>
                            )}

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyItems: 'center',
                                }}
                            >
                                <ViewMoreButton
                                    onClick={handleViewMoreClick}
                                    isCollapsed={isCollapsed}
                                    color={
                                        props.isSeriesCard
                                            ? colors['--pure-white']
                                            : colors['--primary-midnight']
                                    }
                                    viewMoreText={strings.view_details}
                                    viewLessText={strings.hide_details}
                                    fontWeight={400}
                                    fontSize={'16px'}
                                />
                            </Box>
                        </CreditsDurationExpirationContainer>
                    </CardContentContainer>
                </CollapsedCardContainer>
                <ExpandedCardContainer>
                    {!isCollapsed && (
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                padding: '16px',
                                textAlign: 'left',
                            }}
                        >
                            {description}
                        </Typography>
                    )}
                </ExpandedCardContainer>
            </CustomCard>
        </Grid>
    ) : (
        <></>
    );
}
