export function setDiscoverCardOverlay(card: boolean, overlayEnabled: boolean): string {
    if (card) {
        return `linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.25) 100%)`;
    } else {
        if (overlayEnabled) {
            return `linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.25) 100%)`;
        } else {
            return 'none';
        }
    }
}
