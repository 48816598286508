import * as toolkit from '@reduxjs/toolkit';

export interface ModalState {
    isOpen: boolean;
}

export const initialState: ModalState = {
    isOpen: false,
};

export const modalSlice = toolkit.createSlice({
    name: 'Modal',
    initialState,
    reducers: {
        updateModalOpen: (state, action: toolkit.PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
    },
});

export const { updateModalOpen } = modalSlice.actions;

const modalReducer = modalSlice.reducer;
export default modalReducer;
