import { useController, UseControllerProps } from 'react-hook-form';
import { ChangeEvent } from 'react';

import { StyledTextArea } from './quizStyledComponents';
import { strings } from '../../../utils/Strings';

interface MultiLineInputProps extends UseControllerProps {
    isVisible: boolean;
    readOnly: boolean;
}

export function MultiLineInput({ control, name, isVisible, readOnly }: MultiLineInputProps) {
    const { field } = useController({
        name,
        control,
    });
    const cursor = readOnly ? 'not-allowed' : 'auto';

    return (
        <StyledTextArea
            isVisible={isVisible}
            placeholder={readOnly ? field.value : strings.placeholder_input_text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                // send value to hook form
                field.onChange(e.target.value);
            }}
            onBlur={field.onBlur} // notify when input is touched/blur
            value={field.value} // input value
            name={field.name} // send down the input name
            ref={field.ref} // send input ref, so we can focus on input when error appear
            maxLength={500}
            aria-label={field.name}
            readOnly={readOnly}
            cursor={cursor}
        />
    );
}
