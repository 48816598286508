import { useEffect } from 'react';
import { executeLaunchAction } from '../services/launchActionService';
import { useParams } from 'react-router';
import { LoadingSpinner } from './LoadingSpinner';
import { Box } from '@mui/material';
import { GetLaunchActionForActivity } from '../queries/GetLaunchActionForActivityQuery';

export default function ActivityRedirect() {
    const { activityId } = useParams();

    const { data: launchActionResponse } = GetLaunchActionForActivity(activityId || '');

    useEffect(() => {
        if (launchActionResponse) {
            executeLaunchAction(launchActionResponse, { sameWindow: true });
        }
    }, [launchActionResponse]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <LoadingSpinner />
        </Box>
    );
}
