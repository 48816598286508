import { useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { sendAmplitudeEvent } from '../amplitude/AmplitudeUtil';
import { CME_COMPLETED_ACTIVITY_REDIRECT_VIEW } from '../amplitude/analytics';
import { strings } from '../utils/Strings';
import { StaticVerticalStepper } from './custom/StaticVerticalStepper';
import { getCurrentUserId } from '../utils/cookies';
import { useQuery } from 'react-query';
import { executeLaunchAction } from '../services/launchActionService';
import { fetchNextUserLaunchAction } from '../services/nextUserLaunchActionService';

//
// CompletedActivityScreen is a minimalist "You have completed an external
// activity; please close this tab." message for users returning from an
// external/SSO'd activity.
//

export const CompletedActivityScreen = () => {
    const Logo = styled('img')({
        position: 'absolute',
        margin: '42px 0 0 150px',
        width: '155px',
        height: '37px',
        '@media screen and (max-width: 1320px)': {
            margin: '24px 0 0 24px',
            width: '153.49px',
            height: '35.86px',
        },
        '@media screen and (max-width: 480px)': {
            margin: '24px 0 16px 24px',
            width: '118.83px',
            height: '28.11px',
        },
    });

    const ScreenBackgroundContainer = styled('div')(({ theme }) => ({
        width: '100%',
        height: '100%',
        backgroundColor: '#E5E5E5',
        backgroundImage: `url("desktop-gradient.svg")`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 800px',
        [theme.breakpoints.down('lg')]: {
            backgroundImage: `url("tablet-gradient.svg")`,
        },
        [theme.breakpoints.down('md')]: {
            backgroundImage: `url("mobile-gradient.svg")`,
        },
    }));

    const ScreenContentContainer = styled('div')({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        '@media screen and (max-width: 1320px)': {
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            height: 'auto',
        },
    });

    const DoctorIcon = styled('img')({
        position: 'absolute',
        zIndex: '2',
        top: 0,
        left: 440,
        '@media screen and (max-width: 1320px)': {
            top: 440,
            left: 440,
            height: 350,
        },
        '@media screen and (max-width: 1100px)': {
            display: 'none',
        },
    });

    const BubblesIcon = styled('img')({
        position: 'absolute',
        zIndex: '2',
        top: 364,
        right: 536,
        '@media screen and (max-width: 1320px)': {
            top: 480,
            right: 536,
        },
        '@media screen and (max-width: 1100px)': {
            display: 'none',
        },
    });

    const activityCode = new URLSearchParams(window.location.search).get('activityCode') ?? '';

    const { data: nextLaunchAction, isLoading: launchActionLoading } = useQuery(
        [activityCode, getCurrentUserId],
        async () => {
            return fetchNextUserLaunchAction(activityCode);
        },
        {
            enabled: true,
            cacheTime: 300.0,
            refetchOnWindowFocus: false,
            useErrorBoundary: (error: any) => error.response?.status === 500,
            retry: 1,
        }
    );

    useEffect(() => {
        sendAmplitudeEvent(CME_COMPLETED_ACTIVITY_REDIRECT_VIEW, {
            'Activity Code': activityCode,
        });
    }, []);

    useEffect(() => {
        if (nextLaunchAction?.data?.launchAction) {
            executeLaunchAction(nextLaunchAction.data?.launchAction, { sameWindow: true });
        }
    }, [nextLaunchAction]);

    if (launchActionLoading || nextLaunchAction?.data?.launchAction) return <></>; // Don't show anything until we've queried backend

    return (
        <ScreenBackgroundContainer>
            <Logo src="cme-logo.svg" alt="epocrates continuing medical education logo" />
            <ScreenContentContainer>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        component="h1"
                        sx={{
                            '@media screen and (max-width: 1320px)': {
                                margin: '100px 16px 0 16px',
                            },
                            '@media screen and (max-width: 480px)': {
                                margin: '80px 16px 0 16px',
                            },
                            textAlign: 'center',
                            fontSize: 38,
                            fontWeight: 300,
                            lineHeight: '40px',
                        }}
                    >
                        {strings.completed_activity_heading}
                    </Typography>
                    <StaticVerticalStepper />
                    <BubblesIcon src="bubbles-desktop.svg" alt="bubbles icon" />
                    <DoctorIcon src="doctor-desktop.svg" alt="doctor icon" />
                </Box>
            </ScreenContentContainer>
        </ScreenBackgroundContainer>
    );
};
