import { LaunchAction } from '../models/CMECommonResponse';
import { isAuthenticatedUser } from '../utils/cookies';
import { cmeRedirectionService } from './redirectionService';

export async function internalExecuteLaunchAction(
    action: LaunchAction | null | undefined,
    options: { sameWindow?: boolean | undefined } = { sameWindow: undefined },
    callBeforeLaunching?: (launchActionURL: string) => Promise<void>
): Promise<{ launched: boolean; url?: string }> {
    if (!action) return { launched: false };
    let url;

    // check for EPOLMS special case where, if sameWindow option is unspecified, assume same-window
    if (options.sameWindow === undefined && action.cmeSSOActivityCode?.match(/^\s*epolms\b/gim)) {
        options.sameWindow = true;
    }

    let launchFunction: (toUrl: URL | string) => void;
    if (options.sameWindow) {
        launchFunction = (toUrl) => {
            window.location.href = toUrl.toString();
        };
    } else {
        let newTab = window.open();
        launchFunction = (toUrl) => {
            if (newTab) newTab.location.href = toUrl.toString();
        };
    }

    // First see if a CME SSO destination + acessCode launch is specified
    if (action.cmeSSOActivityCode && action.cmeSSODestination) {
        const cmeRedirectData = await cmeRedirectionService({
            destination: action.cmeSSODestination,
            accessCode: action.cmeSSOActivityCode,
        });
        if (cmeRedirectData) {
            url = new URL(cmeRedirectData?.data.redirectUrl);
            if (!isAuthenticatedUser()) url.searchParams.append('skipAuth', 'true');
            if (callBeforeLaunching) {
                await callBeforeLaunching(url?.toString());
            }
            // If running locally, stay local rather than jumping to DEV
            if (
                window.location.hostname === 'localhost' ||
                window.location.hostname === '127.0.0.1' ||
                window.location.hostname === ''
            ) {
                if (url.hostname.match(/^www\.dev\.epocrates\.com\/cme\/program\//gim)) {
                    url.hostname = window.location.hostname;
                    url.protocol = window.location.protocol;
                    url.port = window.location.port;
                }
            }
            launchFunction(url);
            return { launched: true, url: url?.toString() };
        } else {
            return { launched: false, url: undefined };
        }
    }

    // Then see if a CME SSO destination (no activity) launch is specified (e.g. to catalog or signin)
    if (action.cmeSSODestination) {
        const cmeRedirectData = await cmeRedirectionService({
            destination: action.cmeSSODestination,
        });
        if (cmeRedirectData) {
            url = new URL(cmeRedirectData?.data.redirectUrl);
            if (!isAuthenticatedUser()) url.searchParams.append('skipAuth', 'true');
            if (callBeforeLaunching) {
                await callBeforeLaunching(url?.toString());
            }
            launchFunction(url);
        }
        return { launched: true, url: url?.toString() };
    }

    // Then see if a CME Series page is specified
    if (action.cmeSeriesPageId) {
        url = '/cme/series/' + action.cmeSeriesPageId;
        launchFunction(url);
        return { launched: true, url: url?.toString() };
    }

    // Finally try whatever url was specified
    if (action.url) {
        url = new URL(action.url);
        if (callBeforeLaunching) {
            await callBeforeLaunching(url?.toString());
        }
        launchFunction(url);
        return { launched: true, url: url?.toString() };
    }

    // Fall through: No action can be taken
    return { launched: false };
}

export async function executeLaunchAction(
    action: LaunchAction | null | undefined,
    options: { sameWindow?: boolean | undefined } = { sameWindow: undefined },
    callBeforeLaunching?: (launchActionURL: string) => Promise<void>
): Promise<{ launched: boolean; url?: string }> {
    let triesRemaining = 5;
    let delayMs = 500;
    do {
        try {
            const result = await internalExecuteLaunchAction(action, options, callBeforeLaunching);
            return result;
        } catch (err: any) {
            triesRemaining--;
            if (triesRemaining <= 0) {
                throw err;
            }
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            await new Promise((resolve) => setTimeout(resolve, delayMs));
            delayMs *= 2;
        }
    } while (true);
}
