import { styled, Box } from '@mui/material';
import { ResponseAmplitudeState } from '../../models/AmplitudeState';
import { featureSearchEnabled } from '../../utils/staticFeatureFlags';
import { FilterNav } from './FilterNav';
import NavigationTabs, { NavigationTabProps } from './NavigationTabs';
import SearchBar, { Img } from '../Search/SearchBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface HorizontalNavProps extends NavigationTabProps {
    responseAmplitudeData?: ResponseAmplitudeState;
    initialSortSelection: string;
    navigationTabsOnly?: boolean;
}

const TopDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        marginRight: 16,
    },
}));

export default function HorizontalNav(props: HorizontalNavProps) {
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            {featureSearchEnabled(currentSourceTab) ? (
                <div style={{ width: '100%' }}>
                    <SearchBar withLogo={true} />
                </div>
            ) : (
                <Box sx={{ margin: '24px 0 0 0' }}>
                    <Img src="cme-logo.svg" alt="epocrates continuing medical education logo" />
                </Box>
            )}
            <div
                style={{
                    marginTop: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <TopDiv>
                    <NavigationTabs value={props.value} />
                </TopDiv>
                <Box sx={{ paddingTop: '24px' }}>
                    {props.navigationTabsOnly === true ? (
                        <></>
                    ) : (
                        <FilterNav
                            value={props.value}
                            onlyEssential
                            responseAmplitudeData={props.responseAmplitudeData}
                            initialSortSelection={props.initialSortSelection}
                        />
                    )}
                </Box>
            </div>
        </Box>
    );
}
