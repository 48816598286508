import { CMECard, isActivityCard, isSeriesPageCard } from '../models/CMECommonResponse';

export interface SearchResultsOption {
    title: string;
    id: string;
}

export function getSearchResultsTitles(cards: CMECard[]): (SearchResultsOption | undefined)[] | [] {
    let titles: [] | (SearchResultsOption | undefined)[] = [];
    titles = cards
        .map((card: CMECard) => {
            if (
                card?.cardType === 'cme-activity' &&
                card?.cardPayload &&
                isActivityCard(card?.cardPayload)
            ) {
                return {
                    title: card?.cardPayload?.title,
                    id: card?.cardPayload?.activityId,
                };
            } else if (card?.cardType === 'cme-series' && isSeriesPageCard(card?.cardPayload)) {
                return {
                    title: card?.cardPayload?.title?.plain,
                    id: card?.cardPayload?.seriesPageId,
                };
            } else {
                return;
            }
        })
        .filter((card) => card !== undefined);

    return titles;
}
