import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Box, styled } from '@mui/material';

import {
    IContentSlide,
    IProgramGlobalInfo,
    IProgramOutline,
    NavigationIntent,
    ParticipationMode,
} from '../../models/EPOLMSModels';
import { ProgramLayout } from './ProgramLayout';
import { PreviewBanner } from './PreviewBanner';
import { HighlightedContainedButton } from '../StyledButtons';
import { frontMatterRenderOptions } from './frontmatter/renderOptions';

const ContentContainer = styled('div')(({ theme }) => ({
    paddingBottom: '80px',
    [theme.breakpoints.up('xl')]: {
        paddingLeft: '32px',
        paddingRight: '32px',
        width: '1120px',
    },
    [theme.breakpoints.up('md') && theme.breakpoints.down('xl')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
        maxWidth: '768px',
    },
    [theme.breakpoints.down('md')]: {
        paddingLeft: '16px',
        paddingRight: '16px',
        maxWidth: '375px',
    },
}));

export function EPOLMSContentSlide(props: {
    performNavigationIntent: (intent: NavigationIntent) => void;
    global: IProgramGlobalInfo;
    contentSlide: IContentSlide;
    outline?: IProgramOutline | null;
    isLoading: boolean;
    mode?: undefined | ParticipationMode;
}) {
    const renderedBody = documentToReactComponents(
        props.contentSlide.body!.contentfulRichText! as Document,
        frontMatterRenderOptions
    );

    return (
        <>
            <ProgramLayout
                headerInformation={props.global}
                programOutline={props.outline}
                performNavigationIntent={props.performNavigationIntent}
                isLoading={props.isLoading}
            >
                <ContentContainer>
                    <PreviewBanner mode={props.mode} />
                    {!props.isLoading && (
                        <>
                            {renderedBody}
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <HighlightedContainedButton
                                    type="button"
                                    margin="24px 0 0 0"
                                    onClick={() =>
                                        props.performNavigationIntent(
                                            props.contentSlide?.continueButton?.intent ?? 'next'
                                        )
                                    }
                                >
                                    {props.contentSlide.continueButton?.text?.plain}
                                </HighlightedContainedButton>
                            </Box>
                        </>
                    )}
                </ContentContainer>
            </ProgramLayout>
        </>
    );
}
