import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

import { ILikertScaleNestedQuestion } from '../../../models/QuizTransactionService';
import colors from '../../../config/colorConfig';
import { LargeDesktop } from '../../../utils/responsive';

// React component to show a likert scale using react hook form
interface LikertScaleProps {
    question: ILikertScaleNestedQuestion;
    scaleLeftLabel: string | undefined;
    scaleRightLabel: string | undefined;
    scaleValues: string[] | undefined;
    questionName: string;
    control: any;
    questionIndex: number;
    answerValue: string | null | undefined;
    readOnly: boolean;
    onChangeLikerScaleHandler: (
        event: string,
        value: number,
        questionId: string,
        questionLabel: string | undefined
    ) => void;
}

const RadioButtonContainer = styled('div')({});

const LikertTextLabel = styled(Typography)({
    fontFamily: 'Metric',
    color: colors['--primary-midnight'],
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
});

const RadioButtonHighlightContainer = styled('div')({
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 8px',
    borderRadius: '4px',
});

const LikertRadioInputGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('xl')]: {
        gap: '6px',
    },
    gap: '22px',
}));

const LikertScaleContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        direction: 'row',
        rowGap: '16px',
    },
    [theme.breakpoints.up('xl')]: {
        direction: 'column',
        columnGap: '23px',
    },
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
}));

export const LikertScale = ({
    question,
    scaleLeftLabel,
    scaleRightLabel,
    scaleValues,
    questionIndex,
    answerValue,
    readOnly,
    onChangeLikerScaleHandler,
}: LikertScaleProps) => {
    const largeDesktop = LargeDesktop();
    const cursor = readOnly ? 'not-allowed' : 'auto';
    const backgroundColor = () => {
        if (answerValue ?? question.userSelection) {
            if (readOnly) {
                return colors['--ui-grey-ultra-light'];
            }
            return colors['--primary-off-white'];
        }
        return colors['--surface-ultra-light'];
    };
    const border = () => {
        if (question.highlightMode === 'missed') {
            return `1px solid ${colors['--color-alert-critical']}`;
        }
        return 'default';
    };
    const accentColor: string = readOnly ? colors['--ui-grey'] : colors['--primary-teal'];
    const StyledRadioInput = styled('input')({
        border: `1px solid ${colors['--ui-grey-med']}`,
        accentColor: accentColor,
        width: '18px',
        height: '18px',
        cursor: cursor,
    });
    return (
        <Grid container direction="column" gap="16px" data-testid="likertquestion">
            <Grid item>
                {' '}
                <LikertTextLabel> {question.stem.plain} </LikertTextLabel>
            </Grid>
            <Grid
                item
                sx={{
                    borderRadius: '4px',
                    background: backgroundColor(),
                    border: border(),
                }}
            >
                <LikertScaleContainer container>
                    {!largeDesktop ? (
                        <>
                            {/* Likert scale labels are shown in a row and the inputs are shown in the
                            next row */}
                            <Grid item width="100%">
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item>
                                        <LikertTextLabel sx={{ maxWidth: '67px' }}>
                                            {' '}
                                            {scaleLeftLabel}
                                        </LikertTextLabel>
                                    </Grid>
                                    <Grid item>
                                        <LikertTextLabel sx={{ maxWidth: '67px' }}>
                                            {' '}
                                            {scaleRightLabel}
                                        </LikertTextLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <LikertRadioInputGrid
                                    container
                                    direction="row"
                                    justifyContent={'space-between'}
                                >
                                    {scaleValues?.map((value, index) => {
                                        return (
                                            <Grid item key={index}>
                                                <RadioButtonContainer>
                                                    <RadioButtonHighlightContainer>
                                                        <StyledRadioInput
                                                            type="radio"
                                                            id={index.toString()}
                                                            value={value}
                                                            checked={answerValue === value}
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                if (readOnly) return;
                                                                onChangeLikerScaleHandler(
                                                                    event.target.value,
                                                                    questionIndex,
                                                                    question.questionId,
                                                                    question.stem.plain
                                                                );
                                                            }}
                                                            data-testid="radioinput"
                                                            readOnly={readOnly}
                                                            aria-disabled={readOnly}
                                                        />
                                                    </RadioButtonHighlightContainer>
                                                </RadioButtonContainer>
                                            </Grid>
                                        );
                                    })}
                                </LikertRadioInputGrid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item>
                                {' '}
                                <LikertTextLabel> {scaleLeftLabel}</LikertTextLabel>
                            </Grid>
                            <Grid item>
                                <LikertRadioInputGrid
                                    container
                                    direction="row"
                                    justifyContent={'space-between'}
                                >
                                    {scaleValues?.map((value, index) => {
                                        return (
                                            <Grid item key={index}>
                                                <RadioButtonContainer>
                                                    <RadioButtonHighlightContainer>
                                                        <StyledRadioInput
                                                            type="radio"
                                                            id={index.toString()}
                                                            value={value}
                                                            checked={answerValue === value}
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                if (readOnly) return;
                                                                onChangeLikerScaleHandler(
                                                                    event.target.value,
                                                                    questionIndex,
                                                                    question.questionId,
                                                                    question.stem.plain
                                                                );
                                                            }}
                                                            readOnly={readOnly}
                                                            aria-disabled={readOnly}
                                                        />
                                                    </RadioButtonHighlightContainer>
                                                </RadioButtonContainer>
                                            </Grid>
                                        );
                                    })}
                                </LikertRadioInputGrid>
                            </Grid>
                            <Grid item>
                                <LikertTextLabel> {scaleRightLabel}</LikertTextLabel>
                            </Grid>
                        </>
                    )}
                </LikertScaleContainer>
            </Grid>
        </Grid>
    );
};
