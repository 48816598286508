import React from 'react';
import { Typography, Paper, Box } from '@mui/material';
import colors from '../../config/colorConfig';

export const LoginPasswordPageBody = (props: any) => {
    const validationErrors = (
        <>
            <Typography
                data-testid="errorMessage"
                variant="body2"
                sx={{ alignContent: 'left', color: colors['--error'] }}
            >
                {props.validationError}
            </Typography>
        </>
    );
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Paper sx={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 300,
                        fontSize: 24,
                        textAlign: props.titleAlign ? props.titleAlign : 'center',
                    }}
                >
                    {props.title}
                </Typography>
                {props.titleText ? props.titleText : null}
                {props.validationError ? validationErrors : null}
                {props.formBody}
                {props.additionalInfo ? props.additionalInfo : null}
                {props.cmeAccountRegistrationInfo ? props.cmeAccountRegistrationInfo : null}
            </Paper>
        </Box>
    );
};
