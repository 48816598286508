import { Grid, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableUserFilter } from '../../../models/CatalogCardsResponse';
import { FilterState } from '../../../Slices/FilterSlice';
import { Desktop } from '../../../utils/responsive';
import { QuickFilters } from '../../../models/QuickFilters';
import { QuickFilter } from './QuickFilter';
import { filterEmptySpaceClickHandler } from '../FilterScreenOverlay';
import { RootState } from '../../../store/store';

export interface QuickFilterOverlayProps {
    availableUserFilter: AvailableUserFilter | null;
    ViewResultsCount: number | undefined;
}

export const QuickFilterOverlay = (props: QuickFilterOverlayProps) => {
    const filterState: FilterState = useSelector((state: RootState) => state.filter);
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    const dispatch = useDispatch();

    const QuickFilterOverlayWrapper = styled('div')(() => ({
        position: 'relative',
        top: '54px',
        left:
            props?.availableUserFilter?.filterName === QuickFilters.CreditsAndDuration
                ? '400px'
                : '580px',
    }));

    const emptySpaceClickHandler = () => {
        filterEmptySpaceClickHandler(dispatch, filterState, currentSourceTab);
    };
    return (
        <>
            {Desktop() ? (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            display: 'block',
                            height: '100vh',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 3,
                        }}
                    >
                        <Grid
                            container
                            justifyContent="center"
                            onClick={emptySpaceClickHandler}
                            onKeyDown={emptySpaceClickHandler}
                        >
                            <Grid item>
                                <div style={{ width: '1140px', height: '100vh' }}>
                                    <QuickFilterOverlayWrapper>
                                        <QuickFilter
                                            userFilter={props?.availableUserFilter}
                                            viewResultCount={props?.ViewResultsCount}
                                        />
                                    </QuickFilterOverlayWrapper>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};
