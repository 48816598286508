import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CMELayout from './Layout/CMELayout';
import { GetCatalogCards } from '../queries/CatalogScreenQueries';
import { CollectionAmplitudeState, ResponseAmplitudeState } from '../models/AmplitudeState';
import { RootState } from '../store/store';
import { VerticalCardsContainer } from './VerticalCardsContainer';
import { ActiveFilterInformation } from '../Slices/FilterSlice';
import { updateActiveTab } from '../Slices/DisplaySlice';
import { SeriesPage } from './Series/SeriesPage';
import Modal from './Modal/Modal';
import { FilterScreenOverlay } from './Filters/FilterScreenOverlay';
import { Tabs } from '../models/Tabs';
import { Grid, styled } from '@mui/material';
import { ShowErrorModal } from './Error/ShowErrorModal';
import { strings } from '../utils/Strings';
import { LoadingSpinner } from './LoadingSpinner';
import { sendCatalogViewEvent } from '../amplitude/sendCatalogViewEvent';
import { useIsContextualLinkSource } from '../hooks/useIsContextualLinkSource';
import { SearchResults } from './Search/SearchResults';
import { useReturnToPreviousScrollPosition } from '../hooks/useReturnToPreviousScrollPosition';
import { useShowSearchResultsFromSearchParam } from '../hooks/useShowSearchResultsFromSearchParam';
import { FullScreenLoadingContainer } from './FullScreenLoadingContainer';

export const ContentContainer = styled(Grid)({
    paddingBottom: 80,
    flexDirection: 'column',
    margin: '0 !important',
    alignItems: 'center',
    maxWidth: '752px',
});

export const TopScreenLoadingContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
});

export default function CatalogScreen() {
    const dispatch = useDispatch();
    useReturnToPreviousScrollPosition();
    useShowSearchResultsFromSearchParam();

    const activeFilterInformation: ActiveFilterInformation = useSelector(
        (state: RootState) => state.filter.activeFilterInformation
    );
    const isFilterActive = activeFilterInformation.isActive;

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );

    const isSearchActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSearchActive
    );

    const seriesId: string | undefined = useSelector(
        (state: RootState) => state.displayState?.seriesId
    );

    let catalogResponse: any = null;
    catalogResponse = GetCatalogCards();

    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    useEffect(() => {
        if (currentSourceTab !== Tabs.CATALOG) {
            dispatch(updateActiveTab(Tabs.CATALOG));
        }
    }, []);

    useEffect(() => {
        if (isSeriesActive === true) {
            // refetch catalog cards in the background when a Series Page is opened to ensure Save status is updated
            catalogResponse.refetch();
            return;
        }
    }, [isSeriesActive]);

    useEffect(() => {
        if (!isSearchActive && currentSourceTab === Tabs.CATALOG) {
            catalogResponse.refetch();
        }
    }, [isSearchActive]);

    const currentSortSelection: string = useSelector(
        (state: RootState) => state.sort.selectedSortCriteria
    );

    const isContextualLink = useIsContextualLinkSource();

    const responseAmplitudeData: ResponseAmplitudeState = {
        source: 'CATALOG',
        requestInstanceID: catalogResponse?.data?.data?.processMetadata?.instanceId,
    };

    const collectionAmplitudeData: CollectionAmplitudeState = {
        collectionID: '', // collection id should be null on the catalog tab
        collectionIndex: '', // collection index should be null on the catalog tab
        collectionName: 'Catalog',
        collectionType: catalogResponse?.data?.data?.results?.processMetadata?.collectionType,
        collectionProcessID: catalogResponse?.data?.data?.results?.processMetadata?.instanceId,
        collectionProcessVersionID: catalogResponse?.data?.data?.results?.processMetadata.version,
    };

    useEffect(() => {
        if (!catalogResponse?.isSuccess) return;
        if (!isFilterActive && currentSortSelection === '') {
            sendCatalogViewEvent(
                catalogResponse.data?.data?.currentUserSortCriteria,
                catalogResponse.data?.data?.segmentAnnotation,
                isContextualLink
            );
        }
    }, [catalogResponse?.data?.data]);

    const verticalCardContainer = useMemo(() => {
        return (
            <ContentContainer data-testid="dataContainer">
                <VerticalCardsContainer
                    cardsResponse={catalogResponse}
                    responseAmplitudeData={responseAmplitudeData}
                    collectionAmplitudeData={collectionAmplitudeData}
                />
            </ContentContainer>
        );
    }, [catalogResponse?.data?.data?.results?.cards]);

    if (catalogResponse?.error) {
        ShowErrorModal(catalogResponse.error, dispatch, strings.error_loading);
    }

    return (
        <>
            <FilterScreenOverlay filterScreenData={catalogResponse?.data?.data} />
            <CMELayout
                value={1}
                responseAmplitudeData={responseAmplitudeData}
                initialSortSelection={catalogResponse.data?.data?.currentUserSortCriteria}
                filterNav={isSearchActive ? false : true}
            >
                <Modal />
                {isSeriesActive === true ? (
                    <SeriesPage seriesPageId={seriesId} />
                ) : (
                    <>
                        {isSearchActive === true ? (
                            <SearchResults
                                responseAmplitudeData={responseAmplitudeData}
                                collectionAmplitudeData={collectionAmplitudeData}
                            />
                        ) : (
                            <>
                                {catalogResponse.isLoading && (
                                    <FullScreenLoadingContainer>
                                        <LoadingSpinner />
                                    </FullScreenLoadingContainer>
                                )}
                                {catalogResponse.isFetching && !catalogResponse.isLoading && (
                                    <TopScreenLoadingContainer>
                                        <LoadingSpinner />
                                    </TopScreenLoadingContainer>
                                )}
                                {verticalCardContainer}
                            </>
                        )}
                    </>
                )}
            </CMELayout>
        </>
    );
}
