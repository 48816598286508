import { useQuery, UseQueryResult } from 'react-query';
import { CatalogCardsResponse } from '../models/CatalogCardsResponse';
import { ICatalogCardsRequest } from '../models/ICatalogCardsRequest';
import { getCatalogCards } from '../services/catalogCardsService';
import { getCurrentUserId } from '../utils/cookies';

export const SearchCMECards = (req: ICatalogCardsRequest): UseQueryResult<CatalogCardsResponse> => {
    if (req.queryString) {
        let trimmedString = req.queryString?.trim();
        req.queryString = trimmedString;
    }
    return useQuery<CatalogCardsResponse>(
        ['searchCMECards', getCurrentUserId(), req],
        () => getCatalogCards(req),
        {
            enabled: Boolean(req?.queryString?.length && req?.queryString.length > 1),
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    );
};
