import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';

import { EPOLMSReviewSlide } from './EPOLMSReviewSlide';
import { EPOLMSFrontMatterSlide } from './frontmatter/EPOLMSFrontMatterSlide';
import { EPOLMSCreditClaimSlide } from './EPOLMSCreditClaimSlide';
import { EPOLMSContentSlide } from './EPOLMSContentSlide';
import { EPOLMSQuizSlide } from './EPOLMSQuizSlide';
import {
    IProgramGlobalInfo,
    IProgramOutlineEntry,
    IProgramTransactRequest,
    NavigationIntent,
    ParticipationMode,
} from '../../models/EPOLMSModels';
import { GetEPOLMSProgramTransactData } from '../../queries/EPOLMSProgramTransactQuery';
import { sendSlideViewEvent } from '../../amplitude/sendSlideViewEvent';
import { sendSlideNavigateEvent } from '../../amplitude/sendSlideNavigateEvent';
import { LoadingSpinner } from '../LoadingSpinner';
import { executeLaunchAction } from '../../services/launchActionService';

// IProgramTransactionRequestor wraps the actual next-request to the backend /program/transact/v1,
// but bundles it with a monotonically increasing counter and a unique session Id (generated once
// each time the EPOLMSProgramExecutor is mounted).  The counter and session Id ensure that the
// requestor state will trigger the /program/transact/v1 query every time we submit a navigation
// intent, and also in the event the program id were to change for any reason.
export interface IProgramTransactionRequestor {
    backendRequest: IProgramTransactRequest;
    counter: number;
    uniqueSessionId: string;
}

// Top-level Invisible EPOLMS Program Executor:
// Given a programId, it starts a transaction loop with the backend.
// It POSTS: [ programId, currentState, intent ] => Backend /program/transact/v1
// and receives back [ updatedState, next-slide-or-action ]
//
// You could also think of this as a specialized and highly-stateful react router that,
// once loaded at a base path like /cme/program/:programId, routes depending on the backend
// has most recently indicated should be shown, rather than the contents of the current URL.
//
// This separates responsibilities such that:
// 1. The front end is concerned with displaying the most recent slide, collecting any input
// or navigation actions from the user, and informing the backend when they occur.
// 2. The backend is concerned with slide sequencing and business rule execution.
//
export function EPOLMSProgamExecutor(): JSX.Element {
    let { programId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    // const initialNavigation = searchParams.get('navigate');
    // const initialMode = searchParams.get('mode');
    let [uniqueSessionId] = useState<string>(String(Math.round(Math.random() * 999999999999))); // unique per mounted
    let [latestTransactionRequestor, setLatestTransactionRequestor] =
        useState<IProgramTransactionRequestor>({
            backendRequest: {
                programId: programId ?? 'none',
                navigate: (searchParams.get('navigate') as NavigationIntent) ?? 'resume',
                session: window.sessionStorage.getItem('cme-epolms-program-session-state') ?? null,
                mode: (searchParams.get('mode') as ParticipationMode) ?? 'normal',
            },
            counter: 0,
            uniqueSessionId,
        });
    const {
        data: latestTransactionResponse,
        isError,
        error,
        isFetching,
    } = GetEPOLMSProgramTransactData(latestTransactionRequestor, programId, uniqueSessionId);

    // Control whether we need to pause on pre-test before spontaneously navigating to
    //  the next slide. This comes in play when navigating via program outline or back button.

    // Define a function we can pass to children that lets them declare whatever navigation/button clicks as a NavigationIntent like 'next' | 'previous' | etc...
    function performNavigationIntent(
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined,
        buttonIndex?: number | null | undefined,
        buttonText?: string | null | undefined,
        destination?: string | null | undefined
    ) {
        // First, send generic Amplitude "CME - EPOLMS - Navigate" event here, with properties of latestTransactionResponse.programId and .slideId
        sendSlideNavigateEvent(
            latestTransactionResponse?.programId,
            latestTransactionResponse?.slideId,
            intent,
            outlineEntry,
            globalInfo,
            buttonIndex,
            buttonText,
            destination
        );

        // Then: update the requestor object with a newly incremented counter, latest response session state, and whatever the new
        // navigation intent from a button click might be; this will provoke a re-request in the useEffect() above.
        setLatestTransactionRequestor({
            ...latestTransactionRequestor,
            counter: latestTransactionRequestor.counter + 1, // always advance this so it's a new request when the user clicks
            backendRequest: {
                ...latestTransactionRequestor.backendRequest,
                navigate: intent,
                session: window.sessionStorage.getItem('cme-epolms-program-session-state') ?? null,
            },
        });
    }
    // Each time the transaction response has changed:
    useEffect(() => {
        if (
            !latestTransactionResponse?.promptLogin &&
            latestTransactionResponse?.programId &&
            latestTransactionResponse?.slideId
        ) {
            // Send Amplitude "CME - EPOLMS - View" event here, with properties of latestTransactionResponse.programId and .slideId
            sendSlideViewEvent(
                latestTransactionResponse?.programId,
                latestTransactionResponse?.slideId,
                latestTransactionResponse?.outline?.items.find((item) => item.current),
                latestTransactionResponse?.global
            );
        }
    }, [latestTransactionResponse]);

    // Clear out the ?navigate=...&mode=... search params; they're only meant to initialize the session
    useEffect(() => {
        if (!!searchParams.get('navigate') || !!searchParams.get('mode')) {
            searchParams.delete('navigate');
            // preserve mode query param for preview modes
            if (searchParams.get('mode') === 'normal') {
                searchParams.delete('mode');
            }
            setSearchParams(searchParams);
        }
    }, []);

    useEffect(() => {
        if (!latestTransactionResponse?.promptLogin) return;
        const loginRedirectURL: string = `${
            window.location.origin
        }/login?refernext=${encodeURIComponent(window.location.href)}&navigate=resume`;
        window.location.href = loginRedirectURL;
    }, [latestTransactionResponse?.promptLogin]);

    const FullScreenLoadingContainer = styled('div')({
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });

    // wrap content slide in useMemo to prevent content flickering after load
    const MemoContentSlide = useMemo(() => {
        if (latestTransactionResponse?.contentSlide) {
            return (
                <>
                    <EPOLMSContentSlide
                        performNavigationIntent={performNavigationIntent}
                        global={latestTransactionResponse.global}
                        contentSlide={latestTransactionResponse.contentSlide}
                        outline={latestTransactionResponse?.outline}
                        isLoading={isFetching}
                        mode={latestTransactionRequestor.backendRequest.mode}
                    />
                </>
            );
        }
    }, [isFetching]);

    // Show 'Loading' or a blank screen until the first transaction response has come back
    if (!latestTransactionResponse) {
        return (
            <FullScreenLoadingContainer>
                <LoadingSpinner />
            </FullScreenLoadingContainer>
        );
    }

    const slideKey = latestTransactionResponse.programId + ':' + latestTransactionResponse.slideId;

    // Once the transaction roundtrip is made and everything is updated, act as a router and show the appropriate slide/view/etc
    if (latestTransactionResponse?.promptLogin) {
        // Redirect in Effect above to /login?refer_next={current URL}
    } else if (latestTransactionResponse?.immediateSSOLaunchAction) {
        // Call launch action handler with latestTransactionResponse.immediateSSOLaunchAction
        executeLaunchAction(latestTransactionResponse?.immediateSSOLaunchAction, {
            sameWindow: true,
        });
    } else if (latestTransactionResponse?.frontMatterSlide) {
        return (
            <>
                <EPOLMSFrontMatterSlide
                    performNavigationIntent={performNavigationIntent}
                    global={latestTransactionResponse.global}
                    frontMatterSlide={latestTransactionResponse.frontMatterSlide}
                    outline={latestTransactionResponse.outline}
                    isLoading={isFetching}
                    key={slideKey}
                    mode={latestTransactionRequestor.backendRequest.mode}
                />
            </>
        );
    } else if (latestTransactionResponse?.quizSlide) {
        return (
            <>
                <EPOLMSQuizSlide
                    performNavigationIntent={performNavigationIntent}
                    global={latestTransactionResponse.global}
                    quizSlide={latestTransactionResponse.quizSlide}
                    contextId={latestTransactionResponse.quizSlide.quizContextId}
                    outline={latestTransactionResponse.outline}
                    isLoading={isFetching}
                    key={slideKey}
                    mode={latestTransactionResponse.quizSlide?.quizOptions?.mode}
                />
            </>
        );
    } else if (latestTransactionResponse?.contentSlide) {
        return <>{MemoContentSlide}</>;
    } else if (latestTransactionResponse?.creditClaimSlide) {
        return (
            <>
                <EPOLMSCreditClaimSlide
                    performNavigationIntent={performNavigationIntent}
                    global={latestTransactionResponse.global}
                    creditClaimSlide={latestTransactionResponse.creditClaimSlide}
                    outline={latestTransactionResponse.outline}
                    isLoading={isFetching}
                    key={slideKey}
                    mode={latestTransactionResponse.creditClaimSlide?.creditOptions?.mode}
                />
            </>
        );
    } else if (latestTransactionResponse?.reviewSlide) {
        return (
            <>
                <EPOLMSReviewSlide
                    performNavigationIntent={performNavigationIntent}
                    global={latestTransactionResponse.global}
                    reviewSlide={latestTransactionResponse.reviewSlide}
                    outline={latestTransactionResponse.outline}
                    isLoading={isFetching}
                    key={slideKey}
                    mode={latestTransactionRequestor.backendRequest.mode}
                />
            </>
        );
        // } else if (...) { ... add other new slide types here ...
    } else if (isError) {
        return <>{`Hmm, I can't show this slide type; ${error}`}</>;
    }

    return <></>;
}
