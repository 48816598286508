import axios from 'axios';
import { ErrorState } from '../Slices/ErrorSlice';

export const formatError = (error: unknown, message?: string) => {
    let formattedError: ErrorState = {
        message: `An unknown error occurred`,
    };
    if (error && !message) {
        if (axios.isAxiosError(error) && error.response) {
            formattedError = {
                statusCode: error.response.status,
                message: String(error.message),
            };
        } else if (error instanceof Error) {
            formattedError = {
                message: String(error.message),
            };
        } else {
            formattedError = {
                message: String(error),
            };
        }
    } else if (error && message) {
        if (axios.isAxiosError(error) && error.response) {
            formattedError = {
                statusCode: error.response.status,
                message: message,
            };
        } else if (error instanceof Error) {
            formattedError = {
                message: message,
            };
        } else {
            formattedError = {
                message: message,
            };
        }
    }
    return formattedError;
};
