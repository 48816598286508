import {
    TextField,
    Box,
    styled,
    Autocomplete,
    IconButton,
    // CircularProgress,
    Popper,
    PopperProps,
    Paper,
    PaperProps,
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import {
    sendSearchBarClick,
    sendSearchBarManualSearchClick,
    sendSearchClearClick,
} from '../../amplitude/sendSearchAmplitudeEvents';
import colors from '../../config/colorConfig';
import { SearchCMECards } from '../../queries/SearchBarQuery';
import {
    updateSearchText,
    updateIsSearchActive,
    updateIsSeriesActive,
    updateScrollPosition,
} from '../../Slices/DisplaySlice';
import { getSearchResultsTitles, SearchResultsOption } from '../../utils/getSearchResultsTitles';
import { strings } from '../../utils/Strings';
import SearchClearButton from './SearchClearButton';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { useDebounce } from 'use-debounce';
import { useSearchParams } from 'react-router-dom';

//
// SearchBar is an MUI Autocomplete component with custom styling
// that can be rendered with or without the CME logo.
// It appears on Discover, Catalog and Saved screens:
// xs - med screens appears at top of screen
// lg - xl screens appears in LeftNav
//

interface SearchBarProps {
    withLogo: boolean;
}

// CME Logo
export const Img = styled('img')(({ theme }) => ({
    [theme.breakpoints.between('md', 'lg')]: {
        marginRight: 24,
        width: 105,
        height: 25,
    },
    [theme.breakpoints.down('md')]: {
        marginRight: 16,
        width: 91,
        height: 22,
    },
}));

export default function SearchBar(props: SearchBarProps) {
    const dispatch: Dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamText = searchParams.get('searchText');
    const [searchText, setSearchText] = useState<string>(searchParamText || '');

    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [debouncedSearchTerm, isDebouncePending] = useDebounce(searchText, 250);

    let searchParamsObj = { searchText: '' };

    let searchRequest = {
        queryString: debouncedSearchTerm,
    };

    const { data: searchResults, refetch, isLoading, isFetching } = SearchCMECards(searchRequest);

    let options: (SearchResultsOption | undefined)[] | [] = [];
    if (searchResults?.data?.results?.cards) {
        options = getSearchResultsTitles(searchResults?.data?.results?.cards);
    }

    let noResults: boolean = false;
    if (
        (!searchResults?.data?.results?.cards?.length || !searchResults?.data?.results?.cards) &&
        !isLoading &&
        !isFetching
    ) {
        noResults = true;
    }

    const showDebounceLoading = isDebouncePending.isPending();
    let showLoading: boolean = false;
    if (showDebounceLoading || isFetching || isLoading) {
        showLoading = true;
    }

    const currentScrollPosition = window.pageYOffset;

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        setIsOptionsOpen(true);
        if (!event.target.value) {
            setIsOptionsOpen(false);
        }
    };

    const handleSearchClick = async () => {
        searchRequest.queryString = searchText;
        refetch();
        await sendSearchBarManualSearchClick(searchText);
        dispatch(updateSearchText(searchText));
        dispatch(updateIsSearchActive(true));
        setSearchText(searchText);
        dispatch(updateScrollPosition(currentScrollPosition));
        searchParamsObj.searchText = searchText;
        setSearchParams(searchParamsObj);
        setIsOptionsOpen(false);
        dispatch(updateIsSeriesActive(false));
        searchInputRef?.current?.blur();
    };

    const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        if (key === 'Enter' && searchText) {
            searchRequest.queryString = searchText;
            refetch();
            await sendSearchBarManualSearchClick(searchText);
            dispatch(updateSearchText(searchText));
            dispatch(updateIsSearchActive(true));
            setSearchText(searchText);
            dispatch(updateScrollPosition(currentScrollPosition));
            searchParamsObj.searchText = searchText;
            setSearchParams(searchParamsObj);
            setIsOptionsOpen(false);
            dispatch(updateIsSeriesActive(false));
            searchInputRef?.current?.blur();
        } else if (key === 'Escape') {
            setIsOptionsOpen(false);
            searchInputRef?.current?.blur();
        }
    };

    const handleSearchBarFocus = () => {
        if (searchText) {
            setIsOptionsOpen(true);
        } else {
            setIsOptionsOpen(false);
        }
        sendSearchBarClick(searchText);
    };

    const handleClearSearch = async () => {
        await sendSearchClearClick(searchText);
        setSearchText('');
        setIsOptionsOpen(false);
    };

    const handleInputFocus = () => {
        setIsOptionsOpen(true);
    };

    const handleInputBlur = () => {
        setIsOptionsOpen(false);
    };

    const handleSearchSuggestionClick = (event: any) => {
        const searchTextSelected = event.target.innerText;
        setIsOptionsOpen(false);
        setSearchText(event.target.innerText);
        searchParamsObj.searchText = event.target.innerText;
        setSearchParams(searchParamsObj);
        dispatch(updateSearchText(searchTextSelected));
        dispatch(updateIsSearchActive(true));
        searchInputRef?.current?.blur();
    };

    const showClearButton = useMemo(
        () => (searchText && searchText.length ? true : false),
        [searchText.length > 0]
    );

    const autoCompleteStyling = {
        backgroundColor: colors['--pure-white'],
    };

    const searchButtonStyling = {
        width: 32,
        height: 32,
        padding: 0,
        borderRadius: '4px',
        marginLeft: 'auto',
        color: colors['--pure-white'],
        backgroundColor: colors['--primary-midnight'],
        '&:hover': {
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
            backgroundColor: colors['--ui-slate'],
        },
        '&:focus': {
            outline: 'none',
        },
        '&:disabled': {
            backgroundColor: colors['--primary-midnight'],
        },
    };

    const textFieldStyling = {
        '& .cme-web-MuiInputBase-input': {
            padding: '0 0 0 16px',
            borderRadius: '4px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .cme-web-MuiOutlinedInput-root': {
            height: '40px',
            paddingRight: '4px',
            '& > fieldset': {
                borderColor: {
                    border: `1px solid ${colors['--ui-grey-med']}`,
                },
                '& > legend': {
                    width: 'auto',
                },
            },
            '& .Mui-focused': { paddingRight: '4px' },
        },
        '& .cme-web-MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
                borderColor: {
                    border: `1px solid ${colors['--primary-teal']}`,
                },
                boxShadow: '0px 2px 6px -1px rgba(0, 0, 0, 0.1)',
                filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.06))',
            },
        },
    };

    const listBoxStyling = {
        style: {
            maxHeight: 440,
            borderRadius: '4px',
        },
    };

    const StyledListItem = styled('li')({
        padding: '4px 16px',
        cursor: noResults ? 'default' : 'pointer',
        '&:hover': {
            background: noResults ? 'none' : colors['--ui-grey-ultra-light'],
        },
    });

    const LoadingListItem = styled('li')({
        cursor: 'default',
        pointerEvents: 'none',
        padding: '4px 16px',
        color: colors['--ui-grey-semi'],
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const CustomPopper = (props: PopperProps) => (
        <Popper
            onReset={undefined}
            onResetCapture={undefined}
            {...props}
            open={isOptionsOpen}
            placement="bottom"
            disablePortal={true}
            modifiers={[
                {
                    name: 'flip',
                    enabled: false,
                },
            ]}
        />
    );

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const CustomPaper = (props: PaperProps) => {
        return (
            <Paper
                sx={{
                    boxShadow: '0px 2px 6px -1px rgba(0, 0, 0, 0.1)',
                    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.06))',
                }}
                {...props}
            />
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: { xs: '24px', lg: '0' },
            }}
        >
            {props.withLogo && (
                <Img src="cme-logo.svg" alt="epocrates continuing medical education logo" />
            )}
            <Autocomplete
                forcePopupIcon={false}
                disableClearable
                freeSolo={true}
                options={options}
                fullWidth={true}
                onFocus={handleSearchBarFocus}
                inputValue={searchText}
                onInputChange={(event: any) => {
                    setSearchText(event?.target?.value || '');
                }}
                // eslint-disable-next-line @typescript-eslint/no-shadow
                filterOptions={(option: any, params: any) => {
                    let noResultsOption;
                    if (searchText.length > 1 && noResults && !showLoading) {
                        noResultsOption = [
                            {
                                title: strings.formatString(strings.cme_search_no_options, {
                                    value: params.inputValue,
                                }),
                                id: 'search-input-not-found',
                            },
                        ];
                        return noResultsOption;
                    } else if (showLoading || searchText.length === 1) {
                        return [
                            {
                                title: strings.cme_search_loading,
                                id: 'autocomplete-options-loading',
                            },
                        ];
                    }
                    return option;
                }}
                getOptionLabel={(option: any) => option.title || ''}
                ListboxProps={listBoxStyling}
                renderOption={(
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: any
                ) => {
                    return (
                        <>
                            {option.title === strings.cme_search_loading ? (
                                <LoadingListItem {...props} key={option.id}>
                                    {option.title}
                                </LoadingListItem>
                            ) : (
                                <StyledListItem
                                    {...props}
                                    key={`option-${option.id}`}
                                    onClick={handleSearchSuggestionClick}
                                >
                                    {option.title}
                                </StyledListItem>
                            )}
                        </>
                    );
                }}
                PopperComponent={CustomPopper}
                PaperComponent={CustomPaper}
                sx={autoCompleteStyling}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={searchInputRef}
                        placeholder={strings.cme_search_placeholder}
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        onKeyDown={handleKeyPress}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        sx={textFieldStyling}
                        inputProps={{ ...params.inputProps, maxLength: 1000 }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {showClearButton && (
                                        <SearchClearButton handleClick={handleClearSearch} />
                                    )}
                                    <IconButton
                                        type="button"
                                        aria-label="search"
                                        onClick={handleSearchClick}
                                        focusRipple={false}
                                        disableRipple={true}
                                        sx={searchButtonStyling}
                                    >
                                        {/* <CircularProgress
                                                size={20}
                                                thickness={5}
                                                sx={{ color: colors['--accent-violet'] }}
                                            /> */}
                                        <img src="regular-search.svg" alt="magnifying glass" />
                                    </IconButton>
                                </>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
}
