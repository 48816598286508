import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, styled } from '@mui/material';
import UserActionRecommendation from './UserActionRecommendation';
import { strings } from '../utils/Strings';
import { isAuthenticatedUser } from '../utils/cookies';
import { GetSavedScreenActivities } from '../queries/SavedScreenQueries';
import CMELayout from './Layout/CMELayout';
import { CollectionAmplitudeState, ResponseAmplitudeState } from '../models/AmplitudeState';
import type { RootState } from '../store/store';
import { ActiveFilterInformation } from '../Slices/FilterSlice';
import { VerticalCardsContainer } from './VerticalCardsContainer';
import { updateActiveTab } from '../Slices/DisplaySlice';
import { SeriesPage } from './Series/SeriesPage';
import { FilterScreenOverlay } from './Filters/FilterScreenOverlay';
import { Tabs } from '../models/Tabs';
import { sendUserRecommendationActionAmplitudeEvent } from '../amplitude/UserActionRecommendationEvent';
import { ContentContainer, TopScreenLoadingContainer } from './CatalogScreen';
import { ShowErrorModal } from './Error/ShowErrorModal';
import Modal from './Modal/Modal';
import { LoadingSpinner } from './LoadingSpinner';
import {
    sendSavedNoActivitiesViewEvent,
    sendSavedViewAmplitudeEvent,
} from '../amplitude/sendSavedViewEvents';
import { useIsContextualLinkSource } from '../hooks/useIsContextualLinkSource';
import { SearchResults } from './Search/SearchResults';
import { useReturnToPreviousScrollPosition } from '../hooks/useReturnToPreviousScrollPosition';
import { useShowSearchResultsFromSearchParam } from '../hooks/useShowSearchResultsFromSearchParam';
import { FullScreenLoadingContainer } from './FullScreenLoadingContainer';

export const SavedScreenContentContainer = styled(Grid)({
    paddingBottom: 80,
    flexDirection: 'column',
    margin: '0 !important',
    alignItems: 'center',
});

export default function SavedScreen() {
    const dispatch = useDispatch();
    useReturnToPreviousScrollPosition();
    useShowSearchResultsFromSearchParam();

    const isAuthenticated = isAuthenticatedUser();

    const activeFilterInformation: ActiveFilterInformation = useSelector(
        (state: RootState) => state.filter.activeFilterInformation
    );

    const isFilterActive = activeFilterInformation.isActive;

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );

    const seriesId: string | undefined = useSelector(
        (state: RootState) => state.displayState?.seriesId
    );

    const isSearchActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSearchActive
    );

    const currentSortSelection: string = useSelector(
        (state: RootState) => state.sort.selectedSortCriteria
    );

    let savedCardsResponse: any = null;
    savedCardsResponse = GetSavedScreenActivities();

    const responseAmplitudeData: ResponseAmplitudeState = {
        source: 'SAVED',
        requestInstanceID: savedCardsResponse?.data?.data?.processMetadata?.instanceId,
    };

    const collectionAmplitudeData: CollectionAmplitudeState = {
        collectionID: '', // collection id should be null on the saved tab
        collectionIndex: '', // collection index should be null on the saved tab
        collectionName: 'Saved',
        collectionType: savedCardsResponse?.data?.data?.results?.processMetadata?.collectionType,
        collectionProcessID: savedCardsResponse?.data?.data?.results?.processMetadata?.instanceId,
        collectionProcessVersionID:
            savedCardsResponse?.data?.data?.results?.processMetadata.version,
    };

    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    useEffect(() => {
        if (currentSourceTab !== Tabs.SAVED) {
            dispatch(updateActiveTab(Tabs.SAVED));
        }
    }, []);

    const isContextualLink = useIsContextualLinkSource();

    useEffect(() => {
        if (!savedCardsResponse?.isSuccess) return;
        if (!isFilterActive && currentSortSelection === '') {
            sendSavedViewAmplitudeEvent(
                savedCardsResponse.data?.data?.results?.matchedCardCount,
                isContextualLink
            );
            if (savedCardsResponse.data?.data?.results.matchedCardCount === 0) {
                sendSavedNoActivitiesViewEvent();
            }
        }
    }, [savedCardsResponse?.data?.data]);

    const verticalCardContainer = useMemo(() => {
        return (
            <>
                <ContentContainer>
                    <VerticalCardsContainer
                        cardsResponse={savedCardsResponse}
                        responseAmplitudeData={responseAmplitudeData}
                        collectionAmplitudeData={collectionAmplitudeData}
                        matchedCardCount={savedCardsResponse.data?.data?.results?.matchedCardCount}
                    />
                </ContentContainer>
            </>
        );
    }, [savedCardsResponse?.data?.data?.results?.cards]);

    useEffect(() => {
        if (!isAuthenticated) {
            sendUserRecommendationActionAmplitudeEvent(Tabs.SAVED);
            sendSavedViewAmplitudeEvent(0, isContextualLink);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && !isSearchActive && currentSourceTab === Tabs.SAVED) {
            savedCardsResponse.refetch();
        }
    }, [isSearchActive, isAuthenticated]);

    if (!isAuthenticated) {
        return (
            <CMELayout
                value={2}
                initialSortSelection={savedCardsResponse.data?.data?.currentUserSortCriteria}
            >
                <SavedScreenContentContainer container sx={{ maxWidth: '752px' }}>
                    <Box sx={{ height: '100vh' }}>
                        <UserActionRecommendation
                            headingText={strings.saved_unauth}
                            isModal={false}
                        />
                    </Box>
                </SavedScreenContentContainer>
            </CMELayout>
        );
    }

    if (savedCardsResponse.error) {
        ShowErrorModal(savedCardsResponse.error, dispatch, strings.error_loading);
    }

    return (
        <>
            <FilterScreenOverlay filterScreenData={savedCardsResponse?.data?.data} />
            <CMELayout
                value={2}
                responseAmplitudeData={responseAmplitudeData}
                initialSortSelection={savedCardsResponse.data?.data?.currentUserSortCriteria}
                filterNav={isSearchActive ? false : true}
            >
                <Modal />
                {isSeriesActive === true ? (
                    <SeriesPage seriesPageId={seriesId} />
                ) : (
                    <>
                        {isSearchActive === true ? (
                            <SearchResults
                                responseAmplitudeData={responseAmplitudeData}
                                collectionAmplitudeData={collectionAmplitudeData}
                            />
                        ) : (
                            <>
                                {!isSeriesActive && savedCardsResponse.isLoading && (
                                    <FullScreenLoadingContainer>
                                        <LoadingSpinner />
                                    </FullScreenLoadingContainer>
                                )}
                                {!isSeriesActive &&
                                    savedCardsResponse.isFetching &&
                                    !savedCardsResponse.isLoading && (
                                        <TopScreenLoadingContainer>
                                            <LoadingSpinner />
                                        </TopScreenLoadingContainer>
                                    )}
                                {verticalCardContainer}
                            </>
                        )}
                    </>
                )}
            </CMELayout>
        </>
    );
}
