import React from 'react';
import { Section } from '../../models/CMECommonResponse';
import { SeriesCollection } from '../Series/SeriesPageCollection';
import { ResponseAmplitudeState } from '../../models/AmplitudeState';

interface SeriesPageCollectionsProps {
    sections: Section[];
    responseAmplitudeData: ResponseAmplitudeState;
}

export const SeriesPageCollections = ({
    sections,
    responseAmplitudeData,
}: SeriesPageCollectionsProps) => {
    return (
        <>
            {sections.map((section: Section, collectionIndex: number) => (
                <SeriesCollection
                    key={`series-collection-${collectionIndex}-` + section.title.plain}
                    collectionIndex={collectionIndex}
                    responseAmplitudeData={responseAmplitudeData}
                    section={section}
                />
            ))}
        </>
    );
};
