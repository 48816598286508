import { useFormContext } from 'react-hook-form';

import { IFrontendQuizQuestion, QuizTextEntrySize } from '../../../models/QuizTransactionService';
import { SingleLineInput } from './SingleLineInput';
import { MultiLineInput } from './MultiLineInput';
import { MultiChoiceOptions } from './MultiChoiceOptions';
import { LikertScaleWrapper } from './LikertScaleWrapper';

export interface PolymorphicQuizAnswerProps {
    question: IFrontendQuizQuestion;
    questionName: string;
}

export function PolymorphicQuizAnswer({ question, questionName }: PolymorphicQuizAnswerProps) {
    const { control } = useFormContext();
    const readOnly: boolean = question.isReadOnly ?? false;

    if (question.freeText) {
        if (question.freeText.textEntrySize === QuizTextEntrySize.SingleLine) {
            return (
                <SingleLineInput
                    control={control}
                    name={`${questionName}.writeIn`}
                    isVisible={true}
                    readOnly={readOnly}
                />
            );
        }
        if (question.freeText.textEntrySize === QuizTextEntrySize.MultiLine) {
            return (
                <MultiLineInput
                    control={control}
                    name={`${questionName}.writeIn`}
                    isVisible={true}
                    readOnly={readOnly}
                />
            );
        }
    } else if (question.multipleChoice) {
        const inputType = question.multipleChoice.canSelectMultiple ? 'checkbox' : 'radio';

        return (
            <MultiChoiceOptions
                options={question.multipleChoice.options}
                type={inputType}
                name={`${questionName}.choices`}
                readOnly={readOnly}
                rationale={question.multipleChoice.rationale}
            />
        );
    } else if (question.likertScale) {
        return (
            <LikertScaleWrapper
                question={question}
                questionName={questionName}
                readOnly={readOnly}
            />
        );
    }

    return <></>;
}
