import { useQuery, UseQueryResult } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { CatalogCardsResponse } from '../models/CatalogCardsResponse';
import { ICatalogCardsRequest } from '../models/ICatalogCardsRequest';
import { Tabs } from '../models/Tabs';
import { getCatalogCards } from '../services/catalogCardsService';
import { RootState } from '../store/store';
import { getCurrentUserId } from '../utils/cookies';
import { GetCatalogCardsRequest } from '../utils/GetCatalogCardsRequest';
import { updateSortCriteria } from '../Slices/SortSlice';

export const GetCatalogCards = (
    req?: ICatalogCardsRequest
): UseQueryResult<CatalogCardsResponse> => {
    const dispatch = useDispatch();
    let catalogScreenRequest: ICatalogCardsRequest;
    catalogScreenRequest = GetCatalogCardsRequest(req);

    let currentSourceTab: number = useSelector((state: RootState) => state.displayState.activeTab);
    return useQuery<CatalogCardsResponse>(
        ['catalogCards', getCurrentUserId(), catalogScreenRequest],
        () => getCatalogCards(catalogScreenRequest),
        {
            enabled: currentSourceTab === Tabs.CATALOG,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            keepPreviousData: true,
            onSuccess: (data: CatalogCardsResponse) =>
                dispatch(updateSortCriteria(data.data.availableUserSortCriteria)),
        }
    );
};
