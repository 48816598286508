import { Typography, Box } from '@mui/material';
import colors from '../../config/colorConfig';
import { strings } from '../../utils/Strings';
import { HelpAndFeedbackLink } from './HelpAndFeedBackLink';

interface ErrorModalMessageProps {
    errorMessage?: string | null;
}

export const ErrorModalMessage = ({ errorMessage }: ErrorModalMessageProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
                component="h2"
                sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '28px',
                }}
            >
                {errorMessage || strings.error_fallback_title}
            </Typography>
            <Typography
                component="p"
                sx={{
                    fontSize: '16px',
                    lineHeight: '20px',
                    marginTop: '24px',
                    color: colors['--ui-slate'],
                }}
            >
                {strings.error_message_text.part1}
                <HelpAndFeedbackLink />
                {strings.error_message_text.part2}
            </Typography>
        </Box>
    );
};
