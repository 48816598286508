import { SeriesBannerProps } from '../components/Series/SeriesPageBanner';

export interface ResponseProcessMetadata {
    instanceId: string;
    version: string;
}

export interface Chip {
    text: string;
    fgColor: string;
    bgColor: string;
}

export interface DisplayGraphic {
    imageUrl: string;
}

export interface Debug {
    occupations?: string[] | undefined;
    specialties?: string[] | undefined;
    weight?: number | undefined;
    weightComponents?: WeightComponents | undefined;
}

export interface WeightComponents {
    durationInMinutes: number;
    maxCreditValue: number;
    creditValues: any[];
    publicationTimestamp: number;
    daysSincePublication: number;
    expirationTimestamp: number;
    daysUntilExpiration: number;
    scheduleCompletion: number;
    isDuringSchedule: number;
    specialties: any[];
    occupations: any[];
    topics: any[];
    mediaTypes: any[];
    exponential60DayNovelty: number;
    expiresSoon: number;
    promotionalWeight: number;
    specialtyMatch: number;
    occupationMatch: number;
    learnerStarted: number;
    learnerCompleted: number;
    learnerSaved: number;
    daysSinceLearnerSaved: number;
    learnerProgress: number;
    searchRelevanceScore: number;
    userActivityDither: number;
}

export interface DisplayText {
    plain: string;
}

export interface CollectionProcessMetadata extends ResponseProcessMetadata {
    collectionId: number;
    collectionType: string;
}

export interface CMECard {
    cardType: string;
    cardPayload: CardPayload;
}

export type CardPayload =
    | ActivityCard
    | SupplementalCard
    | ResourceCard
    | SeriesPageCard
    | SeriesBannerProps;

export interface SupplementalCard {
    caption: DisplayText;
    buttonText: DisplayText;
    clickAction: LaunchAction | undefined | null;
}
export function isSupplementalCard(what: any): what is SupplementalCard {
    return what?.caption && what?.buttonText;
}

export interface ResourceCard {
    caption: DisplayText;
    clickAction: LaunchAction | undefined | null;
}
export function isResourceCard(what: any): what is ResourceCard {
    return what?.caption;
}

export interface ActivityCard {
    accessCode: string;
    activityId: string;
    announcementText: string | null;
    chipText: string | null;
    creditAmount: number | undefined;
    creditText: string | null;
    description: string;
    durationInMinutes: number;
    durationText: string;
    mediaFormat: string;
    mediaFormatId: number;
    daysSincePublication: number;
    expirationInDays: number | null;
    isSaved?: boolean | undefined;
    shareableUrl: string;
    subtypeId: number;
    subtypeName: string;
    thumbnailUrl: string | null;
    title: string;
    type: string;
    debug?: Debug;
}

export function isActivityCard(what: any): what is ActivityCard {
    return (
        what?.title && what?.hasOwnProperty('creditAmount') && what?.durationText && what.activityId
    );
}

export interface SeriesPageCard {
    seriesPageId: string;
    banner: DisplayGraphic;
    chipText?: string;
    chip: Chip;
    isSaved: boolean;
    title: DisplayText;
    clickAction: LaunchAction;
    creditText: string | null;
    seriesText: string;
    creditAmount: number | undefined;
    durationInMinutes: number;
    daysSincePublication: number;
    expirationInDays: number | null;
    seriesPartCount?: number;
    durationText: string;
    description: DisplayText;
}
export function isSeriesPageCard(what: any): what is SeriesPageCard {
    return what?.seriesPageId && what?.banner; // TODO: Check more things?
}

export function isSeriesBannerCard(what: any): what is SeriesBannerProps {
    return what?.seriesPageId && what?.banner; // TODO: Check more things?
}

export interface Section {
    orientation: string;
    title: DisplayText;
    processMetadata: CollectionProcessMetadata;
    totalCardCount: number;
    matchedCardCount: number;
    returnedCardCount: number;
    cardType: string;
    cards: CMECard[];
}

export interface LaunchAction {
    // First there may be a cme-api SSO target
    cmeSSODestination?: string | null;
    cmeSSOActivityCode?: string | null;

    // Or there may be a CME series page
    cmeSeriesPageId?: string | null;

    // Finally it may just be a URL
    url?: string | null;
}
