import {
    SyntheticEvent,
    MouseEvent,
    Dispatch,
    SetStateAction,
    useRef,
    useEffect,
    useState,
} from 'react';
import { Box, CircularProgress } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import colors from '../../../config/colorConfig';
import { Duration } from './Duration';
import { PlaybackRateMenu } from './PlaybackRateMenu';
import {
    MediaDurationContainer,
    MediaIconButton,
    PlayPauseIconContainer,
    ProgressSlider,
    VideoControlsContainer,
    VideoPlayerContainer,
    VolumeControlsContainer,
    VolumeSlider,
} from './mediaStyledComponents';
import { VideoPlayerError } from './VideoPlayerError';

//
// Custom video controls with play/pause, seek, volume,
// playback rate, and fullscreen controls
//

interface VideoPlayerControlsProps {
    playing: boolean;
    handlePlayPause: () => void;
    muted: boolean;
    handleToggleMuted: () => void;
    duration: number;
    played: number;
    handleSeekChange: (event: Event, value: number | number[], activeThumb: number) => void;
    handleSeekMouseDown: () => void;
    handleSeekMouseUp: (e: SyntheticEvent | Event, value: number | number[]) => void;
    volume: number;
    handleVolumeChange: (event: Event, value: number | number[], activeThumb: number) => void;
    handleVolumeSeekUpHandler: (e: SyntheticEvent | Event, value: number | number[]) => void;
    playbackRate: number;
    handleSetPlaybackRate: (e: MouseEvent<HTMLUListElement>) => void;
    handleClickFullScreen: () => void;
    buffering: boolean;
    showError: boolean;
    playbackMenuOpen: boolean;
    setPlaybackMenuOpen: Dispatch<SetStateAction<boolean>>;
    handleKeyPress: (event: any) => void;
    showLargePlayPause: boolean;
    handleShowLargePlayPause: () => void;
}

export const VideoPlayerControls = function VideoPlayerControls({
    playing,
    handlePlayPause,
    muted,
    handleToggleMuted,
    duration,
    played,
    handleSeekChange,
    handleSeekMouseDown,
    handleSeekMouseUp,
    volume,
    handleVolumeChange,
    handleVolumeSeekUpHandler,
    handleClickFullScreen,
    buffering,
    showError,
    playbackRate,
    handleSetPlaybackRate,
    playbackMenuOpen,
    setPlaybackMenuOpen,
    handleKeyPress,
    showLargePlayPause,
    handleShowLargePlayPause,
}: VideoPlayerControlsProps) {
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const videoControlsRef = useRef<HTMLDivElement>(null);
    const [hideVideoControls, setHideVideoControls] = useState<boolean>(false);
    const [showInitialPlayIcon, setShowInitialPlayIcon] = useState<boolean>(true);

    // hide video controls if video is playing onMouseLeave
    // hide video controls if PlaybackRateMenu is closed onMouseLeave
    const handleMouseLeave = () => {
        if (!playbackMenuOpen) setHideVideoControls(true);
        if (playing) setHideVideoControls(true);
        if (!playbackMenuOpen && playing) setHideVideoControls(true);
    };

    // mousemove event listener shows video controls on mouse move
    // trigger hide video controls with state after 5 second delay
    useEffect(() => {
        let timeout: any;

        const displayOpacity = () => {
            if (videoControlsRef.current) videoControlsRef.current.style.opacity = '1';

            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setHideVideoControls(true);
            }, 5000);
        };

        if (videoContainerRef.current) {
            videoContainerRef.current.addEventListener('mousemove', displayOpacity);
        }

        return () => {
            if (videoContainerRef.current) {
                videoContainerRef.current.removeEventListener('mousemove', displayOpacity);
            }
        };
    }, [playbackMenuOpen]);

    // hide video controls if the PlaybackRateMenu is closed
    // hide video controls if video is playing
    // reset hide video controls state
    useEffect(() => {
        if (hideVideoControls && !playbackMenuOpen && playing) {
            if (videoControlsRef.current) videoControlsRef.current.style.opacity = '0';
        }
        if (hideVideoControls) {
            setHideVideoControls(false);
        }
    }, [hideVideoControls]);

    // prevent video container play/pause on playbackRate menu useOutsideClick
    useEffect(() => {
        if (playbackMenuOpen) {
            if (videoContainerRef.current) videoContainerRef.current.style.pointerEvents = 'none';
        } else {
            if (videoContainerRef.current) videoContainerRef.current.style.pointerEvents = 'auto';
        }
    }, [playbackMenuOpen]);
    if (showError) return <VideoPlayerError />;

    return (
        <>
            <VideoPlayerContainer
                onClick={() => {
                    handlePlayPause();
                    handleShowLargePlayPause();
                    if (showInitialPlayIcon) setShowInitialPlayIcon(false);
                }}
                onMouseLeave={handleMouseLeave}
                ref={videoContainerRef}
                onKeyDown={handleKeyPress}
                tabIndex={0}
            >
                {buffering && (
                    <CircularProgress
                        size={64}
                        sx={{
                            color: colors['--primary-teal-night'],
                        }}
                    />
                )}
                {showLargePlayPause && !buffering && (
                    <PlayPauseIconContainer
                        sx={{
                            opacity: showLargePlayPause ? '1' : '0',
                            transition: 'opacity 0.5s',
                        }}
                    >
                        {playing ? (
                            <PlayArrowRoundedIcon
                                sx={{
                                    width: { xs: '48px', lg: '80px' },
                                    height: { xs: '48px', lg: '80px' },
                                }}
                            />
                        ) : (
                            <PauseRoundedIcon
                                sx={{
                                    width: { xs: '48px', lg: '80px' },
                                    height: { xs: '48px', lg: '80px' },
                                }}
                            />
                        )}
                    </PlayPauseIconContainer>
                )}
                {showInitialPlayIcon && !buffering && (
                    <PlayPauseIconContainer>
                        <PlayArrowRoundedIcon
                            sx={{
                                width: { xs: '48px', lg: '80px' },
                                height: { xs: '48px', lg: '80px' },
                            }}
                        />
                    </PlayPauseIconContainer>
                )}
                <VideoControlsContainer
                    onKeyDown={handleKeyPress}
                    onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                    ref={videoControlsRef}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <ProgressSlider
                            min={0}
                            max={duration}
                            value={played}
                            onDragStart={handleSeekMouseDown}
                            onChange={(e: Event, value: number | number[], activeThumb: number) =>
                                handleSeekChange(e, value, activeThumb)
                            }
                            onChangeCommitted={(
                                e: SyntheticEvent | Event,
                                value: number | number[]
                            ) => handleSeekMouseUp(e, value)}
                            sx={{ color: colors['--primary-teal-night'] }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MediaIconButton
                            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                e.stopPropagation();
                                handlePlayPause();
                                handleShowLargePlayPause();
                                if (showInitialPlayIcon) setShowInitialPlayIcon(false);
                            }}
                            disableRipple
                            sx={{ marginRight: '16px' }}
                            aria-label="play pause button"
                        >
                            {playing ? (
                                <PauseRoundedIcon
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                    }}
                                />
                            ) : (
                                <PlayArrowRoundedIcon
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                    }}
                                />
                            )}
                        </MediaIconButton>
                        <MediaDurationContainer>
                            <Duration seconds={played} /> /
                            <Duration seconds={duration} />
                        </MediaDurationContainer>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                            }}
                        >
                            <VolumeControlsContainer>
                                <VolumeSlider
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    value={volume}
                                    onClick={(e: MouseEvent<HTMLSpanElement>) =>
                                        e.stopPropagation()
                                    }
                                    onChangeCommitted={(
                                        e: SyntheticEvent | Event,
                                        value: number | number[]
                                    ) => handleVolumeSeekUpHandler(e, value)}
                                    onChange={(
                                        e: Event,
                                        value: number | number[],
                                        activeThumb: number
                                    ) => handleVolumeChange(e, value, activeThumb)}
                                />
                                <MediaIconButton
                                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation();
                                        handleToggleMuted();
                                    }}
                                    aria-label="volume button"
                                    disableRipple
                                >
                                    {muted ? (
                                        <VolumeOffIcon
                                            sx={{
                                                width: '32px',
                                                height: '32px',
                                            }}
                                        />
                                    ) : (
                                        <VolumeUpIcon
                                            sx={{
                                                width: '32px',
                                                height: '32px',
                                            }}
                                        />
                                    )}
                                </MediaIconButton>
                            </VolumeControlsContainer>
                            <MediaIconButton
                                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                    e.stopPropagation();
                                    handleClickFullScreen();
                                }}
                                disableRipple
                                sx={{ margin: '0 8px 0 16px' }}
                                aria-label="full screen button"
                            >
                                <FullscreenIcon
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                    }}
                                />
                            </MediaIconButton>
                            <PlaybackRateMenu
                                handleSetPlaybackRate={handleSetPlaybackRate}
                                playbackRate={playbackRate}
                                playbackRateMenuOpen={playbackMenuOpen}
                                setPlaybackMenuOpen={setPlaybackMenuOpen}
                            />
                        </Box>
                    </Box>
                </VideoControlsContainer>
            </VideoPlayerContainer>
        </>
    );
};
