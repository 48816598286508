import { CME_MODAL_CLOSE } from './analytics';
import { Tabs } from '../models/Tabs';
import { sendAmplitudeEvent } from './AmplitudeUtil';

export const sendModalCloseAmplitudeEvent = (currentSourceTab: number, modalTitle: string) => {
    const currentSource = Tabs[currentSourceTab];
    return sendAmplitudeEvent(CME_MODAL_CLOSE, {
        Source: currentSource,
        'Modal Title': modalTitle,
    });
};
