import { styled, Typography } from '@mui/material';
import { theme } from '../styles/theme';
import { strings } from '../utils/Strings';

export default function NoSavedActivitiesScreen() {
    const ImgWrapperDiv = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            marginTop: '53.85px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '93.85px',
        },
        marginTop: '37.85px',
    }));

    const TypographyWrapperDiv = styled('div')(() => ({
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            marginTop: '25.67px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '25.67px',
        },
        marginTop: '21.67px',
    }));

    return (
        <>
            <ImgWrapperDiv>
                <img
                    src="Icon.svg"
                    alt="CME Icon"
                    style={{ height: '52px', width: '59px', alignSelf: 'center' }}
                />
            </ImgWrapperDiv>
            <TypographyWrapperDiv>
                <Typography
                    sx={{
                        height: '60px',
                        width: '319px',
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: 'Metric',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        letterSpacing: 'normal',
                        textAlign: 'center',
                        color: '#000000',
                    }}
                >
                    {strings.no_saved_activities}
                    <br /> {strings.no_saved_activities_recommendation}
                </Typography>
            </TypographyWrapperDiv>
        </>
    );
}
