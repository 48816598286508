import { styled } from '@mui/material';
import colors from '../../config/colorConfig';
import { theme } from '../../styles/theme';
import { ViewResultsButton } from './ViewResultsButton';

export interface ViewResultsProperties {
    totalResults: number | undefined;
}

export const ViewResults = (props: ViewResultsProperties) => {
    const ViewResultLayoutDiv = styled('div')(() => ({
        width: '400px',
        padding: '16px 40px 16px 40px',
        [theme.breakpoints.down('md')]: {
            width: '375px',
            padding: '16px 24px 16px 24px',
        },
    }));

    return (
        <ViewResultLayoutDiv
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '72px',
                backgroundColor: colors['--pure-white'],
                boxShadow: '0 -1px 3px 0 rgba(0, 0, 0, 0.12)',
            }}
        >
            <ViewResultsButton totalResults={props?.totalResults} />
        </ViewResultLayoutDiv>
    );
};
