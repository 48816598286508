import { createTheme } from '@mui/material';
import colors from '../config/colorConfig';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 1200,
            xl: 1440,
        },
    },
    palette: {
        primary: {
            main: colors['--primary-midnight'],
        },
        secondary: {
            main: colors['--ui-slate'],
        },
        error: {
            main: colors['--error'],
        },
        background: { default: colors['--surface-ultra-light'] },
    },
    typography: {
        fontFamily: ['Metric'].join(','),
    },
});
