import { useEffect } from 'react';
import { styled, Divider } from '@mui/material';
import { ContainedButton } from '../StyledButtons';
import colors from '../../config/colorConfig';
import envConfig from '../../config/envConfig';
import { sendFrontMatterContinueClickEvent } from '../../amplitude/sendFrontMatterContinueClickEvent';
import { awaitAllOutstandingEvents } from '../../amplitude/AmplitudeUtil';
import { sendFrontMatterViewEvent } from '../../amplitude/sendFrontMatterViewEvent';

//
// StaticFrontMatter is a static HTML page for
// ACHL AD220 achl-cme-4521
//

export default function StaticFrontMatter() {
    const FrontMatterContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        gap: 8,
        margin: '0 150px',
        fontFamily: 'Metric',
        fontSize: '1em',
        color: colors['--primary-midnight'],
        paddingBottom: '80px',
        [theme.breakpoints.down('lg')]: {
            margin: '0 48px',
        },
        [theme.breakpoints.down('md')]: {
            margin: '0 16px',
        },
    }));

    const LogoContainer = styled('div')({
        display: 'flex',
        margin: '24px 0',
        alignContent: 'center',
        justifyContent: 'center',
    });

    const NoSpacingParagraph = styled('p')({
        margin: 0,
        padding: 0,
    });

    const UnorderdListNoSpacingOrBullets = styled('ul')({
        listStyleType: 'none',
        padding: 0,
    });

    const FrontMatterDetailsContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }));

    const StyledDivider = styled(Divider)({
        width: '100%',
        height: '2px',
    });

    const SponsorLogosContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    }));

    const activityId = 'achl-cme-4521';
    const activityTitle =
        'Diagnostic Pathways for Alzheimer Disease: Optimizing Multidisciplinary Practices';

    const handleContinueClick = async () => {
        const baseEpocratesUrl = envConfig.environment.baseEpocratesUrl;
        const url = `${baseEpocratesUrl}/cme/sso/achl-cme/activity/4521`;

        const continueClickData = {
            url: url,
            activityId: activityId,
            title: activityTitle,
            creditAmount: 1.0,
            duration: 60,
            activityType: 'Paid by ActivityContentCreator',
            mediaType: 'Video',
        };

        sendFrontMatterContinueClickEvent(continueClickData);

        await awaitAllOutstandingEvents();
        window.location.href = url.toString();
    };

    useEffect(() => {
        sendFrontMatterViewEvent(activityId, activityTitle);
    }, []);

    return (
        <>
            <FrontMatterContainer>
                <LogoContainer>
                    <img src="epocrates-cme.svg" alt="epocrates logo text" width="300px" />
                </LogoContainer>
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        backgroundColor: colors['--ui-grey'],
                        color: colors['--pure-white'],
                        padding: '8px',
                    }}
                >
                    <NoSpacingParagraph>Accredited for: CME</NoSpacingParagraph>
                    <h1 style={{ textAlign: 'center' }}>
                        Diagnostic Pathways for Alzheimer Disease: Optimizing Multidisciplinary
                        Practices
                    </h1>
                    <FrontMatterDetailsContainer>
                        <NoSpacingParagraph>Program launch date: 06/28/2022</NoSpacingParagraph>
                        <NoSpacingParagraph>Program expiration date: 06/27/2023</NoSpacingParagraph>
                        <NoSpacingParagraph>
                            Earn up to 1.0 Credit / Contact Hour
                        </NoSpacingParagraph>
                    </FrontMatterDetailsContainer>
                </section>
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 8,
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        {`By clicking the "Continue" button I certify to having read the activity
                            information below.`}
                    </div>
                    <ContainedButton type="button" width="30%" onClick={handleContinueClick}>
                        Continue
                    </ContainedButton>
                </section>
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Overview</h3>
                    <p>
                        A simulated case-based challenge activity that mirrors real-world practice
                        for both PCPs and neurologists to support optimal recognition, diagnostic,
                        and communication practices by clinicians to improve care for patients with
                        Alzheimer disease (AD).
                    </p>
                    <NoSpacingParagraph>Program launch date: 06/28/2022</NoSpacingParagraph>
                    <NoSpacingParagraph>Program expiration date: 06/27/2023</NoSpacingParagraph>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Faculty</h3>
                    <UnorderdListNoSpacingOrBullets>
                        {[
                            `Andrew E. Budson, MD`,
                            `Chief, Cognitive & Behavioral Neurology and Associate Chief of Staff for Education`,
                            `VA Boston Healthcare System`,
                            `Associate Director & Education Core Leader`,
                            `Boston University Alzheimer's Disease Center`,
                            `Professor of Neurology`,
                            `Boston University School of Medicine`,
                            `Lecturer in Neurology`,
                            `Harvard Medical School`,
                            `Boston, MA`,
                        ].map((name) => {
                            return <li key={name}>{name}</li>;
                        })}
                    </UnorderdListNoSpacingOrBullets>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Sponsorship</h3>
                    <p>
                        Sponsored by Penn State College of Medicine and the Academy for Continued
                        Healthcare Learning (ACHL).
                    </p>
                    <SponsorLogosContainer>
                        <img
                            src="https://cdn.epocrates.com/da/2022/6/Picture1_1655462838499.png"
                            alt="Penn State College of Medicine Logo"
                            width="188px"
                            height="74px"
                        />
                        <img
                            src="https://cdn.epocrates.com/da/2022/6/Picture2_1655462850285.png"
                            alt="Academy for Continued Healthcare Learning"
                            width="196px"
                            height="84px"
                        />
                    </SponsorLogosContainer>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Commercial Support</h3>
                    <p>Supported by an educational grant from Biogen.</p>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Disclaimer</h3>
                    <p>
                        The content for this activity was developed independently of the commercial
                        supporter. All materials are included with permission. The opinions
                        expressed are those of the faculty and are not to be construed as those of
                        the publisher or grantor.
                    </p>
                    <p>
                        This educational activity was planned and produced in accordance with the
                        ACCME Standards for Integrity and Independence in Accredited Continuing
                        Education. Recommendations involving clinical medicine in a continuing
                        medical education (CME/CE) activity must be based on evidence that is
                        accepted within the profession of medicine as adequate justification for
                        their indications and contraindications in the care of patients. All
                        scientific research referred to, reported, or used in CME/CE in support or
                        justification of a patient care recommendation must conform to the generally
                        accepted standards of experimental design, data collection, and analysis.
                    </p>
                    <p>
                        {`This CME/CE activity might describe the off-label, investigational, or
                        experimental use of medications and/or devices that may exceed their
                        FDA-approved labeling. Physicians should consult the current manufacturers'
                        prescribing information for these products. ACHL requires the speaker to
                        disclose that a product is not labeled for the use under discussion.`}
                    </p>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Target Audience</h3>
                    <p>
                        This activity is intended for neurologists, primary care physicians,
                        internists, geriatricians, and NPs and PAs who care for patients with or at
                        risk for AD.
                    </p>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Learning Objectives</h3>
                    <p>Upon completion, participants should be able to:</p>
                    <ul>
                        {[
                            `Identify the symptoms of Alzheimer disease to differentiate it from other neurodegenerative disorders and normal aging to gain confidence in making a diagnosis`,
                            `Employ and interpret appropriate cognitive, biomarker, and imaging tests in clinical practice to improve diagnosis of Alzheimer disease`,
                            `Describe the benefits of earlier diagnosis of Alzheimer disease for patients and caregivers`,
                        ].map((name) => {
                            return <li key={name}>{name}</li>;
                        })}
                    </ul>
                </section>
                <StyledDivider />

                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Method of Participation</h3>
                    <p>
                        This activity will take approximately 60 minutes to complete. To receive
                        credit, participants are required to complete the pretest, view the online
                        activity and complete the posttest and evaluation. To receive credit, 75%
                        must be achieved on the posttest. A certificate will be immediately
                        available. There is no fee to participate in the activity or for the
                        generation of the certificate.
                    </p>
                    <p>
                        Questions regarding accreditation can be directed to
                        ContinuingEd@pennstatehealth.psu.edu or 717-531-6483. Refer to course number{' '}
                        <strong>G6819-22-T</strong>
                    </p>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Accreditation Statement</h3>
                    <div style={{ marginBottom: '8px' }}>
                        <img
                            src="https://cdn.epocrates.com/da/2022/6/ACCME_Logo_1655910104759.png"
                            alt="Penn State College of Medicine Logo"
                            width="125px"
                            height="117px"
                        />
                    </div>

                    <p>
                        Penn State College of Medicine is accredited by the Accreditation Council
                        for Continuing Medical Education (ACCME) to provide continuing medical
                        education for physicians.
                    </p>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Credit Designation Statement</h3>
                    <NoSpacingParagraph>
                        Penn State College of Medicine designates this enduring material for a
                        maximum of 1.0 <em>AMA PRA Category 1 Credit</em>™.
                    </NoSpacingParagraph>
                    <p>
                        Physicians should claim only the credit commensurate with the extent of
                        their participation in the activity.
                    </p>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h3>Faculty Disclosures</h3>
                    <p>
                        Penn State College of Medicine requires that the faculty participating in an
                        accredited continuing education activity disclose all affiliations or other
                        financial relationships (1) with the manufacturers of any commercial
                        product(s) and/or provider(s) of commercial services discussed in an
                        educational presentation and (2) with any commercial supporters of the
                        activity. All conflicts of interest have been mitigated prior to this
                        activity.
                    </p>
                    <p>The following financial relationships have been provided:</p>
                    <UnorderdListNoSpacingOrBullets>
                        {[
                            `Andrew E. Budson, MD`,
                            `Author Royalties: Elsevier, Oxford University Press`,
                            `Grant recipient: Cyclerion, Bristol Myers Squibb`,
                        ].map((name) => {
                            return <li key={name}>{name}</li>;
                        })}
                    </UnorderdListNoSpacingOrBullets>
                    <p>
                        Discussion of Off-Label, Investigational, or Experimental Drug/Device Use:
                        None
                    </p>
                </section>
                <StyledDivider />
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        paddingBottom: '80px',
                    }}
                >
                    <h3>Staff and Reviewer Disclosures</h3>
                    <p>
                        Penn State College of Medicine staff members, ACHL staff members, and others
                        involved with the planning, development, and review of the content for this
                        activity have no relevant affiliations or financial relationships to
                        disclose
                    </p>
                </section>
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 8,
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        {`By clicking the "Continue" button I certify to having read the activity
                            information above.`}
                    </div>
                    <ContainedButton type="button" width="30%" onClick={handleContinueClick}>
                        Continue
                    </ContainedButton>
                </section>
            </FrontMatterContainer>
        </>
    );
}
