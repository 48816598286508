import { useQuery, UseQueryResult } from 'react-query';
import {
    ICreditTransactionRequest,
    ICreditTransactionResponse,
} from '../models/CreditTransactionService';
import { EPOLMSCreditTransactAxiosCall } from '../services/epolmsCreditTransact';

export const GetEPOLMSCreditTransactData = (
    creditRequest: ICreditTransactionRequest
): UseQueryResult<ICreditTransactionResponse> => {
    return useQuery<ICreditTransactionResponse>(
        ['CreditTransact', creditRequest.creditSelectionId, creditRequest.contextId],
        () => EPOLMSCreditTransactAxiosCall(creditRequest),
        {
            onSuccess: (data) => {
                if (data.session) {
                    window.sessionStorage.setItem('cme-epolms-credit-session-state', data.session);
                } else {
                    window.sessionStorage.removeItem('cme-epolms-credit-session-state');
                }
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: !!(creditRequest.creditSelectionId && creditRequest.contextId),
            keepPreviousData: true,
        }
    );
};
