import { IProgramGlobalInfo, IProgramOutlineEntry, NavigationIntent } from '../models/EPOLMSModels';
import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CME_EPOLMS_SLIDE_NAVIGATE } from './analytics';

export function sendSlideNavigateEvent(
    programId: string | undefined,
    slideId: string | undefined,
    intent: NavigationIntent,
    outlineEntry?: IProgramOutlineEntry | null | undefined,
    globalInfo?: IProgramGlobalInfo | null | undefined,
    buttonIndex?: number | null | undefined,
    buttonText?: string | null | undefined,
    destination?: string | null | undefined
) {
    return sendAmplitudeEvent(CME_EPOLMS_SLIDE_NAVIGATE, {
        'Program Id': String(programId),
        'Slide Id': String(slideId),
        Source: outlineEntry?.text.plain,
        'Credit Amount': globalInfo?.creditAmount,
        'Duration in Minutes': globalInfo?.durationInMinutes,
        Title: globalInfo?.programTitle?.plain,
        'Activity Type': globalInfo?.activityType,
        'Media Type': globalInfo?.mediaTypes?.join(', '),
        'Navigation Intent': String(intent),
        'Click Button Index': buttonIndex,
        'Click Button Text': buttonText,
        Destination: destination,
    });
}
