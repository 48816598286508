import { SegmentAnnotation } from '../models/CatalogCardsResponse';
import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CME_CATALOG_VIEW } from './analytics';

export const sendCatalogViewEvent = (
    sortCriteria: any,
    segmentAnnotation: SegmentAnnotation,
    isContextualLink: boolean
) => {
    let amplitudeData: any = {};
    amplitudeData = {
        Sorting: sortCriteria,
        ...(isContextualLink ? { Source: 'CONTEXTUAL LINK' } : {}),
    };
    if (segmentAnnotation?.properties) {
        segmentAnnotation?.properties.forEach((segmentProperty) => {
            amplitudeData[segmentProperty.property] = segmentProperty.value;
        });
    }
    return sendAmplitudeEvent(CME_CATALOG_VIEW, amplitudeData);
};
