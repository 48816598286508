import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@mui/material';

import { IFrontendQuizQuestion } from '../../../models/QuizTransactionService';
import { QuizQuestionContainer } from './QuizQuestionContainer';
import { StyledInputSubmit } from './quizStyledComponents';
import { setDefaultFormData } from '../../../utils/setDefaultFormValues';
import { INavigationButton, IQuizNavigationButton } from '../../../models/EPOLMSModels';

export interface QuizFormProps {
    questions: IFrontendQuizQuestion[];
    handleSubmit: any;
    isConcluded: boolean;
    programNavBtn: INavigationButton | null;
    quizNavBtn: IQuizNavigationButton | null;
}

export function QuizForm({
    questions,
    handleSubmit,
    isConcluded,
    programNavBtn,
    quizNavBtn,
}: QuizFormProps) {
    const defaultFormData = setDefaultFormData(questions);
    const methods = useForm({
        defaultValues: defaultFormData,
    });

    // wrap the form in react-hook-form FormProvider so all the forms'
    // children can access form context with useFormContext hook
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <Grid container direction="column" gap="32px">
                    {questions.map((question) => (
                        <QuizQuestionContainer
                            key={question.questionId}
                            question={question}
                            isConcluded={isConcluded}
                        />
                    ))}
                    <Grid item sx={{ maxWidth: '744px', textAlign: 'center' }}>
                        <StyledInputSubmit
                            type="submit"
                            value={quizNavBtn ? quizNavBtn?.text?.plain : programNavBtn?.text.plain}
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}
