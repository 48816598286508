import {
    IProgramGlobalInfo,
    IProgramOutline,
    IReviewSlide,
    NavigationIntent,
    ParticipationMode,
} from '../../models/EPOLMSModels';
import { BodyColumn } from './BodyColumn';

import { PrevContinueNavButtons } from './NavigationButtons';
import { PreviewBanner } from './PreviewBanner';
import { ProgramLayout } from './ProgramLayout';

export function EPOLMSReviewSlide(props: {
    performNavigationIntent: (intent: NavigationIntent) => void;
    global: IProgramGlobalInfo;
    reviewSlide: IReviewSlide;
    outline?: IProgramOutline | null;
    isLoading: boolean;
    mode?: undefined | ParticipationMode;
}) {
    // Load the full-screen program review/summary/certificate-download layout here; pass it the quizSlide.quizId in props.
    return (
        <>
            <ProgramLayout
                headerInformation={props.global}
                programOutline={props.outline}
                performNavigationIntent={props.performNavigationIntent}
                isLoading={props.isLoading}
            >
                <PreviewBanner mode={props.mode} />
                {BodyColumn(
                    <>
                        <h3>Showing Review/Certificate Download Here</h3>
                        Your score on the pre-evaluation test was 67%
                        <br />
                        Your score on the post-evaluation test was 85%
                        <br />
                        Here is the LINK to your Certificate PDF
                        <br />
                        etc...
                        {PrevContinueNavButtons(
                            props.performNavigationIntent,
                            props.reviewSlide,
                            props.isLoading,
                            props.outline?.items.filter((entry) => entry.current)[0],
                            props.global
                        )}
                    </>
                )}
            </ProgramLayout>
        </>
    );
}
