import { Tabs } from '../models/Tabs';

export const getSeriesPageSource = (currentSourceTab: number, isContextualLink: boolean) => {
    if (isContextualLink) {
        return 'CONTEXTUAL LINK';
    } else if (currentSourceTab >= 0) {
        return Tabs[currentSourceTab];
    } else {
        return 'SERIES';
    }
};
