import { useMediaQuery } from '@mui/material';
import { theme } from '../styles/theme';

export const SmallScreen = () => {
    return useMediaQuery(theme.breakpoints.down('md'));
};

export const Desktop = () => {
    return useMediaQuery(theme.breakpoints.up('lg'));
};

export const LargeDesktop = () => {
    return useMediaQuery(theme.breakpoints.up('xl'));
};
export const Tablet = () => {
    return useMediaQuery(theme.breakpoints.down('xl') && theme.breakpoints.up('md'));
};
