import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Node } from '@contentful/rich-text-types';
import { Divider, Grid, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { ReactNode, useEffect } from 'react';

import colors, { colorLookup } from '../../../config/colorConfig';
import { IFrontendCreditOption } from '../../../models/CreditTransactionService';
import { MultiFormatText } from '../../../models/Primitives';
import { setQuizInputAccent } from '../../../utils/setQuizInputAccent';
import { SingleLineInput } from '../quiz/SingleLineInput';
import { QuestionContainer, StyledParagraph } from '../quiz/quizStyledComponents';
import { CreditOptionKeyStatsTable } from './CreditOptionKeyStatsTable';
import {
    ContainerHighlight,
    setQuestionContainerHighlight,
} from '../../../utils/setQuestionContainerHighlight';
import { setInputBorderColor } from '../../../utils/setInputBorderColor';
import { strings } from '../../../utils/Strings';

export interface QuizQuestionContainerProps {
    creditOption: IFrontendCreditOption;
    isConcluded: boolean;
    updatedSelectedOption: (creditOption: IFrontendCreditOption, checked: boolean) => void;
    canSelectMultiple: boolean;
}

export function renderMultiFormatText(
    text: MultiFormatText | null | undefined,
    options?: Options
): ReactNode {
    if (!text) return <></>;
    if (text.contentfulRichText)
        return documentToReactComponents(text.contentfulRichText as any, options);
    if (text.plain !== null || text.plain !== undefined) return <>{text.plain}</>;
    return <></>;
}

export function CreditOptionContainer({
    creditOption,
    isConcluded,
    updatedSelectedOption,
    canSelectMultiple,
}: QuizQuestionContainerProps) {
    const containerShadowColor: ContainerHighlight = setQuestionContainerHighlight(
        creditOption.highlightMode
    );

    const readOnly = !!isConcluded;
    const type = canSelectMultiple ? 'checkbox' : 'radio';

    const LabelContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
    });

    const StyledQuestionLabel = styled('h2')({
        marginBottom: 0,
        fontFamily: 'Metric',
        color: colors['--primary-midnight'],
        fontSize: '18px',
        fontWeight: 400,
        marginLeft: '16px',
        cursor: readOnly ? 'not-allowed' : 'pointer',
    });

    const StyledDivider = styled(Divider)({
        color: colors['--ui-grey-light'],
        width: '100%',
    });

    const StyledAlertText = ({ color, children }: { color?: string; children: ReactNode }) => {
        const StyledChipTextParagraph = styled('p')({
            fontFamily: 'Metric',
            fontWeight: 500,
            fontSize: '16px',
            marginBottom: 0,
            color: colorLookup(color, '--color-alert-critical'),
        });
        return <StyledChipTextParagraph>{children}</StyledChipTextParagraph>;
    };

    const quizRenderOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: Node, children: any) => (
                <StyledParagraph>{children}</StyledParagraph>
            ),
        },
    };

    const renderedQuestion = renderMultiFormatText(creditOption.name, quizRenderOptions);

    // https://react-hook-form.com/api/useformcontext/#main
    const { control } = useFormContext();

    const { field: quantitySelectField } = useController({
        name: creditOption.quantity?.fieldId ?? 'none',
        control,
    });

    const handleQuantityFieldChange = (option: any) => {
        quantitySelectField.onChange(option.target.value);
    };

    useEffect(() => {
        quantitySelectField.onChange(creditOption.quantity?.userEnteredValue ?? '');
    }, [creditOption.quantity?.userEnteredValue]);

    const { field: selectedField } = useController({
        name: creditOption.creditOptionId + ':selected',
        control,
    });
    const handleSelectedOptionChange = (event: any) => {
        const newValue = !!event.target.value;
        selectedField.onChange(newValue);
        updatedSelectedOption(creditOption, newValue);
    };
    const handleSelectedOptionLabelClick = () => {
        if (readOnly) return;
        const newValue = !selectedField.value;
        if (!canSelectMultiple && !newValue) return; // don't un-select radio buttons (single-select case)
        selectedField.onChange(newValue);
        updatedSelectedOption(creditOption, newValue);
    };

    const cursor = readOnly ? 'not-allowed' : 'auto';

    const inputAccentColor = setQuizInputAccent(
        // TODO: Make a credit-specific version of this?
        !!selectedField.value,
        readOnly,
        false, // isMarkedCorrect || false,
        false, // isMarkedIncorrect || false,
        !!selectedField.value
    );

    const StyledInput = styled('input')({
        accentColor: inputAccentColor,
        border: `1px solid ${colors['--ui-grey-med']}`,
        width: '18px',
        height: '18px',
        minWidth: '18px',
        cursor: cursor,
        pointerEvents: readOnly ? 'none' : 'auto',
    });

    const StyledSelect = styled(Select)({
        '.cme-web-MuiSelect-select, cme-web-MuiOutlinedInput-root': {
            padding: '8px 16px',
            width: '200px',
            fontFamily: 'Metric',
        },
    });

    const StyledInputLabel = styled(InputLabel)({
        fontFamily: 'Metric',
        color: colors['--primary-midnight'],
        fontSize: '18px',
    });

    const additionalInputFields = creditOption.additionalFields.map((af, idx) => {
        const inputBorderColor = setInputBorderColor(af.highlightMode);
        return (
            <span key={`${af.fieldId}`}>
                <StyledInputLabel
                    sx={{
                        marginBottom: '0 0 8px 0',
                        paddingBottom: '0px',
                    }}
                    id={`additional-field-${idx}`}
                >
                    {af.fieldName}
                </StyledInputLabel>
                <SingleLineInput
                    readOnly={!selectedField.value}
                    control={control}
                    name={af.fieldId}
                    isVisible
                    borderColor={inputBorderColor}
                />
            </span>
        );
    });

    return (
        <Grid item>
            <section>
                <QuestionContainer
                    isConcluded={isConcluded}
                    containerShadowColor={containerShadowColor}
                >
                    <LabelContainer>
                        <StyledInput
                            value="{optionValue}"
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (readOnly) return;
                                handleSelectedOptionChange(e);
                            }}
                            checked={selectedField.value}
                            type={type}
                            id={creditOption.creditOptionId + ':selected'}
                            readOnly={readOnly}
                            aria-disabled={readOnly}
                        />
                        <StyledQuestionLabel onClick={handleSelectedOptionLabelClick}>
                            {renderedQuestion}
                        </StyledQuestionLabel>
                    </LabelContainer>
                    <StyledDivider />
                    {creditOption.statusChip?.plain && (
                        <StyledAlertText color={creditOption.statusChip?.fgColor}>
                            {creditOption.statusChip?.plain}
                        </StyledAlertText>
                    )}
                    <CreditOptionKeyStatsTable creditOption={creditOption} />
                    {creditOption.quantity && (isConcluded || !!selectedField.value) && (
                        <span>
                            <StyledInputLabel
                                sx={{
                                    margin: '0px 0px 8px 0px',
                                    paddingBottom: '0px',
                                }}
                                id="credit-quantity-select-label"
                            >
                                {strings.credit_claim_input_label}
                            </StyledInputLabel>
                            <StyledSelect
                                defaultValue=""
                                value={quantitySelectField.value}
                                readOnly={!selectedField.value || isConcluded}
                                onChange={handleQuantityFieldChange}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                                        },
                                    },
                                }}
                                sx={{
                                    '& .cme-web-MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        boxShadow: setQuestionContainerHighlight(
                                            creditOption.quantity.highlightMode
                                        ),
                                    },
                                }}
                            >
                                {creditOption.quantity.values.map((value, index) => (
                                    <MenuItem value={value} key={index}>
                                        {value} {creditOption.quantity?.creditUnit}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </span>
                    )}
                    {selectedField.value && !isConcluded && additionalInputFields}
                </QuestionContainer>
            </section>
        </Grid>
    );
}
