import { styled } from '@mui/material';

import {
    IProgramOutline,
    IProgramOutlineEntry,
    IProgramGlobalInfo,
    NavigationIntent,
} from '../../../models/EPOLMSModels';
import { strings } from '../../../utils/Strings';
import { ProgramOutlineTab } from './ProgramOutlineTab';
import { UserIndicator } from './UserIndicator';

//
// ProgramOutlineTabs renders the Program Outline Title, Tabs, and User Indicator.
//

interface ProgramOutlineTabsProps {
    outline: IProgramOutline | null | undefined;
    performNavigationIntent: (
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined,
        buttonIndex?: number | null | undefined,
        buttonText?: string | null | undefined,
        destination?: string | null | undefined
    ) => void;
    globalInfo?: IProgramGlobalInfo | null | undefined;
    isSmallMenu: boolean;
    firstName?: string | undefined;
    lastName?: string | undefined;
}

export function ProgramOutlineTabs({
    outline,
    performNavigationIntent,
    globalInfo,
    isSmallMenu,
    firstName,
    lastName,
}: ProgramOutlineTabsProps) {
    const ProgramOutlineTabsContainer = styled('nav')({
        display: 'grid',
        fontFamily: 'Metric',
        lineHeight: '24px',
        alignItems: 'flex-end',
        gap: '8px',
        marginBottom: '16px',
        paddingLeft: '16px',
    });

    const ProgramOutlineTitle = styled('p')(({ theme }) => ({
        fontFamily: 'Metric',
        fontWeight: 300,
        fontSize: '28px',
        lineHeight: '30px',
        margin: '40px 0 24px 0',
        [theme.breakpoints.down('md')]: {
            margin: '0 0 24px 0',
        },
    }));

    if (!outline) return <></>;

    return (
        <ProgramOutlineTabsContainer>
            <ProgramOutlineTitle>{strings.program_outline_title}</ProgramOutlineTitle>
            <>
                {outline.items.map(
                    (
                        {
                            text,
                            navigationIntent,
                            completed,
                            clickable,
                            current,
                        }: IProgramOutlineEntry,
                        index
                    ) => {
                        return (
                            <ProgramOutlineTab
                                key={`${index}-program-nav-${text.plain}`}
                                handleTabClick={() =>
                                    performNavigationIntent(
                                        navigationIntent!,
                                        outline.items.filter((entry) => entry.current)[0],
                                        globalInfo,
                                        undefined,
                                        undefined,
                                        text.plain
                                    )
                                }
                                text={text.plain}
                                completed={completed}
                                clickable={clickable}
                                current={current}
                                isSmallMenu={isSmallMenu}
                            />
                        );
                    }
                )}
            </>
            <UserIndicator firstName={firstName} lastName={lastName} />
        </ProgramOutlineTabsContainer>
    );
}
