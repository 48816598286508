import { CollectionAmplitudeState, ResponseAmplitudeState } from '../models/AmplitudeState';
import NoSavedActivitiesScreen from './NoSavedActivitiesScreen';
import { VerticalCardsList } from './VerticalCards';

interface VerticalCardsContainerProps {
    cardsResponse: any;
    responseAmplitudeData?: ResponseAmplitudeState;
    collectionAmplitudeData?: CollectionAmplitudeState;
    matchedCardCount?: number;
}

export const VerticalCardsContainer = ({
    cardsResponse,
    responseAmplitudeData,
    collectionAmplitudeData,
    matchedCardCount,
}: VerticalCardsContainerProps) => {
    return (
        <>
            {cardsResponse && (
                <>
                    {/* Build the unique keys for each card to be displayed     */}

                    {cardsResponse.data &&
                        cardsResponse.data.data.results.title === 'Saved For Later' &&
                        !cardsResponse.isFetching &&
                        !cardsResponse.isLoading &&
                        cardsResponse.data?.data?.results?.cards?.length === 0 && (
                            <NoSavedActivitiesScreen />
                        )}

                    {cardsResponse.data && cardsResponse.data?.data?.results?.cards?.length > 0 ? (
                        <VerticalCardsList
                            verticalCardsData={cardsResponse?.data?.data?.results?.cards}
                            responseAmplitudeData={responseAmplitudeData}
                            collectionAmplitudeData={collectionAmplitudeData}
                            matchedCardCount={matchedCardCount}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};
