import { ReactNode, useEffect } from 'react';
import { Container, Box, styled, Typography } from '@mui/material';
import { sendErrorEvent } from '../../amplitude/sendErrorEvent';

interface ErrorPageProps {
    title: string;
    message: string | ReactNode;
    statusCode?: number | null;
}

export function ErrorPage({ title, message, statusCode }: ErrorPageProps) {
    const Logo = styled('img')(({ theme }) => ({
        height: '72px',
        marginBottom: '8px',
        [theme.breakpoints.down('md')]: {
            marginBottom: '4px',
            height: '48px',
        },
    }));

    const LogoContainer = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    }));

    const DoctorImg = styled('img')(({ theme }) => ({
        height: '450px',
        [theme.breakpoints.down('md')]: {
            height: '300px',
        },
    }));

    const ErrorContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    }));

    const ErrorText = styled('section')(({ theme }) => ({
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    }));

    const ErrorMessageText = styled('p')(({ theme }) => ({
        maxWidth: '320px',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '300px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '220px',
            textAlign: 'center',
        },
    }));

    useEffect(() => {
        sendErrorEvent(message, statusCode);
    }, []);

    return (
        <Container
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Metric',
            }}
        >
            <ErrorContainer role="alert">
                <Box>
                    <LogoContainer>
                        <Logo src="brand-primary-midnight.png" alt="epocrates logo" />
                    </LogoContainer>
                    <ErrorText>
                        <Typography
                            component="h1"
                            sx={{
                                fontSize: { xs: '28px', md: '40px' },
                                maxWidth: { xs: '280px', md: '350px', lg: '360px' },
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            {title}
                        </Typography>
                        <ErrorMessageText>{message}</ErrorMessageText>
                    </ErrorText>
                </Box>
                <DoctorImg
                    src={require('../../images/Error-Illustration@1x.png')}
                    alt="Doctor with stethoscope icon"
                />
            </ErrorContainer>
        </Container>
    );
}
