import { styled } from '@mui/material';

import colors from '../../../config/colorConfig';
import { isAuthenticatedUser } from '../../../utils/cookies';
import { strings } from '../../../utils/Strings';

//
// UserIndicator accepts a users first and last name through
// props and displays them
//

interface UserIndicatorProps {
    firstName: string | undefined;
    lastName: string | undefined;
}

export function UserIndicator({ firstName, lastName }: UserIndicatorProps) {
    const isUserAuthenticated: boolean = isAuthenticatedUser();

    const StyledDiv = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Metric',
        color: colors['--ui-grey'],
        justifyContent: 'center',
        fontWeight: 500,
        position: 'fixed',
        bottom: 0,
        margin: '0 0 24px 0',
        [theme.breakpoints.down('md')]: {
            bottom: 'auto',
            position: 'relative',
            margin: '8px 0 0 0',
        },
    }));

    const StyledTitle = styled('p')({
        fontSize: '12px',
        margin: 0,
    });

    const StyledName = styled('p')({
        fontSize: '18px',
        margin: 0,
    });

    if (!isUserAuthenticated) return <></>;

    if (!firstName && !lastName) return <></>;

    return (
        <StyledDiv>
            <StyledTitle>{strings.program_outline_user_indicator}</StyledTitle>
            {firstName && lastName && <StyledName>{`${firstName} ${lastName}`}</StyledName>}
            {firstName && !lastName && <StyledName>{firstName}</StyledName>}
            {!firstName && lastName && <StyledName>{lastName}</StyledName>}
        </StyledDiv>
    );
}
