import * as toolkit from '@reduxjs/toolkit';

export interface UserAttitudeProperties {
    action: string;
    type: string;
    isSnackBarOpen: boolean;
    cardId: string;
    undoFlag?: boolean;
}

const initialState: UserAttitudeProperties = {
    action: '',
    type: '',
    isSnackBarOpen: false,
    cardId: '',
    undoFlag: false,
};

export const userAttitudeSlice = toolkit.createSlice({
    name: 'DisplayState',
    initialState,
    reducers: {
        updateAttitudeProperties: (
            state,
            action: toolkit.PayloadAction<UserAttitudeProperties | undefined>
        ) => {
            if (action.payload) {
                state.action = action.payload.action;
                state.type = action.payload.type;
                state.isSnackBarOpen = action.payload.isSnackBarOpen;
                state.cardId = action.payload.cardId;
            }
        },
        updateUndoFlag: (state, action: toolkit.PayloadAction<boolean>) => {
            if (typeof action.payload === 'boolean') {
                state.undoFlag = action.payload;
            }
        },
    },
});

export const { updateAttitudeProperties, updateUndoFlag } = userAttitudeSlice.actions;

const snackBarStateReducer = userAttitudeSlice.reducer;
export default snackBarStateReducer;
