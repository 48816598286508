import { Grid, styled } from '@mui/material';
import { ImageAndTextCardImage, UnorderedListReducedMargin } from './frontMatterStyledComponents';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, Node, MARKS } from '@contentful/rich-text-types';

//
// ImageAndTextCard accepts Contentful richText content and displays it
// in the following format: left float image with a wrapped unordered list
// or paragraph text beside the image
//

interface ImageAndTextCardProps {
    imgSrc: string;
    altText: string;
    cardText: any;
}

export const ImageAndTextCardContainer = styled(Grid)(({ theme }) => ({
    gap: '16px',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
    },
}));

export const ImageAndTextCard = ({ imgSrc, altText, cardText }: ImageAndTextCardProps) => {
    const renderOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: Node, children: any) => (
                <p className="epolms-paragraph">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node: Node, children: any) => (
                <UnorderedListReducedMargin className="epolms-ul">
                    {children}
                </UnorderedListReducedMargin>
            ),
            [BLOCKS.LIST_ITEM]: (node: Node) => {
                const transformedChildren = documentToReactComponents(node as Document, {
                    renderNode: {
                        // remove p tag from each li
                        [BLOCKS.PARAGRAPH]: (_node, children) => children,
                        [BLOCKS.LIST_ITEM]: (_node, children) => children,
                    },
                });
                return <li>{transformedChildren}</li>;
            },
        },
        renderText: (text: string) => {
            return text.split('\n').reduce((children: any, textSegment: any, index: number) => {
                return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
        },
        renderMark: {
            [MARKS.BOLD]: (text: any) => {
                return <b style={{ fontWeight: 500 }}>{text}</b>;
            },
        },
    };

    return (
        <ImageAndTextCardContainer container direction="row" marginBottom="24px" flexWrap="nowrap">
            <Grid item>
                <ImageAndTextCardImage src={imgSrc} alt={altText} />
            </Grid>
            <Grid item>{documentToReactComponents(cardText as Document, renderOptions)}</Grid>
        </ImageAndTextCardContainer>
    );
};
