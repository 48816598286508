import { Dispatch, SetStateAction } from 'react';

import { IProgramOutline, IProgramOutlineEntry } from '../../../models/EPOLMSModels';
import { ProgramOutlineTab } from './ProgramOutlineTab';

//
// ProgramOutlineSmall renders the current ProgramOutlineTab
// with a menu Icon on xs - md screens. Clicking the ProgramOutlineMobileBtn
// opens the ProgramOutlineDrawer.
//

interface ProgramOutlineMobileBtnProps {
    outline: IProgramOutline | null | undefined;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export function ProgramOutlineSmall({ outline, setIsDrawerOpen }: ProgramOutlineMobileBtnProps) {
    if (!outline) return <></>;
    return (
        <>
            {outline.items.map(
                ({ text, completed, clickable, current }: IProgramOutlineEntry, index: number) => {
                    if (current) {
                        return (
                            <ProgramOutlineTab
                                key={`${index}-program-nav-${text.plain}`}
                                handleTabClick={() => setIsDrawerOpen(true)}
                                text={text.plain}
                                completed={completed}
                                clickable={clickable}
                                current={current}
                                isSmallMenu={true}
                            />
                        );
                    }
                }
            )}
        </>
    );
}
