import { strings } from '../utils/Strings';

export function getSearchResultsHeader(
    searchResultsNumber: number,
    searchResultsString: string,
    searchText: string
) {
    switch (searchResultsNumber) {
        case 0:
            return strings.formatString(strings.search_no_results, {
                searchText: searchText,
            });
        case 1:
            return strings.formatString(strings.search_one_result, {
                numberOfSearchResults: searchResultsString,
                searchText: searchText,
            });
        default:
            return strings.formatString(strings.search_results, {
                numberOfSearchResults: searchResultsString,
                searchText: searchText,
            });
    }
}
