import { styled } from '@mui/material';
import { Lock, CircleOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

import colors from '../../../config/colorConfig';
import { SmallScreen } from '../../../utils/responsive';

//
// ProgramOutlineTab renders the program slide title and
// the slide status icon: Lock, CircleOutlined or check-circle.svg.
// The menu icon is rendered for xs - md screens when the isSmallMenu
// props is set to true.
//
interface ProgramOutlineTabProps {
    text: string;
    completed: boolean;
    clickable: boolean;
    current: boolean;
    handleTabClick: () => void;
    isSmallMenu: boolean;
}

export function ProgramOutlineTab({
    text,
    completed,
    clickable,
    current,
    handleTabClick,
    isSmallMenu,
}: ProgramOutlineTabProps) {
    const ProgramOutlineTabButton = styled('button')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        height: '40px',
        fontSize: '18px',
        fontWeight: 500,
        border: 'none',
        backgroundColor: 'transparent',
        color: colors['--primary-midnight'],
        cursor: 'pointer',
        width: '100%',
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors['--primary-off-white'],
        },
        '&:disabled': {
            color: colors['--ui-grey'],
            cursor: 'not-allowed',
        },
        [theme.breakpoints.down('md')]: {
            padding: isSmallMenu ? '0 16px 0 16px' : 0,
        },
    }));

    const TabActiveIndicator = styled('div')({
        height: '40px',
        width: '2px',
        backgroundColor: colors['--primary-midnight'],
        position: 'absolute',
    });

    const TabContentContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        paddingLeft: '16px',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
        },
    }));

    const IconContainer = styled('div')({
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });

    const renderTabIcon = (isCompleted: boolean, isClickable: boolean) => {
        if (isClickable) {
            if (isCompleted) {
                return <img src="check-circle.svg" alt={'circle with checkmark'} />;
            } else if (!isCompleted) {
                return <CircleOutlined aria-label="circle outline" />;
            }
        } else if (!isClickable) {
            return <Lock aria-label="lock" />;
        }
    };

    const isMobile = SmallScreen();

    return (
        <ProgramOutlineTabButton onClick={() => handleTabClick()} disabled={!clickable}>
            {current && !isMobile && <TabActiveIndicator />}
            <TabContentContainer>
                <IconContainer>{renderTabIcon(completed, clickable)}</IconContainer>
                <>{text}</>
            </TabContentContainer>
            {isSmallMenu && (
                <MenuIcon
                    aria-label={'menu'}
                    sx={{
                        marginLeft: 'auto',
                        color: colors['--primary-midnight'],
                    }}
                />
            )}
        </ProgramOutlineTabButton>
    );
}
