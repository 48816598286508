import { Button, Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../config/colorConfig';
import { UserFilterSelection } from '../../models/ICatalogCardsRequest';
import { updateActiveFilters } from '../../Slices/FilterSlice';
import { RootState } from '../../store/store';
import { filterComponentState } from '../../utils/FilterComponentState';
import { theme } from '../../styles/theme';

interface FilterTextBoxProps {
    enabled: boolean;
    text: string;
    subtitle: string;
    isLargeDisplay: boolean;
    filterName: string | undefined;
    isQuickFilter?: boolean;
}

export const FilterTextBox = (props: FilterTextBoxProps) => {
    let width: number;
    let height: number;
    let activeFilters: UserFilterSelection[] = useSelector(
        (state: RootState) => state.filter.activeFilterSelections
    );
    const isSelected = props?.filterName
        ? filterComponentState(activeFilters, props?.filterName, props.text)
        : false;
    const [selectState, setSelectState] = useState<boolean>(isSelected);
    const dispatch = useDispatch();

    const filterSelection = () => {
        setSelectState(!selectState);
        dispatch(
            updateActiveFilters({
                filterName: props?.filterName,
                filterState: !selectState,
                filterValue: props.text,
            })
        );
    };
    useEffect(() => {
        if (isSelected === false && selectState === true) {
            setSelectState(!selectState);
        }
    }, [isSelected]);

    if (props?.isQuickFilter) {
        width = 74;
        height = 60;
    } else {
        width = props.isLargeDisplay ? 156 : 101;
        height = props.isLargeDisplay ? 59 : 44;
    }

    const FilterButton = styled(Button)(() => ({
        width: width,
        height: height,
        padding: 4,
        border: 0,
        backgroundColor: selectState ? colors['--primary-teal'] : colors['--ui-grey-light'],
        '&:disabled': {
            opacity: 0.4,
        },
        '&:focus': {
            outline: 'none',
        },
        '&:focus-visible': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: selectState ? colors['--primary-teal'] : colors['--ui-grey-light'],
        },
        textTransform: 'none',
        [theme.breakpoints.down('md')]: {
            width: props.isLargeDisplay ? 159 : 104,
            height: props.isLargeDisplay ? 59 : 44,
        },
    }));
    return (
        <Grid item>
            <FilterButton
                disableRipple
                disableFocusRipple
                disabled={props.enabled ? false : true}
                onClick={filterSelection}
                style={{
                    borderRadius: '3px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: props.enabled ? 1.0 : 0.4,
                }}
            >
                {props.isLargeDisplay && (
                    <Grid
                        container
                        direction="column"
                        columnSpacing="3px"
                        justifyContent="center"
                        alignItems="center"
                        width={'100%'}
                    >
                        <Grid item width={'100%'} justifyContent="center" alignItems="center">
                            <Typography
                                noWrap
                                style={{
                                    fontSize: '14px',
                                    color: selectState
                                        ? colors['--pure-white']
                                        : colors['--ui-grey'],
                                    textAlign: 'center',
                                    fontWeight: 'normal',
                                    letterSpacing: 'normal',
                                    fontFamily: 'Metric',
                                    width: '100%',
                                }}
                            >
                                {props.text}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                style={{
                                    fontSize: '11px',
                                    color: selectState
                                        ? colors['--pure-white']
                                        : colors['--ui-grey'],
                                    textAlign: 'center',
                                    fontWeight: 'normal',
                                    letterSpacing: 'normal',
                                    fontFamily: 'Metric',
                                }}
                            >
                                {' '}
                                {props.subtitle}{' '}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {!props.isLargeDisplay && (
                    <Typography
                        noWrap
                        style={{
                            fontSize: '14px',
                            color: selectState ? colors['--pure-white'] : colors['--ui-grey'],
                            textAlign: 'center',
                            fontWeight: 'normal',
                            letterSpacing: 'normal',
                            fontFamily: 'Metric',
                        }}
                    >
                        {props.text}
                    </Typography>
                )}
            </FilterButton>
        </Grid>
    );
};
