/* eslint-disable @typescript-eslint/no-unused-vars */
import ReactPlayer from 'react-player';
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { AudioPlayerControls } from './AudioPlayerControls';
import { OnProgressProps } from 'react-player/base';
import { VideoPlayerControls } from './VideoPlayerControls';
import { Box } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import colors from '../../../config/colorConfig';
import { VideoOverlay } from './mediaStyledComponents';

interface MediaPlayerProps {
    url: string;
    mediaType: 'audio' | 'video';
}

export function MediaPlayer({ url, mediaType }: MediaPlayerProps) {
    const reactPlayerRef = useRef<ReactPlayer>(null);

    const [playing, setPlaying] = useState<boolean>(false);
    const [muted, setMuted] = useState<boolean>(false);
    const [played, setPlayed] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [seeking, setSeeking] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(0.1);
    const [playbackRate, setPlaybackRate] = useState<number>(1.0);
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const [buffering, setBuffering] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [playbackMenuOpen, setPlaybackMenuOpen] = useState<boolean>(false);
    const [showLargePlayPause, setShowLargePlayPause] = useState<boolean>(false);

    const isAudio = mediaType === 'audio';
    const isVideo = mediaType === 'video';

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handlePlay = () => {
        setBuffering(false);
        setPlaying(true);
    };

    const handlePause = () => {
        setPlaying(false);
    };

    const handleToggleMuted = () => {
        setMuted(!muted);
    };

    const handleVolumeChange = useCallback(
        (e: Event, value: number | number[], activeThumb: number) => {
            if (typeof value === 'number') setVolume(value);
            setMuted(value === 0 ? true : false);
        },
        []
    );

    const handleVolumeSeekUpHandler = useCallback(
        (e: SyntheticEvent | Event, value: number | number[]) => {
            if (typeof value === 'number') setVolume(value);
            setMuted(value === 0 ? true : false);
        },
        []
    );

    const handleSeekMouseDown = () => {
        setSeeking(true);
    };

    const handleSeekChange = (e: Event, value: number | number[], activeThumb: number) => {
        if (typeof value === 'number') setPlayed(value);
        if (reactPlayerRef.current && typeof value === 'number')
            reactPlayerRef.current.seekTo(value);
    };

    const handleSeekMouseUp = (e: SyntheticEvent | Event, value: number | number[]) => {
        setSeeking(false);
        if (reactPlayerRef.current && typeof value === 'number')
            reactPlayerRef.current.seekTo(value);
    };

    const handleProgress = (state: OnProgressProps) => {
        if (!seeking) {
            setPlayed(state.playedSeconds);
        }
    };

    const handleDuration = (_duration: number) => {
        setDuration(_duration);
    };

    const fullscreenHandle = useFullScreenHandle();

    const handleClickFullscreen = () => {
        setFullscreen(!fullscreen);
        if (!fullscreen) {
            fullscreenHandle.enter();
        } else {
            fullscreenHandle.exit();
        }
    };

    const handlePlayerReady = (e: any) => {
        setBuffering(false);
    };

    const handleBufferStart = () => {
        setBuffering(true);
    };

    const handleBufferEnd = () => {
        setBuffering(false);
    };

    const handleError = () => {
        setShowError(true);
    };

    const handleSetPlaybackRate = (e: MouseEvent<HTMLUListElement>) => {
        if (e.currentTarget?.innerText) {
            let playbackNumber;
            if (e.currentTarget.innerText === 'Normal') {
                playbackNumber = 1.0;
            } else {
                playbackNumber = parseFloat(e.currentTarget.innerText);
            }
            setPlaybackRate(playbackNumber);
        }
    };

    const handleShowLargePlayPause = () => {
        setShowLargePlayPause(true);

        setTimeout(() => {
            setShowLargePlayPause(false);
        }, 800);
    };

    let currentTime: number;
    const handleKeyPress = (event: KeyboardEvent) => {
        event.preventDefault();
        const player = reactPlayerRef.current;
        if (player) currentTime = player?.getCurrentTime();

        if (player && currentTime) {
            if (event.code === 'ArrowRight') {
                player.seekTo(currentTime + 5);
            } else if (event.code === 'ArrowLeft') {
                player.seekTo(currentTime - 5);
            } else if (event.code === 'Space') {
                setPlaying(!playing);
                handleShowLargePlayPause();
            }
        }
    };

    return (
        <FullScreen handle={fullscreenHandle}>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    marginBottom: '24px',
                    paddingTop: isVideo ? '56.25%' : 'none',
                }}
            >
                <ReactPlayer
                    ref={reactPlayerRef}
                    width="100%"
                    height="100%"
                    style={{
                        overflow: 'hidden',
                        borderRadius: '4px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                    url={url}
                    playing={playing}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    onReady={handlePlayerReady}
                    // onStart={() => console.log('onStart')}
                    onPlay={handlePlay}
                    onPause={handlePause}
                    onBuffer={handleBufferStart}
                    onBufferEnd={handleBufferEnd}
                    onPlaybackRateChange={handleSetPlaybackRate}
                    // onSeek={(e) => console.log('onSeek', e)}
                    // onEnded={() => console.log('ended')}
                    onError={handleError}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    onKeyDown={handleKeyPress}
                    // light={
                    //     isVideo ? (
                    //         <VideoOverlay onClick={handlePlayPause}>
                    //             <PlayArrowRoundedIcon
                    //                 sx={{
                    //                     width: '64px',
                    //                     height: '64px',
                    //                     color: colors['--pure-white'],
                    //                 }}
                    //             />
                    //         </VideoOverlay>
                    //     ) : (
                    //         false
                    //     )
                    // }
                />
                {isAudio && (
                    <AudioPlayerControls
                        playing={playing}
                        handlePlayPause={handlePlayPause}
                        muted={muted}
                        handleToggleMuted={handleToggleMuted}
                        duration={duration}
                        played={played}
                        handleSeekChange={handleSeekChange}
                        handleSeekMouseDown={handleSeekMouseDown}
                        handleSeekMouseUp={handleSeekMouseUp}
                        volume={volume}
                        handleVolumeChange={handleVolumeChange}
                        handleVolumeSeekUpHandler={handleVolumeSeekUpHandler}
                        playbackRate={playbackRate}
                        handleSetPlaybackRate={handleSetPlaybackRate}
                        playbackRateMenuOpen={playbackMenuOpen}
                        setPlaybackMenuOpen={setPlaybackMenuOpen}
                        handleKeyPress={handleKeyPress}
                        showError={showError}
                    />
                )}
                {isVideo && (
                    <VideoPlayerControls
                        playing={playing}
                        handlePlayPause={handlePlayPause}
                        muted={muted}
                        handleToggleMuted={handleToggleMuted}
                        duration={duration}
                        played={played}
                        handleSeekChange={handleSeekChange}
                        handleSeekMouseDown={handleSeekMouseDown}
                        handleSeekMouseUp={handleSeekMouseUp}
                        volume={volume}
                        handleVolumeChange={handleVolumeChange}
                        handleVolumeSeekUpHandler={handleVolumeSeekUpHandler}
                        playbackRate={playbackRate}
                        handleSetPlaybackRate={handleSetPlaybackRate}
                        handleClickFullScreen={handleClickFullscreen}
                        buffering={buffering}
                        showError={showError}
                        playbackMenuOpen={playbackMenuOpen}
                        setPlaybackMenuOpen={setPlaybackMenuOpen}
                        handleKeyPress={handleKeyPress}
                        showLargePlayPause={showLargePlayPause}
                        handleShowLargePlayPause={handleShowLargePlayPause}
                    />
                )}
            </Box>
        </FullScreen>
    );
}
