import { ToggleButton, styled } from '@mui/material';
import { ScoreCardAttemptSpecifier } from '../../../models/QuizTransactionService';
import colors from '../../../config/colorConfig';

interface AttemptsToggleProps {
    attempts: ScoreCardAttemptSpecifier[] | null;
    handleReviewIntent: (attemptId: string, e?: Event) => void;
}

export function AttemptsToggle({ attempts, handleReviewIntent }: AttemptsToggleProps) {
    const StyledAttemptsContainer = styled('div')({
        backgroundColor: colors['--ui-grey-ultra-light'],
        borderRadius: '4px',
        padding: '8px',
        display: 'inline-flex',
        gap: '2px',
        width: 'fit-content',
    });

    type StyleToggleButtonType = {
        isActive: boolean;
    };

    const StyledToggleButton = styled(ToggleButton, {
        shouldForwardProp: (prop) => {
            return prop !== 'isActive';
        },
    })(({ isActive }: StyleToggleButtonType) => ({
        textTransform: 'none',
        borderRadius: '4px !important',
        border: 'none',
        fontWeight: isActive ? 500 : 400,
        fontSize: '16px',
        lineHeight: '20px',
        padding: '8px 16px',
        margin: 0,
        color: isActive ? colors['--accent-teal'] : colors['--ui-grey-semi'],
        backgroundColor: isActive ? colors['--pure-white'] : 'transparent',
        '&:hover': {
            backgroundColor: isActive ? colors['--pure-white'] : 'transparent',
        },
        '&:focus': {
            outline: 'none',
        },
        boxShadow: isActive ? '0px 1px 3px rgba(0, 0, 0, 0.12)' : 'none',
    }));

    if (!attempts) return <></>;

    return (
        <StyledAttemptsContainer>
            {attempts.map((attempt: ScoreCardAttemptSpecifier, index: number) => (
                <StyledToggleButton
                    key={`${attempt.caption.plain}-${index}`}
                    aria-label={attempt.caption.plain}
                    value={attempt.caption.plain || 'Attempt'}
                    isActive={attempt.isActive}
                    onClick={() => handleReviewIntent(attempt.quizIntent)}
                    disableRipple
                >
                    {attempt.caption.plain}
                </StyledToggleButton>
            ))}
        </StyledAttemptsContainer>
    );
}
