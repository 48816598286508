import { styled, Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import colors from '../../../config/colorConfig';
import { strings } from '../../../utils/Strings';

//
// Audio Player Error displays an error message string
// on the AudioPlayerControls component
//

const AudioErrorContainer = styled(Box)(({ theme }) => ({
    backgroundColor: colors['--primary-teal-night'],
    maxWidth: '508px',
    height: '80px',
    borderRadius: '100px',
    display: 'flex',
    padding: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '8px',
    },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    color: colors['--pure-white'],
    fontWeight: 500,
    fontSize: '18px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: '16px',
    },
}));

export function AudioPlayerError() {
    return (
        <AudioErrorContainer>
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    color: colors['--pure-white'],
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ErrorIcon fontSize="medium" />
                <ErrorText>{strings.media_error}</ErrorText>
            </Box>
        </AudioErrorContainer>
    );
}
