import { Box, Divider, styled } from '@mui/material';
import colors from '../../config/colorConfig';
import { strings } from '../../utils/Strings';

//
// StaticVerticalStepper was created for the xs - xl cme/completed-activity screen.
// The steps are not dynamic. Absolute positioning was used for the stepper
// dividers, and placing the stepper on a card.
//

export function StaticVerticalStepper() {
    const StepContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    });

    const NumberedCircle = styled('div')({
        borderRadius: '50%',
        backgroundColor: colors['--primary-midnight'],
        width: 64,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });

    const StepDivider = styled(Divider)({
        height: 80,
        backgroundColor: colors['--primary-midnight'],
        position: 'absolute',
        width: 2,
        border: 'none',
        orientation: 'vertical',
        left: '54px',
    });

    const StepLabelBox = styled('div')({
        textAlign: 'center',
        margin: '0 0 0 24px',
        color: colors['--primary-midnight'],
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Metric',
    });

    const StepLabelTitle = styled('h1')(({ theme }) => ({
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            height: '56px',
            width: '207px',
        },
    }));

    const StepNumber = styled('h2')({
        color: colors['--pure-white'],
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        fontFamily: 'Metric',
        paddingTop: 6,
    });

    const VerticalStepperCard = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors['--pure-white'],
        borderRadius: '4px',
        padding: '24px',
        width: '581px',
        height: '432px',
        position: 'relative',
        marginTop: '24px',
        boxShadow: '0px 2px 6px -1px rgba(0, 0, 0, 0.1)',
        filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.06))',
        [theme.breakpoints.down('md')]: {
            width: '343px',
            height: '452px',
            marginTop: '16px',
        },
    }));

    return (
        <VerticalStepperCard>
            <StepContainer sx={{ marginBottom: '96px' }}>
                <NumberedCircle sx={{ backgroundColor: colors['--success'] }}>
                    <img src="checkmark-white-large.svg" alt="white checkmark" />
                </NumberedCircle>
                <StepLabelBox>
                    <StepLabelTitle>{strings.completed_activity_step1}</StepLabelTitle>
                </StepLabelBox>
            </StepContainer>
            <StepDivider sx={{ top: 96 }} />
            <StepContainer sx={{ marginBottom: '96px' }}>
                <NumberedCircle>
                    <StepNumber>2</StepNumber>
                </NumberedCircle>
                <StepLabelBox>
                    <StepLabelTitle>{strings.completed_activity_step2}</StepLabelTitle>
                </StepLabelBox>
            </StepContainer>
            <StepDivider sx={{ bottom: { xs: 116, md: 96 } }} />
            <StepContainer>
                <NumberedCircle>
                    <StepNumber>3</StepNumber>
                </NumberedCircle>
                <StepLabelBox>
                    <StepLabelTitle>{strings.completed_activity_step3}</StepLabelTitle>
                </StepLabelBox>
            </StepContainer>
        </VerticalStepperCard>
    );
}
