import { styled } from '@mui/material';

import colors from '../../../config/colorConfig';
import { QuizScoreCardData } from '../../../models/QuizTransactionService';
import { StyledParagraph } from './quizStyledComponents';
import { Histogram } from './Histogram';
import { PercentReferenceBar } from './PercentReferenceBar';
import { AttemptsToggle } from './AttemptsToggle';

interface QuizScoreCardProps {
    scoreCard: QuizScoreCardData;
    handleReviewIntent: (attemptId: string, e?: Event) => void;
}

export const StyledSubtextParagraph = styled('p')({
    fontFamily: 'Metric',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    margin: 0,
    color: colors['--ui-grey'],
    textAlign: 'center',
});

export function QuizScoreCard({ scoreCard, handleReviewIntent }: QuizScoreCardProps) {
    const ScoreStyled = styled('div')({
        padding: '0 0 8px 0',
    });
    const ScoreTitleStyled = styled('div')({
        width: '100%',
        textAlign: 'left',
        marginBottom: '10px',
    });
    const ScoreCardContainer = styled('div')({
        backgroundColor: colors['--pure-white'],
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        marginBottom: '24px',
        maxWidth: '744px',
        gap: '16px',
        padding: '24px 24px 32px 24px',
        alignContent: 'left',
        alignItems: 'left',
    });

    return (
        <ScoreCardContainer>
            {(scoreCard.attempts?.length ?? 0) > 1 && (
                <AttemptsToggle
                    attempts={scoreCard.attempts}
                    handleReviewIntent={handleReviewIntent}
                />
            )}
            {scoreCard.userScorePercent !== null && (
                <ScoreStyled>
                    <ScoreTitleStyled>
                        <StyledParagraph>Your Score</StyledParagraph>
                    </ScoreTitleStyled>
                    <Histogram
                        histogramPercent={scoreCard.userScorePercent}
                        histogramColor={scoreCard.userScoreColor}
                        histogramBackgroundColor={scoreCard.userScoreBackgroundColor}
                    />
                </ScoreStyled>
            )}
            {scoreCard.peerScorePercent !== null && (
                <ScoreStyled>
                    <ScoreTitleStyled>
                        <StyledParagraph>Avg. Peer Score</StyledParagraph>
                    </ScoreTitleStyled>
                    <Histogram
                        histogramPercent={scoreCard.peerScorePercent}
                        histogramColor={scoreCard.peerScoreColor}
                        histogramBackgroundColor={scoreCard.peerScoreBackgroundColor}
                    />
                </ScoreStyled>
            )}
            {scoreCard.requiredScorePercent !== null && (
                <ScoreStyled>
                    <ScoreTitleStyled>
                        <StyledParagraph>Passing Score</StyledParagraph>
                    </ScoreTitleStyled>
                    <Histogram
                        histogramPercent={scoreCard.requiredScorePercent}
                        histogramColor={scoreCard.requiredScoreColor}
                        histogramBackgroundColor={scoreCard.requiredScoreBackgroundColor}
                    />
                </ScoreStyled>
            )}
            <div style={{ margin: '0px', padding: '0px', height: '32px' }}>
                <PercentReferenceBar start={'0%'} end={'100%'} />
            </div>
            {!!scoreCard.completedLearnersText && (
                <StyledSubtextParagraph>
                    {scoreCard.completedLearnersText.plain}
                </StyledSubtextParagraph>
            )}
        </ScoreCardContainer>
    );
}
