import { UserFilterSelection } from '../models/ICatalogCardsRequest';

export const filterComponentState = (
    activeFilters: UserFilterSelection[],
    filterType: string,
    filterName: string
): boolean => {
    const isSelected = activeFilters.find(
        (e: UserFilterSelection) =>
            e.filterName === filterType &&
            e.selectedValues.find((filterTitle) => filterTitle === filterName)
    );
    if (isSelected) return true;
    return false;
};
