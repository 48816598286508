import { Box } from '@mui/material';

type IFrameProps = { src: string; title: string };

export function IFrame({ src, title }: IFrameProps) {
    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
                paddingBottom: '56.25%',
                backgroundColor: 'transparent',
                marginBottom: '24px',
            }}
        >
            <iframe
                src={src}
                title={title}
                allowFullScreen={true}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    backgroundColor: 'transparent',
                }}
            />
        </Box>
    );
}
