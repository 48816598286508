import { useState, useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import TextChip from '.././TextChip';
import {
    ResponseProcessMetadata,
    Chip,
    DisplayGraphic,
    DisplayText,
} from '../../models/CMECommonResponse';
import colors from '../../config/colorConfig';
import { sendAmplitudeEvent } from '../../amplitude/AmplitudeUtil';
import { CME_SERIES_PAGE_VIEW_MORE_CLICK } from '../../amplitude/analytics';
import { ViewMoreButton } from '.././ViewMoreButton';
import { strings } from '../../utils/Strings';
import { useWindowReSize } from '../../hooks/useWindowReSize';
import { transformNewLineStringToLineBreakHTML } from '../../utils/transformNewLineStringToLineBreakHTML';
import SavedButton from '../SavedButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Tabs } from '../../models/Tabs';
import { coerceResponseData } from '../../utils/formatData';
import { setDiscoverCardOverlay } from '../../utils/setDiscoverCardOverlay';

// SeriesPageBanner renders the Series Page banner image
// and the summary/description for series pages. SeriesPageBanner
// also renders series/live event cards on the Discover screen
// Series & Live Event film strip.

export interface SeriesBannerProps {
    seriesPageId?: string;
    processMetadata?: ResponseProcessMetadata;
    banner?: DisplayGraphic;
    chip?: Chip;
    isSaved?: boolean;
    title: string | DisplayText;
    creditText: string | null;
    seriesText?: string;
    description?: string | DisplayText;
    daysSincePublication?: number;
    expirationInDays?: number | null;
    responseAmplitudeData: any;
    card?: boolean;
    isBeingRemoved?: boolean;
    handleActivityClick?: () => void;
    handleSavedClick?: () => void;
    isVisible?: boolean;
    amplitudeData?: any;
    cardRef?: React.RefObject<HTMLDivElement>;
    overlayEnabled?: boolean;
}

export default function SeriesPageBanner({
    banner,
    chip,
    creditText,
    seriesText,
    title,
    description,
    responseAmplitudeData,
    isSaved,
    isBeingRemoved,
    handleActivityClick,
    handleSavedClick,
    card,
    cardRef,
    overlayEnabled,
}: SeriesBannerProps) {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [isOverflowing, setIsOverflowing] = useState<boolean | null>();
    const windowSize = useWindowReSize();

    let seriesDescription: string = coerceResponseData(description || '');

    const defaultSavedButtonHandler = () => {
        return;
    };

    const formattedDescription =
        seriesDescription && transformNewLineStringToLineBreakHTML(seriesDescription);

    const CustomCard = styled('div')({
        borderRadius: '4px',
        cursor: card ? 'pointer' : 'default',
        border: 'none',
        backgroundColor: card ? 'transparent' : colors['--primary-midnight'],
        marginRight: card ? '24px' : 'none',
        color: colors['--pure-white'],
        filter: card ? 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))' : 'none',
    });

    const ThumbnailContainer = styled('div')(({ theme }) => ({
        width: card ? '302px' : '100%',
        position: 'relative',
        borderRadius: '4px 4px 0 0',
        [theme.breakpoints.up('xs')]: {
            height: card ? '164px' : '160px',
        },
        [theme.breakpoints.up('sm')]: {
            height: card ? '164px' : '328px',
        },
        [theme.breakpoints.up('md')]: {
            height: card ? '164px' : '360px',
        },
    }));

    const CardImage = styled('img')(({ theme }) => ({
        width: '100%',
        objectFit: 'cover',
        borderRadius: '4px 4px 0 0',
        [theme.breakpoints.up('xs')]: {
            height: card ? '164px' : '160px',
        },
        [theme.breakpoints.up('sm')]: {
            height: card ? '164px' : '328px',
        },
        [theme.breakpoints.up('md')]: {
            height: card ? '164px' : '360px',
        },
    }));

    const overlay = setDiscoverCardOverlay(card ?? false, overlayEnabled ?? false);

    const ThumbnailOverlay = styled('div')(({ theme }) => ({
        position: 'absolute',
        width: card ? '270px' : '100%',
        bottom: 0,
        left: 0,
        borderRadius: '4px 4px 0 0',
        background: overlay,
        [theme.breakpoints.up('xs')]: {
            height: card ? '140px' : '160px',
            padding: card ? '16px 16px 8px 16px' : '16px 40px 16px 16px',
        },
        [theme.breakpoints.up('sm')]: {
            height: card ? '140px' : '328px',
            padding: card ? '16px 16px 8px 16px' : '24px 48px 28px 24px',
        },
        [theme.breakpoints.up('md')]: {
            height: card ? '140px' : '360px',
            padding: card ? '16px 16px 8px 16px' : '24px 48px 28px 24px',
        },
    }));

    const BannerTitleContainer = styled(Box)({
        maxWidth: card ? '270px' : '600px',
        display: 'flex',
        paddingRight: '8px',
    });

    const BannerTitle = styled(Typography)(({ theme }) => ({
        fontWeight: card ? 500 : 400,
        width: '100%',
        // css for multi line ellipses
        textOverflow: 'ellipses',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        [theme.breakpoints.up('xs')]: {
            fontSize: '24px',
            lineHeight: card ? '28px' : '26px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: card ? '24px' : '38px',
            lineHeight: card ? '28px' : '40px',
        },
    }));

    const TextChipSavedContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        position: 'absolute',
        justifyContent: card ? 'space-between' : 'default',
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            bottom: card ? '8px' : '10%',
        },
        [theme.breakpoints.up('sm')]: {
            bottom: card ? '8px' : '8%',
        },
    }));

    let paddingXtraSmall = '12px 16px';
    let paddingSmall = '12px 16px';

    if (card) {
        paddingXtraSmall = '0px 16px';
        paddingSmall = '0px 16px';
    } else {
        paddingXtraSmall = description ? '12px 16px 0 16px' : '12px 16px';
        paddingSmall = description ? '16px 24px 0 24px' : '12px 16px';
    }

    const CreditsDurationContainer = styled('div')(({ theme }) => ({
        width: card ? '270px' : '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        height: '40px',
        borderRadius: card ? '0px 0px 4px 4px' : 'none',
        borderColor: card ? colors['--pure-white'] : 'none',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'space-between',
            padding: paddingXtraSmall,
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: card ? 'space-between' : 'flex-start',
            padding: paddingSmall,
        },
        backgroundColor: card ? colors['--pure-white'] : colors['--primary-midnight'],
        color: card ? colors['--ui-slate'] : colors['--pure-white'],
    }));

    const StyledViewMoreButton = styled(ViewMoreButton)(({ theme }) => ({
        fontStyle: 'normal',
        lineHight: '20px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }));

    const SeriesDescription = styled(Typography)(({ theme }) => ({
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        [theme.breakpoints.only('xs')]: isCollapsed
            ? {
                  WebkitLineClamp: 4,
                  textOverflow: 'ellipses',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
              }
            : {},
        [theme.breakpoints.between('sm', 'md')]: isCollapsed
            ? {
                  WebkitLineClamp: 2,
                  textOverflow: 'ellipses',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
              }
            : {},
    }));

    const DescriptionContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        color: colors['--pure-white'],
        backgroundColor: colors['--primary-midnight'],
        borderRadius: '0 0 4px 4px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        [theme.breakpoints.up('xs')]: {
            padding: '16px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: isOverflowing ? '16px 24px' : '16px 24px 24px 24px',
        },
    }));

    // prevent the View More/Less button from disappearing completely if a user resizes the window while View more is active on Tablet/Mobile
    // this causes an abrupt flash of the description/view more section, because the view more component and description css rerender/change
    // tablet/mobile users won't be resizing the window too much while View More is active (hopefully), so this is a workaround for now...
    useEffect(() => {
        setIsCollapsed(true);
    }, [windowSize]);

    // check for overflow any time window is resized to determine if View More/Less button should be visible or not
    useEffect(() => {
        if (seriesDescription) {
            const offSetHeight = (
                document.getElementsByClassName('banner-description')[0] as HTMLElement
            ).offsetHeight;

            const scrollHeight = (
                document.getElementsByClassName('banner-description')[0] as HTMLElement
            ).scrollHeight;

            if (offSetHeight && offSetHeight < scrollHeight) {
                setIsOverflowing(true);
            } else {
                setIsOverflowing(false);
            }
        }
    }, [windowSize.width]);

    const handleViewMoreClick = () => {
        if (isCollapsed) {
            sendAmplitudeEvent(CME_SERIES_PAGE_VIEW_MORE_CLICK, responseAmplitudeData);
        }
        setIsCollapsed(!isCollapsed);
    };
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    return (
        <div
            ref={cardRef}
            style={{
                height: card ? '206px' : 'none',
                backgroundColor: card ? 'transparent' : 'none',
            }}
        >
            <CustomCard
                onClick={
                    card && currentSourceTab === Tabs.DISCOVER
                        ? handleActivityClick
                        : defaultSavedButtonHandler
                }
            >
                <ThumbnailContainer>
                    <CardImage
                        src={banner?.imageUrl || require('../../images/thumbnail-default.png')}
                        alt="Series Page banner image"
                    />
                    <ThumbnailOverlay>
                        <BannerTitleContainer>
                            <BannerTitle variant="h1">{title}</BannerTitle>
                        </BannerTitleContainer>
                        <TextChipSavedContainer>
                            <Box>
                                {chip && (
                                    <TextChip
                                        text={chip.text}
                                        bgColor={chip.bgColor}
                                        fgColor={chip.fgColor}
                                    />
                                )}
                            </Box>
                            {card && (
                                <Box
                                    style={{ right: card ? '30px' : '22px', position: 'relative' }}
                                >
                                    <SavedButton
                                        isSaved={isSaved || false}
                                        onClick={handleSavedClick || defaultSavedButtonHandler}
                                        isBeingRemoved={isBeingRemoved}
                                    />
                                </Box>
                            )}
                        </TextChipSavedContainer>
                    </ThumbnailOverlay>
                </ThumbnailContainer>
                <CreditsDurationContainer className="credits-and-duration-container">
                    {seriesText && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box>
                                {card ? (
                                    <img src={'cme-book-logo-black.svg'} alt="CME Series Logo" />
                                ) : (
                                    <img src={'regular-cme.svg'} alt="CME Series Logo" />
                                )}
                            </Box>
                            <Box sx={{ marginLeft: '4px' }}>{seriesText}</Box>
                        </Box>
                    )}
                    {creditText && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box sx={{ marginLeft: '16px' }}>
                                {card ? (
                                    <img src={'regular-cme-credit.svg'} alt="CME Credit Logo" />
                                ) : (
                                    <img
                                        src={'regular-cme-credit-pure-white.svg'}
                                        alt="CME Credit Logo"
                                    />
                                )}
                            </Box>
                            <Box sx={{ marginLeft: '4px' }}>{`${creditText}`}</Box>
                        </Box>
                    )}
                </CreditsDurationContainer>
                {!card && seriesDescription && (
                    <DescriptionContainer>
                        <SeriesDescription variant="body1" className="banner-description">
                            {formattedDescription}
                        </SeriesDescription>
                        {isOverflowing && (
                            <Box
                                sx={{
                                    marginTop: '16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <StyledViewMoreButton
                                    onClick={handleViewMoreClick}
                                    isCollapsed={isCollapsed}
                                    color={colors['--pure-white']}
                                    viewMoreText={strings.view_more}
                                    viewLessText={strings.view_less}
                                    fontWeight={500}
                                    fontSize={'16px'}
                                />
                            </Box>
                        )}
                    </DescriptionContainer>
                )}
            </CustomCard>
        </div>
    );
}
