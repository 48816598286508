import { styled } from '@mui/material';
import colors from '../../../config/colorConfig';
import { ContainerHighlight } from '../../../utils/setQuestionContainerHighlight';

export const StyledParagraph = styled('p')({
    fontFamily: 'Metric',
    fontWeight: 400,
    fontSize: '18px',
    margin: 0,
    color: colors['--primary-midnight'],
});

type StyledSingleLineInputType = {
    isVisible: boolean;
    cursor: string;
    readOnly: boolean | undefined;
    borderColor?: string;
};

export const StyledSingleLineInput = styled('input', {
    shouldForwardProp: (prop) => {
        return prop !== 'isVisible' && prop !== 'borderColor';
    },
})(({ isVisible, cursor, readOnly, borderColor }: StyledSingleLineInputType) => ({
    border: `solid 1px ${borderColor || colors['--ui-grey-med']}`,
    borderRadius: '4px',
    fontFamily: 'Metric',
    fontSize: '18px',
    paddingLeft: '16px',
    paddingRight: '16px',
    resize: 'none',
    color: readOnly ? colors['--ui-grey'] : colors['--primary-midnight'],
    '&::placeholder': {
        color: colors['--ui-grey'],
    },
    height: '34px',
    '&:focus': {
        border: `1px solid ${colors['--primary-teal-night']} !important`,
        outline: 'none',
    },
    display: isVisible ? 'inline' : 'none',
    cursor: cursor,
    width: '100%',
    pointerEvents: readOnly ? 'none' : 'auto',
    backgroundColor: readOnly ? colors['--ui-grey-ultra-light'] : 'none',
}));

type StyledTextAreaType = {
    isVisible: boolean;
    cursor: string;
    readOnly: boolean | undefined;
};

export const StyledTextArea = styled('textarea', {
    shouldForwardProp: (prop) => {
        return prop !== 'isVisible';
    },
})(({ isVisible, cursor, readOnly }: StyledTextAreaType) => ({
    border: `solid 1px ${colors['--ui-grey-med']}`,
    borderRadius: '4px',
    fontFamily: 'Metric',
    fontSize: '18px',
    paddingLeft: '16px',
    paddingRight: '16px',
    resize: 'none',
    color: readOnly ? colors['--ui-grey'] : colors['--primary-midnight'],
    '&::placeholder': {
        color: colors['--ui-grey'],
    },
    minHeight: '120px',
    '&:focus': {
        border: `1px solid ${colors['--primary-teal-night']} !important`,
        outline: 'none',
    },
    display: isVisible ? 'inline' : 'none',
    cursor: cursor,
    pointerEvents: readOnly ? 'none' : 'auto',
    backgroundColor: readOnly ? colors['--ui-grey-ultra-light'] : 'none',
}));

export const StyledInputSubmit = styled('input')({
    textTransform: 'none',
    width: '264px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '16px',
    borderRadius: '3px',
    border: 'none',
    color: colors['--pure-white'],
    backgroundColor: colors['--primary-teal-night'],
    cursor: 'pointer',
    padding: '12px 64px',
    '&:hover': {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        backgroundColor: colors['--ui-slate'],
    },
    '&:focus': {
        outline: 'none',
    },
});

type QuestionContainerType = {
    isConcluded: boolean;
    containerShadowColor: ContainerHighlight;
};

export const QuestionContainer = styled('div', {
    shouldForwardProp: (prop) => {
        return prop !== 'isConcluded' && prop !== 'containerShadowColor';
    },
})(({ isConcluded, containerShadowColor }: QuestionContainerType) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    boxShadow: isConcluded ? '0px 1px 3px rgba(0, 0, 0, 0.12)' : containerShadowColor.boxShadow,
    borderRadius: '4px',
    padding: '24px 24px 32px 24px',
    maxWidth: '744px',
    backgroundColor: colors['--pure-white'],
    border: isConcluded ? 'none' : containerShadowColor.border,
}));
