/* eslint-disable jsx-a11y/media-has-caption */
import { BLOCKS, Document, MARKS, Node } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
    StyledTable,
    StyledTableHeader,
    StyledTableRow,
    StyledTableData,
    StyledTableContainer,
    ParagraphNoMargin,
} from './frontMatterStyledComponents';
import { ImageAndTextCard } from './ImageAndTextCard';

import { MediaPlayer } from '../content/MediaPlayer';
import { IFrame } from '../content/IFrame';

export const frontMatterRenderOptions = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
            if (node.data.target.fields.file.contentType.match(/image/gim))
                return (
                    <img
                        src={node.data.target.fields.file.url}
                        alt={node.data.target.fields.description}
                    />
                );
            if (node.data.target.fields.file.contentType.match(/video/gim)) {
                return <MediaPlayer url={node.data.target.fields.file.url} mediaType="video" />;
            }
            if (node.data.target.fields.file.contentType.match(/audio/gim)) {
                return <MediaPlayer url={node.data.target.fields.file.url} mediaType="audio" />;
            }
        },
        [BLOCKS.PARAGRAPH]: (node: Node, children: any) => (
            <p className="epolms-paragraph">{children}</p>
        ),
        [BLOCKS.TABLE]: (node: Node, children: any) => (
            <StyledTableContainer>
                <StyledTable>
                    <tbody>{children}</tbody>
                </StyledTable>
            </StyledTableContainer>
        ),
        [BLOCKS.TABLE_ROW]: (node: Node, children: any) => (
            <StyledTableRow>{children}</StyledTableRow>
        ),
        [BLOCKS.TABLE_HEADER_CELL]: (node: Node) => {
            const transformedChildren = documentToReactComponents(node as Document, {
                renderNode: {
                    // remove p tag from table headers
                    [BLOCKS.PARAGRAPH]: (_node, children) => children,
                    [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => children,
                },
            });
            return <StyledTableHeader>{transformedChildren}</StyledTableHeader>;
        },
        [BLOCKS.TABLE_CELL]: (node: Node) => {
            const transformedChildren = documentToReactComponents(node as Document, {
                renderNode: {
                    [BLOCKS.PARAGRAPH]: (_node, children) => {
                        if ((children as string)[0].length === 0) {
                            // for empty paragraphs, return a paragraph with margin to create spacing
                            return <p>{children}</p>;
                        }
                        return <ParagraphNoMargin>{children}</ParagraphNoMargin>;
                    },
                    [BLOCKS.TABLE_CELL]: (_node, children) => children,
                    renderText: (text: any) => {
                        return text
                            .split('\n')
                            .reduce((children: any, textSegment: any, index: number) => {
                                return [...children, index > 0 && <br key={index} />, textSegment];
                            }, []);
                    },
                },
            });
            return <StyledTableData>{transformedChildren}</StyledTableData>;
        },
        [BLOCKS.UL_LIST]: (node: Node, children: any) => <ul className="epolms-ul">{children}</ul>,
        [BLOCKS.OL_LIST]: (node: Node, children: any) => <ol className="epolms-ol">{children}</ol>,
        // https://github.com/contentful/rich-text/issues/126
        // contentful wraps every text in a p tag by default
        [BLOCKS.LIST_ITEM]: (node: any) => {
            const transformedChildren = documentToReactComponents(node as Document, {
                renderNode: {
                    // remove p tag from each li
                    [BLOCKS.PARAGRAPH]: (_node, children) => children,
                    [BLOCKS.LIST_ITEM]: (_node, children) => children,
                    // handle rendering of nested lists
                    [BLOCKS.UL_LIST]: (_node) => {
                        const transformUnorderedList = documentToReactComponents(
                            _node as Document,
                            {
                                renderNode: {
                                    [BLOCKS.PARAGRAPH]: (__node, children) => children,
                                    [BLOCKS.LIST_ITEM]: (__node, children) => <li>{children}</li>,
                                },
                            }
                        );
                        return <>{transformUnorderedList}</>;
                    },
                    [BLOCKS.OL_LIST]: (_node) => {
                        const transformedOrderedList = documentToReactComponents(
                            _node as Document,
                            {
                                renderNode: {
                                    [BLOCKS.PARAGRAPH]: (__node, children) => children,
                                    [BLOCKS.LIST_ITEM]: (__node, children) => <li>{children}</li>,
                                },
                            }
                        );
                        return <>{transformedOrderedList}</>;
                    },
                },
            });
            return <li>{transformedChildren}</li>;
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
            if (node.data.target.sys.contentType.sys.id === 'epolmsIframe') {
                return (
                    <IFrame
                        src={node.data.target.fields.src}
                        title={node.data.target.fields.title}
                    />
                );
            }
            if (node.data.target.sys.contentType.sys.id === 'epolmsFacultyCard') {
                return (
                    <ImageAndTextCard
                        imgSrc={node.data.target.fields.image.fields.file.url}
                        altText={node.data.target.fields.image.fields.description}
                        cardText={node.data.target.fields.cardText}
                    />
                );
            }
        },
        // https://www.contentful.com/blog/rich-text-field-tips-and-tricks/
        // SHIFT + ENTER in Contentful editor detected as newline
        renderText: (text: any) => {
            return text.split('\n').reduce((children: any, textSegment: any, index: number) => {
                return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
        },
    },
    // set the default fontweight for bolded rich text
    renderMark: {
        [MARKS.BOLD]: (text: any) => {
            return <b style={{ fontWeight: 500 }}>{text}</b>;
        },
    },
};
