import {
    IUserActivityAttitude,
    IUserSeriesAttitude,
    IUserAttitude,
} from '../models/ISetUserAttitudesRequest';

export const createUserActivityAttitudeRequest = (
    isSeriesCard: boolean,
    isSavedState: boolean,
    activityId: string
) => {
    if (isSeriesCard) {
        const seriesRequestJson: IUserAttitude = {
            seriesPageId: activityId,
            isSavedByUser: isSavedState,
        } as IUserSeriesAttitude;

        return seriesRequestJson;
    } else {
        const activityRequestJson: IUserAttitude = {
            activityId: activityId,
            isSavedByUser: isSavedState,
        } as IUserActivityAttitude;

        return activityRequestJson;
    }
};
