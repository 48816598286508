export default function environment() {
    let env = 'PROD';
    if (window !== undefined) {
        const host = window.location.hostname;
        if (host.includes('dev.')) {
            env = 'DEV';
        } else if (host.includes('int.')) {
            env = 'INT';
        } else if (host.includes('localhost') || host.includes('127.0.0.1')) {
            env = 'LOCAL';
        }
    }
    return env;
}

export const prod = () => {
    if (environment() === 'PROD') {
        return true;
    }
    return false;
};
