import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { CollectionAmplitudeState } from '../models/AmplitudeState';
import { SupplementalCard } from '../models/CMECommonResponse';
import { executeLaunchAction } from '../services/launchActionService';
import { getSimplifiedPreWorkData, sendAmplitudeEvent } from '../amplitude/AmplitudeUtil';
import { CME_SERIES_PAGE_BEGIN_PRE_WORK_ASSESSMENT_CLICK } from '../amplitude/analytics';
import { ContainedButton } from './StyledButtons';
import { strings } from '../utils/Strings';
import { ShowErrorModal } from './Error/ShowErrorModal';
import { RootState } from '../store/store';

export function SupplementalActivityCard({
    cardPayload,
    responseAmplitudeData,
    collectionAmplitudeData,
}: {
    cardPayload: SupplementalCard;
    responseAmplitudeData: any;
    collectionAmplitudeData?: CollectionAmplitudeState | null;
}) {
    const PreWorkContainer = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
    });

    const ButtonTextContainer = styled('div')({
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: '8px 12px',
        lineHeight: '18px',
    });

    const ButtonText = styled('div')({
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    });

    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const dispatch = useDispatch();

    const handleButtonClick = async () => {
        try {
            if (cardPayload.clickAction) {
                const action = await executeLaunchAction(cardPayload.clickAction);
                if (action.launched && responseAmplitudeData && collectionAmplitudeData) {
                    const simplifiedData = getSimplifiedPreWorkData(
                        responseAmplitudeData,
                        collectionAmplitudeData,
                        currentSourceTab
                    );
                    sendAmplitudeEvent(
                        CME_SERIES_PAGE_BEGIN_PRE_WORK_ASSESSMENT_CLICK,
                        simplifiedData
                    );
                }
            }
        } catch (error) {
            ShowErrorModal(error, dispatch, strings.error_action);
        }
    };
    return (
        <PreWorkContainer className="pre-work-container">
            {cardPayload.caption && <p>{cardPayload.caption?.plain}</p>}
            {cardPayload.buttonText && (
                <ContainedButton width={'100%'} type="button" onClick={handleButtonClick}>
                    <ButtonTextContainer>
                        <ButtonText>{cardPayload.buttonText.plain}</ButtonText>
                    </ButtonTextContainer>
                </ContainedButton>
            )}
        </PreWorkContainer>
    );
}
