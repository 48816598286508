import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import colors from '../../config/colorConfig';
import { AvailableFilterSelection, AvailableUserFilter } from '../../models/CatalogCardsResponse';
import { ExpandCollapseFilterState, updateExpandCollapseFilters } from '../../Slices/FilterSlice';
import { RootState } from '../../store/store';
import { sendAmplitudeEvent } from '../../amplitude/AmplitudeUtil';
import { CME_FILTER_SEE_MORE_CLICK } from '../../amplitude/analytics';
import { FilterCheckBox } from '../custom/FilterCheckBox';

interface VerticalFilterProps {
    userFilter: AvailableUserFilter;
}

interface VerticalFilterState {
    fullRender: boolean;
    lessButton: boolean;
}

export const VerticalFilters = (props: VerticalFilterProps) => {
    let expandCollapseFilterState: ExpandCollapseFilterState[] = useSelector(
        (state: RootState) => state.filter.expandCollapseFiltersState
    );

    const dispatch = useDispatch();
    let isFullRenderState: boolean = false;
    let lessButton: boolean = false;

    for (let i = 0; i < expandCollapseFilterState.length; i++) {
        if (expandCollapseFilterState[i].filterName === props.userFilter.filterName) {
            if (expandCollapseFilterState[i].isExpand === true) {
                isFullRenderState = true;
                lessButton = true;
                continue;
            }
        }
    }

    // let isFullRender: boolean = false;
    if (props.userFilter.selections.length < 10) {
        isFullRenderState = true;
    }

    const [verticalFilterState, setVerticalFilterState] = useState<VerticalFilterState>({
        fullRender: isFullRenderState,
        lessButton: lessButton,
    });
    const filterSelections: AvailableFilterSelection[] = props.userFilter.selections;

    const handleMoreClick = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        sendAmplitudeEvent(CME_FILTER_SEE_MORE_CLICK, {
            'Filter Expanded': props.userFilter.filterName,
        });
        dispatch(
            updateExpandCollapseFilters({ filterName: props.userFilter.filterName, isExpand: true })
        );
        setVerticalFilterState({ fullRender: true, lessButton: true });
    };
    const handleLessClick = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        dispatch(
            updateExpandCollapseFilters({
                filterName: props.userFilter.filterName,
                isExpand: false,
            })
        );
        setVerticalFilterState({ fullRender: false, lessButton: false });
    };

    //If less than 10 elements in the filter list, present a full render

    const partialRenderjsx = () => {
        const objs = [];
        let iterator = filterSelections.length;
        if (filterSelections.length > 10) {
            iterator = 10;
        }
        for (let i = 0; i < iterator; i++) {
            objs.push(
                <Grid item key={i}>
                    <FilterCheckBox
                        enabled={filterSelections[i].enabled}
                        filterName={props.userFilter.filterName}
                        text={filterSelections[i].value}
                    />
                </Grid>
            );
        }
        return objs;
    };

    return (
        <>
            {!verticalFilterState.fullRender ? (
                <Grid container style={{ marginBottom: '32px' }} direction="column" rowGap="8px">
                    {partialRenderjsx()}
                    <Grid item>
                        <Button
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            sx={{
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                            style={{
                                backgroundColor: colors['--pure-white'],
                                padding: 0,
                                border: 0,
                                textTransform: 'none',
                            }}
                            onClick={handleMoreClick}
                        >
                            <Grid
                                container
                                columns={2}
                                direction="row"
                                columnGap="4px"
                                alignItems="center"
                            >
                                <Grid item>
                                    <img
                                        style={{ height: '20px', width: '20px' }}
                                        src="Regular_add.svg"
                                        alt="Add Icon"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    style={{ width: '28px', height: '20px', paddingTop: '1px' }}
                                >
                                    <Typography
                                        sx={{
                                            width: '28px',
                                            height: '20px',
                                            textAlign: 'center',
                                            fontFamily: 'Metric',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            fontStretch: 'normal',
                                            letterSpacing: 'normal',
                                            lineHeight: 1.43,
                                            color: colors['--primary-teal'],
                                        }}
                                    >
                                        More
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid container style={{ marginBottom: '32px' }} direction="column" rowGap="8px">
                    {props.userFilter.selections.map(
                        (selection: AvailableFilterSelection, index: number) => {
                            return (
                                <Grid item key={index}>
                                    <FilterCheckBox
                                        enabled={selection.enabled}
                                        filterName={props.userFilter.filterName}
                                        text={selection.value}
                                    />
                                </Grid>
                            );
                        }
                    )}
                    {verticalFilterState.lessButton && (
                        <Grid item>
                            <Button
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                    '&:focus': {
                                        outline: 'none',
                                    },
                                }}
                                style={{
                                    backgroundColor: colors['--pure-white'],
                                    padding: 0,
                                    border: 0,
                                    textTransform: 'none',
                                }}
                                onClick={handleLessClick}
                            >
                                <Grid
                                    container
                                    columns={2}
                                    direction="row"
                                    columnGap="4px"
                                    alignItems="center"
                                >
                                    <Grid style={{ paddingTop: '3px' }} item>
                                        <div
                                            style={{
                                                border: `1.0px solid ${colors['--primary-teal']}`,
                                                width: '12.5px',
                                                height: '0.56px',
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{ width: '28px', height: '20px', paddingTop: '1px' }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '28px',
                                                height: '20px',
                                                textAlign: 'center',
                                                fontFamily: 'Metric',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontStretch: 'normal',
                                                letterSpacing: 'normal',
                                                lineHeight: 1.43,
                                                color: colors['--primary-teal'],
                                            }}
                                        >
                                            Less
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};
