import { useEffect, useState, memo } from 'react';
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import colors from '../../config/colorConfig';

const PasswordField = (props: any) => {
    const [values, setValues] = useState({
        showPassword: false,
        password: '',
        validationError: '',
        isError: false,
    });

    useEffect(() => {
        if (props.validationError) {
            setValues({
                ...values,
                validationError: props.validationError,
                isError: true,
            });
        } else {
            setValues({
                ...values,
                validationError: '',
                isError: false,
            });
        }
    }, [props.validationError]);

    const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        // If there an input validation
        if (props.validateInput) {
            const onChangeValidateInput = props.validateInput;
            const validationMsg = onChangeValidateInput(event.target.value);
            if (validationMsg) {
                setValues({
                    ...values,
                    validationError: validationMsg,
                    password: event.target.value,
                    isError: true,
                });
            } else {
                setValues({
                    ...values,
                    validationError: '',
                    password: event.target.value,
                    isError: false,
                });
            }
        } else {
            setValues({ ...values, password: event.target.value });
        }
    };
    const handleKeyUpEvent = () => {
        if (props.validateInput) {
            const onChangeValidateInput = props.validateInput;
            const validationMsg = onChangeValidateInput(values.password);
            if (validationMsg) {
                setValues({
                    ...values,
                    validationError: validationMsg,
                    isError: true,
                });
            } else {
                setValues({
                    ...values,
                    validationError: '',
                    isError: false,
                });
            }
        }
    };
    useEffect(() => {
        if (props.defaultValue) {
            setValues({
                ...values,
                password: props.defaultValue,
            });
            handleChange();
        }
    }, []);
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <FormControl variant="outlined" required fullWidth>
                <InputLabel
                    data-testid={props.labelTestId}
                    htmlFor={props.labelHtmlFor}
                    color="primary"
                >
                    {props.labelTitle}
                </InputLabel>
                <OutlinedInput
                    error={values.isError}
                    id={props.name}
                    data-testid={props.name}
                    name={props.name}
                    inputProps={{
                        maxLength: 100,
                        'data-testid': props.inputTestId,
                    }}
                    onClick={props.onClickEvent}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onKeyUp={handleKeyUpEvent}
                    onChange={handleChange()}
                    onPaste={(event) => event.preventDefault()}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disabled={!values.password && !values.isError}
                                color={!values.isError ? 'primary' : 'error'}
                                classes={{
                                    root: 'icon-button-root',
                                    disabled: 'icon-button-disabled',
                                }}
                                aria-label={props.ariaLabel}
                                onClick={handleClickShowPassword}
                                data-testid={props.iconTestId}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                            >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormHelperText
                data-testid={props.helpTestId}
                style={{
                    color: colors['--failure'],
                    paddingBottom: '1px',
                    paddingTop: '1px',
                    paddingLeft: '14px',
                    marginTop: '1px',
                }}
            >
                {values.validationError}
            </FormHelperText>
        </>
    );
};
export const Password = memo(PasswordField);
