import { SubscriptionResponse } from './../models/SubscriptionResponse';
import axios from 'axios';
import config from '../config/envConfig';
import { getCurrentAccessTokenOrRequestLogin } from '../utils/cookies';
import { IUserIdRequest } from '../models/IUserIdRequest';

export const getUserSubscription = async (
    request: IUserIdRequest
): Promise<SubscriptionResponse> => {
    const instance = axios.create({
        baseURL: config.environment.purchaseUrl,
        timeout: 30000,
    });

    const accessToken = await getCurrentAccessTokenOrRequestLogin();

    try {
        const response = await instance.get(`/subscriptions/user/${request.userId}`, {
            headers: {
                Authorization: `${accessToken}`,
                accept: 'application/json',
            },
        });
        return response as unknown as SubscriptionResponse;
    } catch (error) {
        throw error;
    }
};
