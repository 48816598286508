import React, { RefObject } from 'react';
import { ResponseAmplitudeState, CollectionAmplitudeState } from '../models/AmplitudeState';
import {
    isActivityCard,
    isSupplementalCard,
    isResourceCard,
    CMECard,
    isSeriesBannerCard,
} from '../models/CMECommonResponse';

import { ResourceLinkCard } from './ResourceLinkCard';
import { SupplementalActivityCard } from './SupplementalActivityCard';
import { VerticalViewCard } from './VerticalViewCard';
import { SliderProperties } from './HorizontalSwiper';

export interface PolymorphicCardProps {
    card: CMECard;
    sliderProperties: SliderProperties;
    responseAmplitudeData?: ResponseAmplitudeState | undefined;
    collectionAmplitudeData?: CollectionAmplitudeState | null;
    cardIndex: number;
    swiperRef?: RefObject<HTMLDivElement>;
    isProgramEndpoint?: boolean;
}

export function PolymorphicCard({
    card,
    responseAmplitudeData,
    collectionAmplitudeData,
    cardIndex,
    swiperRef,
    isProgramEndpoint,
}: PolymorphicCardProps): JSX.Element | null {
    // activity card returned from series page
    if (card?.cardType === 'cme-activity' && card.cardPayload && isActivityCard(card.cardPayload)) {
        return (
            <VerticalViewCard
                activityId={card.cardPayload.activityId}
                thumbnailUrl={card.cardPayload.thumbnailUrl || ''}
                chipText={card.cardPayload.chipText || ''}
                description={card.cardPayload.description}
                expirationInDays={card.cardPayload.expirationInDays}
                mediaFormat={card.cardPayload.mediaFormat}
                title={card.cardPayload.title}
                creditText={card.cardPayload.creditText}
                durationText={card.cardPayload.durationText}
                accessCode={card.cardPayload.accessCode}
                durationInMinutes={card.cardPayload.durationInMinutes}
                creditAmount={card.cardPayload.creditAmount}
                subtypeName={card.cardPayload.subtypeName}
                daysSincePublication={card.cardPayload.daysSincePublication}
                isSaved={card.cardPayload.isSaved}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
                cardIndex={cardIndex}
                isSeriesCard={false}
                isProgramEndpoint={isProgramEndpoint}
            />
        );
    } else if (card?.cardType === 'cme-supplemental' && isSupplementalCard(card.cardPayload)) {
        return (
            <SupplementalActivityCard
                cardPayload={card.cardPayload}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
            />
        );
    } else if (card?.cardType === 'cme-resource' && isResourceCard(card.cardPayload)) {
        return (
            <ResourceLinkCard
                cardPayload={card.cardPayload}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
                cardIndex={cardIndex}
            />
        );
    } else if (card && isActivityCard(card)) {
        // activity card returned from discover screen (v1)
        return (
            <VerticalViewCard
                activityId={card.activityId}
                thumbnailUrl={card.thumbnailUrl || ''}
                chipText={card.chipText || ''}
                description={card.description}
                expirationInDays={card.expirationInDays}
                mediaFormat={card.mediaFormat}
                title={card.title}
                creditText={card.creditText}
                durationText={card.durationText}
                accessCode={card.accessCode}
                durationInMinutes={card.durationInMinutes}
                creditAmount={card.creditAmount}
                subtypeName={card.subtypeName}
                daysSincePublication={card.daysSincePublication}
                isSaved={card.isSaved || false}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
                cardIndex={cardIndex}
                isSeriesCard={false}
                swiperRef={swiperRef}
                isProgramEndpoint={isProgramEndpoint}
            />
        );
    } else if (isSeriesBannerCard(card)) {
        return (
            <VerticalViewCard
                activityId={card?.seriesPageId || ''}
                description={card.description || ''}
                expirationInDays={card.expirationInDays}
                creditText={card.creditText}
                daysSincePublication={card.daysSincePublication}
                isSaved={card.isSaved || false}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
                cardIndex={cardIndex}
                isSeriesCard={true}
                banner={card.banner}
                chip={card.chip}
                seriesText={card.seriesText}
                title={card.title}
                swiperRef={swiperRef}
                isProgramEndpoint={isProgramEndpoint}
            />
        );
    } else {
        // Unknown card case -- explicitly skip over this card
        return <></>;
    }
}
