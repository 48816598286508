import environment from '../utils/environment';

const config = {
    environment: {
        get authenticationUrl(): string {
            return process.env[`REACT_APP_${environment()}_AUTHENTICATION_URL`] as string;
        },
        get cmeApiUrl(): string {
            return process.env[`REACT_APP_${environment()}_CME_API_URL`] as string;
        },
        get profileUrl(): string {
            return process.env[`REACT_APP_${environment()}_PROFILE_URL`] as string;
        },
        get segmentApiKey(): string {
            return process.env[`REACT_APP_${environment()}_SEGMENT_ANALYTICS_WRITE_KEY`] ?? '';
        },
        get amplitude_project_name(): string {
            return process.env[`REACT_APP_${environment()}_PROJECT_NAME`] as string;
        },
        get reCAPTCHA_site_key(): string {
            return process.env[`REACT_APP_${environment()}_RECAPTCHA_SITE_KEY`] as string;
        },
        get integrationTest(): boolean {
            return JSON.parse(
                process.env[`REACT_APP_${environment()}_INTEGRATION_TEST`] || 'false'
            );
        },
        get legacyLoginToggle(): boolean {
            return JSON.parse(process.env[`REACT_APP_${environment()}_LEGACY_LOGIN`] || 'false');
        },
        get baseEpocratesUrl(): string {
            return process.env[`REACT_APP_${environment()}_BASE_EPOCRATES_URL`] as string;
        },
        get cmeProcessApiUrl(): string {
            return process.env[`REACT_APP_${environment()}_CME_PROCESS_API_URL`] as string;
        },
        get purchaseUrl(): string {
            return process.env[`REACT_APP_${environment()}_PURCHASE_URL`] as string;
        },
        get cmeRedirectUrl(): string {
            return process.env[`REACT_APP_${environment()}_CME_REDIRECT_URL`] as string;
        },
        get eolURL(): string {
            return process.env[`REACT_APP_${environment()}_EOL_URL`] as string;
        },
        get tempDiscoveryToggle(): boolean {
            return JSON.parse(process.env[`REACT_APP_${environment()}_TEMP_DISCOVERY`] || 'false');
        },
        get contentfulAccessToken(): string {
            return process.env[
                `REACT_APP_${environment()}_CONTENTFUL_DELIVERY_API_ACCESS_TOKEN`
            ] as string;
        },
        get contentfulSpaceId(): string {
            return process.env[`REACT_APP_${environment()}_CONTENTFUL_SPACE_ID`] as string;
        },
        get contentfulEnvironment(): string {
            return process.env[`REACT_APP_${environment()}_CONTENTFUL_ENVIRONMENT`] as string;
        },
        get programTransactUrl(): string {
            return process.env[`REACT_APP_${environment()}_PROGRAM_TRANSACT_URL`] as string;
        },
        get quizTransactUrl(): string {
            return process.env[`REACT_APP_${environment()}_QUIZ_TRANSACT_URL`] as string;
        },
        get creditTransactUrl(): string {
            return process.env[`REACT_APP_${environment()}_CREDIT_TRANSACT_URL`] as string;
        },
        get optimizelyKey(): string {
            return process.env[`REACT_APP_${environment()}_OPTIMIZELY_SDK_KEY`] as string;
        },
    },
};

export default config;
