import { useEffect, useMemo } from 'react';
import { isAuthenticatedUser } from '../utils/cookies';
import { strings } from '../utils/Strings';
import { useDispatch, useSelector } from 'react-redux';
import CMELayout from './Layout/CMELayout';
import { SavedScreenContentContainer } from './SavedScreen';
import UserActionRecommendation from './UserActionRecommendation';
import { Tabs } from '../models/Tabs';
import { useIsContextualLinkSource } from '../hooks/useIsContextualLinkSource';
import { sendDiscoverViewAmplitudeEvent } from '../amplitude/sendDiscoverAmplitudeEvent';
import { sendUserRecommendationActionAmplitudeEvent } from '../amplitude/UserActionRecommendationEvent';
import HorizontalScrollCollection from './HorizontalScrollCollection';
import { DiscoverScreenActivitiesSection } from '../models/DiscoverScreenActivitiesResponse';
import { GetDiscoverScreenActivities } from '../queries/DiscoverScreenQueries';
import { LoadingSpinner } from './LoadingSpinner';
import { SeriesPage } from './Series/SeriesPage';
import { RootState } from '../store/store';
import { ResponseAmplitudeState } from '../models/AmplitudeState';
import { updateActiveFilters } from '../Slices/FilterSlice';
import { updateActiveTab } from '../Slices/DisplaySlice';
import { SearchResults } from './Search/SearchResults';
import Modal from './Modal/Modal';
import { ShowErrorModal } from './Error/ShowErrorModal';
import { useReturnToPreviousScrollPosition } from '../hooks/useReturnToPreviousScrollPosition';
import { useShowSearchResultsFromSearchParam } from '../hooks/useShowSearchResultsFromSearchParam';
import { FullScreenLoadingContainer } from './FullScreenLoadingContainer';
import { Box } from '@mui/material';

export default function DiscoverScreen() {
    const isAuthenticated: boolean = isAuthenticatedUser();

    const dispatch = useDispatch();
    useShowSearchResultsFromSearchParam();
    const isContextualLink = useIsContextualLinkSource();
    useReturnToPreviousScrollPosition();

    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );

    const isSearchActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSearchActive
    );

    const seriesId: string | undefined = useSelector(
        (state: RootState) => state.displayState?.seriesId
    );

    useEffect(() => {
        if (!isAuthenticated) {
            sendUserRecommendationActionAmplitudeEvent(Tabs.DISCOVER);
            sendDiscoverViewAmplitudeEvent(isContextualLink);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        dispatch(updateActiveTab(Tabs.DISCOVER));
        dispatch(updateActiveFilters({ clearAll: true }));
    }, [currentSourceTab]);

    const discoverScreenResponse: any = GetDiscoverScreenActivities();

    useEffect(() => {
        if (discoverScreenResponse?.data?.data && !isSeriesActive)
            sendDiscoverViewAmplitudeEvent(isContextualLink, discoverScreenResponse?.data?.data);
    }, [discoverScreenResponse?.data?.data]);

    useEffect(() => {
        if (isAuthenticated && isSeriesActive === true) {
            // refetch discover cards in the background when a Series Page is opened to ensure Save status is updated
            discoverScreenResponse.refetch();
            return;
        }
    }, [isSeriesActive, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && !isSearchActive && currentSourceTab === Tabs.DISCOVER) {
            discoverScreenResponse.refetch();
        }
    }, [isSearchActive, isAuthenticated]);

    const responseAmplitudeData: ResponseAmplitudeState = {
        source: 'DISCOVER',
        requestInstanceID: discoverScreenResponse?.data?.data?.processInstanceId,
    };

    const DiscoverCollections = useMemo(() => {
        return (
            <>
                {discoverScreenResponse?.data?.data?.series && (
                    <HorizontalScrollCollection
                        isSeriesCard={true}
                        collectionIndex={0}
                        collection={discoverScreenResponse?.data?.data?.series}
                        responseAmplitudeData={responseAmplitudeData}
                    />
                )}
                {discoverScreenResponse?.data?.data &&
                    discoverScreenResponse.data?.data?.sections?.map(
                        (collection: DiscoverScreenActivitiesSection, collectionIndex: number) => (
                            <HorizontalScrollCollection
                                collectionIndex={collectionIndex + 1}
                                collection={collection}
                                key={collection.activityGroupId}
                                responseAmplitudeData={responseAmplitudeData}
                            />
                        )
                    )}
            </>
        );
    }, [discoverScreenResponse?.data?.data]);

    if (!isAuthenticated) {
        return (
            <CMELayout value={0} filterNav={false}>
                <SavedScreenContentContainer container sx={{ maxWidth: '752px' }}>
                    <Box sx={{ height: '100vh' }}>
                        <UserActionRecommendation
                            headingText={strings.discover_unauth}
                            isModal={false}
                        />
                    </Box>
                </SavedScreenContentContainer>
            </CMELayout>
        );
    }

    if (discoverScreenResponse.error) {
        ShowErrorModal(discoverScreenResponse.error, dispatch, strings.error_loading);
    }

    return (
        <>
            <CMELayout value={0} responseAmplitudeData={responseAmplitudeData} filterNav={false}>
                <Modal />
                <SavedScreenContentContainer container sx={{ maxWidth: '752px' }}>
                    {isSeriesActive === true ? (
                        <SeriesPage seriesPageId={seriesId} />
                    ) : (
                        <>
                            {isSearchActive === true ? (
                                <SearchResults responseAmplitudeData={responseAmplitudeData} />
                            ) : (
                                <>
                                    {discoverScreenResponse.isLoading && (
                                        <FullScreenLoadingContainer>
                                            <LoadingSpinner />
                                        </FullScreenLoadingContainer>
                                    )}

                                    {DiscoverCollections}
                                </>
                            )}
                        </>
                    )}
                </SavedScreenContentContainer>
            </CMELayout>
        </>
    );
}
