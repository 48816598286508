import { Box } from '@mui/material';
import colors from '../../../config/colorConfig';

interface QuizResponseChipProps {
    text?: string | null;
}

export function QuizResponseChip({ text }: QuizResponseChipProps) {
    if (!text) return <></>;

    return (
        <Box
            sx={{
                fontSize: '12px',
                fontWeight: 500,
                color: colors['--pure-white'],
                backgroundColor: colors['--ui-grey'],
                marginLeft: 'auto',
                marginTop: '6px',
                flexShrink: 0,
                padding: '0px 4px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '14px',
                fontFamily: 'Metric',
            }}
        >
            {text}
        </Box>
    );
}
