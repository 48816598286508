import { styled } from '@mui/material';
import 'react';
import colors from '../../../config/colorConfig';
import { ResultCard } from '../../../models/QuizTransactionService';
import { StyledInputSubmit } from './quizStyledComponents';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useNavigate } from 'react-router';
import { HandleExitNavigation } from '../programOutline/ExitProgramHandler';
import { NavigationIntent } from '../../../models/EPOLMSModels';

interface QuizResultCardProps {
    resultCard: ResultCard;
    performNavigationIntent: (intent: NavigationIntent) => void;
}

const ResultCardContainer = styled('div')({
    backgroundColor: colors['--pure-white'],
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    marginBottom: '24px',
    maxWidth: '744px',
    gap: '16px',
    padding: '24px 24px 32px 24px',
    alignContent: 'left',
    alignItems: 'left',
});

export const StyledSubtextTitle = styled('p')({
    fontFamily: 'Metric',
    fontWeight: 300,
    fontSize: '28px',
    lineHeight: '30px',
    margin: 0,
    color: colors['--primary-midnight'],
    textAlign: 'left',
});

export const StyledSubtextParagraph = styled('p')({
    fontFamily: 'Metric',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    margin: 0,
    fontStyle: 'normal',
    color: colors['--primary-midnight'],
    textAlign: 'left',
});

export function QuizResultCard({ resultCard, performNavigationIntent }: QuizResultCardProps) {
    const navigate = useNavigate();

    const persistedSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    return (
        <ResultCardContainer>
            <StyledSubtextTitle> {resultCard.caption?.plain}</StyledSubtextTitle>
            <StyledSubtextParagraph>{resultCard.body?.plain}</StyledSubtextParagraph>
            {resultCard.conclusionBody?.plain && (
                <StyledSubtextParagraph>{resultCard.conclusionBody?.plain}</StyledSubtextParagraph>
            )}
            <StyledInputSubmit
                style={{
                    width: '200px',
                    padding: '12px 64px',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    display: 'flex',
                }}
                type="submit"
                value={resultCard.button.text.plain}
                onClick={() => {
                    if ('exit-activity' == resultCard.button.intent) {
                        HandleExitNavigation({ persistedSourceTab, navigate });
                    } else {
                        performNavigationIntent(resultCard.button.intent);
                    }
                }}
            />
        </ResultCardContainer>
    );
}
