export const CME_ACTIVITY_IMPRESSION_EVENT_ID = 'taxo951.0';
export const CME_ACTIVITY_CLICK_EVENT_ID = 'taxo949.0';
export const CME_ACTIVITY_IMPRESSION = 'CME - Activity - Impression';
export const CME_ACTIVITY_CLICK = 'CME - Activity - Click';
export const CME_ACTIVITY_EXPAND_CLICK = 'CME - Activity - Expand - Click';

export const CME_SERIES_PAGE_VIEW = 'CME - Series Page - View';
export const CME_SERIES_ACTIVITIES_IN_THIS_SERIES_HORIZONTAL_SCROLL =
    'CME - Series - Activities in this Series - Horizontal Scroll';
export const CME_SERIES_PAGE_BEGIN_PRE_WORK_ASSESSMENT_CLICK =
    'CME - Series Page - Begin Pre-Work Assessment - Click';
export const CME_SERIES_PAGE_RESOURCE_LINK_CLICK = 'CME - Series Page - Resource Link - Click';
export const CME_SERIES_PAGE_VIEW_MORE_CLICK = 'CME - Series Page - View More - Click';
export const CME_SERIES_IMPRESSION = 'CME - Series - Impression';
export const CME_SERIES_EXPAND_CLICK = 'CME - Series - Expand - Click';
export const CME_SERIES_CLICK = 'CME - Series - Click';
export const CME_SERIES_CLOSE = 'CME - Series - Close ';

export const CME_SAVED_VIEW = 'CME - Saved - View';

export const CME_SAVED_SORT_CLICK = 'CME - Saved - Sort - Click';
export const CME_SAVED_SORT_SELECT = 'CME - Saved - Sort - Select';
export const CME_NO_SAVED_ACTIVITIES_VIEW = 'CME - No Saved Activities - View';

export const CME_COMPLETED_ACTIVITY_REDIRECT_VIEW = 'CME - Completed Activity - Redirect - View';

export const CME_SSO_REDIRECT = 'CME - SSO - Redirect';

export const CME_CATALOG_VIEW = 'CME - Catalog - View';
export const CME_SAVED_FILTER_SELECT = 'CME - Saved - Filter - Select';
export const CME_SAVED_FILTER_CLICK = 'CME - Saved - Filter - Click';
export const CME_FILTER_VIEW_ACTIVITIES = 'CME - Filter - View Activities - Click';
export const CME_FILTER_SEE_MORE_CLICK = 'CME - Filter - See More - Click';
export const CME_FILTER_CLEAR_ALL_CLICK = 'CME - Filter - Clear All - Click';
export const CME_FILTER_CLOSE = 'CME - Filter - Close';
export const CME_NAVIGATION_CLICK = 'CME - Navigation - Click';
export const CME_CATALOG_SORT_CLICK = 'CME - Catalog - Sort - Click';
export const CME_CATALOG_SORT_SELECT = 'CME - Catalog - Sort - Select';

export const CME_LOGIN_CLICK = 'CME - Login - Click';
export const CME_CREATE_ACCOUNT_CLICK = 'CME - Create Account - Click';

export const CME_ACTIVITY_SAVE_CLICK = 'CME - Activity - Save - Click';
export const CME_SAVED_REMOVE_CLICK = 'CME - Saved - Remove Activity - Click';

export const CME_FRONTMATTER_CONTINUE_CLICK = 'CME - Frontmatter - Continue - Click';
export const CME_FRONTMATTER_VIEW = 'CME - Frontmatter - View';

export const CME_USER_ACTION_RECOMMENDATION_VIEW = 'CME - User Recommendation Action - View';
export const CME_MODAL_CLOSE = 'CME - Modal - Close';

export const CME_QUICKFILTER_VIEWACTIVITIES_CLICK = 'CME - Quick Filter - View Activities - Click';
export const CME_QUICKFILTER_CLEAR = 'CME - Quick Filter - Clear';

export const CME_CATALOG_FILTER_CLICK = 'CME - Catalog - Filter - Click';

export const CME_QUICKFILTER_CLOSE = 'CME - QuickFilter - Close';

export const CME_SAVE_ERROR = 'CME - Save Error';
export const CME_ERROR = 'CME - Error';

export const CME_DISCOVER_VIEW = 'CME - Discover - View';

export const CME_COLLECTION_HORIZONTAL_SCROLL = 'CME - Collection - Horizontal Scroll';

export const CME_COLLECTION_VIEW = 'CME - Collection - View';

export const CME_SEARCH_BAR_CLICK = 'CME - Search Bar - Click';
export const CME_SEARCH_CLEAR_CLICK = 'CME - Search - Clear - Click';
export const CME_SEARCH_MANUAL_SEARCH_CLICK = 'CME - Search - Manual Search - Click';

export const CME_SEARCH_RESULTS_CLICK = 'CME - Search - Results - View';

export const CME_EPOLMS_SLIDE_VIEW = 'CME - EPOLMS - Slide - View';
export const CME_EPOLMS_SLIDE_NAVIGATE = 'CME - EPOLMS - Slide - Navigate';

export const CME_WEB_APP_OPTIMIZELY_CLIENT_ERROR = 'cme-web-app: Optimizely Client Error';
