import colors from '../config/colorConfig';
import { ElementHighlightMode } from '../models/QuizTransactionService';

export type ContainerHighlight = {
    boxShadow: string;
    border: string;
};

export function setQuestionContainerHighlight(
    highlightMode: ElementHighlightMode | null | undefined
): ContainerHighlight {
    switch (highlightMode) {
        case null:
            return { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)', border: 'none' };
        case undefined:
            return { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)', border: 'none' };
        case ElementHighlightMode.None:
            return { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)', border: 'none' };
        case ElementHighlightMode.Chosen:
            return { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)', border: 'none' };
        case ElementHighlightMode.Correct:
            return { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)', border: 'none' };
        case ElementHighlightMode.Invalid:
            return { boxShadow: 'none', border: `1px solid ${colors['--color-alert-critical']}` };
        case ElementHighlightMode.Incorrect:
            return { boxShadow: 'none', border: `1px solid ${colors['--color-alert-critical']}` };
        case ElementHighlightMode.Missed:
            return { boxShadow: 'none', border: `1px solid ${colors['--color-alert-critical']}` };
        default:
            return { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)', border: 'none' };
    }
}
