import { Grid, styled, Typography } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../config/colorConfig';
import { SearchCMECards } from '../../queries/SearchBarQuery';
import { updateIsSearchActive } from '../../Slices/DisplaySlice';
import { RootState } from '../../store/store';
import { ContentContainer } from '../CatalogScreen';
import { VerticalCardsContainer } from '../VerticalCardsContainer';
import { CollectionAmplitudeState, ResponseAmplitudeState } from '../../models/AmplitudeState';
import { LoadingSpinner } from '../LoadingSpinner';
import { useEffect } from 'react';
import { sendSearchResultsDisplay } from '../../amplitude/sendSearchAmplitudeEvents';
import { useSearchParams } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop';
import { getSearchResultsHeader } from '../../utils/getSearchResultsHeader';
import { FullScreenLoadingContainer } from '../FullScreenLoadingContainer';

interface SearchResultsProps {
    responseAmplitudeData?: ResponseAmplitudeState;
    collectionAmplitudeData?: CollectionAmplitudeState;
}

export const SearchResults = ({
    responseAmplitudeData,
    collectionAmplitudeData,
}: SearchResultsProps) => {
    const dispatch: Dispatch = useDispatch();
    scrollToTop();
    const searchText: string | undefined = useSelector(
        (state: RootState) => state.displayState.searchText
    );
    let searchRequest = {
        queryString: searchText ?? '',
    };
    const {
        data: searchResultsData,
        refetch,
        isLoading,
        isFetching,
    } = SearchCMECards(searchRequest);

    const searchResults = { data: searchResultsData };
    if (!collectionAmplitudeData && searchResultsData) {
        collectionAmplitudeData = {
            collectionID: '',
            collectionIndex: '',
            collectionName: 'Discover',
            collectionType: searchResultsData?.data?.results?.processMetadata?.collectionType,
            collectionProcessID: searchResultsData?.data?.results?.processMetadata?.instanceId,
            collectionProcessVersionID: searchResultsData?.data?.results?.processMetadata.version,
        };
    }
    const GoBackText = styled(Typography)(() => ({
        width: 52,
        height: 24,
        color: colors['--accent-teal'],
        font: 'Metric',
        fontWeight: 500,
        textAlign: 'center',
    }));

    const GoBackButtonContainer = styled('button')(() => ({
        '&:focus': {
            outline: 'none',
        },
        cursor: 'pointer',
        border: 0,
        padding: 0,
        backgroundColor: 'transparent',
        height: '24px',
        width: '80px',
    }));

    const SearchTextDisplay = styled(Typography)(() => ({
        font: 'Metric',
        fontWeight: 500,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        whiteSpace: 'nowrap',
        maxWidth: '655px',
    }));

    const WrapperContainerGrid = styled(Grid)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            width: '720px',
        },
        [theme.breakpoints.down('md')]: {
            width: '343px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '751px',
        },
    }));

    const WrapperItemGrid = styled(Grid)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            width: '624px',
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 48px - 96px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '343px - 96px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '751px - 96px',
        },
    }));

    const WrapperCardsGrid = styled(Grid)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            width: 'calc(100vw - 32px)',
        },
    }));

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );
    const [searchParams, setSearchParams] = useSearchParams();

    const GoBackButtonHandler = () => {
        searchParams.delete('saveAttempt');
        searchParams.delete('searchText');
        setSearchParams(searchParams);

        dispatch(updateIsSearchActive(false));
    };

    useEffect(() => {
        if (!isSeriesActive) {
            refetch();
        }
    }, [isSeriesActive]);

    const numberOfSearchResults: string =
        searchResultsData?.data?.results?.returnedCardCount.toString() ?? '';

    useEffect(() => {
        sendSearchResultsDisplay(
            searchText,
            searchResultsData?.data?.results?.returnedCardCount,
            collectionAmplitudeData
        );
    }, [searchText]);

    const goBackButton = () => {
        return (
            <GoBackButtonContainer onClick={GoBackButtonHandler}>
                <Grid
                    container
                    style={{ width: '100%', height: '100%' }}
                    direction="row"
                    rowSpacing="4px"
                >
                    <Grid item>
                        <img
                            style={{ height: '24px', width: '24px' }}
                            src="keyboard-arrow-left.svg"
                            alt="Left Arrow"
                        />
                    </Grid>
                    <Grid item>
                        <GoBackText>Go Back</GoBackText>
                    </Grid>
                </Grid>
            </GoBackButtonContainer>
        );
    };

    const searchTextDisplay = () => {
        return (
            <SearchTextDisplay>
                {searchText &&
                    getSearchResultsHeader(
                        searchResultsData?.data?.results?.returnedCardCount || 0,
                        numberOfSearchResults,
                        searchText
                    )}
            </SearchTextDisplay>
        );
    };

    return (
        <>
            {isLoading || isFetching ? (
                <FullScreenLoadingContainer>
                    <LoadingSpinner />
                </FullScreenLoadingContainer>
            ) : (
                <>
                    <WrapperContainerGrid container direction="column">
                        <Grid item style={{ marginTop: '24px' }}>
                            <WrapperContainerGrid
                                container
                                direction="row"
                                style={{ gap: '16px', height: '24px' }}
                                flexWrap="nowrap"
                            >
                                <Grid item>{goBackButton()}</Grid>
                                <WrapperItemGrid item>{searchTextDisplay()}</WrapperItemGrid>
                            </WrapperContainerGrid>
                        </Grid>
                        <Grid item>
                            <WrapperCardsGrid container justifyContent="center">
                                <ContentContainer item data-testid="dataContainer">
                                    <VerticalCardsContainer
                                        cardsResponse={searchResults}
                                        responseAmplitudeData={responseAmplitudeData}
                                        collectionAmplitudeData={collectionAmplitudeData}
                                    />
                                </ContentContainer>
                            </WrapperCardsGrid>
                        </Grid>
                    </WrapperContainerGrid>
                </>
            )}
        </>
    );
};
