import { styled } from '@mui/material';
import colors from '../../config/colorConfig';
import { HighlightedContainedButton } from '../StyledButtons';

interface FrontMatterButtonProps {
    buttonText: string;
    disclaimerText: string;
    handleClick: () => void;
}

export function FrontMatterContinueButton({
    handleClick,
    buttonText,
    disclaimerText,
}: FrontMatterButtonProps): JSX.Element {
    const StyledSection = styled('div')(({ theme }) => ({
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            padding: '16px 24px',
        },
        justifyContent: 'center',
        alignItems: 'center',
        gap: 24,
        backgroundColor: colors['--ui-grey-ultra-light'],
        padding: '16px 16px',
        margin: '0 32px 0 32px',
        borderRadius: '4px',
        flexDirection: 'row',
    }));

    const StyledButtonDisclaimerText = styled('p')(() => ({
        fontFamily: 'Metric',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'center',
        maxWidth: '295px',
        margin: '0px',
        color: colors['--ui-grey'],
    }));

    return (
        <StyledSection>
            <StyledButtonDisclaimerText>{disclaimerText}</StyledButtonDisclaimerText>
            <HighlightedContainedButton type="button" onClick={handleClick}>
                {buttonText}
            </HighlightedContainedButton>
        </StyledSection>
    );
}
