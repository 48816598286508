import { getAmplitudeFilterData } from '../components/Filters/AllFilters';
import { QuickFilterEventType } from '../models/QuickFilterEventType';
import { FilterState } from '../Slices/FilterSlice';
import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CME_QUICKFILTER_CLEAR, CME_QUICKFILTER_VIEWACTIVITIES_CLICK } from './analytics';

export const sendQuickFilterAmplitudeEvent = async (
    quickFilterEventType: number,
    currentSourceTab: number,
    filterState: FilterState
) => {
    if (quickFilterEventType === QuickFilterEventType.viewResults) {
        return sendAmplitudeEvent(
            CME_QUICKFILTER_VIEWACTIVITIES_CLICK,
            await getAmplitudeFilterData(filterState, currentSourceTab)
        );
    } else if (quickFilterEventType === QuickFilterEventType.clear) {
        return sendAmplitudeEvent(
            CME_QUICKFILTER_CLEAR,
            await getAmplitudeFilterData(filterState, currentSourceTab)
        );
    }
};
