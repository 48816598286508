import { axiosClient } from './axiosClient';
import config from '../config/envConfig';
import { CatalogCardsResponse } from '../models/CatalogCardsResponse';
import { ICatalogCardsRequest } from '../models/ICatalogCardsRequest';

const { cmeProcessApiUrl } = config.environment;

export const getCatalogCards = async (
    request: ICatalogCardsRequest
): Promise<CatalogCardsResponse> => {
    const url = `${cmeProcessApiUrl}/Product__Cme/GetCatalogCards`;
    try {
        const headers = {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'Epoc-App-Version': 'latest',
            DeviceType: 'Web',
            'Epoc-Platform': 'Web',
        };

        const response = await axiosClient.post(url, request, {
            headers,
            authMode: 'auto',
        } as any);
        return response as unknown as CatalogCardsResponse;
    } catch (error) {
        throw error;
    }
};
