import config from '../config/envConfig';
import { axiosClient } from './axiosClient';

const { cmeApiUrl } = config.environment;

export const retrieveUserService = async (): Promise<any> => {
    const url = cmeApiUrl + '/v1/cmeprofile';
    return axiosClient
        .get(url, {
            validateStatus: (status) => status >= 200 && status < 500,
        })
        .then((response: any) => response)
        .catch((error: any) => {
            console.log(error);
            return error.response || { status: 500, data: {} };
        });
};

export const pushUserService = async (request: any): Promise<any> => {
    const path = '/v1/cmeprofile';
    return axiosClient
        .put(path, request.userProfileRequest.profile, {
            headers: {
                accept: 'application/json',
            },
        })
        .then((response: any) => response)
        .catch((error: any) => {
            console.log(error);
            return error.response || { status: 500, data: {} };
        });
};
