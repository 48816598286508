export function transformNewLineStringToLineBreakHTML(description: string): JSX.Element {
    return (
        <>
            {description.split('\n').map((text, index, paragraphCount) => {
                if (index + 1 === paragraphCount.length) {
                    // do not add line breaks after the last paragraph
                    return <span key={index}>{text}</span>;
                } else {
                    // add two line breaks after any paragraph that is not the last paragraph
                    return (
                        <span key={index}>
                            {text}
                            <p role="separator" />
                        </span>
                    );
                }
            })}
        </>
    );
}
