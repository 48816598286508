import { DiscoverScreenActivitiesResponse } from '../models/DiscoverScreenActivitiesResponse';
import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CME_COLLECTION_VIEW, CME_DISCOVER_VIEW } from './analytics';

export const sendDiscoverViewAmplitudeEvent = (
    isContextualLink: boolean,
    discoverScreenData?: DiscoverScreenActivitiesResponse
) => {
    let collectionCount: number;
    let collectionIDs: string;
    let collectionNames: string;
    let amplitudeData: any = {};

    if (discoverScreenData) {
        collectionCount = discoverScreenData?.sections?.length;
        collectionIDs = discoverScreenData?.sections
            ?.map((section) => {
                return section?.activityGroupId;
            })
            .toString();
        collectionNames = discoverScreenData?.sections
            ?.map((section) => {
                return section?.title;
            })
            .toString();
        if (discoverScreenData?.series) {
            collectionCount++;
            collectionIDs = collectionIDs.concat(
                ',',
                discoverScreenData?.series?.activityGroupId.toString()
            );
            collectionNames = collectionNames.concat(',', discoverScreenData?.series?.title);
        }
        amplitudeData = {
            'Collection Count': collectionCount,
            'Collection IDs': collectionIDs,
            'Collection Names': collectionNames,
        };
        if (discoverScreenData?.segmentAnnotation?.properties) {
            discoverScreenData?.segmentAnnotation?.properties.forEach((segmentProperty) => {
                amplitudeData[segmentProperty.property] = segmentProperty.value;
            });
        }
    }

    sendAmplitudeEvent(CME_DISCOVER_VIEW, {
        ...amplitudeData,
        ...(isContextualLink ? { Source: 'CONTEXTUAL LINK' } : {}),
    });
};

export function sendDiscoverCollectionViewAmplitudeEvent(
    collectionName: string,
    collectionID: string,
    activityCount: number,
    collectionIndex: number
) {
    sendAmplitudeEvent(CME_COLLECTION_VIEW, {
        'Collection Name': collectionName,
        'Collection ID': collectionID,
        'Activity Count': activityCount,
        'Collection Index': collectionIndex + 1,
    });
}
