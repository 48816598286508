import { styled } from '@mui/material';

export const StyledTableContainer = styled('div')({
    overflowX: 'auto',
    width: '100%',
});

export const StyledTableData = styled('td')({
    border: `1px solid rgb(174, 193, 204)`,
    borderCollapse: 'collapse',
    padding: '10px 12px',
    minWidth: '48px',
    verticalAlign: 'top',
});

export const StyledTable = styled('table')({
    width: 'full',
    border: `1px solid rgb(174, 193, 204)`,
    borderCollapse: 'collapse',
});

export const StyledTableRow = styled('tr')({});

export const StyledTableHeader = styled('th')({
    padding: '10px 12px',
    verticalAlign: 'top',
    border: `1px solid rgb(174, 193, 204)`,
    borderCollapse: 'collapse',
});

export const ParagraphNoMargin = styled('p')({
    margin: 0,
});

export const UnorderedListNoMargin = styled('ul')({
    margin: 0,
});

export const UnorderedListReducedMargin = styled('ul')({
    margin: 0,
    paddingLeft: '24px',
});

export const ImageAndTextCardImage = styled('img')({
    borderRadius: '4px',
    float: 'left',
});
