import { styled, SwipeableDrawer } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import colors from '../../../config/colorConfig';
import {
    IProgramOutline,
    IProgramOutlineEntry,
    IProgramGlobalInfo,
    NavigationIntent,
} from '../../../models/EPOLMSModels';
import { ProgramOutlineTabs } from './ProgramOutlineTabs';

//
// ProgramOutlineDrawer is a swipeable drawer built with Material UI
// which renders ProgramOutlineTabs and appears on xs - md screens.
//

interface ProgramOutlineDrawerProps {
    performNavigationIntent: (
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined,
        buttonIndex?: number | null | undefined,
        buttonText?: string | null | undefined,
        destination?: string | null | undefined
    ) => void;
    globalInfo?: IProgramGlobalInfo | null | undefined;
    outline: IProgramOutline | null | undefined;
    isDrawerOpen: boolean;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    firstName?: string | undefined;
    lastName?: string | undefined;
}

export function ProgramOutlineDrawer({
    performNavigationIntent,
    globalInfo,
    outline,
    isDrawerOpen,
    setIsDrawerOpen,
    firstName,
    lastName,
}: ProgramOutlineDrawerProps) {
    const Puller = styled('div')({
        width: '120px',
        height: '4px',
        backgroundColor: colors['--ui-grey-med'],
        borderRadius: '100px',
        margin: '40px auto 40px auto',
    });

    return (
        <SwipeableDrawer
            open={isDrawerOpen}
            onOpen={() => setIsDrawerOpen(true)}
            anchor={'bottom'}
            onKeyDown={() => setIsDrawerOpen(false)}
            onClose={() => setIsDrawerOpen(false)}
            variant="temporary"
            swipeAreaWidth={120}
            disableSwipeToOpen={true}
            ModalProps={{
                keepMounted: false,
            }}
        >
            <Puller />
            <ProgramOutlineTabs
                performNavigationIntent={performNavigationIntent}
                globalInfo={globalInfo}
                outline={outline}
                isSmallMenu={false}
                firstName={firstName}
                lastName={lastName}
            />
        </SwipeableDrawer>
    );
}
