import axios, { AxiosRequestConfig } from 'axios';
import { getApiAuthorizationHeader } from '../utils/cookies';

export const axiosClient = axios.create({
    timeout: 30000,
    validateStatus(status: any) {
        return status >= 200 && status < 400;
    },
});

//
// Automatically refresh the API token any time we try to hit a *.epocrates.* endpoint.
// Override this by setting the request options .authMode to 'auto' or 'never' as needed.
//

axiosClient.interceptors.request.use(async (request: AxiosRequestConfig) => {
    if (request.url?.includes('epocrates') || request.url?.includes('localhost')) {
        const authHeaderValue = await getApiAuthorizationHeader(
            (request as any).authMode ?? 'always'
        );
        if (authHeaderValue) {
            request.headers = {
                ...request.headers,
                Authorization: authHeaderValue,
            };
        }
    }
    return request;
});

//
// Redirect user to the login page if authentication credentials are invalid with status code 401.
//
axiosClient.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
        const loginRedirectURL: string = `${window.location.origin}/login?refernext=${window.location.href}`;
        window.location.href = loginRedirectURL;
    }

    if (error.response.status === 404) {
        const redirect = `${window.location.origin}/cme/not-found`;
        window.location.href = redirect;
    }

    return Promise.reject(error);
});
