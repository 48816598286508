import { Box, Typography, styled } from '@mui/material';
import TextChip from './TextChip';
import colors from '../config/colorConfig';
import { ResponseAmplitudeState, CollectionAmplitudeState } from '../models/AmplitudeState';
import SavedButton from './SavedButton';
import { setMediaImageSrc } from '../utils/setMediaImageSrc';
import { useSelector } from 'react-redux';
import { Tabs } from '../models/Tabs';
import { RootState } from '../store/store';

//
// This horizontally scrollable card appears on the Discover Screen, on Series Pages
// and on failed Program Post-Test review screens. The card is always
// the same size and is rendered in a swiper.
//

const ThumbnailContainer = styled('div')({
    width: '267px',
    height: '120px',
    position: 'relative',
});

const CardImage = styled('img')({
    width: '267px',
    height: '120px',
    objectFit: 'cover',
    borderRadius: '3px 3px',
});

const ThumbnailOverlay = styled('div')({
    position: 'absolute',
    width: '267px',
    height: '120px',
    bottom: 0,
    left: 0,
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75))',
});

const CardContentContainer = styled('div')({
    margin: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
});

const CreditsDurationContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
});
interface HorizontalViewActivityCardProps {
    activityId: string;
    announcementText?: string | null;
    chipText: string | undefined;
    creditAmount?: number | null;
    creditText?: string | null;
    description: string;
    durationInMinutes?: number;
    durationText?: string;
    mediaFormat: string;
    daysSincePublication?: number;
    expirationInDays: number | null;
    isSaved?: boolean;
    thumbnailUrl: string | null;
    title: string;
    type?: string;
    accessCode?: string;
    shareableUrl?: string;
    subtypeId?: number;
    subtypeName?: string;
    debug?: { [key: string]: any };
    responseAmplitudeData?: ResponseAmplitudeState;
    collectionAmplitudeData?: CollectionAmplitudeState | null;
    cardIndex: number;
    isBeingRemoved?: boolean;
    handleActivityClick?: () => void;
    handleSavedClick: () => void;
    cardRef?: React.RefObject<HTMLDivElement>;
}

export default function HorizontalViewActivityCard(props: HorizontalViewActivityCardProps) {
    const setExpirationString = () => {
        let string = `Expires in ${props.expirationInDays} days`;
        if (props.expirationInDays === 1) {
            string = string.slice(0, -1);
            return string;
        } else if (props.expirationInDays === 0) {
            return `Expires today`;
        }
        return string;
    };

    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const CustomCard = styled('div')({
        width: '267px',
        height: '312px',
        padding: 0,
        borderRadius: '4px',
        backgroundColor: colors['--pure-white'],
        color: colors['--primary-midnight'],
        cursor: 'pointer',
        filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))',
        marginBottom: '4px',
        marginRight: currentSourceTab === Tabs.DISCOVER ? '24px' : '0px',
        border: 'none',
    });

    return (
        <CustomCard ref={props.cardRef} onClick={props.handleActivityClick} id={props.accessCode}>
            <ThumbnailContainer>
                <CardImage
                    src={props.thumbnailUrl || `${require('../images/thumbnail-default.png')}`}
                    alt=""
                />
                <ThumbnailOverlay>
                    <Box sx={{ position: 'absolute', top: '88px', left: '12px', display: 'flex' }}>
                        {props.chipText && <TextChip text={props.chipText} />}
                        <img
                            src={setMediaImageSrc(props.mediaFormat)}
                            alt={`media type: ${props.mediaFormat}`}
                        />
                    </Box>
                    {currentSourceTab === Tabs.DISCOVER && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '82.5px',
                                right: '12px',
                                display: 'flex',
                            }}
                        >
                            <SavedButton
                                isSaved={props?.isSaved || false}
                                onClick={props.handleSavedClick}
                                isBeingRemoved={props.isBeingRemoved}
                            />
                        </Box>
                    )}
                </ThumbnailOverlay>
            </ThumbnailContainer>
            <CardContentContainer>
                <CreditsDurationContainer>
                    <Box>
                        <img src={'regular-cme-credit.svg'} alt="" />
                    </Box>
                    <Box sx={{ marginLeft: '4px', color: colors['--ui-slate'] }}>
                        {props.creditText}
                    </Box>
                    <Box sx={{ marginLeft: '8px' }}>
                        <img src={'clock-circle-black.svg'} alt="" />
                    </Box>
                    <Box
                        sx={{ marginLeft: '4px', color: colors['--ui-slate'] }}
                    >{`${props.durationText}`}</Box>
                </CreditsDurationContainer>
                <Typography
                    variant="h1"
                    sx={{
                        width: '235px',
                        height: '96px',
                        fontSize: '20px',
                        fontWeight: 500,
                        lineHeight: '1.2',
                        textOverflow: 'ellipses',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        marginTop: '11px',
                    }}
                >
                    {props.title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontSize: '14px',
                        lineHeight: 1.43,
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        marginTop: '12px',
                        color: colors['--ui-slate'],
                    }}
                >
                    {setExpirationString()}
                </Typography>
            </CardContentContainer>
        </CustomCard>
    );
}
