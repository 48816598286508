import { AnalyticsBrowser } from '@segment/analytics-next';
import config from '../config/envConfig';
import { CURRENT_USER_ID } from '../utils/localStorageKeys';
import { getAllowAnalytics } from './AllowAnalytics';
import { initializeSegmentClient } from './AmplitudeUtil';

export const loadAnalytics = async () => {
    const userId = localStorage.getItem(CURRENT_USER_ID) ?? '';

    if (await getAllowAnalytics(userId)) {
        const writeKey = config.environment.segmentApiKey;
        const [response] = await AnalyticsBrowser.load({ writeKey });

        if (response) {
            // necessary for AmplitudeUtil support
            initializeSegmentClient(response);
        }
    } else {
        initializeSegmentClient(null);
    }
};
