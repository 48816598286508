import * as toolkit from '@reduxjs/toolkit';

export interface SortState {
    isOpen: boolean;
    availableSortCriteria: string[];
    selectedSortCriteria: string;
}

export const initialState: SortState = {
    isOpen: false,
    availableSortCriteria: [],
    selectedSortCriteria: '',
};

export const sortSlice = toolkit.createSlice({
    name: 'Sort',
    initialState,
    reducers: {
        updateIsSortOpen: (state, action: toolkit.PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        updateSortCriteria: (state, action: toolkit.PayloadAction<string[]>) => {
            if (action.payload) {
                state.availableSortCriteria = action.payload;
            }
        },
        updateSelectedSortCriteria: (state, action: toolkit.PayloadAction<string>) => {
            state.selectedSortCriteria = action.payload;
        },
    },
});

export const { updateIsSortOpen, updateSortCriteria, updateSelectedSortCriteria } =
    sortSlice.actions;

const sortReducer = sortSlice.reducer;
export default sortReducer;
