import React from 'react';
import { ResponseAmplitudeState, CollectionAmplitudeState } from '../models/AmplitudeState';
import { CMECard, isActivityCard, isSeriesPageCard } from '../models/CMECommonResponse';
import { PolymorphicVerticalCard } from './PolymorphicVerticalCard';

interface VerticalCardsProps {
    verticalCardsData: CMECard[];
    responseAmplitudeData?: ResponseAmplitudeState;
    collectionAmplitudeData?: CollectionAmplitudeState;
    matchedCardCount?: number;
}

export const VerticalCardsList = (props: VerticalCardsProps) => {
    const tempKeys: string[] = [];

    function keysForCards() {
        const displayCards: CMECard[] = props.verticalCardsData;

        for (let i = 0; i < displayCards?.length; i++) {
            let cmeCard: CMECard = displayCards[i];
            if (cmeCard?.cardType === 'cme-activity' && isActivityCard(cmeCard?.cardPayload)) {
                tempKeys[i] = cmeCard.cardPayload.activityId;
            } else if (
                cmeCard?.cardType === 'cme-series' &&
                isSeriesPageCard(cmeCard?.cardPayload)
            ) {
                tempKeys[i] = cmeCard.cardPayload.seriesPageId;
            }
        }
    }
    keysForCards();
    return (
        <>
            {props?.verticalCardsData?.map((cmeCard: CMECard, index: number) => (
                <PolymorphicVerticalCard
                    key={tempKeys[index]}
                    card={cmeCard}
                    cardIndex={index}
                    responseAmplitudeData={props?.responseAmplitudeData}
                    collectionAmplitudeData={props?.collectionAmplitudeData}
                    matchedCardCount={props.matchedCardCount}
                />
            ))}
        </>
    );
};
