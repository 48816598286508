import { sendErrorEvent } from '../../amplitude/sendErrorEvent';
import { setError } from '../../Slices/ErrorSlice';
import { updateModalOpen } from '../../Slices/ModalSlice';
import { AppDispatch } from '../../store/store';
import { formatError } from '../../utils/formatError';

export const ShowErrorModal = (error: unknown, dispatch: AppDispatch, message?: string) => {
    const formattedError = formatError(error, message);

    dispatch(setError(formattedError));
    dispatch(updateModalOpen(true));
    sendErrorEvent(formattedError.message, formattedError.statusCode);
};
