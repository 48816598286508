import { combineReducers, configureStore } from '@reduxjs/toolkit';
import displayStateReducer from '../Slices/DisplaySlice';
import filterReducer from '../Slices/FilterSlice';
import snackBarStateReducer from '../Slices/UserAttitudeSlice';
import sortReducer from '../Slices/SortSlice';
import modalReducer from '../Slices/ModalSlice';
import errorReducer from '../Slices/ErrorSlice';
import saveAttemptReducer from '../Slices/SaveAttemptSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import epocNavRedesignReducer from '../Slices/EpocOptimizelyFlagsSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['displayState.activeTab'],
};

const rootReducer = combineReducers({
    filter: filterReducer,
    sort: sortReducer,
    displayState: displayStateReducer,
    snackBarState: snackBarStateReducer,
    modal: modalReducer,
    error: errorReducer,
    saveAttempt: saveAttemptReducer,
    epocNavRedesign: epocNavRedesignReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
