import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Grid, styled } from '@mui/material';

import {
    NavigationIntent,
    IProgramGlobalInfo,
    IFrontMatterSlide,
    IProgramOutline,
    IProgramOutlineEntry,
    ParticipationMode,
} from '../../../models/EPOLMSModels';
import { strings } from '../../../utils/Strings';
import { FrontMatterContinueButton } from '../../FrontMatter/FrontMatterContinueButton';
import { frontMatterRenderOptions } from './renderOptions';
import { ProgramLayout } from '../ProgramLayout';
import { PreviewBanner } from '../PreviewBanner';

export function EPOLMSFrontMatterSlide(props: {
    performNavigationIntent: (
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined,
        buttonIndex?: number | null | undefined,
        buttonText?: string | null | undefined
    ) => void;
    global: IProgramGlobalInfo;
    frontMatterSlide: IFrontMatterSlide;
    outline?: IProgramOutline | null;
    isLoading: boolean;
    mode?: undefined | ParticipationMode;
}) {
    // Load the full-screen front matter layout and fill it in here with props.frontMatter
    // and convert the props.frontMatter.body JSON from Contentful JSON to react native

    const renderedBody = documentToReactComponents(
        props.frontMatterSlide!.body!.contentfulRichText! as any,
        frontMatterRenderOptions
    );

    const ContentfulDataContainer = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '64px',
            paddingRight: '64px',
            width: '1120px',
        },
        [theme.breakpoints.up('md') && theme.breakpoints.down('xl')]: {
            paddingLeft: '32px',
            paddingRight: '32px',
            maxWidth: '768px',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
            maxWidth: '375px',
        },
    }));

    return (
        <>
            <ProgramLayout
                headerInformation={props.global}
                programOutline={props.outline}
                performNavigationIntent={props.performNavigationIntent}
                isLoading={props.isLoading}
            >
                <Grid container direction="column" gap="24px" paddingBottom="80px">
                    <Grid item>
                        <PreviewBanner mode={props.mode} />
                    </Grid>
                    <Grid item>
                        <FrontMatterContinueButton
                            handleClick={() =>
                                props.performNavigationIntent(
                                    props.frontMatterSlide.continueButton?.intent ?? 'next',
                                    props.outline?.items.filter((entry) => entry.current)[0],
                                    props.global,
                                    1,
                                    props.frontMatterSlide.continueButton?.text.plain ||
                                        strings.frontmatter_continue_text
                                )
                            }
                            buttonText={
                                props.frontMatterSlide.continueButton?.text.plain ||
                                strings.frontmatter_continue_text
                            }
                            disclaimerText={strings.frontmatter_continue_disclosuretext_top}
                        />
                    </Grid>
                    <Grid item>
                        <ContentfulDataContainer>{renderedBody}</ContentfulDataContainer>
                    </Grid>
                    <Grid item>
                        <FrontMatterContinueButton
                            handleClick={() =>
                                props.performNavigationIntent(
                                    props.frontMatterSlide?.continueButton?.intent ?? 'next',
                                    props.outline?.items.filter((entry) => entry.current)[0],
                                    props.global,
                                    2,
                                    props.frontMatterSlide.continueButton?.text.plain ||
                                        strings.frontmatter_continue_text
                                )
                            }
                            buttonText={
                                props.frontMatterSlide.continueButton?.text.plain ||
                                strings.frontmatter_continue_text
                            }
                            disclaimerText={strings.frontmatter_continue_disclosuretext_bottom}
                        />
                    </Grid>
                </Grid>
            </ProgramLayout>
        </>
    );
}
