import * as toolkit from '@reduxjs/toolkit';

export interface EpocOptimizelyFlagsState {
    isDeviceDetectionEnabled: boolean;
}

export const initialState: EpocOptimizelyFlagsState = {
    isDeviceDetectionEnabled: false,
};

export const epocOptimizelyFlagsSlice = toolkit.createSlice({
    name: 'EPOCNavRedesign',
    initialState,
    reducers: {
        updateEpocDeviceDetectionEnabled: (state, action: toolkit.PayloadAction<boolean>) => {
            state.isDeviceDetectionEnabled = action.payload;
        },
    },
});

export const { updateEpocDeviceDetectionEnabled } = epocOptimizelyFlagsSlice.actions;

const epocOptimizelyFlagsReducer = epocOptimizelyFlagsSlice.reducer;
export default epocOptimizelyFlagsReducer;
