import { RequestError } from '../models/RequestError';

export const ResponseErrorObject = (responseObj?: any): any => {
    if (responseObj?.status === 401) {
        // TODO: replaced redux action here with two hard coded strings, fix this
        const errorObj: RequestError = new RequestError('Login Failed', 'Login Failed');
        return { status: responseObj?.status, data: errorObj };
    }
    return { status: responseObj?.status, data: {} };
};
