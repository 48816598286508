import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { UserFilterSelection, ICatalogCardsRequest } from '../models/ICatalogCardsRequest';

export function GetCatalogCardsRequest(req?: ICatalogCardsRequest) {
    let catalogCardsReq: ICatalogCardsRequest;

    let activeFilters: UserFilterSelection[] = useSelector(
        (state: RootState) => state.filter.activeFilterSelections
    );
    let currentSortSelection: string = useSelector(
        (state: RootState) => state.sort.selectedSortCriteria
    );

    if (req) {
        catalogCardsReq = {
            userSpecifiedFilters: req.userSpecifiedFilters,
            sortCriteria: req.sortCriteria,
        };
    } else {
        catalogCardsReq = {
            userSpecifiedFilters: activeFilters,
            sortCriteria: currentSortSelection,
        };
    }

    return catalogCardsReq;
}
