import { useQuery, UseQueryResult } from 'react-query';
import {
    IQuizTransactionRequest,
    IQuizTransactionResponse,
} from '../models/QuizTransactionService';
import { EPOLMSQuizTransactAxiosCall } from '../services/epolmsQuizTransact';

export const GetEPOLMSQuizTransactData = (
    quizRequest: IQuizTransactionRequest
): UseQueryResult<IQuizTransactionResponse> => {
    return useQuery<IQuizTransactionResponse>(
        ['QuizTransact', quizRequest.quizId, quizRequest.contextId],
        () => EPOLMSQuizTransactAxiosCall(quizRequest),
        {
            onSuccess: (data) => {
                if (data.session) {
                    window.sessionStorage.setItem('cme-epolms-quiz-session-state', data.session);
                } else {
                    window.sessionStorage.removeItem('cme-epolms-quiz-session-state');
                }
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: Boolean(quizRequest.quizId && quizRequest.contextId),
            keepPreviousData: true,
        }
    );
};
