import { getFrontMatterScreen } from './../services/frontMatterScreenService';
import { UseQueryResult, useQuery } from 'react-query';
import { FrontMatterScreenResponse } from '../models/FrontMatterScreenResponse';
import { IFrontMatterScreenRequest } from '../models/IFrontMatterScreenRequest';

export const GetFrontMatterScreen = (
    activityCode: string
): UseQueryResult<FrontMatterScreenResponse, unknown> =>
    useQuery<FrontMatterScreenResponse>(
        ['frontmatter-screen', { activityCode: activityCode }],
        () => getFrontMatterScreen({ activityCode: activityCode } as IFrontMatterScreenRequest),
        {
            refetchInterval: false,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
        }
    );
