import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from '../config/colorConfig';
interface ChipProps {
    text: string;
    fgColor?: string;
    bgColor?: string;
}

const TextChip = ({ text, fgColor, bgColor }: ChipProps) => {
    const StyledChip = styled(Chip)({
        fontFamily: 'Metric',
        height: '20px',
        margin: '0 8px 0 0',
        padding: '0 8px',
        borderRadius: '3px',
        // TODO: this color will change depending on the text
        backgroundColor: bgColor || colors['--primary-orange'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 500,
        color: fgColor || colors['--pure-white'],
        '& .cme-web-MuiChip-label': {
            padding: '0px',
        },
    });

    return <StyledChip label={text} className="text-chip" />;
};

export default TextChip;
