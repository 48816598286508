import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        cme_search_loading: 'Loading...',
        cme_search_no_options: `We couldn't find any results for "{value}".`,
        cme_search_placeholder: 'Search CME',
        completed_activity_heading: 'How to Earn Credit for this Activity:',
        completed_activity_step1: 'View the activity content',
        completed_activity_step2: 'Close this tab to return to the activity',
        completed_activity_step3: 'Select "Continue" on the activity to claim credit',
        credit_claim_slide_caption: 'Select your credit from one of the option(s) listed below:',
        credit_claim_input_label: 'Select the amount of credits you want to claim:',
        credit_claim_accredited_provider: 'Accredited Provider',
        credit_claim_accrediting_body: 'Accrediting Body',
        credit_claim_certification: 'Certification',
        credit_claim_type: 'Type',
        credit_claim_unit: 'Unit',
        frontmatter_continue_disclosuretext_top:
            'By clicking the "Continue" button, I certify to having read the activity information below.',
        frontmatter_continue_disclosuretext_bottom:
            'By clicking the "Continue" button, I certify to having read the activity information above.',
        frontmatter_continue_text: 'Continue',
        frontmatter_submit_text: 'Submit',
        email_blank_message: 'Please Enter Valid Email',
        epocrates_domain: '.epocrates.com',
        error_toast: {
            primaryText: `Sorry, we can't complete that action right now`,
            preTextSecondary: 'Please try again. Visit',
            postTextSecondary: 'if issue persists.',
        },
        error_action: `We can't complete that action right now`,
        error_content: `We can't launch the content right now`,
        error_fallback_title: 'Whoops! An Unknown Error Occurred',
        error_fallback_message: {
            part1: 'We would love to be able to help you with this. Please visit our ',
            part2: ' section to let us know how you got here.',
        },
        error_occurred_title: 'An Error Occurred',
        error_loading: `We're experiencing problems loading this content`,
        error_network: `We're experiencing network issues`,
        error_message_text: {
            part1: `Please refresh the page and try again. Visit `,
            part2: ` if the issue persists.`,
        },
        error_page_not_found_title: '404 - Page Not Found',
        error_page_not_found_message: {
            part1: `The page you're looking for cannot be found. Visit `,
            part2: ` if you need further assistance.`,
        },
        error_modal_title: 'Sorry',
        for_you_login: 'Log in or create an account to get personalized CME recommendations.',
        frontMatter_credit_text: 'Earn up to:',
        frontMatter_launch_text: 'Released:',
        frontMatter_expiration_text: 'Expires:',
        frontMatter_legal_text: `By clicking the link(s) above, you will be directed to a third-party application or
                website (“Third-party Site”) that is not operated or controlled by epocrates.
                epocrates is not responsible for, and makes no representations regarding, the
                Third-party Site, any content contained therein, or your access to or use thereof.`,
        help_and_feedback: `Help & Feedback`,
        hide_details: 'Hide Details',
        login_email: 'Email',
        login_modal_body:
            'Log in or create an account to save activities, get personalized recommendations, and complete CME on the go.',
        login_modal_title: 'Login or Create an Account',
        login_password: 'Password',
        login_signin_title: 'Sign in to your account',
        login_stay_signed_in: 'Stay signed in',
        login_wall_login: 'Log In',
        login_wall_create_acct: 'Create Account',
        no_saved_activities: 'You have no saved CME content.',
        no_saved_activities_recommendation:
            'Visit Discover or Catalog to browse and save for later.',
        placeholder_input_text: 'Input Text',
        placeholder_read_only_text: 'This is the read-only state',
        playback_speed_title: 'Playback Speed',
        preview_title: 'Preview Mode',
        preview_text: 'This screen is for preview only.',
        program_outline_title: 'Activity Progress',
        program_outline_exit: 'Exit Activity',
        program_outline_user_indicator: 'Logged in as',
        rationale_title: 'Rationale:',
        saved_unauth: 'Log in or create an account to save activities and complete CME on the go.',
        search_no_results: `No Results Found For: {searchText}`,
        search_one_result: `Viewing ({numberOfSearchResults}) Search Result For: {searchText}`,
        search_results: `Viewing ({numberOfSearchResults}) Search Results For: {searchText}`,
        discover_unauth:
            'Log in or create an account to save activities, get personalized recommendations, and complete CME on the go.',
        temp_discover_screen_text1:
            'Access this personalized page now on your mobile device by launching the epocrates app. This feature will be coming soon to the web.',
        temp_discover_screen_text2:
            "Don't have the app? Get it free at the Apple App Store or the Google Play Store.",
        toast_click_button_text: 'Click here ',
        toast_removal_success: `{type} Removed Successfully`,
        toast_removal_undone: '{type} Removal UNDONE',
        toast_save_success: `{type} Saved Successfully`,
        toast_removed_post_text_secondary: `The {type} has been removed. `,
        toast_view_saved_content_post_text_secondary: 'to view your saved Activities & Series',
        toast_undo_post_text_secondary: 'to UNDO the action',
        media_error: 'An error occurred. Please refresh the page or try again later.',
        view_details: 'View Details',
        view_more: 'View More',
        view_less: 'View Less',
        quiz_alert_attention: 'Attention',
    },
});
