import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import envConfig from '../config/envConfig';
import { axiosClient } from '../services/axiosClient';
import { updateUserCookies } from '../utils/cookies';

export interface IAuthorizeableProps {
    element: JSX.Element;
}

export function stripAuthorizeableQueryParams(fromUrl: string = window.location.href): URL {
    const url = new URL(fromUrl);
    url.searchParams.delete('authorizationCode');
    url.searchParams.delete('forceLogout');
    return url;
}

//
// Authorizeable React Component wraps any other JSX element, and adds two behaviors
// critical to SSO:
// 1. Intercepts ?forceLogout=true query parameter, and routes through /login when present
// 2. Intercepts ?authorizationCode=... query parameter, and attempts to redeem that auth
//    code when present, merging the resulting authentication with the current user auth,
//    and forcing a trip through /login if the authorizationCode couldn't be redeemed.
// These support the Mobile apps launching a browser with an authorizationCode to CME Web,
// logging the user in, before continuing to whatever final SSO destination.
//
export function Authorizeable(props: IAuthorizeableProps): JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleOptionalAuthorizationCode = async (): Promise<boolean> => {
        // If ?forceLogout=true was specified, drop credentials and run through the /login first
        const forceLogout = !!searchParams
            .get('forceLogout')
            ?.toString()
            ?.match(/^(1|true)$/gim);
        if (forceLogout) {
            window.location.href = `${
                window.location.origin
            }/login?refernext=${stripAuthorizeableQueryParams()}`;
            return false;
        }

        // If ?authorizationCode=... was specified, try to redeem it via the auth service
        const authorizationCode = searchParams.get('authorizationCode');
        if (authorizationCode) {
            try {
                const redeemUrl =
                    envConfig.environment.authenticationUrl + '/v1/authorizationcode/redeem';
                const response = await axiosClient.post(
                    redeemUrl,
                    {
                        authorizationCode,
                    },
                    {
                        validateStatus: (status: number) => status === 200,
                        authMode: 'never', // This endpoint doesn't want our accessToken, so don't include it
                    } as any
                );
                updateUserCookies(
                    +response.data?.userId,
                    response.data.accessToken,
                    null // we don't have a refresh token from this
                );
            } catch (err: any) {
                // If redeeming didn't work for whatever reason, we can't tell if it's even the same user.
                // Force a login and then come back authenticated.
                console.log(err);
                window.location.href = `${
                    window.location.origin
                }/login?refernext=${stripAuthorizeableQueryParams()}`;
                return false;
            }

            // Navigate to where we are, minus the forceLogout and/or authorizationCode params
            navigate(stripAuthorizeableQueryParams());
            return true;
        }
        return true;
    };

    const { data, error } = useQuery(
        ['parseParametersForAuthCode'],
        handleOptionalAuthorizationCode,
        {
            useErrorBoundary: true,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );

    // While we're waiting to see the outcome, don't show anything
    if (!data || !!error) {
        return <></>;
    }

    // Else show the specified inner element
    return props.element;
}
