import { UseQueryResult } from 'react-query';
import { GetDynamicHomeScreenActivities } from '../../../queries/DynamicHomeScreenActivities';
import {
    DynamicHomeScreenActivitiesResponse,
    IFrontEndCardSectionV2,
} from '../../../models/DynamicHomeScreenActivities';
import HorizontalCollection from '../../HorizontalScrollCollection';
import { styled } from '@mui/material';

interface RecommendedCollectionProps {
    isProgramEndpoint?: boolean;
}

export const RecommendedCollection = ({ isProgramEndpoint }: RecommendedCollectionProps) => {
    const dynamicHomeScreenActivities: UseQueryResult<
        DynamicHomeScreenActivitiesResponse,
        unknown
    > = GetDynamicHomeScreenActivities(10);

    const CollectionWrapper = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('lg')]: {
            marginTop: '72px',
        },
        marginTop: '64px',
        maxWidth: '744px',
    }));

    if (dynamicHomeScreenActivities.isLoading) return <></>;

    const results: IFrontEndCardSectionV2 | undefined =
        dynamicHomeScreenActivities.data?.data.results;

    if (results !== undefined) {
        results.activities = results.cards;
    }
    const requestData = {
        collectionIndex: 0,
        collection: results,
        isSeriesCard: false,
        responseAmplitudeData: {
            source: 'program',
        },
    };

    return (
        <CollectionWrapper>
            <HorizontalCollection {...requestData} isProgramEndpoint={isProgramEndpoint} />
        </CollectionWrapper>
    );
};
