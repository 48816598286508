import { IFrontendQuizQuestion } from '../models/QuizTransactionService';

export function setDefaultFormData(questions: IFrontendQuizQuestion[]) {
    let responses: any = {};
    questions.map((question) => {
        if (question.freeText) {
            responses[`${question.questionId}+${question.debugLabel}`] = {
                writeIn: question.freeText.userEntered || '',
            };
        } else if (question.multipleChoice) {
            let choices: any = [];
            let writeInValue: string | undefined;
            question.multipleChoice.options.map((option) => {
                if (option.userSelected) {
                    choices.push({
                        optionId: option.optionId,
                        optionValue: option.text.plain,
                    });
                    if (option.userEntered) {
                        writeInValue = option.userEntered;
                    }
                }
            });

            responses[`${question.questionId}+${question.debugLabel}`] = {
                choices: choices,
                ...(writeInValue && { writeInValue: writeInValue }),
            };
        } else if (question.likertScale) {
            let nested: any = [];
            question.likertScale.nestedQuestions.map((nestedQuestion) => {
                if (nestedQuestion.userSelection) {
                    nested.push({
                        questionId: nestedQuestion.questionId,
                        questionLabel: 'na',
                        choices: [
                            {
                                optionId: `${nestedQuestion.questionId}-${nestedQuestion.userSelection}`,
                                optionValue: nestedQuestion.userSelection,
                            },
                        ],
                    });
                }
            });

            responses[`${question.questionId}+${question.debugLabel}`] = {
                nested: nested,
            };
        }
    });
    return responses;
}
