import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { ResponseAmplitudeState, CollectionAmplitudeState } from '../../models/AmplitudeState';
import { Section } from '../../models/CMECommonResponse';
import { HorizontalSwiper } from '.././HorizontalSwiper';
import { PolymorphicCard } from '.././PolymorphicCard';

const CollectionContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
        padding: '0 16px 0 16px',
        marginTop: '24px',
    },
    [theme.breakpoints.up('sm')]: {
        padding: '0 24px 0 24px',
        marginTop: '32px',
    },
    [theme.breakpoints.up('md')]: {
        padding: 0,
        marginTop: '32px',
    },
}));

interface SeriesPageCollectionProps {
    collectionIndex: number;
    section: Section;
    responseAmplitudeData: ResponseAmplitudeState;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Disabled(props: any) {
    return <></>;
}

export const SeriesCollection = ({
    collectionIndex,
    section,
    responseAmplitudeData,
}: SeriesPageCollectionProps) => {
    const [collectionState, setCollectionState] = useState<CollectionAmplitudeState>({
        collectionID: null,
        collectionIndex: null,
        collectionName: null,
        collectionType: null,
        collectionProcessID: null,
        collectionProcessVersionID: null,
    });

    useEffect(() => {
        if (responseAmplitudeData) {
            setCollectionState({
                collectionID: section.processMetadata.collectionId,
                collectionIndex: collectionIndex + 1,
                collectionName: section.title.plain,
                collectionType: section.processMetadata.collectionType,
                collectionProcessID: section.processMetadata.instanceId,
                collectionProcessVersionID: section.processMetadata.version,
            });
        }
    }, [section, responseAmplitudeData]);

    if (section.orientation === 'horizontal') {
        return (
            <HorizontalSwiper
                collectionIndex={collectionIndex}
                cards={section.cards}
                collectionTitle={section.title.plain}
                collectionID={section.processMetadata.collectionId}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionState}
                key={`horizontal-section-${collectionIndex}`}
            />
        );
    }

    if (section.orientation === 'vertical') {
        return (
            <CollectionContainer key={`vertical-section-${collectionIndex}`}>
                <h5>{section.title && section.cards.length > 0 && section.title.plain}</h5>
                {section.cards.map((c, i) => {
                    return (
                        <PolymorphicCard
                            key={`polymorphic-card-${collectionIndex}-${i}`}
                            card={c}
                            sliderProperties={{
                                isVisible: true,
                                isActive: false,
                                isNext: false,
                                isPrev: false,
                            }}
                            responseAmplitudeData={responseAmplitudeData}
                            collectionAmplitudeData={collectionState}
                            cardIndex={i + 1}
                        />
                    );
                })}
            </CollectionContainer>
        );
    }

    // Do not render if we didn't recognize the section layout orientation
    return <></>;
};
