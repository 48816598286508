import { Analytics } from '@segment/analytics-next';
import { ResponseAmplitudeState, CollectionAmplitudeState } from '../models/AmplitudeState';
import { getCookie, setCookie } from '../utils/cookies';
import { SeriesPageData } from '../models/SeriesPageResponse';
import { Section } from '../models/CMECommonResponse';
import {
    CME_CATALOG_SORT_CLICK,
    CME_CATALOG_SORT_SELECT,
    CME_SAVED_SORT_CLICK,
    CME_SAVED_SORT_SELECT,
} from './analytics';
import { Tabs } from '../models/Tabs';
import { getSeriesPageSource } from '../utils/getSeriesPageSource';
import { coerceResponseData } from '../utils/formatData';

let outstandingEvents = 0;

function decrementOutstandingEvents() {
    if (outstandingEvents > 0) {
        outstandingEvents -= 1;
    }
}

export async function awaitAllOutstandingEvents(maxTimeoutMs = 5000): Promise<void> {
    const started = new Date().getTime();
    let now = started;
    while (now - started < maxTimeoutMs && outstandingEvents > 0) {
        await new Promise((resolve) => {
            setTimeout(resolve, 20);
        });
        now = new Date().getTime();
    }
}

let currentAmplitudeUser: string | null = null;
let analytics: Analytics | null;

export function initializeSegmentClient(init: Analytics | null): void {
    analytics = init;
}

function getSessionId(): string {
    let sessionId = getCookie('amplitudeSessionId');
    if (!sessionId) {
        sessionId = Date.now().toString();
        setCookie('amplitudeSessionId', 0.5, sessionId);
    }
    return sessionId;
}

export function initializeAmplitudeForUser(user: string): void {
    if (currentAmplitudeUser !== user) {
        analytics?.identify(
            user,
            {},
            {
                integrations: { Amplitude: { session_id: getSessionId() } },
            }
        );
    }
    currentAmplitudeUser = user;
}

export const sendAmplitudeEvent = async (
    eventName: string,
    data?: any,
    user?: string | null
): Promise<void> => {
    if (!analytics) {
        setTimeout(sendAmplitudeEvent, 500, eventName, data, user);
    } else {
        const currentUser = localStorage.getItem('currentUserId');
        if (currentUser) {
            initializeAmplitudeForUser(currentUser);
        }
        outstandingEvents += 1;
        analytics.track(
            eventName,
            data ?? {},
            { integrations: { Amplitude: { session_id: getSessionId() } } },
            decrementOutstandingEvents
        );
    }
};

export const getSimplifiedSeriesViewData = (
    data: SeriesPageData,
    currentSourceTab: number,
    isContextualLink: boolean
) => {
    return {
        Title: data.title.plain,
        'Activities in Series': data.sections.find(
            (section: Section) => section.cardType === 'cme-activity'
        )?.cards.length,
        Duration: data.durationInMinutes,
        'Credit Amount': data.creditAmount,
        Badge: data.chipText,
        'Days since publication': data.daysSincePublication,
        Source: getSeriesPageSource(currentSourceTab, isContextualLink),
        'Series ID': data.seriesPageId,
        'Process Version ID': data.processMetadata.version,
        'Request Instance ID': data.processMetadata.instanceId,
    };
};

export const getSimplifiedCardData = (props: any) => {
    return {
        Title: coerceResponseData(props.title),
        ...(props.isSeriesCard ? { 'Activities in Series': props.seriesPartCount } : {}),
        'Credit Amount': props.creditAmount,
        Duration: props.durationInMinutes,
        Badge: props.chipText || '',
        ...(!props.isSeriesCard && { 'Time to Expiration': props.expirationInDays }),
        'Days since publication': props.daysSincePublication,
        ...(!props.isSeriesCard && {
            'Collection Name': props.collectionAmplitudeData.collectionName,
        }),
        Source: props.responseAmplitudeData.source,
        'Collection ID': props.collectionAmplitudeData.collectionID,
        'Collection Index':
            props?.collectionAmplitudeData?.collectionIndex >= 0
                ? props.collectionAmplitudeData.collectionIndex + 1
                : '',
        ...(props.isSeriesCard
            ? { 'Series Index': props.cardIndex }
            : { 'Activity Index': props.cardIndex }),
        ...(props.isSeriesCard
            ? { 'Series ID': props.activityId }
            : { 'Activity ID': props.activityId }),
        ...(!props.isSeriesCard && { 'Activity Code': props.accessCode }),
        ...(!props.isSeriesCard && { 'Media Type': props.mediaFormat || '' }),
        ...(!props.isSeriesCard && {
            'Collection Type': props.collectionAmplitudeData.collectionType,
        }),
        ...(!props.isSeriesCard && { 'Activity Type': props.subtypeName }),
        'Collection Process Instance ID': props.collectionAmplitudeData.collectionProcessID,
        'Collection Process Version ID': props.collectionAmplitudeData.collectionProcessVersionID,
        'Request Instance ID': props.responseAmplitudeData.requestInstanceID,
    };
};

export const getSimplifiedPreWorkData = (
    responseAmplitudeData: ResponseAmplitudeState,
    collectionAmplitudeData: CollectionAmplitudeState,
    currentSourceTab: number
) => {
    return {
        Title: responseAmplitudeData.title,
        'Activities in Series': responseAmplitudeData.activitiesInThisSeries,
        Source: currentSourceTab >= 0 ? Tabs[currentSourceTab] : 'SERIES',
        'Collection ID': collectionAmplitudeData.collectionID,
        'Collection Index': collectionAmplitudeData.collectionIndex,
        'Collection Process Instance ID': collectionAmplitudeData.collectionProcessID,
        'Collection Process Version ID': collectionAmplitudeData.collectionProcessVersionID,
        'Series ID': responseAmplitudeData.seriesPageId,
        'Process Version ID': responseAmplitudeData.processMetadata?.version,
        'Request Instance ID': responseAmplitudeData.processMetadata?.instanceId,
    };
};

export const amplitudeCallBack = (
    eventName: string,
    eventId: string,
    eventProperties: any
): void => {
    const properties = {
        ...eventProperties,
        'Event ID': eventId,
    };
    sendAmplitudeEvent(eventName, properties, localStorage.getItem('currentUserId') ?? '');
};

export const getSimplifiedHorizontalScrollData = (
    collectionTitle: string,
    cards: any[],
    slidesProgress: number
) => {
    return {
        'Collection Name': collectionTitle,
        'Activity Penetration': slidesProgress,
        'Activity Count': cards.length,
    };
};

export interface SortAmplitudeEventTitlesResponse {
    clickEventTitle: string;
    selectEventTitle: string;
}

export const getSortAmplitudeEventTitles = (
    source: string
): SortAmplitudeEventTitlesResponse | '' => {
    switch (source) {
        case 'SAVED':
            return {
                clickEventTitle: CME_SAVED_SORT_CLICK,
                selectEventTitle: CME_SAVED_SORT_SELECT,
            };
        case 'CATALOG':
            return {
                clickEventTitle: CME_CATALOG_SORT_CLICK,
                selectEventTitle: CME_CATALOG_SORT_SELECT,
            };
        default:
            return '';
    }
};
