import { Tabs } from '../models/Tabs';
import { isAuthenticatedUser } from './cookies';

export function featureSavedEnabled() {
    return false;
}

export function featureSearchEnabled(currentSourceTab: number): boolean {
    if (
        !isAuthenticatedUser() &&
        (currentSourceTab === Tabs.DISCOVER || currentSourceTab === Tabs.SAVED)
    ) {
        return false;
    }

    return true;
}
