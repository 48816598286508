/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import colors from '../../config/colorConfig';
import { CreditIntent, ICreditTransactionRequest } from '../../models/CreditTransactionService';
import {
    ICreditClaimSlide,
    IProgramGlobalInfo,
    IProgramOutline,
    IProgramOutlineEntry,
    NavigationIntent,
    ParticipationMode,
} from '../../models/EPOLMSModels';
import { GetEPOLMSCreditTransactData } from '../../queries/EPOLMSCreditTransactQuery';
import { SmallScreen } from '../../utils/responsive';
import { ProgramLayout } from './ProgramLayout';
import { AlertBanner } from './quiz/AlertBanner';
import { CreditForm, CreditFormData } from './credit/CreditForm';
import { Key } from '@mui/icons-material';
import { strings } from '../../utils/Strings';
import { PreviewBanner } from './PreviewBanner';

export function EPOLMSCreditClaimSlide(props: {
    performNavigationIntent: (
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined
    ) => void;
    global: IProgramGlobalInfo;
    creditClaimSlide: ICreditClaimSlide;
    outline?: IProgramOutline | null;
    isLoading: boolean;
    mode?: undefined | ParticipationMode;
}) {
    const CreditTitle = styled('h1')({
        fontFamily: 'Metric',
        fontWeight: 500,
        fontSize: '24px',
        margin: 0,
        padding: '32px 0 16px 24px',
    });

    const CreditCaption = styled('p')({
        fontFamily: 'Metric',
        fontWeight: 400,
        fontSize: '18px',
        margin: 0,
        padding: '0 0 16px 24px',
    });

    const allowMultipleSelections = false; // TODO: Find this in backend response?

    const CreditContainer = styled('div')(({ theme }) => ({
        paddingBottom: '80px',
        backgroundColor: colors['--surface-ultra-light'],
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '32px',
            width: '1120px',
        },
        [theme.breakpoints.up('md') && theme.breakpoints.down('xl')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
            maxWidth: '768px',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
            maxWidth: '375px',
        },
    }));

    const [creditEndpointCounter, setCreditEndpointCounter] = useState<number>(0);

    let quizTransactRequest: ICreditTransactionRequest = {
        intent: CreditIntent.Resume,
        mode: props.mode,
        creditSelectionId: props.creditClaimSlide.creditSelectionId,
        contextId: props.creditClaimSlide.creditContextId,
        session: window.sessionStorage.getItem('cme-epolms-credit-session-state'),
    };
    const {
        data: creditData,
        refetch,
        isLoading,
        isFetching,
        isSuccess,
    } = GetEPOLMSCreditTransactData(quizTransactRequest);

    const isSmallScreen = SmallScreen();

    const outlineCreditTitle = props.outline?.items.filter((entry) => entry.current)[0].text.plain;

    function navigate(intent: NavigationIntent) {
        props.performNavigationIntent(
            intent,
            props.outline?.items.filter((entry) => entry.current)[0],
            props.global
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        refetch();
    }, []);

    useEffect(() => {
        let creditTemporaryCounter: number = creditEndpointCounter;
        if (isSuccess && !isFetching && !isLoading) {
            creditTemporaryCounter = creditEndpointCounter + 1;
            setCreditEndpointCounter(creditTemporaryCounter);
        }
        if (creditTemporaryCounter >= 2 && creditData?.canAdvanceSpontaneously) {
            navigate(props.creditClaimSlide.continueButton?.intent ?? 'next');
        }
    }, [creditData?.canAdvanceSpontaneously]);

    const handleSubmit = (data: CreditFormData, e: SubmitEvent) => {
        e.preventDefault();
        console.log('Handle Submit!', data);
        if (creditData?.isConcluded) {
            if (creditData?.isAdvanceable) {
                navigate(props.creditClaimSlide.continueButton?.intent ?? 'next');
            } else {
                // NOP - quiz being concluded but not advanceable means it failed; workflow dead end.
            }
        } else {
            quizTransactRequest.intent = CreditIntent.Submit;
            quizTransactRequest.submission = {
                responses: Object.keys(data).map((key) => ({
                    fieldId: key,
                    value: data[key],
                })),
            };
            refetch();
            window.scrollTo(0, 0);
        }
    };

    // Load the full-screen credit-claim layout here; pass it the quizSlide.quizId in props.
    return (
        <>
            <ProgramLayout
                headerInformation={props.global}
                programOutline={props.outline}
                performNavigationIntent={props.performNavigationIntent}
                isLoading={isLoading || isFetching || props.isLoading}
            >
                {creditData?.creditOptions &&
                    !(creditEndpointCounter >= 2 && creditData?.canAdvanceSpontaneously) && (
                        <CreditContainer>
                            {!isSmallScreen && <CreditTitle>{outlineCreditTitle}</CreditTitle>}
                            <CreditCaption>{strings.credit_claim_slide_caption}</CreditCaption>
                            <Box sx={{ maxWidth: '744px' }}>
                                <PreviewBanner mode={props.mode} />
                            </Box>
                            {creditData?.statusChip && (
                                <Box sx={{ maxWidth: '744px' }}>
                                    <AlertBanner
                                        bannerText={creditData?.statusChip?.plain}
                                        bannerIcon={creditData?.statusChip?.icon}
                                        bannerTitle={creditData?.statusChip?.caption}
                                        backgroundColor={creditData?.statusChip?.bgColor}
                                    />
                                </Box>
                            )}
                            <CreditForm
                                creditOptions={creditData.creditOptions}
                                isConcluded={creditData.isConcluded}
                                handleSubmit={handleSubmit}
                                canSelectMultiple={allowMultipleSelections}
                            />
                        </CreditContainer>
                    )}
            </ProgramLayout>
        </>
    );
}
