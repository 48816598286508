import { memo } from 'react';
import colors from '../../config/colorConfig';
import { styled } from '@mui/material';

//
// SearchClearButton is a custom button styled with MUI styled
// It is wrapped in react memo to prevent extra re-renders from
// its parent SearchBar component
//

interface SearchClearButtonProps {
    handleClick: () => void;
}

const StyledCloseButton = styled('button')({
    padding: 0,
    border: 0,
    backgroundColor: colors['--pure-white'],
    outline: 'none',
    cursor: 'pointer',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

function SearchClearButton({ handleClick }: SearchClearButtonProps) {
    return (
        <StyledCloseButton onClick={handleClick}>
            <img
                src="Regular_close.svg"
                alt="Clear Search Button"
                style={{ width: '16px', height: '16px' }}
            />
        </StyledCloseButton>
    );
}

export default memo(SearchClearButton);
