const colors = {
    '--pure-black': '#000000',
    '--primary': '#0f4c90',
    '--primary-midnight': '#06162d',
    '--primary-teal': '#0093c6',
    '--primary-teal-night': '#0178A4',
    '--primary-orange': '#dd5546',
    '--primary-off-white': '#eff7fe',
    '--accent-teal': '#0093C6',
    '--accent-jellyfish': '#7ee1e8',
    '--accent-salmon': '#f6816b',
    '--accent-violet': '#cda0ed',
    '--success': '#00dc8c',
    '--error': '#ff2044',
    '--text-invert': '#ffffff',
    '--background-jellyfish-bg': '#d7fdff',
    '--background-salmon': '#ffd2c9',
    '--background-violet': '#f1e7fa',
    '--background-success': '#E7F3ED',
    '--background-successv2': '#E7F3ED',
    '--background-error': '#FAE7E7',
    '--ui-midnight': '#05152f',
    '--ui-slate': '#1c2f49',
    '--ui-grey': '#506c8e',
    '--ui-grey-semi': '#828a97',
    '--ui-grey-med': '#ccd4dc',
    '--ui-grey-light': '#eff2f8',
    '--ui-grey-ultra-light': '#f6f7fa',
    '--ui-link-pressed': '#005b7a',
    '--scrim-scrim-25': 'rgba(0, 0, 0, 0.25)',
    '--states-backgrounds-hover': '#fbfcfc',
    '--scrim-scrim-05': 'rgba(0, 0, 0, 0.05)',
    '--scrim-scrim-50': 'rgba(0, 0, 0, 0.5)',
    '--title': '#041437',
    '--failure': '#ff0000',
    '--surface-ultra-light': '#fafcfc',
    '--pure-white': '#ffffff',
    '--color-alert-critical': '#CA0D0D',
    '--color-alert-success': '#118647',
};

export function colorLookup(
    color: string | undefined | null,
    defaultValue?: string | undefined
): string {
    if (!color) {
        return colorLookup(defaultValue) ?? colorLookup('--color-alert-critical');
    }
    color = color.trim();
    if ((colors as any)[color]) {
        // if it resolves in the colors table, use that
        return (colors as any)[color];
    } else if (color.match(/^\-\-/gim)) {
        // it is a -- prefixed color, but we couldn't find it
        console.log(`unable to resolve color '${color}'`);
        return 'neon red';
    } else {
        // probably an html named color or #hex; keep it as-is
        return color;
    }
}

export default colors;
