import { strings } from '../../utils/Strings';
import { ErrorPage } from './ErrorPage';
import { HelpAndFeedbackLink } from './HelpAndFeedBackLink';

export const NoMatch = () => {
    return (
        <ErrorPage
            title={strings.error_page_not_found_title}
            message={
                <>
                    {strings.error_page_not_found_message.part1}
                    <HelpAndFeedbackLink />
                    {strings.error_page_not_found_message.part2}
                </>
            }
            statusCode={404}
        />
    );
};
