import colors from '../config/colorConfig';

export function setQuizOptionBackground(
    isChecked: boolean,
    hasHistogramInfo: boolean,
    isReadOnly: boolean,
    isMarkedCorrect: boolean,
    isMarkedIncorrect: boolean,
    isUserSelected: boolean
): string {
    if (isChecked && !hasHistogramInfo) {
        if (isMarkedCorrect && isUserSelected) {
            return colors['--background-success'];
        } else if (isMarkedIncorrect && isUserSelected) {
            return colors['--background-error'];
        } else if (isReadOnly) {
            return colors['--ui-grey-light'];
        } else if (!isReadOnly) {
            return colors['--primary-off-white'];
        }
    }
    // remove highlighting for options with histogram data
    return 'none';
}
