import { CME_ERROR } from './analytics';
import { sendAmplitudeEvent } from './AmplitudeUtil';

export const sendErrorEvent = (errorMessage: string | unknown, statusCode?: number | null) => {
    const url = window.location.href;

    return sendAmplitudeEvent(CME_ERROR, {
        'HTTP Status Code': statusCode ? statusCode : '',
        'Error Message': errorMessage,
        URL: url,
    });
};
