import { AxiosRequestConfig } from 'axios';
import { axiosClient } from './axiosClient';
import config from '../config/envConfig';
import {
    ICreditTransactionRequest,
    ICreditTransactionResponse,
} from '../models/CreditTransactionService';

export async function EPOLMSCreditTransactAxiosCall(
    creditRequest: ICreditTransactionRequest
): Promise<ICreditTransactionResponse> {
    const { creditTransactUrl } = config.environment;
    const result = await axiosClient.post(creditTransactUrl, creditRequest, {
        authMode: 'auto',
    } as AxiosRequestConfig);
    return result.data as ICreditTransactionResponse;
}
