import { UseQueryResult, useQuery } from 'react-query';
import { getLaunchActionForActivity } from '../services/getLaunchActionForActivityService';
import { LaunchAction } from '../models/CMECommonResponse';

export const GetLaunchActionForActivity = (activityId: string): UseQueryResult<LaunchAction> =>
    useQuery(
        ['GetLaunchActionForActivity', activityId],
        () => {
            return getLaunchActionForActivity(activityId);
        },
        {
            cacheTime: 300.0,
            enabled: activityId.length > 0,
            refetchOnWindowFocus: false,
            useErrorBoundary: (error: any) => error.response?.status === 500,
            retry: 1,
        }
    );
