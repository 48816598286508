import { AxiosResponse } from 'axios';
import config from '../config/envConfig';
import { NextUserLaunchActionResponse } from '../models/NextUserLaunchActionResponse';
import { axiosClient } from './axiosClient';

const { cmeProcessApiUrl } = config.environment;
export async function fetchNextUserLaunchAction(
    previousActivityCode: string
): Promise<AxiosResponse<NextUserLaunchActionResponse, any>> {
    const url = `${cmeProcessApiUrl}/Product__Cme/GetNextUserLaunchAction`;
    try {
        const headers = {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'Epoc-App-Version': 'latest',
            DeviceType: 'Web',
            'Epoc-Platform': 'Web',
        };

        const response = await axiosClient.post<
            any,
            AxiosResponse<NextUserLaunchActionResponse, any>
        >(url, { previousActivityCode }, {
            headers,
            authMode: 'always',
        } as any);
        return response;
    } catch (error) {
        throw error;
    }
}
