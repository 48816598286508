import { Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import colors from '../../config/colorConfig';
import { AvailableUserFilter } from '../../models/CatalogCardsResponse';
import { UserFilterSelection } from '../../models/ICatalogCardsRequest';
import { FilterState, updateActiveFilters } from '../../Slices/FilterSlice';
import { RootState } from '../../store/store';
import { theme } from '../../styles/theme';
import { sendAmplitudeEvent } from '../../amplitude/AmplitudeUtil';
import { CME_FILTER_CLEAR_ALL_CLICK } from '../../amplitude/analytics';
import { Desktop } from '../../utils/responsive';
import { ViewResults } from '../custom/ViewResultsAsset';
import { FilterDisplay } from './FilterDisplay';
import { filterEmptySpaceClickHandler, FilterScreenOverlayProps } from './FilterScreenOverlay';
import { FilterType } from '../../models/FilterType';
import { Tabs } from '../../models/Tabs';

export const getAmplitudeFilterData = async (filterState: FilterState, tab?: number) => {
    if (typeof filterState !== 'undefined') {
        let amplitudeFilterData: any = {};
        if (filterState.activeFilterInformation.filterType === FilterType.QuickFilter) {
            const index = filterState.activeFilterSelections.findIndex(
                (activeFilter) =>
                    activeFilter.filterName === filterState.activeFilterInformation.filterName
            );
            if (index === -1) {
                amplitudeFilterData['Filter Selected'] =
                    filterState.activeFilterInformation.filterName;
                amplitudeFilterData['Activities Available'] = filterState?.totalResults;
                return amplitudeFilterData;
            }
            amplitudeFilterData['Filter Selected'] = filterState.activeFilterInformation.filterName;
            amplitudeFilterData['Filtered Values'] =
                filterState.activeFilterSelections[index].selectedValues;
            amplitudeFilterData['Activities Available'] = filterState?.totalResults;
            if (tab) {
                amplitudeFilterData.Source = Tabs[tab];
            }
            return amplitudeFilterData;
        }

        amplitudeFilterData['Activities Available'] = filterState?.totalResults;
        const amplitudeFilterSelectionData = filterState?.activeFilterSelections?.map(
            (activeFilter: UserFilterSelection) => {
                if (activeFilter.filterName === 'Credits & Duration') {
                    amplitudeFilterData['Credits and Duration'] = activeFilter?.selectedValues;
                    return;
                }
                amplitudeFilterData[activeFilter.filterName] =
                    activeFilter?.selectedValues.toString();
            }
        );
        if (tab) {
            amplitudeFilterData.Source = Tabs[tab];
        }
        if (amplitudeFilterSelectionData.length > 0) {
            return Promise.all(amplitudeFilterSelectionData).then(() => {
                return amplitudeFilterData;
            });
        } else {
            return amplitudeFilterData;
        }
    }
};

export const AllFilters = (props: FilterScreenOverlayProps) => {
    const dispatch = useDispatch();
    const filterState: FilterState = useSelector((state: RootState) => state.filter);
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const WrapperSection = styled('section')(() => ({
        padding: '24px 40px 24px 40px',
        [theme.breakpoints.down('md')]: {
            padding: '24px 24px 24px 24px',
        },
    }));
    const WrapperDiv = styled('div')(() => ({
        top: 0,
        [theme.breakpoints.down('md')]: {
            top: '135px',
        },
    }));
    const FilterSection = styled('section')(() => ({
        paddingLeft: '40px',
        paddingRight: '40px',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    }));
    const FilterLayoutDiv = styled('div')(() => ({
        width: '400px',
        [theme.breakpoints.down('md')]: {
            width: '375px',
        },
    }));
    const ContainerGrid = styled(Grid)(() => ({
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'left',
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    }));
    const ClearAllButton = styled('button')(() => ({
        '&:focus': {
            outline: 'none',
        },
    }));
    let activeFilters: UserFilterSelection[] = useSelector(
        (state: RootState) => state.filter.activeFilterSelections
    );

    const [enableClearAll, setEnableClearAll] = useState<boolean>(false);
    const [, setActiveFilterState] = useState<UserFilterSelection[]>([]);

    useEffect(() => {
        if (activeFilters.length > 0) {
            setEnableClearAll(true);
        }
        if (activeFilters.length === 0) {
            setEnableClearAll(false);
        }
        return setActiveFilterState(activeFilters);
    }, [activeFilters]);

    const emptySpaceClickHandler = () => {
        filterEmptySpaceClickHandler(dispatch, filterState, currentSourceTab);
    };

    // Reset all the buttons to the initial state
    const handleClearAll = async () => {
        sendAmplitudeEvent(
            CME_FILTER_CLEAR_ALL_CLICK,
            await getAmplitudeFilterData(filterState, currentSourceTab)
        );
        dispatch(updateActiveFilters({ clearAll: true }));
        setEnableClearAll(false);
    };
    // Render the results returned from the backend
    const handleCloseButton = () => {
        filterEmptySpaceClickHandler(dispatch, filterState, currentSourceTab);
    };

    return (
        <WrapperDiv
            style={{
                position: 'absolute',
                display: 'block',
                height: '100vh',
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 3,
            }}
        >
            <ContainerGrid onClick={emptySpaceClickHandler} container direction="row">
                <Grid item onClick={(e) => e.stopPropagation()}>
                    <FilterLayoutDiv
                        style={{
                            height: '100%',
                            backgroundColor: colors['--pure-white'],
                            boxShadow:
                                '0 5px 10px -3px rgba(0, 0, 0, 0.1), 0 3px 16px 2px rgba(0, 0, 0, 0.06), 0 8px 12px 1px rgba(0, 0, 0, 0.07)',
                        }}
                    >
                        <section>
                            <div>
                                <WrapperSection
                                    style={{
                                        width: '100%',

                                        marginBottom: '32px',
                                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
                                    }}
                                >
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                sx={{
                                                    marginRight: '156px',
                                                    fontFamily: 'Metric',
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    fontStretch: 'normal',
                                                    letterSpacing: 'normal',
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                {' '}
                                                All Filters
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ClearAllButton
                                                onClick={handleClearAll}
                                                disabled={!enableClearAll}
                                                style={{
                                                    marginRight: '16px',
                                                    width: '49px',
                                                    padding: 0,
                                                    border: 0,
                                                    backgroundColor: colors['--pure-white'],
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Metric',
                                                        fontSize: '16px',
                                                        fontWeight: 'normal',
                                                        fontStretch: 'normal',
                                                        letterSpacing: 'normal',
                                                        lineHeight: 1.25,
                                                        color: colors['--primary-teal'],
                                                        opacity: enableClearAll ? 1.0 : 0.3,
                                                        cursor: enableClearAll
                                                            ? 'pointer'
                                                            : 'default',
                                                    }}
                                                >
                                                    <u>Clear all</u>
                                                </Typography>
                                            </ClearAllButton>
                                        </Grid>
                                        <Grid item>
                                            <button
                                                onClick={handleCloseButton}
                                                style={{
                                                    padding: 0,
                                                    border: 0,
                                                    backgroundColor: colors['--pure-white'],
                                                    outline: 'none',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <img
                                                    src="Regular_close.svg"
                                                    alt="Close button"
                                                    style={{ width: '24px', height: '24px' }}
                                                />
                                            </button>
                                        </Grid>
                                    </Grid>
                                </WrapperSection>
                                <FilterSection>
                                    {props.filterScreenData?.availableUserFilters &&
                                        props.filterScreenData?.availableUserFilters.map(
                                            (userFilter: AvailableUserFilter, index: number) => {
                                                if (userFilter.selections.length > 0) {
                                                    return (
                                                        <div key={index}>
                                                            {' '}
                                                            <FilterDisplay
                                                                userFilter={userFilter}
                                                            />{' '}
                                                        </div>
                                                    );
                                                }
                                            }
                                        )}
                                    <div style={{ height: '64px' }} />
                                </FilterSection>
                            </div>
                        </section>
                        <section style={{ position: 'fixed', bottom: '0' }}>
                            <ViewResults
                                totalResults={props?.filterScreenData?.results?.returnedCardCount}
                            />
                        </section>
                    </FilterLayoutDiv>
                </Grid>
                <Grid item>
                    {Desktop() ? <div style={{ width: '800px', height: '100vh' }} /> : <></>}
                </Grid>
            </ContainerGrid>
        </WrapperDiv>
    );
};
