import { memo } from 'react';
import { Box, styled } from '@mui/material';
import colors from '../../config/colorConfig';

const LoginPasswordBackground = (props: any) => {
    const StyledBox = styled(Box)({
        paddingTop: '50px',
        background: colors['--primary-midnight'],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        minWidth: '100h',
    });

    return <StyledBox>{props.pageBody}</StyledBox>;
};

export const DevLoginBackground = memo(LoginPasswordBackground);
