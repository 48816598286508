export const setMediaImageSrc = (mediaFormat: string) => {
    switch (mediaFormat) {
        case 'Video':
            return 'video.svg';
        case 'Text':
            return 'text.svg';
        case 'Audio':
            return 'audio.svg';
        default:
            return '';
    }
};
