import { CME_USER_ACTION_RECOMMENDATION_VIEW } from './analytics';
import { Tabs } from '../models/Tabs';
import { sendAmplitudeEvent } from './AmplitudeUtil';

export const getUserAction = (source: string, isSeriesCard?: boolean) => {
    const discover = Tabs[0];
    const catalog = Tabs[1];
    const saved = Tabs[2];

    switch (source) {
        case discover:
            return 'View Discover Screen';
        case catalog:
            if (isSeriesCard) {
                return 'Save Series on Catalog Screen';
            } else {
                return 'Save Activity on Catalog Screen';
            }
        case saved:
            return 'View Saved Screen';
    }
};

export const sendUserRecommendationActionAmplitudeEvent = (
    currentSourceTab: number,
    isSeriesCard?: boolean
) => {
    const currentSource = Tabs[currentSourceTab];
    const userAction = getUserAction(currentSource, isSeriesCard);

    return sendAmplitudeEvent(CME_USER_ACTION_RECOMMENDATION_VIEW, {
        Source: currentSource,
        'User Action': userAction,
    });
};
