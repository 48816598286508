import { CME_SAVE_ERROR } from './analytics';
import { Tabs } from '../models/Tabs';
import { sendAmplitudeEvent } from './AmplitudeUtil';

export const sendSaveErrorEvent = (
    currentSourceTab: number,
    isSeriesCard: boolean,
    id: string,
    isSavedState: boolean
) => {
    const currentSource = Tabs[currentSourceTab];
    return sendAmplitudeEvent(CME_SAVE_ERROR, {
        Source: currentSource,
        'Card Type': isSeriesCard ? 'Series' : 'Activity',
        ID: id,
        'User Action': isSavedState ? 'Remove' : 'Save',
    });
};
