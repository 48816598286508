import React from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import envConfig from '../config/envConfig';
import { axiosClient } from '../services/axiosClient';
import { sendAmplitudeEvent } from '../amplitude/AmplitudeUtil';
import { CME_SSO_REDIRECT } from '../amplitude/analytics';

export const GenericProviderActivitySSO = () => {
    const { genericProviderName, activityId } = useParams();
    const [searchParams] = useSearchParams();
    let redirectUrl;

    const targetUrl = (ssoTargetOrUrl: string) => {
        if (ssoTargetOrUrl.indexOf('?') > 0) {
            return ssoTargetOrUrl.substring(0, ssoTargetOrUrl.indexOf('?'));
        } else {
            return ssoTargetOrUrl;
        }
    };

    const skipProfileValidation = !!searchParams
        .get('skipProfileValidation')
        ?.toString()
        ?.match(/^(1|true)$/gim);

    const returnRedirectUrl = searchParams.get('returnRedirectUrl')?.toString()?.trim();

    const getSSORedirect = async (): Promise<boolean> => {
        const url = envConfig.environment.cmeApiUrl + '/v1/cmelaunch/redirect/sso';
        const ssoTargetOrUrl =
            `${genericProviderName}/${activityId}` +
            (!!returnRedirectUrl ? `?returnRedirectUrl=${returnRedirectUrl}` : '');

        try {
            const response = await axiosClient.post(
                url,
                {
                    skipProfileValidation,
                    claims: {},
                    ssoTargetOrUrl,
                },
                {
                    validateStatus: (status: number) => status === 200,
                }
            );
            if (response.data.profileRequiresReview) {
                redirectUrl = new URL(
                    window.location.origin + `/cmeprofile?refernext=${window.location.href}`
                ).toString();
                sendAmplitudeEvent(CME_SSO_REDIRECT, {
                    'Target Url': targetUrl(ssoTargetOrUrl),
                    'Redirect Url': redirectUrl,
                    'Profile Review': true,
                });
                window.location.href = redirectUrl;
                return false;
            }
            redirectUrl = new URL(response.data?.redirectUrl).toString();

            sendAmplitudeEvent(CME_SSO_REDIRECT, {
                'Target Url': targetUrl(ssoTargetOrUrl),
                'Redirect Url': redirectUrl,
                'Profile Review': false,
            });
            window.location.href = redirectUrl;
        } catch (error: any) {
            throw error;
        }

        return true;
    };

    useQuery('perform-sso', getSSORedirect, {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        useErrorBoundary: true,
    });

    return <></>;
};
