import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from '../config/colorConfig';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Tabs } from '../models/Tabs';

interface SavedButtonProps {
    isSaved: boolean;
    onClick: () => void;
    isBeingRemoved?: boolean;
}

const Icon = (props: { text: string }) => {
    if (props.text.substring(0, 6) === 'Remove') {
        return <img style={{ height: '15.8px', width: '15.8px' }} src="Remove.svg" alt="Remove" />;
    } else if (props.text === 'Saved') {
        return (
            <img style={{ height: '15.8px', width: '15.8px' }} src="checkmark.svg" alt="Saved" />
        );
    } else if (props.text === 'Save') {
        return <img style={{ height: '15.8px', width: '15.8px' }} src="add.svg" alt="Save" />;
    }
    return null;
};

const SavedButton = ({ isSaved, onClick, isBeingRemoved }: SavedButtonProps) => {
    // const undoFlag: boolean | undefined = useSelector(
    //     (state: RootState) => state.snackBarState.undoFlag
    // );
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    const SavedButtonContainer = styled('button')({
        padding: '0px 16px 4px 4px',
        border: `2px solid ${colors['--pure-white']}`,
        backgroundColor: !!isSaved ? colors['--pure-white'] : 'transparent',
        borderRadius: '28px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    });

    let text: string = '';
    if (isBeingRemoved) {
        text = 'Remove?';
    } else {
        text = isSaved ? 'Saved' : 'Save';
    }
    const [displayText, setDisplayText] = useState<string>(text);
    if (currentSourceTab === Tabs.DISCOVER && displayText !== text) {
        if (!(displayText === 'Remove?' && text === 'Saved')) setDisplayText(text);
    }
    const handleClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (displayText === 'Saved') {
            text = 'Remove?';
            // Resets the text to Saved if the remove button is not clicked in 10 seconds
            setTimeout(() => {
                setDisplayText('Saved');
            }, 10 * 1000);
            setDisplayText(text);
        } else if (displayText === 'Remove?' || text === 'Save') {
            onClick();
        }
    };

    return (
        <>
            <SavedButtonContainer onClick={handleClick}>
                <Grid container gap={'10px'} height="20px" direction="row">
                    <Grid item maxHeight="20px">
                        <Icon text={displayText} />
                    </Grid>
                    <Grid item maxHeight="20px">
                        <Typography
                            sx={{
                                fontFamily: 'Metric',
                                fontWeight: '400',
                                fontSize: '16px',
                                maxHeight: '20px',
                                color: isSaved ? colors['--ui-midnight'] : colors['--pure-white'],
                            }}
                        >
                            {' '}
                            {displayText}{' '}
                        </Typography>
                    </Grid>
                </Grid>
            </SavedButtonContainer>
        </>
    );
};

export default SavedButton;
