import { Subscription, SubscriptionResponse } from '../models/SubscriptionResponse';

// getSubscriptionType accepts a subscriptionResponse and returns a boolean representing premium subscription status
export const getSubscriptionType = (subscriptionResponse: SubscriptionResponse) => {
    let subscriptionStatus: boolean = false;
    if (subscriptionResponse.status === 200) {
        // TODO : User a for loop or a different iteration method to breakout early
        subscriptionResponse.data.forEach((subscription: Subscription) => {
            if (subscription.active) {
                subscription?.activeProducts?.forEach((product) => {
                    if (product?.metadata) {
                        if (product.metadata?.PRODUCT_FAMILY === 'PLUS') {
                            subscriptionStatus = true;
                        }
                    }
                });
            }
        });
    }
    return subscriptionStatus;
};
