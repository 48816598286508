import { ParticipationMode } from './EPOLMSModels';
import { CreditId } from './IdTypes';
import { MultiFormatText } from './Primitives';
import { ElementHighlightMode, StatusChip } from './QuizTransactionService';

export enum CreditIntent {
    Submit = 'submit',
    Resume = 'resume',
}

export interface ICreditQuestionUserResponse {
    fieldId: string;
    value: string | boolean;
}

export interface ICreditAttemptSubmission {
    responses: ICreditQuestionUserResponse[];
}

export interface ICreditTransactionRequest {
    creditSelectionId: CreditId;
    contextId: string;
    intent?: undefined | CreditIntent;
    mode?: undefined | ParticipationMode;
    submission?: undefined | ICreditAttemptSubmission;
    session?: undefined | null | string;
}

export interface ICreditTransactionResponse {
    userId: string;
    programId: string;
    slideId: CreditId;
    isAdvanceable: boolean; // can enclosing context show a 'Continue' button that advances to the next *slide*?
    canAdvanceSpontaneously: boolean; // should enclosing content advance immediately to the next slide at its earliest convenience?
    isEphemeral: boolean; // true whenever the session is ephemeral (not being persisted to db)
    isConcluded: boolean; // true whenever the Credit is done, either passed or failed
    statusChip: StatusChip | null; // whenever there are problems with the response submission or failure to pass
    creditOptions: IFrontendCreditOption[];
    session: string;
}

export interface IFrontendCreditOption {
    creditOptionId: string;
    name: MultiFormatText; // PlatformQ Global: AMA PRA Category 1
    accreditedProvider: MultiFormatText; // Platform Q
    accreditingBody: string; // ACCME
    certification: string; // AMA PRA Category 1 Credit(TM)
    classification: string; // CME, CE, NA, etc
    quantity: IFrontendCreditQuantity | null;
    additionalFields: IFrontendCreditAdditionalField[];
    userSelected: boolean;
    highlightMode: ElementHighlightMode;
    statusChip: StatusChip | null;
}

export interface IFrontendCreditQuantity {
    fieldId: string;
    creditUnit: string; // Credit, Hour, Contact Hour, etc
    values: string[];
    highlightMode: ElementHighlightMode | null;
    userEnteredValue: string;
}

export interface IFrontendCreditAdditionalField {
    fieldId: string;
    fieldName: string;
    userEnteredValue: string;
    highlightMode: ElementHighlightMode | null;
}
