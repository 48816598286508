import { useController, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Grid } from '@mui/material';

import {
    IFrontendQuizQuestion,
    IQuizQuestionUserResponse,
} from '../../../models/QuizTransactionService';
import { LikertScale } from './LikertScale';

interface LikertScaleWrapperProps {
    question: IFrontendQuizQuestion;
    questionName: string;
    readOnly: boolean;
}

interface LikertScaleRequestObject {
    nested: IQuizQuestionUserResponse[];
}

export const LikertScaleWrapper = ({
    question,
    questionName,
    readOnly,
}: LikertScaleWrapperProps) => {
    const { control } = useFormContext();
    const { field } = useController({
        control,
        name: questionName,
    });

    const [likertScaleValues, setLikertScaleValues] = useState<LikertScaleRequestObject>({
        nested: field?.value?.nested ?? [],
    });
    const onChangeLikerScaleHandler = (
        eventValue: string,
        index: number,
        questionId: string,
        questionLabel: string | undefined
    ) => {
        // replace the value at the index with the new value without mutating the original array
        const likertQuestionUserResponse: IQuizQuestionUserResponse = {
            questionId: questionId,
            questionLabel: questionLabel ?? 'na',
            choices: [
                {
                    optionId: `${questionId}-${eventValue}`,
                    optionValue: eventValue,
                },
            ],
        };
        const updatedLikertScaleValues = [...likertScaleValues.nested];
        updatedLikertScaleValues[index] = likertQuestionUserResponse;
        const likertUserResponse = {
            nested: updatedLikertScaleValues,
        };

        field.onChange(likertUserResponse);
        setLikertScaleValues(likertUserResponse);
    };
    return (
        <Grid container direction="column" gap="16px" key={`${question.questionId}-container`}>
            {question &&
                question.likertScale &&
                question.likertScale.nestedQuestions.map((nestedQuestion, index) => {
                    const nestedData: IQuizQuestionUserResponse = field?.value?.nested[index];
                    const choice = (nestedData?.choices && nestedData?.choices[0]) ?? {
                        optionValue: '',
                    };
                    const answerValue: string | null | undefined =
                        choice.optionValue || nestedQuestion.userSelection;
                    return (
                        <LikertScale
                            question={nestedQuestion}
                            scaleLeftLabel={question.likertScale?.scaleLeftLabel.plain}
                            scaleRightLabel={question.likertScale?.scaleRightLabel.plain}
                            scaleValues={question.likertScale?.scaleValues}
                            questionName={questionName}
                            control={control}
                            answerValue={answerValue}
                            questionIndex={index}
                            onChangeLikerScaleHandler={onChangeLikerScaleHandler}
                            readOnly={readOnly}
                            key={nestedQuestion.questionId}
                        />
                    );
                })}
        </Grid>
    );
};
