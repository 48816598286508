import { AuthResponse } from './../models/AuthResponse';
import { AuthRequest } from './../models/AuthRequest';
import axios, { AxiosRequestConfig } from 'axios';
import config from '../config/envConfig';
import { getWebUserAgent } from '@athena/epoc-frontend-library/dist';
import { store } from '../store/store';

export const devLogin = async (request: AuthRequest): Promise<AuthResponse> => {
    const { authenticationUrl } = config.environment;
    const state = store.getState();
    const isDeviceManagementEnabled = state.epocNavRedesign.isDeviceDetectionEnabled;

    const instance = axios.create({
        baseURL: authenticationUrl,
        timeout: 60000,
    });

    const axiosConfig: AxiosRequestConfig = {
        headers: isDeviceManagementEnabled
            ? {
                  'Epoc-Platform': 'Web',
                  'Device-Id': '234fcgcmelocalhost',
                  'Device-Family': getWebUserAgent(),
              }
            : { 'Content-Type': 'application/json' },
    };

    const url = isDeviceManagementEnabled ? `/v2/login` : `/v1/login`;

    try {
        const response = await instance.post(url, request, axiosConfig);
        return response as unknown as AuthResponse;
    } catch (error) {
        throw error;
    }
};
