import { SyntheticEvent, MouseEvent, Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import { Duration } from './Duration';
import { PlaybackRateMenu } from './PlaybackRateMenu';
import {
    MediaDurationContainer,
    VolumeSlider,
    MediaIconButton,
    VolumeControlsContainer,
    ProgressSlider,
    AudioControlsContainer,
} from './mediaStyledComponents';
import { AudioPlayerError } from './AudioPlayerError';

//
// Custom audio controls with play/pause, seek, volume,
// and playback rate controls
//

interface AudioPlayerControlsProps {
    playing: boolean;
    handlePlayPause: () => void;
    muted: boolean;
    handleToggleMuted: () => void;
    duration: number;
    played: number;
    handleSeekChange: (event: Event, value: number | number[], activeThumb: number) => void;
    handleSeekMouseDown: () => void;
    handleSeekMouseUp: (e: SyntheticEvent | Event, value: number | number[]) => void;
    volume: number;
    handleVolumeChange: (event: Event, value: number | number[], activeThumb: number) => void;
    handleVolumeSeekUpHandler: (e: SyntheticEvent | Event, value: number | number[]) => void;
    playbackRate: number;
    handleSetPlaybackRate: (e: MouseEvent<HTMLUListElement>) => void;
    playbackRateMenuOpen: boolean;
    setPlaybackMenuOpen: Dispatch<SetStateAction<boolean>>;
    handleKeyPress: (event: any) => void;
    showError: boolean;
}

export function AudioPlayerControls({
    playing,
    handlePlayPause,
    muted,
    handleToggleMuted,
    duration,
    played,
    handleSeekChange,
    handleSeekMouseDown,
    handleSeekMouseUp,
    volume,
    handleVolumeChange,
    handleVolumeSeekUpHandler,
    playbackRate,
    handleSetPlaybackRate,
    playbackRateMenuOpen,
    setPlaybackMenuOpen,
    handleKeyPress,
    showError,
}: AudioPlayerControlsProps) {
    if (showError) return <AudioPlayerError />;

    return (
        <AudioControlsContainer onKeyDown={handleKeyPress} tabIndex={0}>
            <MediaIconButton
                onClick={handlePlayPause}
                disableRipple
                sx={{ marginRight: { xs: '0px', md: '16px' } }}
            >
                {playing ? (
                    <PauseRoundedIcon sx={{ width: '32px', height: '32px', marginRight: '8px' }} />
                ) : (
                    <PlayArrowRoundedIcon
                        sx={{ width: '32px', height: '32px', marginRight: '8px' }}
                    />
                )}
            </MediaIconButton>
            <MediaDurationContainer>
                <Duration seconds={played} /> /
                <Duration seconds={duration} />
            </MediaDurationContainer>
            <Box
                sx={{
                    margin: { xs: '0 12px', md: '0 24px' },
                    width: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ProgressSlider
                    min={0}
                    max={duration}
                    value={played}
                    onDragStart={handleSeekMouseDown}
                    onChange={(e: Event, value: number | number[], activeThumb: number) =>
                        handleSeekChange(e, value, activeThumb)
                    }
                    onChangeCommitted={(e: SyntheticEvent | Event, value: number | number[]) =>
                        handleSeekMouseUp(e, value)
                    }
                />
            </Box>
            <VolumeControlsContainer>
                <VolumeSlider
                    min={0}
                    max={1}
                    step={0.1}
                    value={volume}
                    onChangeCommitted={(e: SyntheticEvent | Event, value: number | number[]) =>
                        handleVolumeSeekUpHandler(e, value)
                    }
                    onChange={(e: Event, value: number | number[], activeThumb: number) =>
                        handleVolumeChange(e, value, activeThumb)
                    }
                />
                <MediaIconButton onClick={handleToggleMuted} disableRipple>
                    {muted ? (
                        <VolumeOffIcon sx={{ width: '32px', height: '32px' }} />
                    ) : (
                        <VolumeUpIcon sx={{ width: '32px', height: '32px' }} />
                    )}
                </MediaIconButton>
            </VolumeControlsContainer>
            <Box sx={{ marginLeft: { xs: '8px', md: '16px' } }}>
                <PlaybackRateMenu
                    playbackRate={playbackRate}
                    handleSetPlaybackRate={handleSetPlaybackRate}
                    playbackRateMenuOpen={playbackRateMenuOpen}
                    setPlaybackMenuOpen={setPlaybackMenuOpen}
                />
            </Box>
        </AudioControlsContainer>
    );
}
