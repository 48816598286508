import { useQuery, UseQueryResult } from 'react-query';
import { retrieveUserService } from '../services/cmeProfileService';
import { isAuthenticatedUser } from '../utils/cookies';

export interface GetAccountProfileResponse {
    data: IUserCMEProfileResponse;
    status: number;
    done: boolean;
}

interface IUserCMEProfile {
    userId: number;
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    dateOfBirth: string | null;
    epocratesOccupationId: number | null;
    epocratesSpecialtyId: number | null;
    epocratesSubSpecialtyId: number | null;
    rieventDegreeId: number | null;
    npiNumber: string | null;
    practiceCountryISO2: string | null;
    practicePostalCode: string | null;
}

interface IUserCMEProfileResponse {
    profile: IUserCMEProfile;
    problemFields?: { [key: string]: string };
}

export const GetAccountProfile = (): UseQueryResult<GetAccountProfileResponse> => {
    let profileQueryEnabled: boolean = false;

    if (isAuthenticatedUser()) profileQueryEnabled = true;

    return useQuery(['account-profile'], retrieveUserService, {
        enabled: profileQueryEnabled,
        cacheTime: 120.0,
        refetchOnWindowFocus: false,
    });
};
