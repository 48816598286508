import { AxiosResponse } from 'axios';
import config from '../config/envConfig';
import { axiosClient } from './axiosClient';
import { LaunchAction } from '../models/CMECommonResponse';

const { cmeProcessApiUrl } = config.environment;
export async function getLaunchActionForActivity(activityCode: string): Promise<LaunchAction> {
    const url = `${cmeProcessApiUrl}/Product__Cme/GetLaunchActionForActivity`;
    try {
        const headers = {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'Epoc-App-Version': 'latest',
            DeviceType: 'Web',
            'Epoc-Platform': 'Web',
        };

        const response = await axiosClient.post<any, AxiosResponse<LaunchAction, any>>(
            url,
            { activityCode },
            {
                headers,
                authMode: 'never',
            } as any
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}
