import { Box, ListItem, Typography, MenuItem, ClickAwayListener } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Check from '@mui/icons-material/Check';
import { MouseEvent, SetStateAction, Dispatch, useRef, useEffect } from 'react';
import { MediaIconButton, PlaybackMenuContainer } from './mediaStyledComponents';
import colors from '../../../config/colorConfig';
import CloseIcon from '@mui/icons-material/Close';
import { strings } from '../../../utils/Strings';

interface PlaybackRateMenuProps {
    playbackRate: number;
    handleSetPlaybackRate: (e: MouseEvent<HTMLUListElement>) => void;
    playbackRateMenuOpen: boolean;
    setPlaybackMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export function PlaybackRateMenu({
    playbackRate,
    handleSetPlaybackRate,
    playbackRateMenuOpen,
    setPlaybackMenuOpen,
}: PlaybackRateMenuProps) {
    const menuRef = useRef<HTMLUListElement>(null);

    const handleOpen = () => {
        setPlaybackMenuOpen(true);
    };

    const handleClose = () => {
        setPlaybackMenuOpen(false);
    };

    const playbackOptions = [0.25, 0.5, 0.75, 'Normal', 1.25, 1.5, 1.75, 2.0];

    useEffect(() => {
        if (playbackRateMenuOpen) {
            if (menuRef.current) menuRef.current.style.visibility = 'visible';
            if (menuRef.current) menuRef.current.style.pointerEvents = 'auto';
        } else {
            if (menuRef.current) menuRef.current.style.visibility = 'hidden';
            if (menuRef.current) menuRef.current.style.pointerEvents = 'none';
        }
    }, [playbackRateMenuOpen]);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ position: 'relative' }}>
                <MediaIconButton
                    aria-label="playback-rate-menu"
                    aria-haspopup="menu"
                    onClick={handleOpen}
                    disableRipple
                >
                    <MoreVertIcon sx={{ width: '32px', height: '32px' }} />
                </MediaIconButton>
                <PlaybackMenuContainer ref={menuRef}>
                    <ListItem sx={{ width: '176px' }}>
                        <Typography
                            sx={{ fontFamily: 'Metric', fontSize: '18px', fontWeight: 400 }}
                        >
                            {strings.playback_speed_title}
                        </Typography>
                        <MediaIconButton
                            disableRipple
                            sx={{ marginLeft: 'auto', color: colors['--primary-midnight'] }}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </MediaIconButton>
                    </ListItem>
                    {playbackOptions.map((option) => {
                        return (
                            <MenuItem
                                key={`playback-menu-${option}`}
                                sx={{ fontFamily: 'Metric', fontSize: '18px', width: '176px' }}
                                onClick={(e: any) => {
                                    handleSetPlaybackRate(e);
                                    handleClose();
                                }}
                            >
                                {option}
                                {(playbackRate === option ||
                                    (playbackRate === 1.0 && option === 'Normal')) && (
                                    <Check sx={{ marginLeft: 'auto' }} />
                                )}
                            </MenuItem>
                        );
                    })}
                </PlaybackMenuContainer>
            </Box>
        </ClickAwayListener>
    );
}
