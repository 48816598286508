import axios from 'axios';
import config from '../config/envConfig';

const { profileUrl } = config.environment;

type Allow = {
    valid: boolean;
};

export async function getAllowAnalytics(userId?: string): Promise<boolean> {
    try {
        let url = profileUrl + '/validate/allowAnalytics';
        if (userId && !isNaN(Number(userId))) {
            url += '?userId=' + userId;
        }
        const response = await axios.get<Allow>(url);
        if (response?.status === 200 && typeof response?.data?.valid === 'boolean') {
            return response.data.valid;
        } else {
            return false;
        }
    } catch (exception) {
        return false;
    }
}
