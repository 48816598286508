import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ReactNode } from 'react';
import { Desktop } from '../../utils/responsive';
import { FilterNav } from './FilterNav';
import HorizontalNav from './HorizontalNav';
import LeftNav from './LeftNav';
import { ScreenLayout } from './ScreenLayout';
import { ResponseAmplitudeState } from '../../models/AmplitudeState';
import { RemoveActionToast } from '../RemoveActionToast';
import { RootState } from '../../store/store';
import { ActiveFilterInformation, updateFilterState } from '../../Slices/FilterSlice';
import { FilterType } from '../../models/FilterType';

export default function CMELayout({
    children,
    value,
    responseAmplitudeData,
    initialSortSelection,
    filterNav = true,
}: {
    children: ReactNode;
    value: number;
    responseAmplitudeData?: ResponseAmplitudeState;
    initialSortSelection?: string;
    filterNav?: boolean;
}) {
    const dispatch = useDispatch();
    const sortSelection: string = initialSortSelection ?? 'Recent (Newest to Oldest)';
    const isSnackBarOpen: boolean = useSelector(
        (state: RootState) => state.snackBarState.isSnackBarOpen
    );
    const activeFilterInformation: ActiveFilterInformation = useSelector(
        (state: RootState) => state.filter.activeFilterInformation
    );
    if (!Desktop()) {
        if (
            activeFilterInformation.isActive &&
            activeFilterInformation.filterType === FilterType.QuickFilter
        ) {
            dispatch(
                updateFilterState({ isActive: false, filterType: FilterType.None, filterName: '' })
            );
        }
    }

    return (
        <>
            <div
                style={{
                    position: activeFilterInformation.isActive ? 'fixed' : 'initial',
                    width: '100%',
                }}
            >
                {Desktop() ? (
                    <ScreenLayout>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100%',
                                minHeight: 'calc(100vh - 229px)',
                                '@media (min-width:1200px) and (max-width:1440px)': {
                                    justifyContent: 'start',
                                },
                            }}
                        >
                            <LeftNav value={value} />
                            <div>
                                {filterNav === true && (
                                    <>
                                        <FilterNav
                                            onlyEssential={false}
                                            value={value}
                                            responseAmplitudeData={responseAmplitudeData}
                                            initialSortSelection={sortSelection}
                                        />
                                    </>
                                )}
                                {children}
                            </div>
                        </Box>
                    </ScreenLayout>
                ) : (
                    <ScreenLayout>
                        <Box
                            sx={{
                                width: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: { xs: '0px 16px', md: '0px 24px' },
                            }}
                        >
                            <HorizontalNav
                                value={value}
                                responseAmplitudeData={responseAmplitudeData}
                                navigationTabsOnly={filterNav === false ? true : false}
                                initialSortSelection={sortSelection}
                            />

                            {children}
                        </Box>
                    </ScreenLayout>
                )}
            </div>
            {isSnackBarOpen && <RemoveActionToast />}
        </>
    );
}
