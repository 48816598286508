import { ReactNode } from 'react';

import { formatDate } from '../../utils/formatDate';
import { strings } from '../../utils/Strings';
import {
    StyledHeading1,
    StyledHeading2,
    LogoContainer,
    HeaderContainer,
    FrontMatterDetailsContainer,
} from './frontMatterStyledComponents';

//
// FrontMatterHeaderSection dynamically displays activity header
// information and a static epocrates logo
//

interface FrontMatterHeaderSectionProps {
    accreditationText: string | undefined;
    title: string | ReactNode;
    creditText: string | undefined;
    launchText: string | undefined;
    expirationText: string | undefined;
}

export function FrontMatterHeaderSection({
    accreditationText,
    title,
    creditText,
    launchText,
    expirationText,
}: FrontMatterHeaderSectionProps) {
    return (
        <>
            <LogoContainer>
                <img src="epocrates-cme.svg" alt="epocrates logo text" width="300px" />
            </LogoContainer>
            <HeaderContainer>
                {accreditationText && <StyledHeading2>{accreditationText}</StyledHeading2>}
                {typeof title === 'string' ? <StyledHeading1>{title}</StyledHeading1> : title}
                <FrontMatterDetailsContainer>
                    {launchText && (
                        <StyledHeading2>{`${strings.frontMatter_launch_text} ${formatDate(
                            launchText
                        )}`}</StyledHeading2>
                    )}
                    {expirationText && (
                        <StyledHeading2>{`${strings.frontMatter_expiration_text} ${formatDate(
                            expirationText
                        )}`}</StyledHeading2>
                    )}
                    {creditText && (
                        <StyledHeading2>{`${strings.frontMatter_credit_text} ${creditText}`}</StyledHeading2>
                    )}
                </FrontMatterDetailsContainer>
            </HeaderContainer>
        </>
    );
}
