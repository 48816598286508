// NOTE: The JSON contract structures here have a very high level of correlation to the similarly-named structures
// defined/used in cme-epolms-service ProgramTransactionService.ts
import { MultiFormatText } from './Primitives';
import { QuizId } from './IdTypes';
import { LaunchAction } from './CMECommonResponse';
import { QuizIntent } from './QuizTransactionService';

// NavigationIntent is a user-initiated (via button click, completing an external SSO activity, coming in from catalog, etc)
// attempt to advance the state of the participation.  Coming in from the catalog it'll usually be 'resume'; once in the
// activity it'll usually be 'next'.
// Note that some values like "goto" and "push" will actually be strings of the form "goto:ES8231421" or "push:ES9351351"
// which is hard to express as a ts type.  However, the frontend should rarely need to parse or formulate these cases,
// instead just receiving them from the backend as the nav intent for certain buttons, and passing them back when clicked.
export type NavigationIntent =
    | 'next' // confirm (frontmatter) and/or go to the next/rightward slide in deck
    | 'back' // show the previous/leftward slide in deck
    | 'resume' // pick up where the user left off or restart as needed
    | 'review' // go to the last revew slide
    | 'restart' // go to the earliest mandatory slide even if it's already complete
    | 'goto' // goto a specific slide
    | 'push' // push onto session nav stack, e.g. button to optionally see front matter
    | 'pop' // pop from session nav stack, e.g. 'Return' button from an optional front matter
    | 'sso-return-pass' // client is returning from an external site to sso launch slide and it is complete (so behaves like 'next')
    | 'sso-return-fail'
    | 'exit-activity'; // client is returning from an external site to sso launch slide and it is failed (behavior TBD)

export enum ParticipationMode {
    Normal = 'normal', // this is what normal learners do: take the course in the correct order, and either pass/fail/abandon eventually
    PublishPreview = 'publish-preview', // this suspends all navigation rules/constraint and allows the publisher/client to see any/all the slides
    TesterPreview = 'tester-preview', // this behaves like a normal user but doesn't persist results or transmit usage stats
}

export interface IProgramTransactRequest {
    programId: string;
    navigate?: undefined | NavigationIntent;
    session?: undefined | null | string; // symmetric-encrypted session state for use by backend; store this in tab but don't parse.
    mode?: undefined | ParticipationMode; // defaults to 'normal' when unspecified
}

export interface INavigationButton {
    text: { plain: string };
    intent: NavigationIntent;
}

export interface IQuizNavigationButton {
    text: { plain: string };
    intent: QuizIntent | NavigationIntent;
}

export interface IProgramOutlineEntry {
    text: { plain: string };
    current: boolean;
    completed: boolean;
    clickable: boolean;
    navigationIntent: null | NavigationIntent;
    children?: null | undefined | IProgramOutlineEntry[];
}

export interface IProgramOutline {
    items: IProgramOutlineEntry[];
}

export interface IFrontMatterSlide {
    previousButton: null | INavigationButton;
    continueButton: null | INavigationButton;
    disclaimerText: { plain: string };
    body: MultiFormatText;
}

export interface IQuizSlide {
    quizId: QuizId; // quizes can have multiple stages and need a nested endpoint/executor, which you'd just load with a .quizId; TBD
    quizContextId: string; // opaque contextId to be passed to quiz transact endpoint, typically of the form PROGRAM:SLIDE
    previousButton: null | INavigationButton;
    continueButton: null | INavigationButton;
    quizOptions: null | { [key: string]: any };
}

export interface IContentSlide {
    titleText: { plain: string };
    body: {
        contentfulRichText: { [key: string]: any };
    };
    previousButton: null | INavigationButton;
    continueButton: null | INavigationButton;
}

export interface ICreditClaimSlide {
    titleText: { plain: string };
    creditContextId: string;
    creditSelectionId: string;
    previousButton: null | INavigationButton;
    continueButton: null | INavigationButton;
    creditOptions: null | { [key: string]: any };
}

export interface IReviewSlide {
    titleText: { plain: string };
    status: string; // passed/failed, TBD
    creditsEarned: number;
    certificatePdfUrl: string;
    startedDate: Date;
    completedDate: Date;
    previousButton: null | INavigationButton;
    continueButton: null | INavigationButton;
}

export interface IProgramGlobalInfo {
    programTitle: undefined | MultiFormatText;
    publicationDateText?:
        | undefined
        | {
              plain: string;
          };
    expirationDateText?:
        | undefined
        | {
              plain: string;
          };
    accreditationText?: undefined | { plain: string };
    durationText?: undefined | { plain: string };
    durationInMinutes: undefined | number;
    creditOptionsText?:
        | undefined
        | {
              plain: string;
          };
    creditTypeText?: undefined | { plain: string };
    creditAmount: undefined | number;
    maxCreditAmountText?: undefined | { plain: string };
    supporterText?: undefined | { plain: string };
    bannerImageUrl: undefined | string;
    activityType: undefined | string;
    mediaTypes: undefined | string[];
}

export interface IProgramTransactResponse {
    programId: string;
    slideId: string;

    // Backend needs to store some session data somewhere, and it's easiest (and facilitates debugging) to plumb it through
    // the frontend vs caching it in a database like redis/postgres/dynamo.  Frontend shouldn't make any assumptions about
    // what is inside the sessionState object; it may even be signed or encrypted.  Store it globally per-tab in the
    // browser's *session*-local storage and return it, in whatever state it's in, to the backend each time through.
    session: null | string;

    outline: null | IProgramOutline;
    global: IProgramGlobalInfo;

    // One-hot scheme, exactly one of the following will be truthy, and that indicates what the next slide should be/do:
    promptLogin: null | boolean;
    immediateSSOLaunchAction: null | LaunchAction;
    frontMatterSlide: null | IFrontMatterSlide;
    quizSlide: null | IQuizSlide;
    contentSlide: null | IContentSlide;
    creditClaimSlide: null | ICreditClaimSlide;
    reviewSlide: null | IReviewSlide;
    // ^^^ all these slide contents are TBD, shown here for illustrative purposes
}
