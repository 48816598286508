import { CmeRedirectResponse } from '../models/CmeRedirectResponse';
import { axiosClient } from './axiosClient';
import { CmeRedirectRequestBody } from '../models/CmeRedirectRequestBody';
import config from '../config/envConfig';

const { cmeRedirectUrl } = config.environment;

export const cmeRedirectionService = async (
    cmeRedirectRequestBody: CmeRedirectRequestBody
): Promise<CmeRedirectResponse> => {
    try {
        const response = await axiosClient.post(cmeRedirectUrl, cmeRedirectRequestBody, {
            headers: {
                accept: 'application/json',
            },
            authMode: 'auto',
        } as any);
        return response as unknown as CmeRedirectResponse;
    } catch (error) {
        throw error;
    }
};
