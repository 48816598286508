import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, Select, styled, Box, useMediaQuery, InputBase } from '@mui/material';
import colors from '../config/colorConfig';
import { RootState } from '../store/store';
import {
    sendAmplitudeEvent,
    getSortAmplitudeEventTitles,
    SortAmplitudeEventTitlesResponse,
} from '../amplitude/AmplitudeUtil';
import { updateSelectedSortCriteria, updateIsSortOpen } from '../Slices/SortSlice';
import { ResponseAmplitudeState } from '../models/AmplitudeState';

//
// SortBy is rendered by the FilterNav component and appears is
// responsive for xs - xl Saved and Catalog screens. SortBy is created
// from MUIv5 Select, and custom styled. State is stored in a Redux
// store as SortState in combination with local component state.
//

interface SortByProps {
    responseAmplitudeData?: ResponseAmplitudeState;
    initialSortSelection: string;
    disabled: boolean;
}

export function SortBy({ responseAmplitudeData, initialSortSelection, disabled }: SortByProps) {
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    const CheckMark = styled('img')({
        color: colors['--primary-midnight'],
    });

    const isOpen: boolean = useSelector((state: RootState) => state.sort.isOpen);

    const DropDownIcon = styled('img')({
        position: 'absolute',
        pointerEvents: 'none',
        transform: isOpen ? 'rotate(180deg)' : 'none',
        right: 16,
    });

    const [amplitudeEventTitles, setAmplitudeEventTitles] = useState<
        SortAmplitudeEventTitlesResponse | ''
    >();

    const availableSortCriteria: string[] = useSelector(
        (state: RootState) => state.sort.availableSortCriteria
    );

    const [userSelection, setUserSelection] = useState<string>(initialSortSelection);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(updateIsSortOpen(false));
    };

    const handleOpen = () => {
        dispatch(updateIsSortOpen(true));
        if (amplitudeEventTitles) {
            sendAmplitudeEvent(amplitudeEventTitles.clickEventTitle);
        }
    };

    useEffect(() => {
        if (responseAmplitudeData?.source) {
            const activeTitles = getSortAmplitudeEventTitles(responseAmplitudeData?.source);

            setAmplitudeEventTitles(activeTitles);
        }
    }, [responseAmplitudeData?.source]);

    useEffect(() => {
        setUserSelection(initialSortSelection);
    }, [initialSortSelection]);

    const StyledInput = styled(InputBase)(({ theme }) => ({
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'center',
        backgroundColor: colors['--ui-grey-light'],
        height: 24,
        fontSize: 16,
        fontWeight: 500,
        color: colors['--primary-midnight'],
        fontFamily: 'Metric',
        lineHeight: 0.94,
        borderRadius: '4px',
        [theme.breakpoints.up('md')]: {
            width: 272,
        },
        [theme.breakpoints.up('lg')]: {
            width: 275,
        },
        '&.Mui-disabled': {
            backgroundColor: colors['--ui-grey-ultra-light'],
            color: 'black',
        },
    }));

    return (
        <Select
            disabled={disabled}
            value={disabled ? 'Recent (Newest to Oldest)' : userSelection}
            open={isOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={(e: any) => {
                setUserSelection(e.target.value);
                if (userSelection) dispatch(updateSelectedSortCriteria(e.target.value));

                if (amplitudeEventTitles) {
                    sendAmplitudeEvent(amplitudeEventTitles.selectEventTitle, {
                        Selection: e.target.value,
                    });
                }
            }}
            renderValue={(value: string) =>
                isMobile ? (
                    <Box
                        sx={{
                            display: 'flex',
                            paddingLeft: '8px',
                            paddingTop: '4px',
                            marginRight: '-8px',
                        }}
                    >
                        <Box
                            sx={{
                                opacity: disabled ? 0.4 : 1.0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src="mobile-sort-arrows.svg" alt="up and down arrows icon" />
                        </Box>
                        <span style={{ marginLeft: '4px', fontWeight: '500px' }}>Sort by</span>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            paddingTop: '4px',
                            height: '24px',
                            maxWidth: '224px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            marginLeft: '12px',
                        }}
                    >{`Sort by: ${value}`}</Box>
                )
            }
            input={<StyledInput />}
            variant="standard"
            IconComponent={() => (
                <Box
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        opacity: disabled ? 0.4 : 1.0,
                        display: isMobile ? 'none' : 'flex',
                    }}
                >
                    <DropDownIcon
                        src="Icons_Streamline_regular_24pt_filter.svg"
                        alt="DropDown arrow icon"
                    />
                </Box>
            )}
            MenuProps={{
                BackdropProps: {
                    invisible: false,
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        transitionDuration: '0s !important',
                    },
                },
                PaperProps: {
                    sx: {
                        transitionDuration: '0s !important',
                        width: 294,
                        backgroundColor: colors['--pure-white'],
                        borderRadius: '3px',
                        boxShadow:
                            '0px 8px 12px 1px rgba(0, 0, 0, 0.07), 0px 3px 16px 2px rgba(0, 0, 0, 0.06), 0px 5px 10px -3px rgba(0, 0, 0, 0.1);',
                    },
                },
                sx: {
                    transitionDuration: '0s !important',
                    '& .cme-web-MuiMenuItem-root': {
                        padding: '10px 12px 10px 28px',
                        '&:hover': { backgroundColor: colors['--ui-grey-ultra-light'] },
                        '&.Mui-selected': {
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: colors['--ui-grey-ultra-light'],
                            },
                        },
                    },
                    '& .cme-web-ButtonBase-root': {
                        '&.Mui-selected': {
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: colors['--ui-grey-ultra-light'],
                            },
                        },
                    },
                    '& .MuiTouchRipple-root': {
                        animation: 'none',
                        backgroundColor: 'transparent',
                        color: 'transparent',
                        ripple: 'none',
                    },
                },
            }}
        >
            {availableSortCriteria.map((asc) => {
                const isCheckmarkVisible = userSelection ? userSelection.indexOf(asc) > -1 : false;
                return (
                    <MenuItem value={asc} key={asc}>
                        <Box
                            sx={{
                                width: '18px',
                                height: '18px',
                                marginRight: '12.5px',
                                display: 'flex',
                                alignContent: 'center',
                            }}
                        >
                            {isCheckmarkVisible && <CheckMark src="check.svg" alt="check mark" />}
                        </Box>
                        {asc}
                    </MenuItem>
                );
            })}
        </Select>
    );
}
