import { axiosClient } from './axiosClient';
import { ISeriesRequest } from '../models/ISeriesRequest';
import { SeriesPageResponse } from '../models/SeriesPageResponse';
import config from '../config/envConfig';

const { cmeProcessApiUrl } = config.environment;

export const getSeriesPage = async (request: ISeriesRequest): Promise<SeriesPageResponse> => {
    const url = `${cmeProcessApiUrl}/Product__Cme/GetSeriesPage`;
    try {
        const headers = {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'Epoc-App-Version': 'latest',
            DeviceType: 'Web',
            'Epoc-Platform': 'Web',
        };

        const response = await axiosClient.post(url, request, {
            headers,
            authMode: 'auto',
        } as any);
        return response as unknown as SeriesPageResponse;
    } catch (error) {
        throw error;
    }
};
