import { Box, IconButton, Slider, styled } from '@mui/material';
import colors from '../../../config/colorConfig';

export const VolumeSlider = styled(Slider)({
    color: colors['--pure-white'],
    opacity: 0,
    transition: 'opacity 0.5s ease-out',
    '&:hover .cme-web-MuiSlider-thumb': {
        visibility: 'visible',
    },
    '& .cme-web-MuiSlider-thumb': {
        visibility: 'hidden',
        '&:hover': {
            boxShadow: 'none',
            visibility: 'visible',
        },
        '&:active': {
            boxShadow: 'none',
        },
    },
});

export const VolumeControlsContainer = styled(Box)({
    display: 'flex',
    width: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'width 0.5s ease-out',
    '&:hover span:first-of-type': {
        opacity: 1,
        transition: 'opacity 0.5s ease-in',
    },
    '&:hover': {
        width: '200px',
        transition: 'width 0.5s ease-in',
    },
});

export const ProgressSlider = styled(Slider)({
    maxWidth: '100%',
    color: colors['--pure-white'],
    flexShrink: 2,
    padding: '0 !important',
    '&:hover .cme-web-MuiSlider-thumb': {
        visibility: 'visible',
        boxShadow: 'none',
    },
    '&:active .cme-web-MuiSlider-thumb': {
        visibility: 'visible',
        boxShadow: 'none',
    },
    '& .cme-web-MuiSlider-thumb': {
        visibility: 'hidden',
        boxShadow: 'none',
    },
});

export const MediaDurationContainer = styled(Box)({
    display: 'flex',
    color: colors['--pure-white'],
    fontFamily: 'Metric',
    fontSize: '18px',
    gap: '4px',
    flexShrink: 0,
    fontVariantNumeric: 'tabular-nums',
});

export const MediaIconButton = styled(IconButton)({
    '&:focus': {
        outline: 'none',
    },
    color: colors['--pure-white'],
    padding: 0,
});

export const VideoPlayerContainer = styled(Box)({
    width: '100%',
    height: '100%',
    zIndex: 2,
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    outlineStyle: 'none',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '4px',
});

export const VideoControlsContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
    bottom: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    opacity: '0',
    transition: 'opacity 0.5s',
    width: '100%',
    borderRadius: '0px 0px 4px 4px',
    [theme.breakpoints.up('xs')]: {
        padding: '4px 8px 8px 8px',
    },
    [theme.breakpoints.up('lg')]: {
        padding: '4px 16px 16px 16px',
    },
}));

export const VideoOverlay = styled(Box)(({ theme }) => ({
    backgroundColor: colors['--ui-grey-med'],
    width: '100%',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    [theme.breakpoints.up('xs')]: {
        height: '200px',
    },
    [theme.breakpoints.up('md')]: {
        height: '294px',
    },
    [theme.breakpoints.up('xl')]: {
        height: '600px',
    },
}));

export const AudioControlsContainer = styled(Box)({
    backgroundColor: colors['--primary-teal-night'],
    maxWidth: '508px',
    width: '100%',
    height: '80px',
    borderRadius: '100px',
    display: 'inline-flex',
    padding: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    outlineStyle: 'none',
    boxShadow: 'none',
    borderColor: 'transparent',
});

export const PlaybackMenuContainer = styled('ul')({
    height: '208px',
    zIndex: 5,
    position: 'absolute',
    bottom: '24px',
    right: '4px',
    visibility: 'hidden',
    overflowY: 'scroll',
    backgroundColor: colors['--pure-white'],
    padding: 0,
    borderRadius: '4px',
    color: colors['--primary-midnight'],
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
});

export const PlayPauseIconContainer = styled(Box)({
    color: colors['--pure-white'],
    backgroundColor: colors['--primary-teal-night'],
    borderRadius: '100px',
    display: 'flex',
    alignContent: 'center',
});
