import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Container, styled, Box } from '@mui/material';
import { ScreenLayout } from '../Layout/ScreenLayout';
import { sendAmplitudeEvent, getSimplifiedSeriesViewData } from '../../amplitude/AmplitudeUtil';
import { CME_SERIES_CLOSE, CME_SERIES_PAGE_VIEW } from '../../amplitude/analytics';
import { SeriesPageCollections } from './SeriesPageCollections';
import { ResponseAmplitudeState } from '../../models/AmplitudeState';
import SeriesPageBanner from './SeriesPageBanner';
import { ResponseProcessMetadata, Section } from '../../models/CMECommonResponse';
import { SeriesPageSupporterText } from './SeriesPageSupporterText';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { updateIsSeriesActive } from '../../Slices/DisplaySlice';
import { GetSeriesPage } from '../../queries/SeriesPageQuery';
import { SeriesPageData } from '../../models/SeriesPageResponse';
import { strings } from '../../utils/Strings';
import { ShowErrorModal } from '../Error/ShowErrorModal';
import Modal from '../Modal/Modal';
import { LoadingSpinner } from '../LoadingSpinner';
import { getFormattedSupporterText } from '../../utils/getFormattedSupporterText';
import { useIsContextualLinkSource } from '../../hooks/useIsContextualLinkSource';
import { scrollToTop } from '../../utils/scrollToTop';
import { getSeriesPageSource } from '../../utils/getSeriesPageSource';
import { FullScreenLoadingContainer } from '../FullScreenLoadingContainer';

const SeriesPageContentContainer = styled(Container)(({ theme }) => ({
    flexDirection: 'column',
    paddingBottom: 80,
    [theme.breakpoints.up('md')]: {
        marginTop: '24px',
    },
}));
interface SeriesPageProps {
    seriesPageId?: string;
}

const CloseButton = styled('button')(({ theme }) => ({
    position: 'absolute',
    zIndex: 2,
    top: '24px',
    right: '24px',
    background: 'transparent',
    padding: 0,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '24px',
    width: '24px',
    [theme.breakpoints.down('md')]: {
        top: '16px',
        right: '16px',
    },
    '&:focus': {
        outline: 'none',
    },
}));

export const SeriesPage = (props: SeriesPageProps) => {
    const dispatch = useDispatch();

    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );

    let seriesPageId: string | undefined;
    seriesPageId = useParams()?.seriesPageId;
    if (props?.seriesPageId) {
        seriesPageId = props.seriesPageId;
    }
    scrollToTop();
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const [responseAmplitudeData, setResponseAmplitudeData] = useState<ResponseAmplitudeState>({
        source: null,
        requestInstanceID: null,
        title: null,
        duration: null,
        creditAmount: null,
        badge: null,
        daysSincePublication: null,
        activitiesInThisSeries: null,
        processMetadata: null,
        seriesPageId: null,
    });

    let seriesPageResponse: any = null;
    seriesPageResponse = GetSeriesPage(seriesPageId as unknown as string);

    let formattedSupporterText: any = null;
    if (seriesPageResponse?.data?.data.supporterText)
        formattedSupporterText = getFormattedSupporterText(
            seriesPageResponse?.data?.data.supporterText.plain
        );

    const isContextualLink = useIsContextualLinkSource();

    const seriesCloseButton = () => {
        const simplifiedData = getSimplifiedSeriesViewData(
            seriesPageResponse?.data?.data as SeriesPageData,
            currentSourceTab,
            isContextualLink
        );
        sendAmplitudeEvent(CME_SERIES_CLOSE, simplifiedData);
        dispatch(updateIsSeriesActive(false));
    };

    let source: number;

    useEffect(() => {
        if (!seriesPageResponse?.isSuccess) return;

        if (isSeriesActive) {
            source = currentSourceTab;
        } else {
            source = -1;
        }
        const simplifiedData = getSimplifiedSeriesViewData(
            seriesPageResponse?.data?.data as SeriesPageData,
            source,
            isContextualLink
        );
        sendAmplitudeEvent(CME_SERIES_PAGE_VIEW, simplifiedData);

        // update state for amplitude events
        setResponseAmplitudeData({
            source: getSeriesPageSource(source, isContextualLink),
            title: seriesPageResponse.data.data.title.plain,
            requestInstanceID: seriesPageResponse.data.data.processMetadata.instanceId,
            duration: seriesPageResponse.data.data.durationInMinutes,
            creditAmount: seriesPageResponse.data.data.creditAmount,
            badge: seriesPageResponse.data.data.chipText,
            daysSincePublication: seriesPageResponse.data.data.daysSincePublication,
            activitiesInThisSeries: seriesPageResponse.data.data.sections.find(
                (section: Section) => section.cardType === 'cme-activity'
            )?.cards.length,
            processMetadata: seriesPageResponse.data.data
                .processMetadata as ResponseProcessMetadata,
            seriesPageId: seriesPageResponse.data.data.seriesPageId,
        });
    }, [seriesPageResponse?.data?.data]);

    if (seriesPageResponse.isLoading && isSeriesActive) {
        return (
            <SeriesPageContentContainer
                maxWidth={false}
                sx={{ maxWidth: '752px' }}
                disableGutters={true}
            >
                <FullScreenLoadingContainer>
                    <LoadingSpinner />
                </FullScreenLoadingContainer>
            </SeriesPageContentContainer>
        );
    } else if (seriesPageResponse.isLoading && !isSeriesActive) {
        // handle temporary full page Series Screen loading
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LoadingSpinner />
            </Box>
        );
    }

    if (seriesPageResponse.error) {
        ShowErrorModal(seriesPageResponse.error, dispatch, strings.error_loading);
    }

    return (
        <>
            {isSeriesActive ? (
                <SeriesPageContentContainer
                    maxWidth={false}
                    sx={{ maxWidth: '752px' }}
                    disableGutters={true}
                >
                    <Modal />
                    {seriesPageResponse?.data?.data && (
                        <div style={{ position: 'relative' }}>
                            <CloseButton onClick={seriesCloseButton}>
                                <img
                                    src="close.svg"
                                    alt="close button"
                                    style={{ height: '24px', width: '24px' }}
                                />
                            </CloseButton>
                            <div style={{ position: 'relative' }}>
                                <SeriesPageBanner
                                    banner={seriesPageResponse.data.data.banner}
                                    chip={seriesPageResponse.data.data.chip}
                                    seriesText={seriesPageResponse.data.data.seriesText}
                                    creditText={seriesPageResponse.data.data.creditText}
                                    title={seriesPageResponse.data.data.title.plain}
                                    overlayEnabled={seriesPageResponse.data.data.overlayEnabled}
                                    description={
                                        seriesPageResponse.data.data.description
                                            ? seriesPageResponse.data.data.description.plain
                                            : ''
                                    }
                                    responseAmplitudeData={getSimplifiedSeriesViewData(
                                        seriesPageResponse.data.data as SeriesPageData,
                                        currentSourceTab,
                                        isContextualLink
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    {seriesPageResponse?.data?.data.supporterText && (
                        <SeriesPageSupporterText supporterText={formattedSupporterText} />
                    )}
                    {seriesPageResponse?.data?.data.sections && (
                        <SeriesPageCollections
                            sections={seriesPageResponse.data.data.sections as Section[]}
                            responseAmplitudeData={responseAmplitudeData}
                        />
                    )}
                </SeriesPageContentContainer>
            ) : (
                <ScreenLayout>
                    <Modal />
                    <SeriesPageContentContainer
                        maxWidth={false}
                        sx={{ maxWidth: '752px' }}
                        disableGutters={true}
                        data-testid="seriesPageData"
                    >
                        {seriesPageResponse?.data?.data && (
                            <SeriesPageBanner
                                banner={seriesPageResponse.data.data.banner}
                                chip={seriesPageResponse.data.data.chip}
                                seriesText={seriesPageResponse.data.data.seriesText}
                                creditText={seriesPageResponse.data.data.creditText}
                                title={seriesPageResponse.data.data.title.plain}
                                overlayEnabled={seriesPageResponse.data.data.overlayEnabled}
                                description={
                                    seriesPageResponse.data.data.description
                                        ? seriesPageResponse.data.data.description.plain
                                        : ''
                                }
                                responseAmplitudeData={getSimplifiedSeriesViewData(
                                    seriesPageResponse.data?.data as SeriesPageData,
                                    currentSourceTab,
                                    isContextualLink
                                )}
                            />
                        )}
                        {seriesPageResponse?.data?.data.supporterText && (
                            <SeriesPageSupporterText supporterText={formattedSupporterText} />
                        )}
                        {seriesPageResponse?.data?.data.sections && (
                            <SeriesPageCollections
                                sections={seriesPageResponse.data.data.sections as Section[]}
                                responseAmplitudeData={responseAmplitudeData}
                            />
                        )}
                    </SeriesPageContentContainer>
                </ScreenLayout>
            )}
        </>
    );
};
