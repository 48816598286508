import { ParticipationMode } from '../../models/EPOLMSModels';
import { strings } from '../../utils/Strings';
import { AlertBanner } from './quiz/AlertBanner';

type PreviewBannerProps = {
    mode?: ParticipationMode;
};

export function PreviewBanner({ mode }: PreviewBannerProps) {
    const isPreviewMode =
        mode === ParticipationMode.PublishPreview || mode === ParticipationMode.TesterPreview;

    return (
        <>
            {isPreviewMode ? (
                <AlertBanner
                    bannerText={strings.preview_text}
                    bannerIcon={null}
                    bannerTitle={strings.preview_title}
                />
            ) : (
                <></>
            )}
        </>
    );
}
