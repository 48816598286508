import { Grid } from '@mui/material';
import {
    AvailableFilterSelection,
    AvailableUserFilter,
} from '../../../models/CatalogCardsResponse';
import { FilterTextBox } from '../../custom/FilterTextBox';

// Accepts the list of data to be displayed and returns the display of buttons in a horizontal direction

interface QuickFilterButtonProps {
    userFilter: AvailableUserFilter | null;
}

export const QuickFilterButtons = (props: QuickFilterButtonProps) => {
    const filterName = props?.userFilter?.filterName;

    return (
        <Grid container direction="row" gap="8px">
            <>
                {props?.userFilter?.selections.map((selection: AvailableFilterSelection) => {
                    return (
                        <>
                            <Grid item>
                                <FilterTextBox
                                    enabled={selection.enabled}
                                    text={selection.value}
                                    subtitle={selection.subtitle}
                                    isLargeDisplay={true}
                                    filterName={filterName}
                                    isQuickFilter={true}
                                />
                            </Grid>
                        </>
                    );
                })}
            </>
        </Grid>
    );
};
