import * as toolkit from '@reduxjs/toolkit';

export interface ErrorState {
    statusCode?: number | null;
    message: string | null;
}

export const initialState: ErrorState = {
    statusCode: null,
    message: null,
};

export const errorSlice = toolkit.createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError: (state, action: toolkit.PayloadAction<ErrorState>) => {
            state.statusCode = action.payload.statusCode;
            state.message = action.payload.message;
        },
    },
});

export const { setError } = errorSlice.actions;

const errorReducer = errorSlice.reducer;
export default errorReducer;
