import { Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../config/colorConfig';
import { UserFilterSelection } from '../../models/ICatalogCardsRequest';
import { updateActiveFilters } from '../../Slices/FilterSlice';
import { RootState } from '../../store/store';
import { filterComponentState } from '../../utils/FilterComponentState';

interface FilterCheckProps {
    text: string;
    filterName: string;
    enabled: boolean;
}
export const FilterCheckBox = (props: FilterCheckProps) => {
    const Uncheckedbox = styled('input')(() => ({
        borderRadius: '3px',
        border: `1.5px solid ${colors['--ui-grey']}`,
        ':disabled': {
            border: `1.5px solid ${colors['--ui-grey-med']}`,
        },
    }));

    const Checkedbox = styled('input')(() => ({
        borderRadius: '3px',
        display: 'block',
        ':checked': {
            accentColor: colors['--primary-teal'],
        },
    }));
    const dispatch = useDispatch();
    let activeFilters: UserFilterSelection[] = useSelector(
        (state: RootState) => state.filter.activeFilterSelections
    );
    const isSelected = filterComponentState(activeFilters, props.filterName, props.text);
    const [selectState, setSelectState] = useState<boolean>(isSelected);
    const handleCheckBoxClick = () => {
        setSelectState(!selectState);
        dispatch(
            updateActiveFilters({
                filterName: props.filterName,
                filterState: !selectState,
                filterValue: props.text,
            })
        );
    };

    useEffect(() => {
        if (isSelected === false && selectState === true) {
            setSelectState(!selectState);
        }
    }, [isSelected]);

    return (
        <Grid container direction="row" columnGap="16px" flexWrap={'nowrap'}>
            <Grid item style={{ height: '20px' }}>
                {selectState ? (
                    <Checkedbox
                        checked
                        disabled={props.enabled ? false : true}
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        onChange={handleCheckBoxClick}
                    />
                ) : (
                    <Uncheckedbox
                        disabled={props.enabled ? false : true}
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        onChange={handleCheckBoxClick}
                    />
                )}
            </Grid>

            <Grid item>
                <Typography
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Metric',
                        fontSize: '16px',
                        color: colors['--primary-midnight'],
                        opacity: props.enabled ? 1.0 : 0.4,
                    }}
                >
                    {' '}
                    {props.text}{' '}
                </Typography>
            </Grid>
        </Grid>
    );
};
