import { FormControlLabel, Checkbox } from '@mui/material';
import React, { useEffect } from 'react';
import colors from '../config/colorConfig';
import { DevLoginBackground } from './custom/DevLoginBackground';
import { LoginPasswordPageBody } from './custom/LoginPasswordPageBody';
import { AuthRequest } from '../models/AuthRequest';
import { logoutUser, setCookie } from '../utils/cookies';
import { strings } from '../utils/Strings';
import { Password } from './custom/PasswordField';
import { TextField } from './custom/TextField';
import { CURRENT_USER_ID } from '../utils/localStorageKeys';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { devLogin } from '../services/devLogin';
import { useSearchParams } from 'react-router-dom';
import { ContainedButton } from './StyledButtons';
import { updateActiveTab } from '../Slices/DisplaySlice';
import { Tabs } from '../models/Tabs';
import { useDispatch } from 'react-redux';

// handle authentication in DEV only
export const LoginForm = (props: { logoutFirst?: boolean }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.logoutFirst) {
            logoutUser();
        }
    }, []);

    const loginQuery = useMutation(devLogin, {
        onSuccess: (data) => {
            localStorage.setItem(CURRENT_USER_ID, data?.data.userId?.toString());
            setCookie('sessionTimer', 24, 'sessionTimer');
            setCookie('accessToken', 24, data.data.tokens.accessToken);
            setCookie('refreshToken', 24, data.data.tokens.refreshToken);
            setCookie('currentUserId', 24, data.data.userId?.toString());
            const referNext = searchParams.get('refernext');
            if (referNext) {
                // This may be external, or it may be under /cme/sso/saml2/* which must navigate/load outside of React
                window.location.href = referNext;
            } else {
                dispatch(updateActiveTab(Tabs.DISCOVER));
                navigate('/cme/discover');
            }
        },
        onError: (error) => {
            console.log(`Error logging in please try again. Error: ${error}`);
        },
    });

    let emailData = '';

    const validateInput = (data: string) => {
        emailData = data;
        if (!emailData) {
            return strings.email_blank_message;
        }
        return null;
    };

    const handleSubmit = async (formEvent: any): Promise<void> => {
        formEvent.preventDefault();
        const authRequest: AuthRequest = {
            email: formEvent.target.elements.email.value,
            password: formEvent.target.elements.password.value,
        };
        // call react query to login the user
        await loginQuery.mutateAsync(authRequest);
    };

    const formBody = (
        <form onSubmit={handleSubmit}>
            <TextField
                autoCapitalize="off"
                name="email"
                testId="email"
                helpTestId="emailHelp"
                label={strings.login_email}
                validationMessage={strings.email_blank_message}
                validation={validateInput}
            />

            <Password
                labelTestId="passwordLabel"
                labelHtmlFor="outlined-adornment-password"
                labelTitle={strings.login_password}
                name="password"
                ariaLabel="toggle password visibility"
                iconTestId="showPasswordId"
                labelWidth={70}
            />
            <ContainedButton type="submit" width="100%" margin="20px 0 0 0">
                {strings.login_wall_login}
            </ContainedButton>
            <FormControlLabel
                id="stayedSignedInId"
                data-testid="stayedSignedInId"
                name="stayedSignedInId"
                control={
                    <Checkbox
                        name="staySignedIn"
                        color="primary"
                        style={{
                            color: colors['--ui-grey'],
                        }}
                    />
                }
                style={{
                    color: colors['--ui-slate'],
                }}
                label={strings.login_stay_signed_in}
            />
        </form>
    );

    const bodyTitle = strings.login_signin_title;
    const pageBody = (
        <>
            <LoginPasswordPageBody
                title={bodyTitle}
                // progressButton={state.login_progress}
                formBody={formBody}
                // additionalInfo={additionalInfo}
            />
        </>
    );
    return <DevLoginBackground pageBody={pageBody} />;
};
