import { styled } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import colors from '../../../config/colorConfig';

interface AlertBannerProps {
    bannerText: string;
    bannerTitle?: string | null | undefined;
    backgroundColor?: string;
    bannerIcon: string | null | undefined;
}

export function AlertBanner({
    bannerText,
    bannerTitle,
    bannerIcon,
    backgroundColor,
}: AlertBannerProps) {
    if (!backgroundColor) {
        backgroundColor = colors['--ui-grey-ultra-light'];
    } else {
        if (Object.keys(colors).includes(backgroundColor)) {
            backgroundColor = Object.entries(colors).find(([key]) => key === backgroundColor)?.[1];
        }
    }
    let Icon = () => {
        if (bannerIcon === 'error') {
            return <ErrorIcon sx={{ color: colors['--color-alert-critical'] }} />;
        }
        return <></>;
    };
    const BannerCardContainer = styled('div')({
        backgroundColor: backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding: '16px',
        marginBottom: '24px',
        gap: '8px',
    });

    const BannerTitleContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
    });

    const BannerTitle = styled('p')({
        fontFamily: 'Metric',
        fontWeight: 500,
        fontSize: '18px',
        margin: '0 0 0 8px',
    });

    const BannerMessage = styled('p')({
        fontFamily: 'Metric',
        fontWeight: 400,
        fontSize: '18px',
        margin: '0',
        textAlign: 'center',
    });

    return (
        <BannerCardContainer>
            <BannerTitleContainer>
                <Icon />
                <BannerTitle>{bannerTitle}</BannerTitle>
            </BannerTitleContainer>
            <BannerMessage>{bannerText}</BannerMessage>
        </BannerCardContainer>
    );
}
