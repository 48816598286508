import { useDecision } from '@optimizely/react-sdk';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { CURRENT_USER_ID } from '../utils/localStorageKeys';
import App from './App';
import { deviceManagementEnabledFlag } from '../utils/optimizelyFlags';
import { updateEpocDeviceDetectionEnabled } from '../Slices/EpocOptimizelyFlagsSlice';
import { loadAnalytics } from '../amplitude/AnalyticsConfig';

export function AppWrapper() {
    const dispatch = useDispatch();
    const [deviceManagementDecision, clientReady] = useDecision(deviceManagementEnabledFlag);

    const currentUserId = localStorage.getItem(CURRENT_USER_ID);

    useEffect(() => {
        dispatch(updateEpocDeviceDetectionEnabled(deviceManagementDecision.enabled));
    }, [deviceManagementDecision, clientReady]);

    useEffect(() => {
        loadAnalytics();
    }, [currentUserId]);

    if (!clientReady) return <></>;

    return <App />;
}
