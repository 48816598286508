import { styled } from '@mui/material';
import colors from '../../../config/colorConfig';
import { SmallScreen } from '../../../utils/responsive';

interface AttemptsBannerProps {
    requiredScoreText: string | null;
    attemptsText: string | null;
    previousScoreText?: string | null;
}

export function AttemptsBanner({
    requiredScoreText,
    attemptsText,
    previousScoreText,
}: AttemptsBannerProps) {
    const BannerContainer = styled('div')({
        backgroundColor: colors['--pure-white'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '32px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        padding: '16px',
        marginBottom: '24px',
        maxWidth: '744px',
        fontFamily: 'Metric',
        fontSize: '18px',
        fontWeight: 400,
    });

    const isSmallScreen = SmallScreen();

    return (
        <BannerContainer>
            {previousScoreText && !isSmallScreen && <div>{previousScoreText}</div>}
            <div>{requiredScoreText}</div>
            <div>{attemptsText}</div>
        </BannerContainer>
    );
}
