import { useState } from 'react';

import {
    IProgramOutline,
    IProgramOutlineEntry,
    IProgramGlobalInfo,
    NavigationIntent,
} from '../../../models/EPOLMSModels';
import { SmallScreen } from '../../../utils/responsive';
import { ExitProgramButton } from './ExitProgramBtn';
import { ProgramOutlineDrawer } from './ProgramOutlineDrawer';
import { ProgramOutlineSmall } from './ProgramOutlineSmall';
import { ProgramOutlineTabs } from './ProgramOutlineTabs';

//
// ProgramOutline renders the ExitProgramBtn, ProgramOutlineTabs and
// ExitProgram button on md - xl screens. ProgramOutlineSmall
// is rendered for xs - md screens.
//

interface ProgramOutlineProps {
    performNavigationIntent: (
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined,
        buttonIndex?: number | null | undefined,
        buttonText?: string | null | undefined,
        destination?: string | null | undefined
    ) => void;
    globalInfo?: IProgramGlobalInfo | null | undefined;
    outline: IProgramOutline | null | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
}

export function ProgramOutline({
    performNavigationIntent,
    globalInfo,
    outline,
    firstName,
    lastName,
}: ProgramOutlineProps) {
    const isSmallScreen = SmallScreen();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    if (!outline) return <></>;

    return (
        <>
            {!isSmallScreen && <ExitProgramButton />}
            {isSmallScreen ? (
                <>
                    <ProgramOutlineDrawer
                        performNavigationIntent={performNavigationIntent}
                        globalInfo={globalInfo}
                        outline={outline}
                        isDrawerOpen={isDrawerOpen}
                        setIsDrawerOpen={setIsDrawerOpen}
                        firstName={firstName}
                        lastName={lastName}
                    />
                    <ProgramOutlineSmall outline={outline} setIsDrawerOpen={setIsDrawerOpen} />
                </>
            ) : (
                <ProgramOutlineTabs
                    performNavigationIntent={performNavigationIntent}
                    globalInfo={globalInfo}
                    outline={outline}
                    isSmallMenu={false}
                    firstName={firstName}
                    lastName={lastName}
                />
            )}
        </>
    );
}
