import { useQuery, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';
import { DiscoverResponse } from '../models/DiscoverScreenActivitiesResponse';
import { Tabs } from '../models/Tabs';
import { fetchCMEDiscoverActivities } from '../services/fetchCMEDiscoverActivities';
import { RootState } from '../store/store';
import { getCurrentUserId, isAuthenticatedUser } from '../utils/cookies';

export const GetDiscoverScreenActivities = (): UseQueryResult<DiscoverResponse, unknown> => {
    let currentSourceTab: number = useSelector((state: RootState) => state.displayState.activeTab);
    return useQuery<DiscoverResponse>(
        ['DiscoverCards', getCurrentUserId()],
        () => fetchCMEDiscoverActivities(),
        {
            enabled: isAuthenticatedUser() && currentSourceTab === Tabs.DISCOVER,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            keepPreviousData: true,
        }
    );
};
