import { RefObject, useState } from 'react';
import { Typography, Box, styled } from '@mui/material';
import { ArrowForwardIosRounded, ArrowBackIosRounded } from '@mui/icons-material';
import { ResponseAmplitudeState, CollectionAmplitudeState } from '../models/AmplitudeState';
import { sendAmplitudeEvent, getSimplifiedHorizontalScrollData } from '../amplitude/AmplitudeUtil';
import {
    CME_COLLECTION_HORIZONTAL_SCROLL,
    CME_SERIES_ACTIVITIES_IN_THIS_SERIES_HORIZONTAL_SCROLL,
} from '../amplitude/analytics';
import { isDesktop } from 'react-device-detect';
import colors from '../config/colorConfig';
// Swiper imports
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// swiper bundle styles
import 'swiper/swiper-bundle.min.css';
// swiper core styles
import 'swiper/swiper.min.css';
// modules styles
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

import { PolymorphicCard } from './PolymorphicCard';

export interface SliderProperties {
    isActive: boolean;
    isPrev: boolean;
    isNext: boolean;
    isVisible: boolean;
}

const SwiperContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:first-of-type': {
        marginTop: '24px',
    },
    marginTop: '32px',
    [theme.breakpoints.up('xs')]: {
        padding: '0 0 0 12px',
    },
    [theme.breakpoints.up('md')]: {
        padding: 0,
        marginLeft: '-4px',
    },
}));

const NavArrowButton = styled('button')({
    color: colors['--primary-midnight'],
    outline: 'none',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    padding: 0,
    cursor: 'pointer',
    background: 'transparent',
    '&:focus': {
        outline: 'none',
    },
    '&:disabled': {
        cursor: 'default',
        color: colors['--ui-grey-med'],
    },
});

const TitleAndArrowsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: isDesktop ? 'center' : 'flex-start',
    paddingLeft: '4px',
    [theme.breakpoints.up('xs')]: {
        paddingRight: '12px',
    },
    [theme.breakpoints.up('sm')]: {
        paddingRight: '20px',
    },
    [theme.breakpoints.up('md')]: {
        paddingRight: 0,
    },
}));

const ArrowButtonsContainer = styled('div')({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

// styling of Swiper and SwiperSlide done with inline styles
// styling with mui v5 styled did not work with swiper v6
SwiperCore.use([Navigation, Pagination]);

interface HorizontalSwiperProps {
    isSeriesCard?: boolean;
    collectionIndex: number;
    cards: any[];
    collectionTitle: string;
    collectionID: any;
    responseAmplitudeData?: ResponseAmplitudeState;
    collectionAmplitudeData?: CollectionAmplitudeState | null;
    collectionRef?: RefObject<HTMLDivElement>;
    isProgramEndpoint?: boolean;
}

export const HorizontalSwiper = ({
    collectionIndex,
    cards,
    collectionTitle,
    collectionID,
    responseAmplitudeData,
    collectionAmplitudeData,
    isSeriesCard,
    collectionRef,
    isProgramEndpoint,
}: HorizontalSwiperProps) => {
    const [slidesProgress, setSlidesProgress] = useState<number>(0);

    const swiperParams = {
        observer: true,
        observeParents: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: false,
        slidesPerView: 'auto' as 'auto',
        spaceBetween: 16,
        navigation: {
            nextEl: `#right-btn-${collectionIndex}-${collectionID}`,
            prevEl: `#left-btn-${collectionIndex}-${collectionID}`,
        },
        cssMode: true,
        direction: 'horizontal' as 'horizontal',
        touchReleaseOnEdges: true,
        watchOverflow: false,
        onInit: (index: any) => {
            // on swiper initialization, set slide progress state to last visible card
            const lastIndex = index.visibleSlidesIndexes.length - 1;
            setSlidesProgress(index.visibleSlidesIndexes[lastIndex] + 2);
        },

        onSlideChange: (index: any) => {
            const lastIndex = index.visibleSlidesIndexes.length - 1;
            const currentProgress = index.visibleSlidesIndexes[lastIndex] + 2;

            // only update slide progress for highest card index the user has seen
            if (currentProgress > slidesProgress && currentProgress <= cards.length) {
                setSlidesProgress(currentProgress);
            }

            const amplitudeData = getSimplifiedHorizontalScrollData(
                collectionTitle,
                cards,
                slidesProgress
            );
            if (responseAmplitudeData?.source === 'SERIES') {
                sendAmplitudeEvent(
                    CME_SERIES_ACTIVITIES_IN_THIS_SERIES_HORIZONTAL_SCROLL,
                    amplitudeData
                );
            } else if (responseAmplitudeData?.source === 'DISCOVER') {
                sendAmplitudeEvent(CME_COLLECTION_HORIZONTAL_SCROLL, amplitudeData);
            }
        },
    };

    if (!cards.length) return <></>;

    return (
        <SwiperContainer key={`series-collection-${collectionIndex}-` + collectionTitle}>
            <TitleAndArrowsContainer>
                <Typography
                    component="h3"
                    color={colors['--primary-midnight']}
                    sx={{
                        height: 24,
                        fontSize: 20,
                        fontWeight: 500,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.2,
                        letterSpacing: 'normal',
                        textAlign: 'left',
                    }}
                >
                    {collectionTitle}
                </Typography>
                {isDesktop && (
                    <ArrowButtonsContainer>
                        <NavArrowButton
                            id={`left-btn-${collectionIndex}-${collectionID}`}
                            type="button"
                            sx={{
                                marginRight: '16px',
                            }}
                            aria-label="Left Navigation Button"
                        >
                            <ArrowBackIosRounded />
                        </NavArrowButton>
                        <NavArrowButton
                            id={`right-btn-${collectionIndex}-${collectionID}`}
                            type="button"
                            aria-label="Right Navigation Button"
                        >
                            <ArrowForwardIosRounded />
                        </NavArrowButton>
                    </ArrowButtonsContainer>
                )}
            </TitleAndArrowsContainer>
            <Box width={'100%'} marginTop={'12px'} ref={collectionRef}>
                <Swiper
                    {...swiperParams}
                    style={{
                        width: 'auto',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    {cards.map((card: any, index: number) => (
                        <SwiperSlide
                            key={`card-${index}`}
                            style={{
                                width: isSeriesCard ? '302px' : '267px',
                                height: 'auto',
                                position: 'relative',
                                padding: '4px',
                            }}
                        >
                            {(slideProperties: SliderProperties) => (
                                <PolymorphicCard
                                    card={card}
                                    sliderProperties={slideProperties}
                                    responseAmplitudeData={responseAmplitudeData}
                                    collectionAmplitudeData={collectionAmplitudeData}
                                    cardIndex={index + 1}
                                    swiperRef={collectionRef}
                                    isProgramEndpoint={isProgramEndpoint}
                                />
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </SwiperContainer>
    );
};
