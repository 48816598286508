import { useSelector } from 'react-redux';
import { Typography, Box, styled } from '@mui/material';
import { strings } from '../utils/Strings';
import { ContainedButton, OutlinedButton } from './StyledButtons';
import colors from '../config/colorConfig';
import { sendAmplitudeEvent } from '../amplitude/AmplitudeUtil';
import { CME_LOGIN_CLICK, CME_CREATE_ACCOUNT_CLICK } from '../amplitude/analytics';
import { RootState } from '../store/store';
import { Tabs } from '../models/Tabs';

//
// The UserActionRecommendation component returns a
// prompt for users to login or create an account.
// The returned component depends on whether the
// isModal prop is true or false
//

export default function UserActionRecommendation({
    headingText,
    isModal,
}: {
    headingText: string;
    isModal: boolean;
}) {
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );

    const sourceTitle = Tabs[currentSourceTab];

    const saveAttemptId: string = useSelector((state: RootState) => state.saveAttempt.contentId);

    const isSearchActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSearchActive
    );
    const searchText: string | undefined = useSelector(
        (state: RootState) => state.displayState.searchText
    );

    const StyledContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            margin: '0 16px 0 16px',
        },
        [theme.breakpoints.down('md')]: {
            margin: '0 24px 0 24px',
        },
    }));

    const StyledHeading = styled(Typography)(({ theme }) => ({
        fontWeight: 300,
        fontSize: '24px',
        lineHeight: '26px',
        color: colors['--primary-midnight'],
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            marginTop: '48px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '40px',
        },
    }));

    const StyledParagraph = styled('p')(({ theme }) => ({
        fontWeight: 300,
        fontSize: '28px',
        lineHeight: '30px',
        textAlign: 'center',
        margin: '16px 0px 0px 0px',
        [theme.breakpoints.down('md')]: {
            fontSize: '24px',
            lineHeight: '26px',
            margin: '24px 0px 0px 0px',
        },
    }));

    const CenteredContainer = styled('div')({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    });

    const ButtonsContainer = styled('div')({
        maxWidth: '470px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    });

    const handleLogin = () => {
        const loginUrl: string = `${window.location.origin}/login?refernext=${window.location.href}`;
        let queryParamString: string = '';
        if (saveAttemptId) {
            queryParamString = queryParamString + `?saveAttempt=${saveAttemptId}`;

            if (isSearchActive && searchText) {
                queryParamString = queryParamString + `&searchText=${searchText}`;
            }
        }
        window.location.href = loginUrl + encodeURIComponent(queryParamString);
        sendAmplitudeEvent(CME_LOGIN_CLICK, { Source: sourceTitle });
    };

    const handleSignup = () => {
        const signupUrl: string = `${window.location.origin}/createaccount?refernext=${window.location.href}`;
        let queryParamString: string = '';
        if (saveAttemptId) {
            queryParamString = queryParamString + `?saveAttempt=${saveAttemptId}`;

            if (isSearchActive && searchText) {
                queryParamString = queryParamString + `&searchText=${searchText}`;
            }
        }
        window.location.href = signupUrl + encodeURIComponent(queryParamString);
        sendAmplitudeEvent(CME_CREATE_ACCOUNT_CLICK, { Source: sourceTitle });
    };

    const userRecScreenContent = (
        <StyledContainer>
            <StyledHeading variant="h1">{headingText}</StyledHeading>
            <img
                src="illustrations-cme-detailed-light-bg-cropped.svg"
                alt="medical items icon"
                width="100%"
            />
            <Box display="flex" justifyContent="center">
                <ContainedButton
                    type="button"
                    onClick={handleLogin}
                    height="39px"
                    width={['100%', '343px', '343px', '343px', '343px']}
                    margin="24px 0 0 0"
                >
                    {strings.login_wall_login}
                </ContainedButton>
            </Box>
            <Box display="flex" justifyContent="center">
                <OutlinedButton
                    type="button"
                    onClick={handleSignup}
                    margin="9px 0 0 0"
                    height="39px"
                    width={['100%', '343px', '343px', '343px', '343px']}
                >
                    {strings.login_wall_create_acct}
                </OutlinedButton>
            </Box>
        </StyledContainer>
    );

    const userRecModalContent = (
        <>
            <StyledParagraph>{strings.login_modal_body}</StyledParagraph>
            <img
                src="illustrations-cme-detailed-light-bg-cropped.svg"
                alt="medical items icon"
                width="100%"
            />
            <CenteredContainer>
                <ButtonsContainer
                    style={{
                        maxWidth: '470px',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <ContainedButton
                        type="button"
                        onClick={handleLogin}
                        height="39px"
                        width="100%"
                        margin="0px !important"
                    >
                        {strings.login_wall_login}
                    </ContainedButton>
                    <OutlinedButton
                        type="button"
                        onClick={handleSignup}
                        margin="9px 0 0 0"
                        height="39px"
                        width="100%"
                    >
                        {strings.login_wall_create_acct}
                    </OutlinedButton>
                </ButtonsContainer>
            </CenteredContainer>
        </>
    );

    return isModal ? userRecModalContent : userRecScreenContent;
}
