import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { sendFrontMatterContinueClickEvent } from '../../amplitude/sendFrontMatterContinueClickEvent';
import { awaitAllOutstandingEvents } from '../../amplitude/AmplitudeUtil';
import { FrontMatterHeaderSection } from './FrontMatterHeaderSection';
import { FrontMatterButtonSection } from './FrontMatterButtonSection';
import { useEffect } from 'react';
import { sendFrontMatterViewEvent } from '../../amplitude/sendFrontMatterViewEvent';
import { GetFrontMatterScreen } from '../../queries/FrontMatterScreenQuery';
import { LoadingSpinner } from '../LoadingSpinner';
import Modal from '../Modal/Modal';
import { executeLaunchAction } from '../../services/launchActionService';
import { ShowErrorModal } from '../Error/ShowErrorModal';
import { strings } from '../../utils/Strings';
import { FrontMatterContainer, FrontmatterLoadingContainer } from './frontMatterStyledComponents';

//
// Dynamic FrontMatter displays activity frontmatter content dynamically
// based on responses from the GetFrontMatterScreen endpoint
//

export default function DynamicFrontMatter() {
    const { activityCode } = useParams();
    const dispatch = useDispatch();

    let frontMatterScreenResponse: any = null;
    frontMatterScreenResponse = GetFrontMatterScreen(activityCode as unknown as string);

    const handleContinueClick = async () => {
        let executeLaunchActionURL: string = '';
        try {
            if (frontMatterScreenResponse?.data?.data?.clickAction) {
                const getLaunchActionUrl = async (launchActionURL: string) => {
                    executeLaunchActionURL = launchActionURL;
                    if (executeLaunchActionURL) {
                        const continueClickData = {
                            url: executeLaunchActionURL,
                            activityId: activityCode,
                            title: frontMatterScreenResponse?.data?.data?.title?.plain,
                            creditAmount: frontMatterScreenResponse?.data?.data?.creditText?.plain,
                            duration: frontMatterScreenResponse?.data?.data?.durationInMinutes,
                            activityType: frontMatterScreenResponse?.data?.data?.activityType,
                            mediaType: frontMatterScreenResponse?.data?.data?.mediaType[0],
                        };
                        await sendFrontMatterContinueClickEvent(continueClickData);
                        await awaitAllOutstandingEvents();
                    }
                };
                const action = await executeLaunchAction(
                    frontMatterScreenResponse?.data?.data?.clickAction,
                    { sameWindow: true },
                    getLaunchActionUrl
                );
                if (!action.launched) {
                    ShowErrorModal(strings.error_content, dispatch);
                }
            }
        } catch (error) {
            ShowErrorModal(error, dispatch, strings.error_action);
        }
    };

    useEffect(() => {
        const launchImmediately = async () => {
            try {
                await executeLaunchAction(frontMatterScreenResponse?.data?.data?.clickAction, {
                    sameWindow: true,
                });
            } catch (error) {
                ShowErrorModal(error, dispatch, strings.error_action);
            }
        };

        if (frontMatterScreenResponse?.data?.data?.launchImmediately) {
            launchImmediately();
        }

        if (
            frontMatterScreenResponse?.data?.data &&
            !frontMatterScreenResponse?.data?.data?.launchImmediately
        )
            sendFrontMatterViewEvent(
                activityCode || '',
                frontMatterScreenResponse?.data?.data?.title?.plain
            );
    }, [frontMatterScreenResponse?.data]);

    if (
        frontMatterScreenResponse?.isLoading ||
        frontMatterScreenResponse?.data?.data?.launchImmediately
    ) {
        return (
            <FrontmatterLoadingContainer>
                <Modal />
                <LoadingSpinner />
            </FrontmatterLoadingContainer>
        );
    }

    if (frontMatterScreenResponse?.error) {
        ShowErrorModal(frontMatterScreenResponse?.error, dispatch, strings.error_loading);
    }

    return (
        <>
            <FrontMatterContainer>
                <Modal />
                <FrontMatterHeaderSection
                    accreditationText={
                        frontMatterScreenResponse?.data?.data?.accreditationText?.plain
                    }
                    title={frontMatterScreenResponse?.data?.data?.title?.plain}
                    creditText={frontMatterScreenResponse?.data?.data?.creditText?.plain}
                    launchText={frontMatterScreenResponse?.data?.data?.launchText?.plain}
                    expirationText={frontMatterScreenResponse?.data?.data?.expirationText?.plain}
                />
                <FrontMatterButtonSection
                    buttonText={frontMatterScreenResponse?.data?.data?.buttonText?.plain}
                    disclaimerText={frontMatterScreenResponse?.data?.data?.disclaimerText?.plain}
                    handleClick={handleContinueClick}
                />
                <>{parse(frontMatterScreenResponse?.data?.data?.frontMatterText?.html)}</>
                <FrontMatterButtonSection
                    buttonText={frontMatterScreenResponse?.data?.data?.buttonText?.plain}
                    disclaimerText={frontMatterScreenResponse?.data?.data?.disclaimerText?.plain}
                    handleClick={handleContinueClick}
                />
            </FrontMatterContainer>
        </>
    );
}
