import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../config/colorConfig';
import { AvailableUserFilter, CatalogCardsData } from '../../models/CatalogCardsResponse';
import { ActiveFilterInformation, FilterState, updateFilterState } from '../../Slices/FilterSlice';
import { RootState } from '../../store/store';
import { Desktop } from '../../utils/responsive';
import { AllFilters, getAmplitudeFilterData } from './AllFilters';
import { FilterType } from '../../models/FilterType';
import { QuickFilterOverlay } from './QuickFilter/QuickFilterOverlay';
import { sendAmplitudeEvent } from '../../amplitude/AmplitudeUtil';
import { CME_FILTER_CLOSE, CME_QUICKFILTER_CLOSE } from '../../amplitude/analytics';

export interface FilterScreenOverlayProps {
    filterScreenData: CatalogCardsData;
}

export async function filterEmptySpaceClickHandler(
    dispatch: any,
    filterState: FilterState,
    currentSourceTab: number
) {
    if (filterState.activeFilterInformation.filterType === FilterType.QuickFilter)
        sendAmplitudeEvent(
            CME_QUICKFILTER_CLOSE,
            await getAmplitudeFilterData(filterState, currentSourceTab)
        );
    else
        sendAmplitudeEvent(
            CME_FILTER_CLOSE,
            await getAmplitudeFilterData(filterState, currentSourceTab)
        );
    dispatch(updateFilterState({ isActive: false, filterType: FilterType.None, filterName: '' }));
    return;
}

export const FilterScreenOverlay = (props: FilterScreenOverlayProps) => {
    const filterState: FilterState = useSelector((state: RootState) => state.filter);
    const currentSourceTab: number = useSelector(
        (state: RootState) => state.displayState.activeTab
    );
    const dispatch = useDispatch();

    const activeFilterInformation: ActiveFilterInformation = useSelector(
        (state: RootState) => state.filter.activeFilterInformation
    );

    const [filterScreen, setFilterScreen] = useState<boolean>(false);

    let filterScreenData: CatalogCardsData = props?.filterScreenData;

    useEffect(() => {
        setFilterScreen(activeFilterInformation.isActive);
    }, [activeFilterInformation.isActive]);

    let selectedQuickFilterData: AvailableUserFilter | null = null;
    let availableUserFilters: AvailableUserFilter[] = [];
    if (filterScreen && activeFilterInformation.filterType === FilterType.QuickFilter) {
        availableUserFilters = filterScreenData?.availableUserFilters;
        for (let i = 0; i < availableUserFilters?.length; i++) {
            if (availableUserFilters[i].filterName === activeFilterInformation.filterName) {
                selectedQuickFilterData = availableUserFilters[i];
            }
        }
    }

    const viewResultCount = filterScreenData?.results?.returnedCardCount;

    const emptySpaceClickHandler = () => {
        filterEmptySpaceClickHandler(dispatch, filterState, currentSourceTab);
    };

    const desktop = Desktop();

    return (
        <>
            {filterScreen ? (
                <>
                    {activeFilterInformation.filterType === FilterType.QuickFilter ? (
                        desktop && (
                            <QuickFilterOverlay
                                availableUserFilter={selectedQuickFilterData}
                                ViewResultsCount={viewResultCount}
                            />
                        )
                    ) : (
                        <AllFilters filterScreenData={props.filterScreenData} />
                    )}
                </>
            ) : (
                <></>
            )}
            {filterScreen ? (
                <div
                    onClick={emptySpaceClickHandler}
                    onKeyDown={emptySpaceClickHandler}
                    role="button"
                    aria-hidden="true"
                    style={{
                        position: 'fixed',
                        display: 'block',
                        width: '100%',
                        height: '100vmax',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: colors['--scrim-scrim-25'],
                        zIndex: 2,
                    }}
                ></div>
            ) : (
                <></>
            )}
        </>
    );
};
