import { Box, LinearProgress, Typography, styled } from '@mui/material';

import colors, { colorLookup } from '../../../config/colorConfig';
import { normalizePercentage } from '../../../utils/normalizePercentage';
import { classPrefix } from '../../../utils/constants';

type HistogramProps = {
    histogramPercent: number | null;
    histogramColor?: null | undefined | string;
    histogramBackgroundColor?: null | undefined | string;
};

export function Histogram({
    histogramPercent,
    histogramColor,
    histogramBackgroundColor,
}: HistogramProps) {
    const StyledHistogram = styled(LinearProgress)({
        height: 24,
        borderRadius: 4,
        backgroundColor: colorLookup(histogramBackgroundColor ?? colors['--ui-grey-light']),
        [`& .${classPrefix}-MuiLinearProgress-bar`]: {
            borderRadius: 4,
            backgroundColor: colorLookup(histogramColor ?? colors['--ui-grey']),
        },
    });

    if (histogramPercent == null || undefined) return <></>;

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <StyledHistogram
                        variant="determinate"
                        value={Math.max(1, normalizePercentage(histogramPercent))}
                    />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography
                        fontSize="16px"
                        lineHeight="20px"
                        fontWeight={500}
                        color={colors['--primary-midnight']}
                        textAlign="center"
                    >{`${Math.max(0, normalizePercentage(histogramPercent))}%`}</Typography>
                </Box>
            </Box>
        </>
    );
}
