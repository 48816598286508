import { IRationaleInformation, MultiFormatText } from './Primitives';
import { ContextId, OptionId, QuizId, QuestionId, QuestionLabel } from './IdTypes';
import { DisplayChip, IDisplayChip } from './FrontEndCommonStructures';
import { INavigationButton, IQuizNavigationButton, ParticipationMode } from './EPOLMSModels';

export enum QuizIntent {
    Submit = 'submit',
    Resume = 'resume',
    Review = 'review',
}

export enum FreeText {
    MultiLine = 'Multi-Line',
    SingleLine = 'Single-Line',
}

export interface QuizQuestion {
    questionId: QuestionId;
    questionLabel: QuestionLabel;
}

export interface QuizQuestionAnswer extends QuizQuestion {
    writeIn: string | null;
    choice: [QuizChoice] | null;
}

export interface QuizChoice {}

export interface ScoreCardAttemptSpecifier {
    caption: MultiFormatText;
    isActive: boolean;
    quizIntent: string; // opaque, pass to the "intent" request body param; typically "view:final-attempt" or "view:first-attempt", or similar
}

export interface QuizScoreCardData {
    attempts: null | ScoreCardAttemptSpecifier[];
    userScorePercent: number | null; // on 0..100
    userScoreColor: string | null;
    userScoreBackgroundColor: string | null;
    peerScorePercent: number | null; // on 0..100
    peerScoreColor: string | null;
    peerScoreBackgroundColor: string | null;
    requiredScorePercent: number | null; // on 0..100
    requiredScoreColor: string | null;
    requiredScoreBackgroundColor: string | null;
    completedLearnersText: MultiFormatText | null; // e.g. "794 Learners Completed this Activity"
}

export interface IQuizTransactionResponse {
    quizId: QuizId;
    attemptsMade: number;
    attemptsRemaining: number;
    attemptsText: null | MultiFormatText;
    isAdvanceable: boolean; // can enclosing context show a 'Continue' button that advances to the next *slide*?
    canAdvanceSpontaneously: boolean; // should enclosing content advance immediately to the next slide at its earliest convenience?
    isEphemeral: boolean; // true whenever the session is ephemeral (not being persisted to db)
    isConcluded: boolean; // true whenever the quiz is done, either passed or failed
    lastScoreText: null | MultiFormatText;
    requiredScoreText: null | MultiFormatText;
    statusChip: StatusChip | null; // whenever there are problems with the response submission or failure to pass
    scoreCard: null | QuizScoreCardData;
    questions: IFrontendQuizQuestion[];
    session: string;
    resultCard: null | ResultCard;
    continueButton: IQuizNavigationButton | null;
}

export interface ResultCard {
    caption: MultiFormatText;
    body: MultiFormatText;
    conclusionBody: MultiFormatText | undefined;
    button: INavigationButton;
}

export interface ILikertScaleNestedQuestion {
    questionId: QuestionId;
    stem: MultiFormatText;
    isReadOnly: boolean;
    isRequired: boolean;
    userSelection?: string | null;
    highlightMode?: ElementHighlightMode | null;
}

export interface ILikertScaleQuestion {
    scaleLeftLabel: MultiFormatText;
    scaleRightLabel: MultiFormatText;
    scaleValues: string[]; // typically ['1', '2', '3', '4', '5']
    nestedQuestions: ILikertScaleNestedQuestion[];
}

export interface ErrorChip extends DisplayChip {
    icon?: null | string;
}

export interface IFreeTextChoiceQuestion {
    textEntrySize: QuizTextEntrySize;
    userEntered?: string | null;
}

export enum QuizTextEntrySize {
    SingleLine = 'Single-Line',
    MultiLine = 'Multi-Line',
}

export enum QuizWriteInConfig {
    None = 'None',
    SingleLine = 'Single-Line',
    MultiLine = 'Multi-Line',
}

export interface IHistogramInformation {
    histogramPercent: null | number; // 0...100 or null
    histogramColor: null | string; // color name/code
    histogramBackgroundColor: null | string;
}

export interface IMultiChoiceOption {
    optionId: OptionId;
    label: string;
    text: MultiFormatText;
    userSelected: boolean;
    userEntered?: string | null;
    isMarkedCorrect: boolean; // for grading phase
    isMarkedIncorrect: boolean; // for grading phase
    highlightMode: ElementHighlightMode | null;
    rationale: null | IRationaleInformation;
    writeIn: QuizWriteInConfig;
    histogramInformation: IHistogramInformation | null;
    responseChip: IDisplayChip | null;
}

export interface IMultipleChoiceQuestion {
    canSelectMultiple: boolean;
    rationale: null | IRationaleInformation;
    options: IMultiChoiceOption[];
}

export interface StatusChip extends DisplayChip {
    icon?: null | string;
    caption?: null | string;
}

export enum ElementHighlightMode {
    None = 'none', // no mark
    Invalid = 'invalid', // input error/missing
    Chosen = 'chosen', // non-judgemental you-chose-this
    Missed = 'missed', // should have selected, but didn't
    Incorrect = 'incorrect', // selected, but shouldn't have
    Correct = 'correct', // selected and was correct
}

export interface IFrontendQuizQuestion {
    questionId: QuestionId;
    labelText: MultiFormatText; // E.g. 'Question 1'
    debugLabel: string; // E.g. 'Question 1', but always a plain tring; for use respons submissions, amplitude, graylog, etc
    isReadOnly: boolean; // show prior selections/annotations but don't allow the user to change
    isRequired: boolean; //
    isMarkedCorrect: boolean; // for grading purposes, this is true iff the user has answered this question correctly
    isMarkedIncorrect: boolean; // for grading purposes, true if user answered this incorrectly
    requiredChip?: DisplayChip | null;
    statusChip?: StatusChip | null;
    highlightMode?: ElementHighlightMode | null;
    stem: MultiFormatText;

    // Only one of these will be defined on a given question:
    freeText: null | IFreeTextChoiceQuestion;
    multipleChoice: null | IMultipleChoiceQuestion;
    likertScale: null | ILikertScaleQuestion;
}

export interface IQuizQuestionUserResponseChoice {
    optionId: string;
    optionValue: string;
    writeInValue?: string | undefined;
}

export interface IQuizQuestionUserResponse {
    questionId: QuestionId; // EQxxxxxxxxxx unique question id
    questionLabel: string; // user/debug-friendly label like '7' or '3.7' for a nested question
    choices?: undefined | IQuizQuestionUserResponseChoice[]; // typically only 1, but multi-select could result in several
    nested?: undefined | IQuizQuestionUserResponse[]; // For likert-scale or other nested/combined questions
    writeIn?: undefined | string;
}

export interface IQuizAttemptSubmission {
    responses: IQuizQuestionUserResponse[] | {}[];
}

export interface IQuizTransactionRequest {
    quizId: QuizId;
    contextId?: undefined | null | ContextId; // Optional, but links the quiz participation to a program participation
    intent?: undefined | QuizIntent;
    mode?: undefined | ParticipationMode;
    submission?: undefined | IQuizAttemptSubmission;
    session?: undefined | null | string;
}
