import { useEffect, useRef } from 'react';
import { HorizontalSwiper } from './HorizontalSwiper';
import { CollectionAmplitudeState, ResponseAmplitudeState } from '../models/AmplitudeState';
import { sendDiscoverCollectionViewAmplitudeEvent } from '../amplitude/sendDiscoverAmplitudeEvent';

//
// HorizontalScrollCollection displays non-expandable cards in a swiper component
//

interface HorizontalScrollCollectionProps {
    isSeriesCard?: boolean;
    collectionIndex: number;
    collection: any;
    responseAmplitudeData: ResponseAmplitudeState;
    isProgramEndpoint?: boolean;
}

export default function HorizontalScrollCollection({
    isSeriesCard,
    collectionIndex,
    collection,
    responseAmplitudeData,
    isProgramEndpoint,
}: HorizontalScrollCollectionProps) {
    const { title, activities, activityGroupId, cards } = collection;
    const discoverCollectionRef = useRef<HTMLDivElement>(null);

    const collectionAmplitudeData: CollectionAmplitudeState = {
        collectionID: activityGroupId,
        collectionIndex: collectionIndex,
        collectionName: collection?.title,
        collectionType: collection?.subtypeId,
        collectionProcessID: collection?.processInstanceId,
        collectionProcessVersionID: collection?.processVersionId,
    };

    let isVisible: boolean = false;

    const updateVisibleState = () => {
        const discoverCollectionRect: DOMRect | undefined =
            discoverCollectionRef?.current?.getBoundingClientRect();
        if (
            discoverCollectionRect &&
            discoverCollectionRect?.y < window.innerHeight &&
            ((discoverCollectionRect?.y > 0 &&
                (window.innerHeight - discoverCollectionRect?.y) / discoverCollectionRect?.height >
                    0.5) ||
                (discoverCollectionRect?.y <= 0 &&
                    discoverCollectionRect?.bottom / discoverCollectionRect?.height > 0.5))
        ) {
            if (!isVisible) {
                isVisible = true;
                sendDiscoverCollectionViewAmplitudeEvent(
                    title,
                    activityGroupId,
                    isSeriesCard ? cards.length : activities.length,
                    collectionIndex
                );
            }
        } else {
            if (isVisible) {
                isVisible = false;
            }
        }
    };

    useEffect(() => {
        updateVisibleState();
    }, [discoverCollectionRef.current]);

    const handleScroll = (event: any) => {
        let isHorizontalScroll: boolean = false;
        if (event.path?.length === 2) {
            isHorizontalScroll = false;
        } else {
            isHorizontalScroll = true;
        }
        if (isHorizontalScroll) {
            return;
        } else {
            updateVisibleState();
        }
    };

    window.addEventListener('scroll', handleScroll, true);

    const cmeCollection = (
        <>
            <HorizontalSwiper
                collectionIndex={collectionIndex}
                cards={isSeriesCard ? cards : activities}
                collectionTitle={title}
                collectionID={activityGroupId}
                isSeriesCard={isSeriesCard}
                responseAmplitudeData={responseAmplitudeData}
                collectionAmplitudeData={collectionAmplitudeData}
                collectionRef={discoverCollectionRef}
                isProgramEndpoint={isProgramEndpoint}
            />
        </>
    );
    return cmeCollection;
}
