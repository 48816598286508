/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from '../../config/colorConfig';
import { theme } from '../../styles/theme';
import { LargeDesktop } from '../../utils/responsive';

interface EPOLMSHeaderProps {
    fromText: string | undefined;
    title: string | undefined;
    releaseDate: string | undefined;
    expiresDate: string | undefined;
    creditText: string | undefined;
    durationText: string | undefined;
    creditType: string | undefined;
    imageUrl: string | undefined;
}

export const EPOLMSHeader = ({
    fromText,
    title,
    releaseDate,
    expiresDate,
    creditText,
    durationText,
    creditType,
    imageUrl,
}: EPOLMSHeaderProps) => {
    const FromHeader = styled('h6')({
        color: colors['--ui-grey'],
        fontFamily: 'Metric',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '11px',
        lineHeight: '12px',
        marginBottom: '8px',
    });

    const ActivityInfoHeader = styled('h6')({
        color: colors['--ui-grey'],
        fontFamily: 'Metric',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        marginBottom: '0px',
    });

    const HeaderLayout = styled(Grid)(() => ({
        [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '32px',
            paddingTop: '24px',
            alignItems: 'center',
        },
        flexDirection: 'row',
        paddingBottom: '24px',
    }));

    const releaseDateDisplayText: string = `${releaseDate}`;
    const expiresDateDisplayText: string = `${expiresDate}`;
    const creditDisplayText: string = `${creditText}`;
    const durationDisplayText: string = `${durationText}`;

    const Title = styled('h2')({
        color: colors['--primary-midnight'],
        fontFamily: 'Metric',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '24px',
        lineHeight: '26px',
        marginBottom: '16px',
        [theme.breakpoints.down('xl')]: {
            fontSize: '20px',
            marginBottom: '8px',
            textAlign: 'center',
        },
    });
    const DividingEllipsis = styled('div')({
        width: '4px',
        height: '4px',
        backgroundColor: colors['--ui-grey'],
        borderRadius: '50%',
    });
    interface ProgramInformationProps {
        text: string;
        isDotVisible?: boolean;
        marginRight?: string;
    }
    const ProgramInformationWrapper = styled(Grid)({
        [theme.breakpoints.down('xl')]: {
            gap: '4px',
        },
        gap: '8px',
    });

    const ProgramInformation = ({ text, isDotVisible = true }: ProgramInformationProps) => {
        if (!text) {
            return <></>;
        }
        return (
            <ProgramInformationWrapper container direction="row" alignItems="center">
                <Grid item>
                    <ActivityInfoHeader> {text} </ActivityInfoHeader>
                </Grid>
                {isDotVisible && (
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <DividingEllipsis />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </ProgramInformationWrapper>
        );
    };

    if (!title) {
        return <></>;
    }

    const ThumbnailWrapper = styled(Grid)(() => ({
        [theme.breakpoints.up('xl')]: {
            marginRight: '16px',
        },
        [theme.breakpoints.down('xl')]: {
            marginBottom: '16px',
        },
    }));

    const Thumbnail = styled('img')(() => ({
        [theme.breakpoints.up('xl')]: {
            borderRadius: '4px',
            width: '152px',
        },
        [theme.breakpoints.down('xl')]: {
            width: '552px',
        },
        [theme.breakpoints.down('md')]: {
            width: '375px',
            height: '120px',
        },
        height: '120px',
    }));

    const HeaderInfoWrapper = styled(Grid)(() => ({
        [theme.breakpoints.up('xl')]: {
            marginRight: '16px',
            alignItems: 'flex-start',
        },
        [theme.breakpoints.down('xl')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        justifyContent: 'center',
        alignItems: 'center',
    }));
    const ProgramInfoWrapper = styled(Grid)(() => ({
        [theme.breakpoints.down('xl')]: {
            justifyContent: 'center',
        },
    }));

    const HeaderContainer = styled(Grid)(() => ({
        [theme.breakpoints.up('xl')]: {
            maxWidth: '793px',
            alignItems: 'flex-start',
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '552px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '375px',
        },
        alignItems: 'center',
    }));

    const ProgramInformationDesktop = () => {
        return (
            <>
                <Grid container direction="row" gap="8px">
                    <Grid item>
                        {releaseDate && <ProgramInformation text={releaseDateDisplayText} />}
                    </Grid>
                    <Grid item>
                        {expiresDate && <ProgramInformation text={expiresDateDisplayText} />}
                    </Grid>
                    <Grid item>
                        {creditText && <ProgramInformation text={creditDisplayText} />}
                    </Grid>
                    <Grid item>
                        {durationText && <ProgramInformation text={durationDisplayText} />}
                    </Grid>
                    <Grid item>
                        {creditType && (
                            <ActivityInfoHeader> Credit Type: {creditType}</ActivityInfoHeader>
                        )}
                    </Grid>
                </Grid>
            </>
        );
    };

    const ProgramInformationNonDesktop = () => {
        return (
            <>
                <Grid container direction="column" gap="8px">
                    <Grid item>
                        <Grid container direction="row" justifyContent={'center'} gap="4px">
                            <Grid item>
                                {releaseDate && (
                                    <ProgramInformation text={releaseDateDisplayText} />
                                )}
                            </Grid>
                            <Grid item>
                                {expiresDate && (
                                    <ProgramInformation text={expiresDateDisplayText} />
                                )}
                            </Grid>
                            <Grid item>
                                {creditText && (
                                    <ProgramInformation
                                        text={creditDisplayText}
                                        isDotVisible={false}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent={'center'} gap="4px">
                            <Grid item>
                                {durationText && <ProgramInformation text={durationDisplayText} />}
                            </Grid>
                            <Grid item>
                                {creditType && (
                                    <ActivityInfoHeader>
                                        {' '}
                                        Credit Type: {creditType}
                                    </ActivityInfoHeader>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <HeaderLayout container>
            <ThumbnailWrapper item>
                <Thumbnail src={imageUrl} alt="Thumbnail" />
            </ThumbnailWrapper>
            <HeaderInfoWrapper item>
                <HeaderContainer container direction="column">
                    <Grid item>{fromText && <FromHeader>{fromText} </FromHeader>}</Grid>
                    <Grid item> {title && <Title>{title} </Title>} </Grid>
                    <Grid item>
                        <ProgramInfoWrapper container direction="row"></ProgramInfoWrapper>
                        {LargeDesktop() ? (
                            <ProgramInformationDesktop />
                        ) : (
                            <ProgramInformationNonDesktop />
                        )}
                    </Grid>
                </HeaderContainer>
            </HeaderInfoWrapper>
        </HeaderLayout>
    );
};
