import { styled } from '@mui/material';
import { IFrontendCreditOption } from '../../../models/CreditTransactionService';
import { renderMultiFormatText } from './CreditOptionContainer';
import colors from '../../../config/colorConfig';
import { frontMatterRenderOptions } from '../frontmatter/renderOptions';
import { strings } from '../../../utils/Strings';

export function CreditOptionKeyStatsTable({
    creditOption,
}: {
    creditOption: IFrontendCreditOption;
}) {
    const StyledTableBody = styled('tbody')(({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: `25% 25% 25% 15% 10%`,
        justifyContent: 'space-between',
        fontFamily: 'Metric',
        fontSize: '18px',
        [theme.breakpoints.down('xl')]: {
            gridTemplateColumns: '1fr',
            gap: '16px',
        },
    }));

    const StyledTableRow = styled('tr')({
        display: 'flex',
        flexDirection: 'column',
    });

    const StyledTableHeader = styled('th')({
        margin: 0,
        fontWeight: 500,
        color: colors['--ui-grey'],
    });

    const StyledTableData = styled('td')({});

    return (
        <table>
            <StyledTableBody>
                <StyledTableRow>
                    <StyledTableHeader>
                        {strings.credit_claim_accredited_provider}
                    </StyledTableHeader>
                    <StyledTableData
                        sx={{
                            display: 'flex',
                            alignContent: 'flex-start',
                            justifyItems: 'center',
                            marginBottom: '-16px',
                        }}
                    >
                        {renderMultiFormatText(
                            creditOption.accreditedProvider,
                            frontMatterRenderOptions
                        )}
                    </StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader>{strings.credit_claim_accrediting_body}</StyledTableHeader>
                    <StyledTableData>{creditOption.accreditingBody}</StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader>{strings.credit_claim_certification}</StyledTableHeader>
                    <StyledTableData>{creditOption.certification}</StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader>{strings.credit_claim_type}</StyledTableHeader>
                    <StyledTableData>{creditOption.classification}</StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableHeader>{strings.credit_claim_unit}</StyledTableHeader>
                    <StyledTableData>{creditOption.quantity?.creditUnit}</StyledTableData>
                </StyledTableRow>
            </StyledTableBody>
        </table>
    );
}
