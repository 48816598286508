import { memo, useEffect, useState } from 'react';
import { FormControl, TextField as MaterialTextField, FormHelperText } from '@mui/material';
import colors from '../../config/colorConfig';

interface State {
    validationError: string;
    validationInformation: string;
    isError: boolean;
    value: any;
}

const TextFieldBeforeMemo = (props: any) => {
    const [values, setValues] = useState<State>({
        validationError: '',
        validationInformation: '',
        isError: false,
        value: props.value,
    });

    const validateField = (event: any) => {
        const { validation } = props;
        if (props.validation) {
            const validMessage = validation(event.target.value);
            if (validMessage) {
                setValues({
                    ...values,
                    validationError: validMessage,
                    isError: true,
                    value: event.target.value,
                });
            } else {
                setValues({
                    ...values,
                    validationError: '',
                    isError: false,
                    value: event.target.value,
                });
            }
        } else {
            setValues({
                ...values,
                validationError: '',
                isError: false,
                value: event.target.value,
            });
        }
    };

    useEffect(() => {
        const event = { target: { value: props.value ?? '' } };
        validateField(event);
    }, props.revalidateOnChanged || []);

    useEffect(() => {
        if (props.value !== undefined || props.value !== null) {
            setValues({ ...values, value: props.value });
        }
    }, [props.value]);

    useEffect(() => {
        if (props.validationError) {
            setValues({
                ...values,
                validationError: props.validationError,
                isError: true,
                value: props.value,
            });
        } else {
            setValues({
                ...values,
                validationError: '',
                isError: false,
                value: props.value,
            });
        }
    }, [props.validationError]);
    useEffect(() => {
        if (props.validationInformation) {
            setValues({
                ...values,
                validationInformation: props.validationInformation,
                isError: false,
            });
        } else {
            setValues({ ...values, validationInformation: '', isError: false });
        }
    }, [props.validationInformation]);
    return (
        <FormControl required fullWidth>
            <MaterialTextField
                required={props.isRequired !== 'false'}
                error={values.isError}
                id={props.name}
                name={props.name}
                type={props.type}
                placeholder={props.label}
                data-testid={props.testId}
                label={props.label}
                inputProps={{
                    maxLength: 100,
                    id: `${props.testId}_input`,
                    autoCapitalize: props.autoCapitalize,
                }}
                InputLabelProps={{
                    shrink: props.shrink,
                }}
                onClick={props.onClickField}
                onBlur={validateField}
                onChange={validateField}
                variant="outlined"
                defaultValue={values.value}
                value={values.value}
            />
            {values.validationError ? (
                <FormHelperText
                    data-testid={props.helpTestId}
                    style={{ color: colors['--failure'], paddingLeft: '14px' }}
                >
                    {values.validationError || ' '}
                </FormHelperText>
            ) : (
                <FormHelperText
                    data-testid={props.helpTestId}
                    style={{ color: colors['--ui-slate'], paddingLeft: '14px' }}
                >
                    {values.validationInformation || ' '}
                </FormHelperText>
            )}
        </FormControl>
    );
};
export const TextField = memo(TextFieldBeforeMemo);
