import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import environment, { prod } from '../utils/environment';
import DynamicFrontMatter from './FrontMatter/DynamicFrontMatter';
import StaticFrontMatter from './FrontMatter/StaticFrontMatter';
import SavedScreen from './SavedScreen';
import { LoginForm } from '../components/LoginForm';
import { ErrorBoundary } from 'react-error-boundary';
import { SeriesPage } from './Series/SeriesPage';
import { theme } from '../styles/theme';
import { Authorizeable } from '../containers/Authorizeable';
import CatalogScreen from './CatalogScreen';
import { CompletedActivityScreen } from './CompletedActivityScreen';
import { ErrorFallback } from './Error/ErrorFallback';
import { NoMatch } from './Error/NoMatch';
import DiscoverScreen from './DiscoverScreen';
import { EPOLMSProgamExecutor } from './epolms/EPOLMSProgramExecutor';
import { SSORoutes } from './SSORoutes';
import MetaTags from 'react-meta-tags';
import ActivityRedirect from './ActivityRedirect';

const Redirect = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(props?.to);
    }, []);
    return <></>;
};

const siteTitle = 'epocrates Continuing Medical Education (CME)';
const siteDescription =
    'Continuing Medical Education (CME) programs, activities, live-events, and credit-earning opportunities ' +
    'for physicians and healthcare providers to stay up-to-date on their accreditation requirements, presented by epocrates. ' +
    'Discover personalized CME activities or browse and search the complete epocrates CME catalog.';
const siteKeywords =
    'CME, Continuing Medical Education, Continued Medical Education, Education, Doctors, Nurses, Physicians, Credit, Credits, ' +
    'Live Events, Seminars, Podcasts, Medicine, Medical, Healthcare, Healthcare Providers, Earn Credits, Certification, ' +
    'Board Certification, Accreditation, AMA PRA Category 1';

const App = () => {
    return (
        <div
            style={{
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'absolute',
            }}
        >
            <MetaTags>
                <title>{siteTitle}</title>
                <meta id="meta-description" name="description" content={siteDescription} />
                <meta id="og-title" property="og:title" content={siteTitle} />
                <meta id="dc-title" property="DC.title" content={siteTitle} />
                <meta id="meta-keywords" property="keywords" content={siteKeywords} />
                <meta id="meta-robots" property="robots" content="index, follow" />
            </MetaTags>
            <ThemeProvider theme={theme}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Routes>
                        {!prod() && (
                            <>
                                <Route path="" element={<Redirect to={'/cme/discover'} />} />
                            </>
                        )}
                        <Route path="/cme/catalog" element={<CatalogScreen />} />
                        <Route path="/cme/discover" element={<DiscoverScreen />} />
                        <Route path="/cme/saved" element={<SavedScreen />} />
                        <Route
                            path="/cme/series/:seriesPageId"
                            element={<Authorizeable element={<SeriesPage />} />}
                        />
                        <Route path="/cme/sso/*" element={<SSORoutes />} />
                        <Route
                            path="/cme/completed-activity"
                            element={<CompletedActivityScreen />}
                        />
                        <Route
                            path="cme/activity/achl-cme-4521/frontmatter"
                            element={<Authorizeable element={<StaticFrontMatter />} />}
                        />
                        <Route
                            path="cme/activity/:activityCode/frontmatter"
                            element={<Authorizeable element={<DynamicFrontMatter />} />}
                        />
                        <Route
                            path="cme/redirect/activity/:activityId"
                            element={<ActivityRedirect />}
                        />

                        {environment() !== 'PROD' ? (
                            <Route
                                path="/cme/program/:programId"
                                element={<Authorizeable element={<EPOLMSProgamExecutor />} />}
                            />
                        ) : undefined}

                        {/* When developing locally, include a standin login form; when deployed, use the shared core-web version */}
                        {environment() === 'LOCAL' ? (
                            <>
                                <Route path="/login" element={<LoginForm />} />
                                <Route path="/logout" element={<LoginForm logoutFirst />} />
                            </>
                        ) : undefined}
                        {/* Fall-through to 404 */}
                        <Route path="cme/not-found" element={<NoMatch />} />
                        <Route path="*" element={<NoMatch />} />
                    </Routes>
                </ErrorBoundary>
            </ThemeProvider>
        </div>
    );
};

export default App;
