import { styled } from '@mui/material';

export const LoadingSpinner = () => {
    const AnimatedTopSemiCircle = styled('div')(({ theme }) => ({
        position: 'absolute',
        top: '0%',
        left: '0%',
        width: '100px',
        height: '50px',
        borderRadius: '150px 150px 0 0',
        background: '#0093C6',
        transformOrigin: 'bottom center',
        animation: 'rotateTop 2s infinite',
        zIndex: 200,
        '@keyframes rotateTop': {
            from: {
                transform: 'rotate(360deg)',
            },
            to: {
                transform: 'rotate(0deg)',
            },
        },
        [theme.breakpoints.down('md')]: {
            width: '64px',
            height: '32px',
        },
    }));

    const AnimatedBottomSemiCircle = styled('div')(({ theme }) => ({
        position: 'absolute',
        bottom: '0%',
        left: '0%',
        width: '100px',
        height: '50px',
        borderRadius: '0 0 150px 150px',
        background: '#0093C6',
        transformOrigin: 'top center',
        animation: 'rotateTop 2s infinite',
        zIndex: 200,
        [theme.breakpoints.down('md')]: {
            width: '64px',
            height: '32px',
        },
    }));

    const LowercaseELogoTop = styled('img')(({ theme }) => ({
        position: 'absolute',
        zIndex: 700,
        top: '50%',
        left: '26%',
        [theme.breakpoints.down('md')]: {
            width: '29.72px',
            height: '32px',
        },
    }));

    const LowercaseELogoBottom = styled('img')(({ theme }) => ({
        position: 'absolute',
        zIndex: 700,
        bottom: '50%',
        left: '26%',
        [theme.breakpoints.down('md')]: {
            width: '29.72px',
            height: '32px',
        },
    }));

    const SemiCircleContainer = styled('div')(({ theme }) => ({
        position: 'relative',
        width: '100px',
        height: '50px',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            width: '64px',
            height: '32px',
        },
    }));

    return (
        <div data-testid="loadingSpinner">
            <SemiCircleContainer>
                <AnimatedTopSemiCircle>
                    <LowercaseELogoTop
                        src="lowercase-e-white.svg"
                        alt="rotating lowercase letter e"
                    />
                </AnimatedTopSemiCircle>
            </SemiCircleContainer>
            <SemiCircleContainer>
                <AnimatedBottomSemiCircle>
                    <LowercaseELogoBottom
                        src="lowercase-e-white.svg"
                        alt="rotating lowercase letter e"
                    />
                </AnimatedBottomSemiCircle>
            </SemiCircleContainer>
        </div>
    );
};
