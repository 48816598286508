import { UseQueryResult, useQuery } from 'react-query';
import { IUserIdRequest } from '../models/IUserIdRequest';
import { getUserSubscription } from '../services/subscriptionService';
import { SubscriptionResponse } from '../models/SubscriptionResponse';

export const GetUserSubscription = (
    userId: number | undefined
): UseQueryResult<SubscriptionResponse> => {
    const userString = String(userId);

    return useQuery(
        ['user-subscription'],
        () => getUserSubscription({ userId: userString } as IUserIdRequest),
        {
            enabled: !!userId,
            cacheTime: 120.0,
            refetchOnWindowFocus: false,
        }
    );
};
