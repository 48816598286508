import { CollectionAmplitudeState, ResponseAmplitudeState } from '../models/AmplitudeState';
import { ResourceCard } from '../models/CMECommonResponse';
import { executeLaunchAction } from '../services/launchActionService';
import { sendAmplitudeEvent } from '../amplitude/AmplitudeUtil';
import { CME_SERIES_PAGE_RESOURCE_LINK_CLICK } from '../amplitude/analytics';
import { OutlinedButton } from './StyledButtons';
import { useDispatch } from 'react-redux';
import { strings } from '../utils/Strings';
import { ShowErrorModal } from './Error/ShowErrorModal';

export function ResourceLinkCard({
    cardPayload,
    collectionAmplitudeData,
    cardIndex,
}: {
    cardPayload: ResourceCard;
    responseAmplitudeData?: ResponseAmplitudeState;
    collectionAmplitudeData?: CollectionAmplitudeState | null;
    cardIndex: number;
}) {
    const dispatch = useDispatch();

    const handleButtonClick = async () => {
        try {
            if (cardPayload.clickAction) {
                const action = await executeLaunchAction(cardPayload.clickAction);
                if (action.launched && collectionAmplitudeData) {
                    const simplifiedData = {
                        'Resource Link Number': cardIndex,
                        URL: action.url || 'N/A',
                    };

                    sendAmplitudeEvent(CME_SERIES_PAGE_RESOURCE_LINK_CLICK, simplifiedData);
                }
            }
        } catch (error) {
            ShowErrorModal(error, dispatch, strings.error_action);
        }
    };

    return (
        <div className="resources-container">
            {(cardPayload as ResourceCard).caption && (
                <OutlinedButton
                    width={'100%'}
                    type="button"
                    margin={'8px 0 0 0'}
                    onClick={handleButtonClick}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'space-between',
                            padding: '0 12px 0 12px',
                        }}
                    >
                        <div
                            style={{
                                minWidth: 0,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'horizontal',
                            }}
                        >
                            {cardPayload.caption?.plain}
                        </div>
                        <img
                            src="regular-keyboard-arrow-right.svg"
                            alt="right arrow icon"
                            style={{
                                width: '24px',
                                height: '24px',
                                objectFit: 'contain',
                            }}
                        />
                    </div>
                </OutlinedButton>
            )}
        </div>
    );
}
