import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CME_FRONTMATTER_CONTINUE_CLICK } from './analytics';

interface FrontMatterButtonClickData {
    url: string | undefined;
    activityId: string | undefined;
    title: string;
    creditAmount: number | string | undefined;
    duration: number | string | undefined;
    activityType: string | undefined;
    mediaType: string | undefined;
}

export const sendFrontMatterContinueClickEvent = ({
    url,
    activityId,
    title,
    creditAmount,
    duration,
    activityType,
    mediaType,
}: FrontMatterButtonClickData) =>
    sendAmplitudeEvent(CME_FRONTMATTER_CONTINUE_CLICK, {
        URL: url,
        'Activity ID': activityId,
        Title: title,
        'Credit Amount': creditAmount || '',
        'Duration in Minutes': duration || '',
        'Activity Type': activityType || '',
        'Media Type': mediaType || '',
    });
