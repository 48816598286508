import * as toolkit from '@reduxjs/toolkit';

export interface SaveAttempt {
    contentId: string;
}

export const initialState: SaveAttempt = {
    contentId: '',
};

export const saveAttemptSlice = toolkit.createSlice({
    name: 'saveAttempt',
    initialState,
    reducers: {
        updateSaveAttemptId: (state, action: toolkit.PayloadAction<string>) => {
            state.contentId = action.payload;
        },
    },
});

export const { updateSaveAttemptId } = saveAttemptSlice.actions;

const saveAttemptReducer = saveAttemptSlice.reducer;
export default saveAttemptReducer;
