import { useQuery, UseQueryResult } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogCardsResponse } from '../models/CatalogCardsResponse';
import { ICatalogCardsRequest } from '../models/ICatalogCardsRequest';
import { Tabs } from '../models/Tabs';
import { getSavedForLaterCards } from '../services/savedForLaterCardsService';
import { RootState } from '../store/store';
import { getCurrentUserId, isAuthenticatedUser } from '../utils/cookies';
import { GetCatalogCardsRequest } from '../utils/GetCatalogCardsRequest';
import { updateSortCriteria } from '../Slices/SortSlice';

export const GetSavedScreenActivities = (
    req?: ICatalogCardsRequest
): UseQueryResult<CatalogCardsResponse> => {
    const dispatch = useDispatch();
    let savedScreenRequest: ICatalogCardsRequest;
    savedScreenRequest = GetCatalogCardsRequest(req);

    let currentSourceTab: number = useSelector((state: RootState) => state.displayState.activeTab);
    return useQuery<CatalogCardsResponse>(
        ['savedForLaterCards', getCurrentUserId(), savedScreenRequest],
        () => getSavedForLaterCards(savedScreenRequest),
        {
            enabled: isAuthenticatedUser() && currentSourceTab === Tabs.SAVED,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            keepPreviousData: true,
            onSuccess: (data: CatalogCardsResponse) =>
                dispatch(updateSortCriteria(data.data.availableUserSortCriteria)),
        }
    );
};
