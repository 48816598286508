import { IQuizQuestionUserResponse } from '../models/QuizTransactionService';

export function transformReactHookFormData(data: any): IQuizQuestionUserResponse[] | {}[] {
    let responses = [];

    for (const key in data) {
        let transformedObject = {};
        let splitQuestionIdFromLabel = key.split('+');
        let writeInValue: string;
        let transformedChoices: any[] = [];

        if (data[key] && data[key].hasOwnProperty('writeInValue')) {
            writeInValue = data[key].writeInValue;
        }

        // transform choices array to add the writeInValue to the relevant answer option
        if (data[key] && data[key].hasOwnProperty('choices')) {
            transformedChoices = data[key].choices.map((choice: any) => {
                if (choice.hasWriteInValue) {
                    delete choice.hasWriteInValue;
                    choice = { ...choice, writeInValue: writeInValue };
                    return choice;
                } else {
                    return choice;
                }
            });
        }

        transformedObject = {
            questionId: splitQuestionIdFromLabel[0],
            questionLabel: splitQuestionIdFromLabel[1],
            ...(data[key] &&
                data[key].hasOwnProperty('choices') && { choices: transformedChoices }),
            ...(data[key] && data[key].hasOwnProperty('writeIn') && { writeIn: data[key].writeIn }),
            ...(data[key] && data[key].hasOwnProperty('nested') && { nested: data[key].nested }),
        };
        responses.push(transformedObject);
    }
    return responses;
}
