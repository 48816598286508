import { Box, Typography, styled } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import colors from '../../../config/colorConfig';
import { strings } from '../../../utils/Strings';

//
// VideoPlayerError displays an error message overlayed
// on a ReactPlayer video
//

const VideoErrorContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: colors['--pure-black'],
    zIndex: 4,
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '8px',
    },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    color: colors['--pure-white'],
    fontWeight: 500,
    fontSize: '18px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: '16px',
    },
}));

export function VideoPlayerError() {
    return (
        <VideoErrorContainer>
            <Box sx={{ width: '24px', height: '24px', marginRight: '8px' }}>
                <ErrorIcon
                    sx={{
                        color: colors['--pure-white'],
                    }}
                />
            </Box>
            <ErrorText>{strings.media_error}</ErrorText>
        </VideoErrorContainer>
    );
}
