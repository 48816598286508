import { NavigateFunction } from 'react-router';
import { Tabs } from '../../../models/Tabs';

interface ExitNavigationProps {
    persistedSourceTab: number;
    navigate: NavigateFunction;
}

export function HandleExitNavigation({ persistedSourceTab, navigate }: ExitNavigationProps) {
    const handleNavigation = () => {
        switch (persistedSourceTab) {
            case Tabs.DISCOVER:
                navigate('/cme/discover');
                break;
            case Tabs.CATALOG:
                navigate('/cme/catalog');
                break;
            case Tabs.SAVED:
                navigate('/cme/saved');
                break;
            default:
                navigate('/cme/catalog');
        }
    };
    handleNavigation();
}
