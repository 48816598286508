import { Box, styled } from '@mui/material';
import colors from '../../config/colorConfig';

export const FrontMatterContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    margin: '0 150px',
    fontFamily: 'Metric',
    lineHeight: '20px',
    paddingBottom: '80px',
    color: colors['--primary-midnight'],
    [theme.breakpoints.down('lg')]: {
        margin: '0 48px',
    },
    [theme.breakpoints.down('md')]: {
        margin: '0 16px',
    },
}));

export const LogoContainer = styled('div')({
    display: 'flex',
    margin: '24px 0',
    alignContent: 'center',
    justifyContent: 'center',
});

export const HeaderContainer = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors['--ui-grey'],
    color: colors['--pure-white'],
    padding: '8px',
    gap: 4,
});

export const FrontMatterDetailsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    },
}));

export const StyledHeading1 = styled('h1')(({ theme }) => ({
    textAlign: 'center',
    fontSize: '40px',
    fontWeight: 500,
    lineHeight: '48px',
    margin: 0,
    [theme.breakpoints.down('md')]: {
        fontSize: '24px',
        lineHeight: '32px',
    },
}));

export const StyledHeading2 = styled('h2')({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    margin: 0,
});

export const StyledHeading3 = styled('h3')({
    fontSize: '24px',
    fontWeight: 500,
    margin: 0,
});

export const ParagraphNoMargin = styled('p')({
    margin: 0,
});

export const UnorderedListNoMargin = styled('ul')({
    margin: 0,
});

export const OrderedListNoMargin = styled('ol')({
    margin: 0,
});

export const FrontmatterLoadingContainer = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const UnorderedListNoSpacingOrBullets = styled('ul')({
    listStyleType: 'none',
    margin: 0,
    padding: 0,
});

export const BoldedListItem = styled('li')({
    fontWeight: 500,
});

export const ImageAndTextCardImage = styled('img')({
    float: 'left',
    marginRight: '16px',
    display: 'block',
});

export const ImageAndTextCardContainer = styled('span')({
    marginBottom: '16px',
    '&:last-child': {
        marginBottom: '0',
    },
});
