import { Box, Link, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router';
import colors from '../../config/colorConfig';
import { theme } from '../../styles/theme';
import { sendAmplitudeEvent } from '../../amplitude/AmplitudeUtil';
import { CME_NAVIGATION_CLICK } from '../../amplitude/analytics';
import { Desktop } from '../../utils/responsive';
import { Tabs } from '../../models/Tabs';
import {
    updateActiveTab,
    updateIsSearchActive,
    updateIsSeriesActive,
    updateScrollPosition,
    updateSearchText,
} from '../../Slices/DisplaySlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { updateActiveFilters } from '../../Slices/FilterSlice';
import { updateSortCriteria, updateSelectedSortCriteria } from '../../Slices/SortSlice';
import { scrollToTop } from '../../utils/scrollToTop';
import config from '../../config/envConfig';

export interface NavigationTabProps {
    value: number;
}
interface NavigationButtonProps {
    label: string;
}
const WrapperDiv = styled('div')(() => ({
    border: 'none',
    '&:hover': {
        border: 'none',
    },
}));

const SelectedButtonStyle = styled('button')(() => ({
    border: 'none',
    height: 39,
    padding: '0px 0px 24px 0px',
    justifyContent: 'center',
    backgroundColor: 'unset',
    '&:focus': {
        outline: 'none !important',
    },
    '&:hover': {
        cursor: 'pointer',
    },
    '&:focus-visible': {
        border: '1px solid black',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
        width: 80,
    },
    [theme.breakpoints.up('xs')]: {
        height: 31,
        padding: '0px 0px 16px 0px ',
    },
    [theme.breakpoints.up('sm')]: {
        width: 91,
    },
    [theme.breakpoints.up('lg')]: {
        width: '100%',
        height: 'inherit',
        padding: '8px 16px',
        justifyContent: 'left',
        '&:focus': {
            outline: 'none !important',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors['--primary-off-white'],
        },
        borderLeft: `2px solid ${colors['--primary-midnight']}`,
    },
}));

const UnSelectedButtonStyle = styled('button')(() => ({
    border: 'none',
    height: 42,
    padding: '0px 0px 27px 0px',
    justifyContent: 'center',
    backgroundColor: 'unset',
    '&:focus': {
        outline: 'none !important',
    },
    '&:hover': {
        cursor: 'pointer',
    },
    '&:focus-visible': {
        border: '1px solid black',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
        width: 80,
    },
    [theme.breakpoints.up('xs')]: {
        height: 34,
        padding: '0px 0px 19px 0px ',
    },
    [theme.breakpoints.up('sm')]: {
        width: 91,
    },
    [theme.breakpoints.up('lg')]: {
        width: '100%',
        height: 'inherit',
        padding: '8px 16px',
        justifyContent: 'left',
        backgroundColor: colors['--pure-white'],
        '&:focus': {
            outline: 'none !important',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors['--primary-off-white'],
        },
        borderLeft: `2px solid ${colors['--pure-white']}`,
    },
}));

const SelectedTypographyStyle = styled(Typography)(() => ({
    fontFamily: 'Metric',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.94,
    letterSpacing: 'normal',
    color: colors['--primary-midnight'],
    [theme.breakpoints.up('lg')]: {
        lineHeight: 1.25,
        color: colors['--primary-midnight'],
        textAlign: 'left',
        fontSize: '18px',
    },
}));

const UnSelectedTypographyStyle = styled(Typography)(() => ({
    opacity: '0.5',
    fontFamily: 'Metric',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.94,
    letterSpacing: 'normal',
    color: colors['--primary-midnight'],
    [theme.breakpoints.up('lg')]: {
        opacity: '1.00',
        lineHeight: 1.25,
        color: colors['--ui-grey'],
        textAlign: 'left',
        fontSize: '18px',
    },
}));

export default function NavigationTabs(props: NavigationTabProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSeriesActive: boolean = useSelector(
        (state: RootState) => state.displayState.isSeriesActive
    );
    const { baseEpocratesUrl } = config.environment;

    const handleNavClick = (e: any) => {
        const discoverTitle = Tabs[0];
        const catalogTitle = Tabs[1];
        const savedTitle = Tabs[2];
        const myCME = Tabs[3];

        dispatch(updateScrollPosition(0));
        if (isSeriesActive) {
            dispatch(updateIsSeriesActive(false));
        }
        dispatch(updateIsSearchActive(false));
        dispatch(updateSearchText(''));
        dispatch(updateActiveFilters({ clearAll: true }));
        dispatch(updateSortCriteria([]));
        dispatch(updateSelectedSortCriteria(''));

        if (e.currentTarget.id == Tabs.DISCOVER) {
            dispatch(updateActiveTab(Tabs.DISCOVER));
            sendAmplitudeEvent(CME_NAVIGATION_CLICK, {
                'Event ID': 'taxo1000.0',
                Tab: discoverTitle,
            });
            navigate('/cme/discover');
        } else if (e.currentTarget.id == Tabs.CATALOG) {
            dispatch(updateActiveTab(Tabs.CATALOG));
            sendAmplitudeEvent(CME_NAVIGATION_CLICK, {
                'Event ID': 'taxo1000.0',
                Tab: catalogTitle,
            });
            navigate('/cme/catalog');
        } else if (e.currentTarget.id == Tabs.SAVED) {
            dispatch(updateActiveTab(Tabs.SAVED));
            sendAmplitudeEvent(CME_NAVIGATION_CLICK, { 'Event ID': 'taxo1000.0', Tab: savedTitle });
            navigate('/cme/saved');
        } else if (e.currentTarget.id == Tabs.MYCME) {
            dispatch(updateActiveTab(Tabs.MYCME));
            sendAmplitudeEvent(CME_NAVIGATION_CLICK, { 'Event ID': 'taxo1000.0', Tab: myCME });
        }
        scrollToTop();
    };

    const buttons: NavigationButtonProps[] = [
        { label: 'Discover' },
        { label: 'Catalog' },
        { label: 'Saved' },
        { label: 'My CME' },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'row', lg: 'column' },
                padding: { xs: 0, lg: '0 16px' },
                borderBottom: { xs: `1px solid ${colors['--ui-grey-med']}`, lg: 'none' },
                width: '100%',
                gap: { lg: '8px' },
            }}
        >
            {buttons.map(({ label }, index) =>
                props.value === index ? (
                    <WrapperDiv sx={{ display: !Desktop() ? 'inline' : 'inherit' }} key={index}>
                        <SelectedButtonStyle
                            id={index.toString()}
                            onClick={(e) => {
                                handleNavClick(e);
                            }}
                            aria-label={`${label} Tab`}
                        >
                            <SelectedTypographyStyle textTransform={'none'}>
                                {label}
                            </SelectedTypographyStyle>
                        </SelectedButtonStyle>
                        {!Desktop() && (
                            <div
                                style={{
                                    height: '3px',
                                    backgroundColor: colors['--primary-teal'],
                                }}
                            />
                        )}
                    </WrapperDiv>
                ) : (
                    <WrapperDiv sx={{ display: !Desktop() ? 'inline' : 'inherit' }} key={index}>
                        {label === 'My CME' ? (
                            <UnSelectedButtonStyle
                                id={index.toString()}
                                onClick={(e) => {
                                    handleNavClick(e);
                                }}
                                aria-label={`${label} Tab`}
                            >
                                <Link
                                    href={`${baseEpocratesUrl}/cme/transcript`}
                                    sx={{
                                        textDecoration: 'none',
                                        '&:hover': { textDecoration: 'none' },
                                    }}
                                >
                                    <UnSelectedTypographyStyle textTransform={'none'}>
                                        {label}
                                    </UnSelectedTypographyStyle>
                                </Link>
                            </UnSelectedButtonStyle>
                        ) : (
                            <UnSelectedButtonStyle
                                id={index.toString()}
                                onClick={(e) => {
                                    handleNavClick(e);
                                }}
                                aria-label={`${label} Tab`}
                            >
                                <UnSelectedTypographyStyle textTransform={'none'}>
                                    {label}
                                </UnSelectedTypographyStyle>
                            </UnSelectedButtonStyle>
                        )}
                    </WrapperDiv>
                )
            )}
        </Box>
    );
}
