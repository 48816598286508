import colors from '../config/colorConfig';
import { ElementHighlightMode } from '../models/QuizTransactionService';

export function setInputBorderColor(
    highlightMode: ElementHighlightMode | null | undefined
): string {
    switch (highlightMode) {
        case null:
            return colors['--ui-grey-med'];
        case undefined:
            return colors['--ui-grey-med'];
        case ElementHighlightMode.None:
            return colors['--ui-grey-med'];
        case ElementHighlightMode.Invalid:
            return colors['--color-alert-critical'];
        case ElementHighlightMode.Missed:
            return colors['--color-alert-critical'];
        default:
            return colors['--ui-grey-med'];
    }
}
