import { styled, Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import colors from '../../config/colorConfig';
import { updateModalOpen } from '../../Slices/ModalSlice';
import { sendModalCloseAmplitudeEvent } from '../../amplitude/ModalCloseEvent';
import { ModalContent } from './ModalContent';
import { strings } from '../../utils/Strings';
import { ErrorState, setError } from '../../Slices/ErrorSlice';

//
// Modal is a reusable component created from
// the Material UI Dialog component
//

const StyledDialog = styled(Dialog)({
    '& .cme-web-MuiPaper-root': {
        borderRadius: '3px',
        boxShadow:
            '0px 4px 6px rgba(0, 0, 0, 0.07), 0px 1px 12px rgba(0, 0, 0, 0.06), 0px 2px 6px -1px rgba(0, 0, 0, 0.1)',
        backgroundColor: colors['--pure-white'],
    },
});

const TitleCloseButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const CloseButton = styled('button')({
    padding: 0,
    border: 0,
    backgroundColor: colors['--pure-white'],
    outline: 'none',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyItems: 'center',
    alignContent: 'center',
    '&:focus': { outline: 'none' },
});

interface ModalProps {
    title?: string;
    isOpen?: boolean;
}

export default function Modal({ title, isOpen }: ModalProps) {
    const dispatch = useDispatch();

    const isModalOpen: boolean = useSelector((state: RootState) => state.modal.isOpen);

    const activeTab: number = useSelector((state: RootState) => state.displayState.activeTab);

    const error: string | unknown = useSelector((state: RootState) => state.error.message);

    const modalTitle: string = error ? strings.error_modal_title : strings.login_modal_title;

    const handleClose = () => {
        dispatch(updateModalOpen(false));
        // clear error state after modal closes
        if (!isModalOpen && error) {
            let nullErrorState: ErrorState = { message: null, statusCode: null };
            dispatch(setError(nullErrorState));
        }
        sendModalCloseAmplitudeEvent(activeTab, title || modalTitle);
    };

    return (
        <StyledDialog
            PaperProps={{ sx: { backgroundColor: colors['--scrim-scrim-50'] } }}
            onClose={handleClose}
            open={isOpen || isModalOpen}
            maxWidth="md"
            data-testid={error ? 'errorModal' : 'loginModal'}
        >
            <DialogTitle sx={{ m: 0, p: '24px 24px 32px 24px', lineHeight: '24px', width: '100%' }}>
                <TitleCloseButtonContainer>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        {error && (
                            <img
                                style={{ marginRight: '10px' }}
                                src="Error.svg"
                                alt="red error icon"
                            />
                        )}
                        {title || modalTitle}
                    </Box>
                    <CloseButton onClick={handleClose} data-testid="modalCloseButton">
                        <img src="Regular_close.svg" alt="Close button" />
                    </CloseButton>
                </TitleCloseButtonContainer>
            </DialogTitle>
            <DialogContent sx={{ m: { xs: '0 16px 16px 16px', md: '0 24px 40px 24px' }, p: '0' }}>
                <ModalContent />
            </DialogContent>
        </StyledDialog>
    );
}
