import { sendAmplitudeEvent } from './AmplitudeUtil';
import { CME_NO_SAVED_ACTIVITIES_VIEW, CME_SAVED_VIEW } from './analytics';

export const sendSavedViewAmplitudeEvent = (
    numberOfSavedActivities: number,
    isContextualLink: boolean
) => {
    sendAmplitudeEvent(CME_SAVED_VIEW, {
        'Number of Saved Activities': numberOfSavedActivities,
        ...(isContextualLink ? { Source: 'CONTEXTUAL LINK' } : {}),
    });
};

export const sendSavedNoActivitiesViewEvent = () => {
    return sendAmplitudeEvent(CME_NO_SAVED_ACTIVITIES_VIEW, {
        'Event ID': 'taxo999.0',
    });
};
