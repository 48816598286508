import { Grid } from '@mui/material';
import {
    NavigationIntent,
    IProgramOutlineEntry,
    IProgramGlobalInfo,
} from '../../models/EPOLMSModels';
import { ContainedButton } from '../StyledButtons';

//
// PrevContinueNavButtons is a presentational component providing
// styled navigation buttons to various EPOLMS components: EPOLMSContentSlide,
// EPOLMSCreditClaimSlide, EPOLMSFrontMatterSlide,EPOLMSQuizSlide, EPOLMSReviewSlide.
// This file may be removed when designs and styling are finalized.
//

export function PrevContinueNavButtons(
    performNavigationIntent: (
        intent: NavigationIntent,
        outlineEntry?: IProgramOutlineEntry | null | undefined,
        globalInfo?: IProgramGlobalInfo | null | undefined,
        buttonIndex?: number | null | undefined,
        buttonText?: string | null | undefined
    ) => void,
    slide: {
        previousButton?: any | null;
        continueButton?: any | null;
    },
    isDisabled: boolean,
    outlineEntry?: IProgramOutlineEntry,
    globalInfo?: IProgramGlobalInfo
) {
    return (
        <>
            <Grid>
                {slide.previousButton && (
                    <ContainedButton
                        width={'45%'}
                        type="button"
                        margin={'8px 8px 8px 8px'}
                        onClick={() => {
                            performNavigationIntent(
                                slide.previousButton!.intent,
                                outlineEntry,
                                globalInfo,
                                undefined,
                                slide.previousButton?.text.plain
                            );
                        }}
                        disabled={isDisabled}
                    >
                        {slide.previousButton.text.plain}
                    </ContainedButton>
                )}
                {slide.continueButton && (
                    <ContainedButton
                        width={'45%'}
                        type="button"
                        margin={'8px 8px 8px 8px'}
                        onClick={() => {
                            performNavigationIntent(
                                slide.continueButton!.intent,
                                outlineEntry,
                                globalInfo,
                                undefined,
                                slide.continueButton?.text.plain
                            );
                        }}
                    >
                        {slide.continueButton.text.plain}
                    </ContainedButton>
                )}
            </Grid>
        </>
    );
}
