import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { strings } from '../../../utils/Strings';
import { CreditOptionContainer } from './CreditOptionContainer';
import { StyledInputSubmit } from '../quiz/quizStyledComponents';
import { IFrontendCreditOption } from '../../../models/CreditTransactionService';

export interface CreditFormProps {
    creditOptions: IFrontendCreditOption[];
    handleSubmit: any;
    isConcluded: boolean;
    canSelectMultiple: boolean;
}

export interface CreditFormData {
    [key: string]: string | boolean;
}

export function CreditForm({
    creditOptions,
    handleSubmit,
    isConcluded,
    canSelectMultiple,
}: CreditFormProps) {
    // TODO? const defaultFormData = setDefaultFormData(creditOptions);
    const defaultFormData: any = {};
    for (const option of creditOptions) {
        if (option.quantity) {
            defaultFormData[option.quantity.fieldId] = '';
        }
        for (const field of option.additionalFields ?? []) {
            defaultFormData[field.fieldId] = field.userEnteredValue ?? '';
        }
        defaultFormData[option.creditOptionId + ':selected'] = !!option.userSelected;
    }
    const methods = useForm<CreditFormData>({
        defaultValues: defaultFormData,
    });

    const updateSelectedOption = (creditOption: IFrontendCreditOption, checked: boolean) => {
        // If it's a radio (one-hot) scheme and this option became checked, un-check all the other options.
        if (!canSelectMultiple && checked) {
            for (const iteratedOption of creditOptions) {
                if (iteratedOption.creditOptionId !== creditOption.creditOptionId) {
                    methods.setValue(iteratedOption.creditOptionId + ':selected', false);
                }
            }
        }
    };

    // wrap the form in react-hook-form FormProvider so all the forms'
    // children can access form context with useFormContext hook
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <Grid container direction="column" gap="32px">
                    {creditOptions.map((creditOption) => (
                        <CreditOptionContainer
                            key={creditOption.creditOptionId}
                            creditOption={creditOption}
                            isConcluded={isConcluded}
                            updatedSelectedOption={updateSelectedOption}
                            canSelectMultiple={canSelectMultiple}
                        />
                    ))}
                    <Grid item sx={{ maxWidth: '744px', textAlign: 'center' }}>
                        <StyledInputSubmit
                            type="submit"
                            value={
                                isConcluded
                                    ? strings.frontmatter_continue_text
                                    : strings.frontmatter_submit_text
                            }
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}
