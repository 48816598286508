import { Box, styled } from '@mui/material';
import { LoadingSpinner } from '../LoadingSpinner';

//
// FixedLoading spinner is a presentational component which displays
// the loading spinner in a fixed and centered position. The loading
// spinner appears above the content.
//

export function FixedLoadingSpinner() {
    const FixedLoadingContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        position: 'fixed',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '552px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '375px',
        },
        maxWidth: '1120px',
    }));

    return (
        <FixedLoadingContainer>
            <LoadingSpinner />
        </FixedLoadingContainer>
    );
}
